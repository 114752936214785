import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Radio, Space } from 'antd';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import PurchaseAndPayments from '../components/PurchaseAndPayments';
import SalesAndCollectionTables from '../components/SalesAndCollectionTables';
import { useLazyGetExcelDailyCollandPaymentQuery } from '../endpoints/totalCollectionPayments';

type Props = {};

export default function TotalCollectionPayments({}: Props) {
  const [value, setValue] = useState<'sales&collection' | 'sales&payments'>(
    'sales&collection'
  );

  const [fetchExcel] = useLazyGetExcelDailyCollandPaymentQuery();
  const refSales = useRef(null);
  const refPayment = useRef(null);
  const refSalesPurchase = useRef(null);
  const refSalesPayment = useRef(null);

  const cancel = (e: any) => {
    if (value === 'sales&collection') {
      //'Print Sales'
      handleSales();
    } else {
      //Print Purchase
      handleRefSalesPurchase();
    }
  };

  const confirm = (e: any) => {
    if (value === 'sales&collection') {
      //Print Collection
      handleRefPayment();
    } else {
      //Print Payments
      handleRefSalesPayment();
    }
  };

  const handleSales = useReactToPrint({
    content: () => refSales.current,
    pageStyle: () => {
      return `@page { size: a4; margin: none; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }`;
    },
  });
  const handleRefPayment = useReactToPrint({
    content: () => refPayment.current,
    pageStyle: () => {
      return `@page { size: a4; margin: none; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }`;
    },
  });
  const handleRefSalesPurchase = useReactToPrint({
    content: () => refSalesPurchase.current,
    pageStyle: () => {
      return `@page { size: a4; margin: none; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }`;
    },
  });
  const handleRefSalesPayment = useReactToPrint({
    content: () => refSalesPayment.current,
    pageStyle: () => {
      return `@page { size: a4; margin: none; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }`;
    },
  });

  return (
    <>
      <BreadCrumb
        arrOfOption={[
          'Reports',
          value === 'sales&collection'
            ? 'Sales & Collection'
            : 'Purchase & Payments',
        ]}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Popconfirm
          title={'Select Print Table'}
          icon={<PrinterOutlined />}
          description=' . '
          onConfirm={confirm}
          onCancel={cancel}
          cancelText={
            value === 'sales&collection' ? 'Print Sales' : 'Print Purchase'
          }
          okText={
            value === 'sales&collection' ? 'Print Collection' : 'Print Payments'
          }
          placement='bottom'
        >
          <Button type='primary'>
            <PrinterOutlined />
            Print
          </Button>
        </Popconfirm>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel(
              value === 'sales&collection'
                ? 'salesCollection'
                : 'purcheasePayment'
            );
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Space style={{ padding: '0px 10px' }}>
        <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
          <Radio.Button value='sales&collection'>
            Sales & Collection
          </Radio.Button>
          <Radio.Button value='sales&payments'>
            Purchase & Payments
          </Radio.Button>
        </Radio.Group>
      </Space>

      <div>
        {value === 'sales&collection' ? (
          <SalesAndCollectionTables
            value={value}
            refPayment={refPayment}
            refSales={refSales}
          />
        ) : (
          <PurchaseAndPayments
            value={value}
            refSalesPayment={refSalesPayment}
            refSalesPurchase={refSalesPurchase}
          />
        )}
      </div>
    </>
  );
}
