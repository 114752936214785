import { Button, Form, Row, Select, message } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setModal } from '../../../../common/slices/commonSlice';
import {
  FormInput,
  MobileInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import CountryName from '../../../../helpers/CountryName';
import { useCreateAgentProfileMutation } from '../api/endpoints/agentProfileEndPoints';
import { IAgentProfileCategoryAddInfo } from '../types/agentProfileTypes';

const AgentsAddModal = ({
  setInstanceAgentSelect,
  setClick,
}: {
  setClick?: (arg: boolean) => void;
  setInstanceAgentSelect?:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
}) => {
  const mobileChildren: React.ReactNode[] = [];
  for (let i = 0; i < CountryName.length; i++) {
    mobileChildren.push(
      <Select.Option
        key={CountryName[i].isoCode + '  ' + CountryName[i].dialCode}
      >
        {CountryName[i].isoCode + '  ' + CountryName[i].dialCode}
      </Select.Option>
    );
  }

  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  const [addAgentProfile, { isSuccess, data, isLoading, isError }] =
    useCreateAgentProfileMutation();
  const [form] = Form.useForm();

  const handleCraeteAgent = async (values: IAgentProfileCategoryAddInfo) => {
    const formData = new FormData();
    values.agent_name && formData.append('agent_name', values.agent_name);
    values.agent_email && formData.append('agent_email', values.agent_email);
    values.agent_mobile &&
      formData.append('agent_mobile', values.agent_mobile?.toString() || '');
    values.agent_commission_rate &&
      formData.append(
        'agent_commission_rate',
        values.agent_commission_rate?.toString() || ''
      );

    user?.user_id && formData.append('agent_created_by', String(user?.user_id));

    addAgentProfile(formData);
  };

  useEffect(() => {
    if (data) {
      setInstanceAgentSelect && setInstanceAgentSelect(Number(data?.data));
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setModal(false));
      setClick && setClick(false);
      form.resetFields();
    } else if (isError) {
      message.error('Something wrong!');
    }
  }, [isSuccess, isError]);

  return (
    <Form
      labelAlign='left'
      onFinish={handleCraeteAgent}
      form={form}
      validateMessages={validateMessages}
      layout='vertical'
    >
      <Row gutter={[5, 0]}>
        <FormInput
          name='agent_name'
          label='Name'
          size={12}
          smSize={12}
          mdSize={12}
          required
        />

        <NumberInput
          name='agent_commission_rate'
          label='Commision Rate'
          size={12}
          maxChar={2}
          required
        />

        <MobileInput
          label='Phone No'
          name='agent_mobile'
          rules={[
            {
              message: 'Invalid number format',
              validator: (_, value) => {
                if (
                  !value ||
                  (/^\+?[0-9+]+$/.test(value) &&
                    value.length >= 10 &&
                    value.length <= 16)
                ) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    'Invalid number format. Must be between 10 and 16 characters.'
                  );
                }
              },
            },
          ]}
          size={12}
        />

        <FormInput
          name='agent_email'
          label='Email'
          size={12}
          smSize={12}
          mdSize={12}
        />

        <Form.Item
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Submit
          </Button>
        </Form.Item>
      </Row>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default AgentsAddModal;
