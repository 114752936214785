import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  IMaharamEditFormData,
  IMaharamFormData,
  MaharamDataType,
} from '../types/MaharamTypes';

const MAHARAM = 'MAHARAM';
export const maharamEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all maharam
    getAllMaharam: build.query<HTTPResponse<MaharamDataType[]>, void>({
      query: () => ({ url: `/configuration/mahram/view-all` }),
      providesTags: () => [{ type: 'Maharam', id: MAHARAM }],
    }),

    //get all maharam with pagination
    getAllMaharamWithPagination: build.query<
      HTTPResponse<MaharamDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/mahram?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Maharam', id: MAHARAM }],
    }),

    //create maharam
    createMaharam: build.mutation<void, IMaharamFormData>({
      query: (body) => ({
        url: `/configuration/mahram/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Maharam', id: MAHARAM }],
    }),

    //edit maharam
    editMaharam: build.mutation<void, IMaharamEditFormData>({
      query: (body) => ({
        url: `/configuration/mahram/update/${body.maharam_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Maharam', id: MAHARAM }],
    }),

    //delete maharam

    deleteMaharam: build.mutation<void, MaharamDataType>({
      query: (body) => ({
        url: `/configuration/mahram/delete/${body.maharam_id}`,
        method: 'DELETE',
        body: { deleted_by: body.maharam_updated_by },
      }),
      invalidatesTags: () => [{ type: 'Maharam', id: MAHARAM }],
    }),
  }),
});

export const {
  useGetAllMaharamQuery,
  useCreateMaharamMutation,
  useEditMaharamMutation,
  useDeleteMaharamMutation,
  useGetAllMaharamWithPaginationQuery,
} = maharamEndpoints;
