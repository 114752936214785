import { Col, Form, InputNumber, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import {
  useLazyGetAllVendorsQuery,
  useLazyGetVendorDetailsQuery,
} from '../../vendor/api/endpoints/vendorEndpoints';

type Props = {
  show_balance?: boolean;
  setBalance?: (arg: number) => void;
  form?: FormInstance<any>;
  NewBalance?: number;
  disabled?: boolean;
};

function Select_Vendor({ show_balance, setBalance, form, disabled }: Props) {
  const [vendorID, setVendorID] = useState<number>();
  const [fetchVendor, { isLoading, data: vendor, isSuccess }] =
    useLazyGetAllVendorsQuery();

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      fetchVendor(value);
    }, 300);
  };

  useEffect(() => {
    fetchVendor('');
  }, []);

  const [fetchVendorByID, { data: singleVendor }] =
    useLazyGetVendorDetailsQuery();

  let balance = 0;

  if (singleVendor?.data?.vendor_lbalance) {
    if (Number(singleVendor.data.vendor_lbalance) < 0) {
      balance = Number(singleVendor.data.vendor_lbalance);
    } else {
      balance = Number(singleVendor.data.vendor_lbalance);
    }
  }

  useEffect(() => {
    if (vendorID) {
      fetchVendorByID(vendorID);
    }
    setVendorID(undefined);
  }, [vendorID]);

  useEffect(() => {
    setBalance && setBalance(balance);
  }, [singleVendor]);

  return (
    <>
      <Col span={6} xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: `Vendor is required!`,
            },
          ]}
          name='vendor_id'
          label='Vendor'
        >
          <Select
            placeholder={`Select Vendor`}
            loading={isLoading}
            onSearch={(value) => handleSearch(value)}
            showSearch
            disabled={disabled}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onSelect={(e: number) => {
              setVendorID(e);
            }}
            allowClear
          >
            {vendor &&
              vendor.data?.map((item, index) => {
                return (
                  <Select.Option value={item.vendor_id} key={index}>
                    {item.vendor_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>

      {show_balance && (
        <Col span={6} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            required
            name={'due_advance'}
            label={
              singleVendor?.data?.vendor_lbalance &&
              Number(singleVendor?.data?.vendor_lbalance) >= 0
                ? 'Present Balance: '
                : 'Present Due'
            }
          >
            <InputNumber
              disabled
              value={
                singleVendor?.data?.vendor_lbalance &&
                Number(singleVendor?.data?.vendor_lbalance) < 0
                  ? Number(singleVendor?.data?.vendor_lbalance) * -1
                  : singleVendor?.data?.vendor_lbalance
              }
              style={{
                width: '100%',
                borderColor:
                  balance !== undefined && balance > 0
                    ? 'green'
                    : balance == 0
                    ? 'black'
                    : 'red',
              }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
}

export default Select_Vendor;
