import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DAILYSUMMARY } from '../../Api/constants';
import { IDailySummaryDataType } from '../types/dailySummaryTypes';

export const dailySummaryEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all DailySummary
    getAllDailySummaryReport: build.query<
      HTTPResponse<IDailySummaryDataType>,
      {
        query: string;
      }
    >({
      query: ({ query }) => ({
        url: `/report/daily-summary${query}`,
      }),
      providesTags: [{ type: 'DailySummary', id: DAILYSUMMARY }],
    }),
  }),
});

export const { useLazyGetAllDailySummaryReportQuery } = dailySummaryEndpoints;
