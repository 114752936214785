import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { SALESMANCOMMISSIONREPORT } from '../../Api/constants';
import { ISalesManCommissionDataType } from '../types/salesManCommissionTypes';

export const salesManCommissionReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all SaleaManCommissionReport
    getSaleaManCommissionReport: build.query<
      HTTPResponse<ISalesManCommissionDataType>,
      {
        employee_id: string | number;
        query: string;
      }
    >({
      query: ({ employee_id, query }) => ({
        url: `/report/salesman-collection-due/${employee_id}${query}`,
      }),
      providesTags: [
        { type: 'SaleaManCommissionReport', id: SALESMANCOMMISSIONREPORT },
      ],
    }),
  }),
});

export const { useLazyGetSaleaManCommissionReportQuery } =
  salesManCommissionReportEndpoints;
