import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import InvoiceListHeader from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteInvoiceUmmrahMutation,
  useGetAllInvoiceUmmrahQuery,
} from '../../api/endpoints/invoiceUmrahEndpoints';
type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const InvoiceUmmrahLists = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [count, setCount] = useState<number>(0);
  const [isTrash, setIsTrash] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // LINKS
  const createLink = '/invoiceumrah/add';
  const viewLink = '/invoiceumrah/details';
  const editLink = '/invoiceumrah/edit';

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const [query, setQuery] = useState<string>('');
  const {
    data,
    isFetching,
    isLoading: isLoadingData,
    refetch: getAllInvoices,
  } = useGetAllInvoiceUmmrahQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );
  const allData = data?.data;

  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteInvoiceUmmrahMutation();

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Invoice deleted successfully.');
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    setCount(data?.count || 0);
    setDataSource(allData);
  }, [isFetching, isTrash, allData]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice', 'Ummrah']} />

      <InvoiceListHeader
        InvocieModalTitle='Invoice(Umrah)'
        data={data?.data}
        setIsTrash={setIsTrash}
        editLink={editLink}
        createLink={createLink}
        handleOnDelete={handleOnDelete}
        isTrash={isTrash}
        permission={permission}
        viewLink={viewLink}
        setDataSource={setDataSource}
        dataSource={dataSource}
        isLoading={isLoadingData}
        deleteLoading={isLoading}
        addMRPermission={addMRPermission}
        refetch={refetch}
        setCount={setCount}
        count={count}
        setQuery={setQuery}
        setDate={setDate}
        setSearch={setSearch}
        columnIndex={columnIndex}
      />
    </>
  );
};

export default InvoiceUmmrahLists;
