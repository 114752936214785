import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  IProductCategory,
  IProductEditFormData,
  IProductFormData,
  ProductDataType,
} from '../types/productTypes';

const PRODUCTS = 'PRODUCTS';

export const productEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all products
    getAllProducts: build.query<HTTPResponse<ProductDataType[]>, void>({
      query: () => ({ url: `/configuration/products/get-all` }),
      providesTags: () => [{ type: 'Products', id: PRODUCTS }],
    }),

    //get all products with pag
    getAllProductsWithPag: build.query<
      HTTPResponse<ProductDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/products?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Products', id: PRODUCTS }],
    }),

    //get all product categories
    getAllProductCategory: build.query<HTTPResponse<IProductCategory[]>, void>({
      query: () => ({ url: `/configuration/products/category/get-all` }),
    }),

    //create products
    createProducts: build.mutation<void, IProductFormData>({
      query: (body) => ({
        url: `/configuration/products/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Products', id: PRODUCTS }],
    }),

    //edit products

    editProducts: build.mutation<void, IProductEditFormData>({
      query: (body) => ({
        url: `/configuration/products/edit-product/${body.product_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Products', id: PRODUCTS }],
    }),

    //delete products
    deleteProduct: build.mutation<void, ProductDataType>({
      query: (body) => ({
        url: `/configuration/products/delete-product/${body.product_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),

      invalidatesTags: () => [{ type: 'Products', id: PRODUCTS }],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetAllProductCategoryQuery,
  useCreateProductsMutation,
  useEditProductsMutation,
  useDeleteProductMutation,
  useGetAllProductsWithPagQuery,
} = productEndpoints;
