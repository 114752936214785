import React from 'react';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  IAccommodationChildCreateDataType,
  IAccommodationGetChildDataType,
} from '../types/accommodationTypes';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { pagination } from '../../../../../common/utils/common.utils';

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: () => void;
  isModalOpen: boolean;
  vendors: IAccommodationGetChildDataType[];
};
const ViewVendors = ({ setIsModalOpen, isModalOpen, vendors }: Props) => {
  const searchColumnProps =
    useGetSearchColumnProps<IAccommodationGetChildDataType>();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const column: ColumnsType<IAccommodationGetChildDataType> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      ...searchColumnProps('vendor_name'),
    },
    {
      title: 'Cost Price',
      dataIndex: 'accmvendor_cost_price',
      key: 'accmvendor_cost_price',
      ...searchColumnProps('accmvendor_cost_price'),
    },
  ];

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title='Vendor Information'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          size='small'
          bordered
          pagination={pagination(vendors.length)}
          dataSource={vendors}
          columns={column}
          rowKey={(record) =>
            record.accmvendor_vendor_id + ' ' + record.accmvendor_cost_price
          }
        />
      </Modal>
    </>
  );
};

export default ViewVendors;
