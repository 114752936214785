import { Button, Col, Divider, Form, FormInstance, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { SelectRoomType } from '../../../../components/common/FormItem/SelectCustomFeilds';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';
import { InvoiceHajjType } from '../Types/InvoiceHajjTypes';
import { useWatch } from 'antd/es/form/Form';
import { useParams } from 'react-router';

type Props = {
  fields: FormListFieldData[];
  name: number;
  index: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  form: FormInstance<any>;
  edit_data?: InvoiceHajjType;
};

export default function HotelInfoSubCom({
  fields,
  name,
  index,
  add,
  remove,
  form,
  edit_data,
}: Props) {
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();

  const is_deleted: 0 | 1 = useWatch(
    ['hotel_information', index, 'is_deleted'],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      gutter={[10, 0]}
      align='middle'
    >
      <FormInputItem
        name={[name, 'hotel_name']}
        label='Hotel Name'
        size={5}
        disabled={disabled}
      />
      <SelectRoomType
        name={[name, 'hotel_room_type_id']}
        label='Room type'
        size={5}
        disabled={disabled}
      />
      <DateInput
        name={[name, 'hotel_check_in_date']}
        label='Check In Date'
        size={6}
        disabled={disabled}
      />

      <DateInput
        name={[name, 'hotel_check_out_date']}
        label='Check Out Date'
        size={5}
        disabled={disabled}
      />

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[index, 'is_deleted']} />

      {index === 0 ? (
        <Col lg={3}>
          <Form.Item label={' '}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={3}>
          <Form.Item label={' '}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'hotel_information',
                  index,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['hotel_information', index, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (
                    (edit_data?.hotel_information?.length ||
                      edit_data?.hotel_information?.length === 0) &&
                    index >= edit_data?.hotel_information?.length
                  )
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}
