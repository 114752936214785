import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { ILoanReportTypes } from '../types/loanReportTypes';

export const loanReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all AitReport
    getAllLoanReport: build.query<
      HTTPResponse<ILoanReportTypes[]>,
      {
        authority_id: number | string;
        loan_category_id: number | string;
        query: string;
      }
    >({
      query: ({ loan_category_id, authority_id, query }) => ({
        url: `/report/loan-report/${authority_id}/${loan_category_id}${query}`,
      }),
      providesTags: [{ type: 'LoanReport', id: 'LOANREPORT' }],
    }),
    getLoanExcel: build.query<
      any,
      {
        category: string;
        authority: string;
        query: string;
        excelName?: string;
        excelApiName: string;
      }
    >({
      query: ({ category, query, excelName, excelApiName, authority }) => ({
        url: `/report/${excelApiName}/${authority}/${category}${query}`,
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const { useLazyGetAllLoanReportQuery, useLazyGetLoanExcelQuery } =
  loanReportEndpoints;
