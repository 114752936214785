import { ACCOUNT_LIST, BALANCE_TRANSFER } from './../Constants/constants';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  IBalanceTransfer,
  IBalanceTransferFormData,
  IViewBalanceTransfer,
} from '../../AccountsTypes/AccountsTypes';
import { message } from 'antd';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';

export const balanceTransferEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all balance transfer
    getAllBalanceTransfer: build.query<
      HTTPResponse<IBalanceTransfer[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/accounts/balance-transfer?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [{ type: 'Balance_Transfer', id: BALANCE_TRANSFER }],
    }),

    //get view balance transfer
    getViewBalanceTransfer: build.query<
      HTTPResponse<IViewBalanceTransfer[]>,
      number
    >({
      query: (body) => ({ url: `/accounts/balance-transfer/${body}` }),
      providesTags: () => [{ type: 'Balance_Transfer', id: BALANCE_TRANSFER }],
    }),

    //get all trashed balance transfer
    getAllTrashedBalanceTransfer: build.query<
      HTTPResponse<IBalanceTransfer[]>,
      void
    >({
      query: () => ({ url: `/accounts/balance-transfer/trash` }),
      providesTags: () => [{ type: 'Balance_Transfer', id: BALANCE_TRANSFER }],
    }),

    //create balance transfer
    createBalanceTransfer: build.mutation<
      HTTPResponse<number>,
      IBalanceTransferFormData
    >({
      query: (body) => ({
        url: `/accounts/balance-transfer`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [
        { type: 'Balance_Transfer', id: BALANCE_TRANSFER },
        { type: 'Accounts', id: ACCOUNT_LIST },
      ],
    }),

    //delete balance transfer
    deleteBalanceTransfer: build.mutation<
      void,
      { btransfer_id: number; created_by: number }
    >({
      query: (body) => ({
        url: `/accounts/balance-transfer/${body.btransfer_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Balance Transfer Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Balance_Transfer', id: BALANCE_TRANSFER },
      ],
    }),

    //restore balance transfer
    restoreBalanceTransfer: build.mutation<
      void,
      { btransfer_id: number; created_by: number }
    >({
      query: (body) => ({
        url: `/accounts/restore-balance-transfers/${body.btransfer_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Balance Transfer Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Balance_Transfer', id: BALANCE_TRANSFER },
      ],
    }),
  }),
});

export const {
  useGetAllBalanceTransferQuery,
  useLazyGetAllTrashedBalanceTransferQuery,
  useCreateBalanceTransferMutation,
  useDeleteBalanceTransferMutation,
  useRestoreBalanceTransferMutation,
  useGetViewBalanceTransferQuery,
} = balanceTransferEndpoints;
