import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface IError {
  message: string;
  type: string;
}
interface IState {
  error: IError | null;
  isLoanModal: boolean;
  isEditTakingLoanModal: boolean;
  isEditGivingLoanModal: boolean;
  isAlreadyTakenLoanModal: boolean;
  isAlreadyGivenLoanModal: boolean;
  modalLoading: boolean;
  defaultLoading: boolean;
}

const initialState: IState = {
  error: null,
  isLoanModal: false,
  isEditTakingLoanModal: false,
  isEditGivingLoanModal: false,
  isAlreadyTakenLoanModal: false,
  isAlreadyGivenLoanModal: false,
  modalLoading: false,
  defaultLoading: false,
};

const loanModalSlice = createSlice({
  name: "loanModalSlice",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IError>) {
      state.error = action.payload;
    },
    setLoanModal(state, action: PayloadAction<boolean>) {
      state.isLoanModal = action.payload;
    },
    setEditTakingLoanModal(state, action: PayloadAction<boolean>) {
      state.isEditTakingLoanModal = action.payload;
    },
    setEditGivingLoanModal(state, action: PayloadAction<boolean>) {
      state.isEditGivingLoanModal = action.payload;
    },
    setEditAlreadyTakenLoanModal(state, action: PayloadAction<boolean>) {
      state.isAlreadyTakenLoanModal = action.payload;
    },
    setEditAlreadyGivenLoanModal(state, action: PayloadAction<boolean>) {
      state.isAlreadyGivenLoanModal = action.payload;
    },
  },
});

export const {
  setError,
  setLoanModal,
  setEditTakingLoanModal,
  setEditGivingLoanModal,
  setEditAlreadyTakenLoanModal,
  setEditAlreadyGivenLoanModal,
} = loanModalSlice.actions;

export const commonLoan = (state: RootState) => state.loanModalSlice;
export default loanModalSlice.reducer;
