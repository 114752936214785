import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { PAYROLLREPORT } from '../../Api/constants';
import { IPayrollReportsData } from '../types/payrollReportTypes';

export const PayrollReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayrollReport: build.query<
      HTTPResponse<IPayrollReportsData>,
      {
        payroll_id: string;
        query: string;
      }
    >({
      query: ({ query, payroll_id }) => ({
        url: `/report/payroll-report/${payroll_id}${query}`,
      }),
      providesTags: [{ type: 'PayrollReport', id: PAYROLLREPORT }],
    }),
  }),
});

export const { useLazyGetAllPayrollReportQuery } = PayrollReportEndpoints;
