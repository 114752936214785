import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import {
  IAccountClientBillAdjustment,
  IAccountClientBillAdjustmentFormData,
  IAccountSingleClientBillAdjustment,
} from '../../AccountsTypes/AccountsTypes';
import { CLIENT_BILL_ADJUSTMENT } from '../Constants/constants';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';

export const clientBillAdjustmentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all client_bill_adjustment
    getAllClientBillAdjustment: build.query<
      HTTPResponse<IAccountClientBillAdjustment[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/accounts/client-bill-adjustment?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [
        { type: 'Client_Bill_Adjustment', id: CLIENT_BILL_ADJUSTMENT },
      ],
    }),

    //get all trashed client_bill_adjustment
    getAllTrashedClientBillAdjustment: build.query<
      HTTPResponse<IAccountClientBillAdjustment[]>,
      void
    >({
      query: () => ({ url: `/accounts/client-bill/trashed` }),
      providesTags: () => [
        { type: 'Client_Bill_Adjustment', id: CLIENT_BILL_ADJUSTMENT },
      ],
    }),

    //get single client_bill_adjustment
    getSingleClientBillAdjustment: build.query<
      HTTPResponse<IAccountSingleClientBillAdjustment>,
      number
    >({
      query: (id) => ({ url: `/accounts/client-bill-adjustment/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Client_Bill_Adjustment', id: id } as const,
      ],
    }),

    //create client_bill_adjustment
    createClientBillAdjustment: build.mutation<
      void,
      IAccountClientBillAdjustmentFormData
    >({
      query: (body) => ({
        url: `/accounts/client-bill-adjustment`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Client Bill Adjustment Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Client_Bill_Adjustment', id: CLIENT_BILL_ADJUSTMENT },
      ],
    }),

    //delete client_bill_adjustment
    deleteClientBillAdjustment: build.mutation<
      void,
      { cbilladjust_id: number; deleted_by: number }
    >({
      query: (body) => ({
        url: `/accounts/client-bill-adjustment/${body.cbilladjust_id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Client Bill Adjustment Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Client_Bill_Adjustment', id: CLIENT_BILL_ADJUSTMENT },
      ],
    }),

    //restore client_bill_adjustment
    restoreClientBillAdjustment: build.mutation<
      void,
      { cbilladjust_id: number; created_by: number }
    >({
      query: (body) => ({
        url: `/accounts/client-bill-adjustment/${body.cbilladjust_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Client Bill Adjustment Restored Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Client_Bill_Adjustment', id: CLIENT_BILL_ADJUSTMENT },
      ],
    }),
  }),
});

export const {
  useGetAllClientBillAdjustmentQuery,
  useLazyGetAllClientBillAdjustmentQuery,
  useLazyGetAllTrashedClientBillAdjustmentQuery,
  useGetSingleClientBillAdjustmentQuery,
  useCreateClientBillAdjustmentMutation,
  useDeleteClientBillAdjustmentMutation,
  useRestoreClientBillAdjustmentMutation,
} = clientBillAdjustmentEndpoints;
