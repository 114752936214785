import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TOTALDUEADVANCECLIENTS } from '../../Api/constants';
import { ITotal_Due_Advance_ClientDataType } from '../types/Total_Due_Advance_ClientTypes';

export const total_due_advance_clientEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all total due_advance of clients
    clientDueAdvance: build.query<
      HTTPResponse<ITotal_Due_Advance_ClientDataType[]>,
      {
        client_id: string | number;
        payment_date: string;
        filter: string;
        current: number;
        pageSize: number;
      }
    >({
      query: ({ client_id, filter, payment_date, pageSize, current }) => ({
        url: `/report/client-advance-due/${client_id}?page=${current}&size=${pageSize}&payment_date=${payment_date}&filter=${filter}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceClients',
          id: TOTALDUEADVANCECLIENTS,
        },
      ],
    }),
    getClientByCategory: build.query<
      HTTPResponse<{ client_id: number; client_name: string }[]>,
      any
    >({
      query: (body) => ({
        url: `/report/client-by-category/${body}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceClients',
          id: TOTALDUEADVANCECLIENTS,
        },
      ],
    }),
  }),
});

export const { useClientDueAdvanceQuery, useLazyClientDueAdvanceQuery } =
  total_due_advance_clientEndpoints;
