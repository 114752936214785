import { Form, Input, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import CategorySelect from '../components/CategorySelect';
import { IProductFormData } from '../types/productTypes';
import { useCreateProductsMutation } from '../endpoints/productsEndpoints';
import { useEffect } from 'react';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const ProductAddModal = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [addProduct, { isError, isSuccess, isLoading }] =
    useCreateProductsMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Product Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IProductFormData) => {
    const body: IProductFormData = {
      ...values,
      product_created_by: user?.user_id as number,
    };
    await addProduct(body);
    dispatch(setModal(false));
    form.resetFields();
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout='horizontal'
      labelAlign='left'
      {...layout}
    >
      <Form.Item
        name='product_name'
        label='Product Name'
        rules={[
          { required: true, message: 'Please input product name!' },
          { max: 65, message: 'Maximum 65 characters' },
        ]}
      >
        <Input placeholder='Enter product name' />
      </Form.Item>

      <CategorySelect />

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default ProductAddModal;
