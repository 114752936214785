import { Col, Form, Select } from 'antd';

const ActivityLogOption = () => {
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={8}>
      <Form.Item name='activity_log' label='Activity Log' required>
        <Select allowClear placeholder='Select Activity Log'>
          <Select.Option key='1' value='Client Logs'>
            {'Client Logs'}
          </Select.Option>
          <Select.Option key='2' value='Vendor Logs'>
            {'Vendor Logs'}
          </Select.Option>
          <Select.Option key='3' value='Invoice Logs'>
            {'Invoice Logs'}
          </Select.Option>
        </Select>
      </Form.Item>
    </Col>
  );
};

export default ActivityLogOption;
