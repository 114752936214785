import { Button, Form, Input, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateCompaniesMutation } from '../Endpoints/companiesEndpoints';
import { ICompanyFormData } from '../types/companyTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const CompanyAddModal = ({
  companiesNameList,
}: {
  companiesNameList: string[];
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [addCompany, { isError, isSuccess, isLoading }] =
    useCreateCompaniesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Companies Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: ICompanyFormData) => {
    const body: ICompanyFormData = {
      ...values,
      company_created_by: user?.user_id as number,
    };
    await addCompany(body);
    dispatch(setModal(false));
    form.resetFields();
  };
  return (
    <Form
      layout='horizontal'
      form={form}
      labelAlign='left'
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Company Name'
        name='company_name'
        rules={[
          { required: true, message: 'Please enter company name!' },
          { max: 75, message: 'Maximum 65 characters' },
          {
            validator(_, value) {
              if (value) {
                if (companiesNameList.includes(value.trim())) {
                  return Promise.reject('Companies Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter company name' />
      </Form.Item>
      <Form.Item
        label='Contact Person'
        name='company_contact_person'
        rules={[{ max: 75, message: 'Maximum 65 characters' }]}
      >
        <Input placeholder='Enter company contact person' />
      </Form.Item>
      <Form.Item label='Designation' name='company_designation'>
        <Input placeholder='Enter company designation' />
      </Form.Item>
      <Form.Item
        label='Phone'
        name='company_phone'
        rules={[{ max: 20, message: 'Maximum 20 characters' }]}
      >
        <Input placeholder='Enter company phone' />
      </Form.Item>
      <Form.Item label='Address' name='company_address'>
        <TextArea placeholder='Enter company address' maxLength={55} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button type='primary' htmlType='submit' loading={isLoading}>
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default CompanyAddModal;
