import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, TimePicker } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useState, useEffect } from 'react';
import { FormInputItem } from '../../../components/common/FormItem/FormItems';
import { SelectTranportType } from '../../../components/common/FormItem/SelectCustomFeilds';
import { ITransportInfo } from '../Type/Invoce.other.interface';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';
import { useParams } from 'react-router';

type Props = {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  name: any;
  index: number;
  trans_info?: ITransportInfo[] | undefined;
};

function Transport_Info_List_Info({
  add,
  remove,
  form,
  name,
  index,
  trans_info,
}: Props) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const { id } = useParams();
  const transport_type_id = useWatch(
    ['transport_information', name, 'transport_type_id'],
    form
  );
  const transport_reference_no = useWatch(
    ['transport_information', name, 'transport_reference_no'],
    form
  );
  const transport_pickup_place = useWatch(
    ['transport_information', name, 'transport_pickup_place'],
    form
  );
  const transport_pickup_time = useWatch(
    ['transport_information', name, 'transport_pickup_time'],
    form
  );
  const transport_dropoff_place = useWatch(
    ['transport_information', name, 'transport_dropoff_place'],
    form
  );

  const transport_dropoff_time = useWatch(
    ['transport_information', name, 'transport_dropoff_time'],
    form
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      transport_type_id ||
      transport_reference_no ||
      transport_pickup_place ||
      transport_pickup_time ||
      transport_dropoff_place ||
      transport_dropoff_time
    ) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      timeoutId = setTimeout(() => {
        form.validateFields([
          ['transport_information', name, 'transport_type_id'],
          ['transport_information', name, 'transport_reference_no'],
          ['transport_information', name, 'transport_pickup_place'],
          ['transport_information', name, 'transport_pickup_time'],
          ['transport_information', name, 'transport_dropoff_place'],
          ['transport_information', name, 'transport_dropoff_time'],
        ]);
      });
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    transport_type_id,
    transport_reference_no,
    transport_pickup_place,
    transport_pickup_time,
    transport_dropoff_place,
    transport_dropoff_time,
  ]);

  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const transport_is_deleted: 0 | 1 = useWatch(
    ['transport_information', index, 'transport_is_deleted'],
    form
  );
  useEffect(() => {
    if (transport_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [transport_is_deleted]);
  return (
    <Row
      style={
        transport_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
            }
          : { display: 'flex', alignItems: 'center' }
      }
      gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
    >
      <SelectTranportType
        name={[name, 'transport_type_id']}
        label='Transport Type'
        size={4}
      />
      <FormInputItem
        name={[name, 'transport_reference_no']}
        label='Reference No	'
        size={4}
      />
      <FormInputItem
        name={[name, 'transport_pickup_place']}
        label='Pickup Place	'
        size={4}
      />

      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item
          name={[name, 'transport_pickup_time']}
          label='Time'
          colon={false}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <FormInputItem
        name={[name, 'transport_dropoff_place']}
        label='Drop Off Place	'
        size={4}
      />

      <Col xs={24} sm={12} md={8} lg={3}>
        <Form.Item
          label='Dropoff Time'
          name={[name, 'transport_dropoff_time']}
          colon={false}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      {/* TICKET DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[name, 'transport_is_deleted']} />
      {index === 0 ? (
        <Button type='primary' onClick={() => add()}>
          <PlusOutlined />
        </Button>
      ) : (
        <Form.Item label={false}>
          <Button
            type='primary'
            danger
            onClick={() => {
              const prev = form.getFieldValue([
                'transport_information',
                name,
                'ticket_is_deleted',
              ]);
              form.setFieldValue(
                ['transport_information', name, 'transport_is_deleted'],
                Number(!prev)
              );
              if (id) {
                if (trans_info?.length && index >= trans_info.length)
                  remove(name);
              } else {
                remove(name);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Form.Item>
      )}
    </Row>
  );
}

export default Transport_Info_List_Info;
