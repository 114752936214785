import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import { ICitiesGetDataType, ICitiesSendDataType } from '../types/citiesType';
const tourCitiesTag = 'cities-tour';
const CITIES = 'CITIES';
export const CitiesTourEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all cities
    getAllCitiess: build.query<HTTPResponse<ICitiesGetDataType[]>, void>({
      query: () => ({ url: `/tour-ittineray/cities/get-all` }),
      providesTags: () => [{ type: tourCitiesTag, id: CITIES }],
    }),

    //get all cities with pagination
    getAllCitiesWithPagination: build.query<
      HTTPResponse<ICitiesGetDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/tour-ittineray/cities?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: tourCitiesTag, id: CITIES }],
    }),

    //create Cities
    createTourCities: build.mutation<void, ICitiesSendDataType[]>({
      query: (body) => ({
        url: `/tour-ittineray/cities/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: tourCitiesTag, id: CITIES }],
    }),

    // // edit Cities
    editTourCities: build.mutation<
      void,
      { body: ICitiesGetDataType; id?: number | string }
    >({
      query: ({ body, id }) => ({
        url: `/tour-ittineray/cities/update/${id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: tourCitiesTag, id: CITIES }],
    }),

    //delete Cities
    deleteTourCities: build.mutation<void, ICitiesGetDataType>({
      query: (body) => ({
        url: `/tour-ittineray/cities/delete/${body.city_id}`,
        method: 'DELETE',
        body: { deleted_by: body.city_updated_by },
      }),
      invalidatesTags: () => [{ type: tourCitiesTag, id: CITIES }],
    }),
  }),
});
export const {
  useGetAllCitiessQuery,
  useCreateTourCitiesMutation,
  useDeleteTourCitiesMutation,
  useEditTourCitiesMutation,
  useGetAllCitiesWithPaginationQuery,
} = CitiesTourEndPoints;
