import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  ExpenseSubHeadDataType,
  IExpenseSubHeadEditFormData,
  IExpenseSubHeadFormData,
} from '../types/ExpenseSubHeadTypes';

const EXPENSESUBHEAD = 'EXPENSESUBHEAD';
export const expenseSubHeadEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all expense subhead
    getAllExpenseSubHead: build.query<
      HTTPResponse<ExpenseSubHeadDataType[]>,
      void
    >({
      query: () => ({ url: `/configuration/expense_Subhead/read` }),
      providesTags: () => [{ type: 'ExpenseSubHead', id: EXPENSESUBHEAD }],
    }),

    //get all expense subhead by head id
    getExpenseSubHeadByID: build.query<
      HTTPResponse<ExpenseSubHeadDataType[]>,
      number
    >({
      query: (id) => ({ url: `/configuration/expense_Subhead/read/${id}` }),
      providesTags: () => [{ type: 'ExpenseSubHead', id: EXPENSESUBHEAD }],
    }),

    //create expense subhead
    createExpenseSubHead: build.mutation<void, IExpenseSubHeadFormData>({
      query: (body) => ({
        url: `/configuration/expense_Subhead/create`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        toasterNotification('success', 'Expense Sub Head Added Successfully');
      },
      invalidatesTags: () => [{ type: 'ExpenseSubHead', id: EXPENSESUBHEAD }],
    }),

    //edit expense subhead
    editExpenseSubHead: build.mutation<void, IExpenseSubHeadEditFormData>({
      query: (body) => ({
        url: `/configuration/expense_Subhead/update/${body.subhead_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        toasterNotification('success', 'Expense Sub Head Edited Successfully');
      },

      invalidatesTags: () => [{ type: 'ExpenseSubHead', id: EXPENSESUBHEAD }],
    }),

    //delete expense subhead
    deleteExpenseSubhead: build.mutation<void, ExpenseSubHeadDataType>({
      query: (body) => ({
        url: `/configuration/expense_Subhead/delete/${body.subhead_id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async () => {
        toasterNotification('success', 'Expense Sub Head Deleted Successfully');
      },
      invalidatesTags: () => [{ type: 'ExpenseSubHead', id: EXPENSESUBHEAD }],
    }),
  }),
});

export const {
  useGetAllExpenseSubHeadQuery,
  useGetExpenseSubHeadByIDQuery,
  useCreateExpenseSubHeadMutation,
  useEditExpenseSubHeadMutation,
  useDeleteExpenseSubheadMutation,
} = expenseSubHeadEndpoints;
