import { Button, Col, Form, Row, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../components/common/FormItem/FormItems';
import {
  useEditAdvanceReturnMutation,
  useLazyGetAdvanceReturnForEditQuery,
} from '../api/endpoints/advanceEndpoints';
import PayMethodAndAcc from '../components/common/PayMethodAndAcc';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import { SelectVendors } from '../../../components/common/FormItem/SelectCustomFeilds';
export const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 },
};
type Props = {};
const AdvanceReturnEdit = (props: Props) => {
  const id = useParams().id;
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [venodrAmount, setVendorAmount] = useState<number>(0);
  const [vendorId, setVendorId] = useState<number>();
  const [payMethodId, setPayMethodId] = useState<number>(1);
  const [accountsBalanced, setAccountBalanced] = useState<number>();

  const accountId = useWatch('account_id', form);
  useEffect(() => {
    form.setFieldsValue({
      vpay_availeable_balance: accountsBalanced,
    });
  }, [accountsBalanced]);

  const [getAdvanceReturnForEdit, { data, isLoading: advanceReturnFetching }] =
    useLazyGetAdvanceReturnForEditQuery();
  const advanceReturn = data?.data;

  const availableBalance = form.getFieldValue('vpay_availeable_balance');

  useEffect(() => {
    if (id) {
      getAdvanceReturnForEdit(Number(id));
    }
  }, [id]);

  useEffect(() => {
    setVendorId(advanceReturn?.advr_vendor_id as number);
    setPayMethodId(advanceReturn?.advr_payment_type as number);
    form.setFieldsValue({
      comb_vendor: advanceReturn?.comb_vendor,
      vpay_present_due: Math.abs(venodrAmount),
      vendor_id: advanceReturn?.advr_vendor_id,
      advr_payment_type: advanceReturn?.advr_payment_type,
      account_id: advanceReturn?.advr_account_id,
      date: dayjs(advanceReturn?.return_date),
      cheque_no: advanceReturn?.cheque_number,
      bank_name: advanceReturn?.bank_name,
      advance_amount: advanceReturn?.advr_amount,
      note: advanceReturn?.advr_note,
      trans_no: advanceReturn?.trans_no,
      transaction_charge: advanceReturn?.transaction_charge,
      vpcheque_withdraw_date: dayjs(advanceReturn?.vpcheque_withdraw_date),
    });
  }, [venodrAmount, advanceReturnFetching, advanceReturn]);

  // handle submit
  const [updateAdvanceReturn, { isError, isLoading, isSuccess, error }] =
    useEditAdvanceReturnMutation();

  const onFinish = async () => {
    const values = form.getFieldsValue();

    values.date = values.date?.format('YYYY-MM-DD');
    values.advr_created_by = user?.user_id;
    values.advr_id = advanceReturn?.advr_id;

    await updateAdvanceReturn(values);
  };

  // ================== notification =========================
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor advance returned updated!');
      navigate('/vendors/advance');
    }
    if (isError) {
      message.error('Vendor advance return can not update now!');
    }
  }, [isLoading]);

  return (
    <Style>
      <BreadCrumb arrOfOption={['Return Advance Payment']} />
      <Link to='/vendors/advance'>
        <Button
          type='primary'
          style={{ marginBottom: '0.5rem' }}
        >
          <ArrowLeftOutlined />
          List of Advance Return
        </Button>
      </Link>

      <div className='border p-10'>
        <Form
          form={form}
          onFinish={onFinish}
          layout='horizontal'
          {...layout}
          labelAlign='left'
        >
          <Row
            gutter={[16, 16]}
            justify='space-between'
          >
            <Col span={11}>
              <SelectVendors
                name='comb_vendor'
                label='Select vendor'
                size={24}
                disabled={id ? true : false}
              />
              <PayMethodAndAcc
                payMethodId={payMethodId}
                setPayMethodId={setPayMethodId}
                size={24}
                setAccountBalanced={setAccountBalanced}
                accountId={accountId}
                showAvailableBalanceRightSide
                form={form}
              />
              <DateInput
                name='date'
                label='Return date'
                required
                size={24}
                mdSize={24}
                smSize={24}
              />
            </Col>

            <Col span={11}>
              {Number(payMethodId) !== 4 && (
                <NumberInput
                  label='Available Balance'
                  name='vpay_availeable_balance'
                  color={undefined}
                  readOnly
                  min='0'
                  size={24}
                  maxChar={14}
                  minChar={0}
                />
              )}
              <NumberInput
                maxAmount={Number(availableBalance) + 1}
                label='Advance amount'
                name='advance_amount'
                required
                size={24}
                mdSize={24}
                smSize={24}
                min='0'
              />

              <TextAreaInput
                label='Return note'
                name='note'
                size={24}
              />
              <FormButton
                label='Return Advance Payment'
                size={24}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Style>
  );
};

export default AdvanceReturnEdit;

const Style = styled.div``;
