import { Col, Form, InputNumber, Select } from 'antd';
import { useEffect, useState } from 'react';
import {
  IAllAccountType,
  ITransferableAccount,
} from '../AccountsTypes/AccountsTypes';
import {
  useGetAllAccountsQuery,
  useGetAllTrsAccQuery,
} from '../Api/Endpoints/accountsEndpoints';

type Props = {
  placeholder?: string;
  required?: boolean;
  setLastBalance?: React.Dispatch<React.SetStateAction<number | undefined>>; // THIS IS ONLY FOR SEND LAST BALANCE TO PARENT
};
const Select_Account = ({ required, placeholder, setLastBalance }: Props) => {
  const [id_AccountFrom, setId_AccountFrom] = useState<number>();
  const [last_Balance, setLast_Balance] = useState<number>();

  const [selectedAccountId, setSelectedAccountId] = useState<
    number | undefined
  >(undefined);

  const { data: transferableAccounts } = useGetAllTrsAccQuery();
  const { data: allAccounts } = useGetAllAccountsQuery();

  const onAccountFromSelect = (value: string) => {
    const index = value.split('/');

    setSelectedAccountId(Number(index[1]));
    setId_AccountFrom(Number(index[0]));
  };

  const filteredAccounts = selectedAccountId
    ? allAccounts?.data?.filter(
        (account) => account.account_id !== selectedAccountId
      )
    : [];

  useEffect(() => {
    if (transferableAccounts?.data && id_AccountFrom != undefined) {
      const accbalance =
        transferableAccounts.data[id_AccountFrom as number].accbalance_amount;
      setLast_Balance(accbalance);
      setLastBalance && setLastBalance(accbalance);
    }
  }, [id_AccountFrom]);

  return (
    <>
      <Col span={8} xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          name='transfer_from_id'
          label='Transfer From'
          rules={[
            {
              required: required || false,
              message: `Transfer From is required!`,
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            placeholder={placeholder}
            optionFilterProp='children'
            onSelect={onAccountFromSelect}
          >
            {transferableAccounts?.data?.map(
              (item: ITransferableAccount, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={index + '/' + item.account_id}
                  >
                    {`${item.account_name}`}
                  </Select.Option>
                );
              }
            )}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8} xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          label='Balance'
          // name={'balance'}
          rules={[{ required: true }]}
        >
          <InputNumber
            readOnly
            value={last_Balance}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>

      <Col span={8} xs={24} sm={24} md={24} lg={12}>
        <Form.Item
          name='transfer_to_id'
          label='Transfer To'
          rules={[
            {
              required: required || false,
              message: `transfer_to_id is required!`,
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            placeholder='Select Transfer To Account'
            optionFilterProp='children'
          >
            {filteredAccounts?.map((item: IAllAccountType, index) => {
              return (
                <Select.Option key={index} value={item.account_id}>
                  {`${item.account_name}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default Select_Account;
