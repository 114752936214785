import { MessageOutlined } from '@ant-design/icons';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { Button, Popconfirm, Space, Switch, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import {
  HTTPResponse,
  IPermission,
  ISetState,
  crud,
} from '../../../../../common/types/commonTypes';
import { useDeleteClientMutation } from '../../api/endpoints/clientEndpoints';
import { ClientDataType } from '../../types/clientInterfaces';

type TActivate = MutationTrigger<
  MutationDefinition<
    {
      client_id: number;
      status: 'active' | 'inactive';
      created_by: number;
    },
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
    string,
    HTTPResponse<void>,
    'AccountsApi'
  >
>;

type TClientColumns = {
  setEditInfo: ISetState<ClientDataType | null>;
  showModal: () => void;
  activate: TActivate;
  loading: boolean;
  permission?: IPermission;
  checkButtonState?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const viewClientcolumns = ({
  activate,
  loading,
  setEditInfo,
  showModal,
  permission,
  pagination,
}: TClientColumns): ColumnsType<ClientDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [changeStateId, setChangeStateId] = useState<number>();

  const handleClick = (record: ClientDataType) => {
    setEditInfo(record);
  };
  const user = useAppSelector(selectUser);
  const [deleteClient, { isLoading, isSuccess, isError }] =
    useDeleteClientMutation();

  const handleClientDelete = async (values: any, index: number) => {
    setColumnIndex(index);
    await deleteClient({
      ...values,
      client_updated_by: user?.user_id,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  return [
    {
      title: 'Sl.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Category',
      dataIndex: 'category_prefix',
      key: 'category_prefix',
    },
    {
      title: 'Reference',
      dataIndex: 'client_entry_id',
      key: 'client_entry_id',
    },
    {
      title: 'Client Type',
      dataIndex: 'client_type',
      key: 'client_type',
    },
    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'client_name',

      render: (_, { client_name, client_id }) => {
        return (
          <Link to={`/reports/client_ledger?client_id=${client_id}`}>
            {client_name}
          </Link>
        );
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Present Due/Advance',
      dataIndex: 'client_last_balance',
      key: 'client_last_balance',

      render: (_, record) => {
        const { client_last_balance } = record;

        return (
          <>
            {client_last_balance != undefined && client_last_balance > 0 && (
              <>
                <div>
                  Advance :
                  <span style={{ color: 'green' }}>
                    {Number(client_last_balance)}
                  </span>
                </div>
              </>
            )}
            {client_last_balance == 0 &&
              'Balance : ' + Number(client_last_balance)}
            {client_last_balance != undefined && client_last_balance < 0 && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: 'red' }}>
                  Due : {client_last_balance * -1}
                </span>
                <Button
                  type='text'
                  size='small'
                  onClick={() => {
                    handleClick(record);
                    showModal();
                  }}
                >
                  <MessageOutlined />
                </Button>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Status',
      dataIndex: 'client_activity_status',
      key: 'client_activity_status',

      render: (_, { client_activity_status, client_id }) => (
        <>
          <Popconfirm
            title={`${
              client_activity_status ? 'Sure to Inactive?' : 'Sure to active?'
            }`}
            onConfirm={() => {
              setChangeStateId(client_id);
              activate({
                client_id,
                status: client_activity_status === 0 ? 'active' : 'inactive',
                created_by: user?.user_id as number,
              });
            }}
          >
            <Switch
              loading={changeStateId === client_id && loading}
              style={{
                backgroundColor: client_activity_status ? 'green' : '#ff4d4f',
              }}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
              checked={client_activity_status ? true : false}
            />
          </Popconfirm>
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={`/clients/details/client-${record.client_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.update] && (
            <Link
              to={`/clients/edit/client-${record.client_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>Edit </Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.delete] && record.trxn_count == 0 ? (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleClientDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                <Typography style={{ color: '#fff' }}>Delete </Typography>
              </Button>
            </Popconfirm>
          ) : (
            <Button disabled danger size='small' type='primary'>
              <Typography>Delete </Typography>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
