import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import {
  SelectCity,
  SelectCountry,
  SelectRoomType,
  SelectVendors,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';

const AccommodationInfo = () => {
  const [getCityByCId, setCityByCId] = useState<number>(0);
  const handlechange = (e: number) => {
    setCityByCId(e);
  };
  return (
    <>
      <Form.List
        name='accommodation_info'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <React.Fragment key={index}>
                <Card style={{ marginBottom: '5px' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                    <SelectCountry
                      label='Country'
                      required
                      onChange={(e) => handlechange(Number(e))}
                      name={[field.name, 'accommodation_country_id']}
                      size={5}
                      smSize={6}
                      mdSize={8}
                    />

                    <SelectCity
                      required
                      label='City'
                      getCityByCId={getCityByCId}
                      name={[field.name, 'accommodation_city_id']}
                      size={5}
                      smSize={12}
                      mdSize={8}
                    />

                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={3}
                    >
                      <Form.Item
                        label='Hotel Name'
                        name={[field.name, 'accommodation_hotel_name']}
                        rules={[
                          {
                            required: true,
                            message: 'Hotel Name is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <SelectRoomType
                      required
                      label='Room Type'
                      name={[field.name, 'accommodation_room_type_id']}
                      size={5}
                      mdSize={8}
                    />

                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={3}
                    >
                      <Form.Item
                        label='Pax'
                        name={[field.name, 'accommodation_pax_name']}
                        rules={[
                          {
                            required: true,
                            message: 'Pax is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={2}
                    >
                      <Form.Item label='Action'>
                        {index === 0 ? (
                          <Button
                            type='primary'
                            onClick={() => add()}
                          >
                            <PlusOutlined />
                            Add More
                          </Button>
                        ) : (
                          <Button
                            type='primary'
                            danger
                            onClick={() => remove(field.name)}
                          >
                            <MinusOutlined />
                            Remove
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <>
                    {/* ****************************************** */}
                    <>
                      <Form.List
                        name={[field.name, 'vendors']}
                        initialValue={[{}]}
                      >
                        {(fieldsz, { add, remove }) =>
                          fieldsz.map((fieldz, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Row
                                  key={index}
                                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                                >
                                  <SelectVendors
                                    label='Vendor'
                                    size={5}
                                    required
                                    name={[fieldz.name, 'accmvendor_vendor_id']}
                                  />

                                  <Col
                                    xs={24}
                                    sm={12}
                                    md={12}
                                    lg={8}
                                  >
                                    <Form.Item
                                      label='Cost Price'
                                      name={[
                                        fieldz.name,
                                        'accmvendor_cost_price',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Cost Price is required',
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={6}
                                  >
                                    <Form.Item label='Action'>
                                      {index === 0 ? (
                                        <Button
                                          type='primary'
                                          onClick={() => add()}
                                        >
                                          <PlusOutlined />
                                          Add More
                                        </Button>
                                      ) : (
                                        <Button
                                          type='primary'
                                          danger
                                          onClick={() => remove(fieldz.name)}
                                        >
                                          <MinusOutlined />
                                          Remove
                                        </Button>
                                      )}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            );
                          })
                        }
                      </Form.List>
                    </>
                  </>
                </Card>
              </React.Fragment>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default AccommodationInfo;
