import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Row, Typography, theme } from 'antd';
import { Col } from 'antd/lib/grid';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  ReceiptHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import { FormatDatePrint } from '../../../../common/utils/common.utils';
import { invoiceViewStyle } from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { Container } from '../../Components/MoneyReceiptDesign';
import { useGetAdvrDataForEditQuery } from '../../api/endpoints/moneyReceiptEndpoints';

type Props = {};

const AdvanceMoneyReturnView = (props: Props) => {
  const { id } = useParams();
  const componentRef = useRef(null);

  const { data } = useGetAdvrDataForEditQuery(Number(id));
  const receipt_data = data?.data;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,

    documentTitle: `money-receipt-advance-return  -${
      receipt_data &&
      `${receipt_data.advr_vouchar_no}-${receipt_data.client_name} ${dayjs(
        receipt_data.advr_created_by
      ).format('DD-MM-YYYY')}`
    }`,
  });

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <>
      <BreadCrumb arrOfOption={['Advance Return Details']} />
      <Row style={{ gap: 10 }}>
        <Col style={{ marginBottom: '1rem' }}>
          <Link to={'/moneyreceipt/advance'}>
            <Button type='primary' icon={<ArrowLeftOutlined />}>
              Return List
            </Button>
          </Link>
        </Col>

        <Col>
          <Button onClick={handlePrint} type='primary'>
            <PrinterOutlined />
            Print
          </Button>
        </Col>
      </Row>

      <div style={invoiceViewStyle}>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <Container
            style={{
              width: '8.27in',
              margin: '0 auto',
              background: '#fff',
              padding: '14px',
            }}
          >
            <div ref={componentRef} style={{ padding: '0.3in' }}>
              <header>
                <Row justify={'space-between'} align='middle'>
                  <ReceiptHeader />
                </Row>

                <TitleCenter title='advance return' />
              </header>

              <Row justify={'space-between'} style={{ margin: '30px 0' }}>
                <Typography.Text
                  style={{
                    fontSize: '14px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Voucher No: {receipt_data?.advr_vouchar_no}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: '14px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  Date :{' '}
                  {receipt_data?.advr_payment_date
                    ? FormatDatePrint(receipt_data?.advr_payment_date)
                    : ''}
                </Typography.Text>
              </Row>

              <section>
                <Row justify={'space-between'}>
                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      Payment with thanks from:
                      <span
                        style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                        className='underline'
                      >
                        {receipt_data?.client_name}
                      </span>
                    </Typography.Text>

                    <Row
                      justify={'space-between'}
                      style={{ marginTop: '10px' }}
                    >
                      <Typography.Text
                        style={{
                          fontSize: '14px',
                          fontFamily: "'Source Sans Pro', sans-serif",
                        }}
                      >
                        For the purpose of :{' '}
                        <span className='underline'>
                          {' '}
                          {receipt_data?.advr_note}{' '}
                        </span>
                      </Typography.Text>
                    </Row>
                  </Col>

                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      Payment Amount :{' '}
                      <span className='underline'>
                        {' '}
                        {receipt_data?.advr_amount}
                      </span>
                    </Typography.Text>

                    <Typography.Text
                      style={{
                        fontSize: '14px',
                        display: 'block',
                        marginTop: '10px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <span className='underline'>
                        {receipt_data?.advr_amount && (
                          <NumToWord
                            number={Number(receipt_data?.advr_amount)}
                          />
                        )}
                      </span>
                    </Typography.Text>
                  </Col>
                </Row>
              </section>

              <Row
                style={{ marginTop: 50 }}
                justify='space-between'
                align={'middle'}
              >
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      background: 'black',
                      margin: '0px',
                      width: '150px',
                      height: '1px',
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Customer Signature
                  </Typography.Text>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      background: 'black',
                      margin: '0px',
                      width: '150px',
                      height: '1px',
                    }}
                  />
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Authority Signature
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </Container>
        </ConfigProvider>
      </div>
    </>
  );
};

export default AdvanceMoneyReturnView;
