import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useGetSingleVendorBillAdjustmentQuery } from '../../Api/Endpoints/vendorBillAdjustmentEndpoints';
import dayjs from 'dayjs';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};

const Vendor_Bill_Adjustment_View = (props: Props) => {
  const { id } = useParams();

  const { isLoading, data: vendorBillView } =
    useGetSingleVendorBillAdjustmentQuery(Number(id));
  return (
    <Style>
      <BreadCrumb arrOfOption={['Vendor Bill Adjustment Details']} />
      <Link to='/accounts/bill_adjustment'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Vendor Bill Adjustement
        </Button>
      </Link>

      <Card title='Vendor Bill Adjustment Details'>
        <Descriptions size='small' bordered column={1}>
          <DescriptionsItem label='Client Name :'>
            {vendorBillView?.data?.vendor_name}
          </DescriptionsItem>

          <DescriptionsItem label='Adjustment Type :	'>
            {vendorBillView?.data?.vbilladjust_type}
          </DescriptionsItem>
          <DescriptionsItem label='Amount :	'>
            {Fixed2(vendorBillView?.data?.vbilladjust_amount)}
          </DescriptionsItem>
          <DescriptionsItem label='Payment date :'>
            {vendorBillView?.data?.vbilladjust_create_date &&
              dayjs(vendorBillView?.data?.vbilladjust_create_date).format(
                'MM-DD-YYYY'
              )}
          </DescriptionsItem>
          <DescriptionsItem label='Payment Note :'>
            {vendorBillView?.data?.vbilladjust_note}
          </DescriptionsItem>
        </Descriptions>
      </Card>
      {isLoading && loadingIndicator}
    </Style>
  );
};

export default Vendor_Bill_Adjustment_View;
const Style = styled.div``;
