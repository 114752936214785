import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  theme,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Fixed2, formatAmount } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { portraitPageStyle } from '../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader';
import { a4sizeStyle } from '../../../Loan_Management/Pages/Loan/ViewLoan';
import { invoiceViewTitleStyle } from '../../../Money_Receipt/Components/MoneyReceiptDesign';
import { useGetTaxRefundViewQuery } from '../../Api/Endpoints/AirTicketTaxRefundEndpoints';
import ViewRefundHeader, {
  ViewRefundFooter,
} from '../../Components/ViewRefundHeader';
import { flexBetween } from '../Airticket/AirticketRefundView';

type Props = {};

const AirTicketTax_View = (props: Props) => {
  const { id } = useParams();

  const { data, isLoading } = useGetTaxRefundViewQuery(id!, { skip: !id });
  const details = data?.data;

  const componentRefClient = useRef(null);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `Invoice Tax Refund - ${dayjs()}`,
    pageStyle: portraitPageStyle,
  });

  const columns: ColumnsType<ITaxRefundTicketInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <>{index + 1}</>,
    },

    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },

    {
      title: 'Ticket No',
      key: 'airticket_ticket_no',
      dataIndex: 'airticket_ticket_no',
    },

    {
      title: 'Refund Tax',
      key: 'refund_tax_amount',
      dataIndex: 'refund_tax_amount',
      render: (curr) => formatAmount(curr),
      align: 'right',
    },
  ];
  return (
    <div>
      <BreadCrumb arrOfOption={['Dashboard', 'Refund', 'View Tax Refund']} />

      <Space>
        <Link to={'/refund/history/tax'}>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return To Tax Refund List
          </Button>
        </Link>
      </Space>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: '20px 0',
        }}
      >
        <div>
          <div style={flexBetween}>
            <Button type='primary' onClick={handleClientPrint}>
              <PrinterOutlined />
              Print
            </Button>
          </div>

          <ConfigProvider
            theme={{
              algorithm: theme.defaultAlgorithm,
            }}
          >
            <Card style={a4sizeStyle}>
              <div ref={componentRefClient}>
                <ViewRefundHeader />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography.Title level={5} style={invoiceViewTitleStyle}>
                    Refund Voucher
                  </Typography.Title>
                </div>

                <Row justify={'space-between'} align='middle'>
                  <Col
                    style={{
                      color: '#fff',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Text className='changeFontBlock'>
                      <b>Client Name :</b> {details?.client_name}
                    </Typography.Text>
                  </Col>

                  <Col className='changeFontBlock'>
                    <Typography.Title className='changeFontBlock' level={5}>
                      Tax Refund Information:
                    </Typography.Title>

                    <Typography.Text className='changeFontBlock'>
                      <b>Voucher No :</b> {details?.refund_voucher}
                    </Typography.Text>

                    <Typography.Text className='changeFontBlock'>
                      <b>Date :</b>{' '}
                      {dayjs(details?.created_at).format('DD MMM YYYY')}
                    </Typography.Text>
                  </Col>
                </Row>

                <Table
                  size='small'
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  bordered
                  columns={columns}
                  dataSource={details?.ticket_info}
                  style={{ marginTop: '10%' }}
                  pagination={false}
                  rowKey={(record) => record.airticket_ticket_no}
                  summary={(data) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3}>
                            <Typography style={{ fontWeight: 500 }}>
                              TOTAL REFUND TAX
                            </Typography>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <Typography
                              style={{ fontWeight: 500, textAlign: 'right' }}
                            >
                              {formatAmount(
                                data?.reduce(
                                  (acc, curr) =>
                                    acc + Fixed2(curr.refund_tax_amount),
                                  0
                                )
                              )}
                            </Typography>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />

                <ViewRefundFooter
                  hideNote
                  amount={details?.ticket_info?.reduce(
                    (acc, curr) => acc + Fixed2(curr.refund_tax_amount),
                    0
                  )}
                />
              </div>
            </Card>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default AirTicketTax_View;

export interface ITaxRefundDetails {
  refund_id: number;
  client_refund_type: string;
  refund_voucher: string;
  client_name: string;
  vendor_refund_type: string;
  client_total_tax_refund: string;
  vendor_total_tax_refund: string;
  created_at: string;
  client_account: string;
  vendor_account: string;
  ticket_info: ITaxRefundTicketInfo[];
}

export interface ITaxRefundTicketInfo {
  airticket_ticket_no: string;
  airticket_gds_id: number;
  refund_tax_amount: string;
  vendor_name: string;
}
