import { Button, Form, Input, Radio, message } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateTransportTypesMutation } from '../endpoints/transportTypeEndpoints';
import { ITransportTypeFormData } from '../types/transportTypeTypes';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';

const TransportTypeAddModal = ({
  TransportTypeNameList,
}: {
  TransportTypeNameList: string[] | undefined;
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [addTransportTypes, { isError, isSuccess, isLoading }] =
    useCreateTransportTypesMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Treansport Type has been Created');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: ITransportTypeFormData) => {
    await addTransportTypes(values);
    dispatch(setModal(false));
    form.resetFields();
  };
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        name='ttype_name'
        rules={[
          { required: true, message: 'Please input transport type name!' },
          { max: 85, message: 'Maximum 85 characters' },
          {
            validator(_, value) {
              if (value) {
                if (TransportTypeNameList?.includes(value)) {
                  return Promise.reject('Department Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter transport type name' />
      </Form.Item>

      <Form.Item
        label='Transport Type Status'
        name='ttype_status'
        rules={[{ required: true, message: 'Please select status' }]}
      >
        <Radio.Group>
          <Radio value={1}>Active</Radio>
          <Radio value={0}>Inactive</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item wrapperCol={{ span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit{' '}
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default TransportTypeAddModal;
