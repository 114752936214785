import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IRole } from '../types/user.types';
import { ITableData, convertRoleViewData } from '../utils/role.utils';

const PermissionViewer = ({ data }: { data: IRole }) => {
  const tableData = convertRoleViewData(data);

  const columns: ColumnsType<ITableData> = [
    {
      title: 'Serial',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Permission Name',
      dataIndex: 'permissionName',
      key: 'permissionName',
    },
    {
      title: 'Update',
      dataIndex: 'update',
      key: 'update',
      render: (update: boolean) => (update ? 'Yes' : '-'),
    },
    {
      title: 'Create',
      dataIndex: 'create',
      key: 'create',
      render: (create: boolean) => (create ? 'Yes' : '-'),
    },
    {
      title: 'Read',
      dataIndex: 'read',
      key: 'read',
      render: (read: boolean) => (read ? 'Yes' : '-'),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (del: boolean) => (del ? 'Yes' : '-'),
    },
  ];

  return (
<>

<h4 style={{border:"1px solid lightgray", padding:"8px 10px", marginBottom:10 , borderRadius:5, display:'flex', justifyContent:"space-between", color:'gray'}} ><span>USER TYPE : {data.role_user_type}</span> <span>ROLE NAME : {data.role_name}</span> </h4>




<Table
      size='small'
      bordered
      columns={columns}
      dataSource={tableData}
      pagination={{ pageSize: 50 }}
    />
</>
  );
};

export default PermissionViewer;
