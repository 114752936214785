import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { PREREGISTRATION } from '../../Api/constants';
import { IPreregistrationApiDataType } from '../types/preRegistration';

export const preRegistrationEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all PreRegistration

    getPreRegistration: build.mutation<
      HTTPResponse<IPreregistrationApiDataType[]>,
      {
        possible_year: string;
        query: string;
      }
    >({
      query: ({ possible_year, query }) => ({
        url: `/report/pre-registration${query}`,
        method: 'POST',
        body: {
          possible_year: possible_year,
        },
      }),
      invalidatesTags: [{ type: 'PreRegistration', id: PREREGISTRATION }],
    }),

    getExcelsPreRegistration: build.query<
      any,
      {
        year: string;
        query: string;
      }
    >({
      query: ({ year, query }) => ({
        url: `/report/preRegistrationReport${query}`,
        method: 'POST',
        body: {
          possible_year: year,
        },
        responseHandler: async (response) => {
          const filename = `preRegistrationReport.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetPreRegistrationMutation,
  useLazyGetExcelsPreRegistrationQuery,
} = preRegistrationEndpoints;
