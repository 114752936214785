import { SendOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { FormInputItem } from '../../../components/common/FormItem/FormItems';

interface feedback {
  username: string;
  message: string;
}

type Props = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  setFormData: React.Dispatch<React.SetStateAction<{}>>;
  current: number;
  formData: {};
};

const Feedback_form = ({
  setCurrent,
  current,
  setFormData,
  formData,
}: Props) => {
  const [form] = useForm();

  const onFinish = (values: feedback) => {
    setFormData(values);
    next();
  };

  const next = () => {
    setCurrent(current + 1);
  };
  useEffect(() => {
    form.setFieldsValue({ ...formData });
  }, []);

  return (
    <>
      <Row style={{ height: '100%' }} justify={'center'} align={'middle'}>
        <Col xs={24} sm={12} md={12} lg={{ span: 20, offset: 0 }}>
          <Card>
            <Card>
              <p>
                We're always looking for ways to improve. Please let us know
                what you think by using the form below
              </p>
            </Card>

            <Form form={form} onFinish={onFinish} layout='vertical'>
              <FormInputItem
                label='Name'
                name={'fd_agency_name'}
                placeholder='Enter your name'
                size={24}
                smSize={24}
                mdSize={24}
              />
              <FormInputItem
                required
                label='Subject'
                name={'fd_subject'}
                placeholder='Write your subject'
                size={24}
                smSize={24}
                mdSize={24}
              />
              <Col xs={24} sm={24} md={24} lg={{ span: 24, offset: 0 }}>
                <Form.Item name={'fd_message'} label='Message' required>
                  <TextArea
                    placeholder='Write your message'
                    rows={6}
                  ></TextArea>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={{ span: 24, offset: 0 }}>
                <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
                  <Button
                    icon={<SendOutlined />}
                    type='primary'
                    htmlType='submit'
                  >
                    Next
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Feedback_form;
