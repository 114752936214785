import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import {
  LoanAuthorityDataType,
  LoanAuthorityEditFormData,
  LoanAuthorityFormData,
} from '../../LoanTypes/LoanTypes';
import { AUTHORITY } from '../Constants/constants';

export const authorityEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all for select
    getAuthority: build.query<HTTPResponse<LoanAuthorityDataType[]>, void>({
      query: () => ({ url: `/loan-management/authority` }),
      providesTags: [{ type: 'Authority', id: AUTHORITY }],
    }),

    // get all for List view
    getAllAuthority: build.query<
      HTTPResponse<LoanAuthorityDataType[]>,
      IPaginationSD
    >({
      query: (arg) => ({
        url: `/loan-management/authority/all?page=${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
      }),
      providesTags: [{ type: 'Authority', id: AUTHORITY }],
    }),

    //create authority
    createAuthority: build.mutation<void, LoanAuthorityFormData>({
      query: (body) => ({
        url: '/loan-management/authority',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Authority Created Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Authority', id: AUTHORITY }],
    }),

    //edit authority
    editAuthority: build.mutation<void, LoanAuthorityEditFormData>({
      query: (body) => ({
        url: `/loan-management/authority/${body.authority_id}`,
        method: 'PATCH',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Authority Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Authority', id: AUTHORITY },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //delete authority
    deleteAuthority: build.mutation<
      void,
      { authority_id: number; deleted_by: number }
    >({
      query: (body) => ({
        url: `/loan-management/authority/${body.authority_id}`,
        method: 'DELETE',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Authority Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Authority', id: AUTHORITY },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
  }),
});

export const {
  useGetAuthorityQuery, // This use for select
  useGetAllAuthorityQuery, // This use for Get All
  useCreateAuthorityMutation,
  useEditAuthorityMutation,
  useDeleteAuthorityMutation,
} = authorityEndpoints;
