import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { RoomTypeDataType } from '../types/roomTypesTypes';
import { useEditRoomTypesMutation } from '../endpoints/roomTypesEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  roomTypeNameList: string[];
  info: RoomTypeDataType;
  setNull: ISetState<RoomTypeDataType | null>;
}
const RoomTypesEditModal = ({ info, setNull, roomTypeNameList }: IProps) => {
  const dispatch = useAppDispatch();
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ rtype_name: info.rtype_name });
  }, [info]);

  const [editRoomType, { isError, isSuccess, isLoading }] =
    useEditRoomTypesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('RoomTypes Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as RoomTypeDataType;
      values.rtype_id = info.rtype_id;
      await editRoomType({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };

  return (
    <div>
      <Form
        labelAlign='left'
        {...layout}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name='rtype_name'
          label='Room Type Name: '
          rules={[
            { required: true, message: 'Please input Room type name!' },
            { max: 85, message: 'Maximum 85 characters' },
            {
              validator(_, value) {
                if (value) {
                  if (roomTypeNameList.includes(value)) {
                    return Promise.reject('Department Name is already Exist');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder='Edit room type name' />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
          <Button
            disabled={modalSlice.modalLoading}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default RoomTypesEditModal;
