import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IAccountReportCommonDataType } from '../types/accountReportTypes';
import { Typography } from 'antd';

export const accountReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IAccountReportCommonDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'actransaction_date',
      key: 'actransaction_date',
      render: (_, record) => {
        return `${dayjs(record.actransaction_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Details',
      dataIndex: 'transection_type',
      key: 'transection_type',
    },
    {
      title: 'Tr.Type',
      dataIndex: 'actransaction_type',
      key: 'actransaction_type',
      align: 'center',
      render: (_, record) => {
        return (
          <Typography
            style={{
              color: record?.actransaction_type == 'DEBIT' ? 'red' : 'green',
            }}
          >
            {record?.actransaction_type}
          </Typography>
        );
      },
    },
    {
      title: 'Debit',
      dataIndex: 'actransaction_type',
      key: 'actransaction_type',
      align: 'right',
      width: 'auto',
      render: (_, record) => {
        if (record.actransaction_type == 'DEBIT') {
          return (
            <Typography style={{ color: 'red' }}>
              {Number(record.actransaction_amount) || 0}
            </Typography>
          );
        }
      },
    },
    {
      title: 'Credit',
      dataIndex: 'actransaction_type',
      key: 'actransaction_type',
      align: 'right',
      width: 'auto',
      render: (_, record) => {
        if (record.actransaction_type == 'CREDIT') {
          return (
            <Typography style={{ color: 'green' }}>
              {Number(record.actransaction_amount) || 0}
            </Typography>
          );
        }
      },
    },
  ];
};
