import { FormListFieldData } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { NumberInput } from '../../../components/common/FormItem/FormItems';
import { SelectExpenseHead } from '../../../components/common/FormItem/SelectCustomFeilds';
import { IAmountTotal } from '../ExpenseTypes/Expensetypes';
type Props = {};

const Expense_Info = ({
  field,
  form,
  expenseInitialValues,
}: {
  field: FormListFieldData;
  form: FormInstance<any>;
  expenseInitialValues?: IAmountTotal[];
}) => {
  const [headID, setHeadID] = useState<number>();

  useEffect(() => {
    if (headID) {
      form.setFields([
        {
          name: ['expense_details', field.name, 'expdetails_subhead_id'],
          value: undefined,
        },
      ]);
    }
  }, [headID]);

  return (
    <>
      <SelectExpenseHead
        label='Head'
        name={[field.name, 'expdetails_head_id']}
        size={8}
        mdSize={12}
        smSize={12}
        placeholder='Select Head'
        required
        setHeadID={setHeadID}
      />
      <NumberInput
        label='Amount'
        name={[field.name, 'expdetails_amount']}
        size={8}
        mdSize={12}
        smSize={12}
        min='1'
        maxChar={14}
        required
      />
    </>
  );
};

export default Expense_Info;
