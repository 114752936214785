import { ColumnsType } from 'antd/lib/table';
import { Input, Form } from 'antd';
import { IOthersRefundInfo } from '../../../RefundTypes/RefundTypes';

export const OthersRefundClientInfoUtils = (
  onTableQtyRefundChange: Function,
  onTableClientChargeRefundChange: Function,
  onTableVendorChargeRefundChange: Function
): ColumnsType<IOthersRefundInfo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Invoice Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
    },
    {
      title: 'Remaining Quantity',
      dataIndex: 'billing_remaining_quantity',
      key: 'billing_remaining_quantity',
    },

    {
      title: 'Refund Quantity',
      key: 'operation',
      dataIndex: 'refund_quantity',
      render: (text, record, index) => (
        <Form.Item
          name={[index, 'refund_quantity']}
          rules={[
            {
              message: 'Quantity is required!',
            },
            ({ getFieldValue }) => ({
              validator: (_, value) => {
                if (value && record.billing_remaining_quantity != undefined) {
                  if (value > Number(record.billing_remaining_quantity)) {
                    return Promise.reject(
                      'Quantity cannot be greater than remaining quantity'
                    );
                  }
                  if (value < 1) {
                    return Promise.reject('Quantity cannot be less than 1');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableQtyRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.refund_quantity !== prevRecord.refund_quantity;
      },
    },
    {
      title: 'Client Refund Charge',
      key: 'operation',
      dataIndex: 'client_refund_charge',
      render: (text, record, index) => (
        <Form.Item
          style={{ width: '120px' }}
          name={[index, 'client_refund_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (
                  value &&
                  record.refund_quantity != undefined &&
                  record.billing_unit_price
                ) {
                  if (
                    value >
                    record.refund_quantity * record.billing_unit_price
                  ) {
                    return Promise.reject(
                      'Refund charge cannot be greater than return price'
                    );
                  }
                  if (value < 1) {
                    return Promise.reject(
                      'Refund charge cannot be less than 1'
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableClientChargeRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_refund_charge !== prevRecord.client_refund_charge;
      },
    },
    {
      title: 'Vendor Refund Charge',
      key: 'operation',
      dataIndex: 'vendor_refund_charge',
      render: (text, record, index) => (
        <Form.Item
          style={{ width: '120px' }}
          name={[index, 'vendor_refund_charge']}
          rules={[
            {
              validator: (_, value) => {
                if (
                  value &&
                  record.refund_quantity != undefined &&
                  record.billing_unit_price
                ) {
                  if (
                    value >
                    record.refund_quantity * record.billing_unit_price
                  ) {
                    return Promise.reject(
                      'Refund charge cannot be greater than return price'
                    );
                  }
                  if (value < 1) {
                    return Promise.reject(
                      'Refund charge cannot be less than 1'
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input
            value={text}
            onChange={(v) => onTableVendorChargeRefundChange(v, index, record)}
            type={'number'}
          />
        </Form.Item>
      ),
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_refund_charge !== prevRecord.vendor_refund_charge;
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
    },
    {
      title: 'Cost Price',
      dataIndex: 'billing_cost_price',
      key: 'billing_cost_price',
    },
    {
      title: 'Client Total Price',
      dataIndex: 'client_total_price',
      key: 'client_total_price',
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_total_price !== prevRecord.client_total_price;
      },
    },
    {
      title: 'Vendor Total Price',
      dataIndex: 'vendor_total_price',
      key: 'vendor_total_price',
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_total_price !== prevRecord.vendor_total_price;
      },
    },
    {
      title: 'Client Refund Amount',
      dataIndex: 'client_refund_amount',
      key: 'client_refund_amount',
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_refund_amount !== prevRecord.client_refund_amount;
      },
    },
    {
      title: 'Vendor Refund Amount',
      dataIndex: 'vendor_refund_amount',
      key: 'vendor_refund_amount',
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_refund_amount !== prevRecord.vendor_refund_amount;
      },
    },
    {
      title: 'Client Return Price',
      dataIndex: 'client_refund_price',
      key: 'client_refund_price',
      shouldCellUpdate: (record, prevRecord) => {
        return record.client_refund_price !== prevRecord.client_refund_price;
      },
    },
    {
      title: 'Vendor Return Price',
      dataIndex: 'vendor_refund_price',
      key: 'vendor_refund_price',
      shouldCellUpdate: (record, prevRecord) => {
        return record.vendor_refund_price !== prevRecord.vendor_refund_price;
      },
    },
  ];
};
