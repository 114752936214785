import { JOURNEYDATEWISECLIENTS } from './../../Api/constants';
import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { IJourneyDateWiseClientsDataType } from '../types/JourneyDateWiseClientsTypes';

export const JourneyDateWiseClientsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all JourneyDateWiseClients
    getJourneyDateWiseClients: build.query<
      HTTPResponse<IJourneyDateWiseClientsDataType[]>,
      {
        comb_client: string | number;
        query: string;
      }
    >({
      query: ({ query, comb_client }) => ({
        url: `/report/journey-date-wise-client-report/${query}`,
        method: 'POST',
        body: { comb_client: comb_client },
      }),
      providesTags: [
        { type: 'JourneyDateWiseClients', id: JOURNEYDATEWISECLIENTS },
      ],
    }),

    getExcelsJourneyDateWise: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
      }
    >({
      query: ({ client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}${query}`,
        method: 'POST',
        body: {
          comb_client: client_id,
        },
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetJourneyDateWiseClientsQuery,
  useLazyGetExcelsJourneyDateWiseQuery,
} = JourneyDateWiseClientsEndpoints;
