import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import request from './app/utils/request';
import './index.css';
import reportWebVitals from './reportWebVitals';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.withCredentials = true;

const container = document.getElementById('root')!;
const root = createRoot(container);
export const { reqWithAuth, setToken, getSessionId, setSessionId } = request();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
