import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, TimePicker } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { FormInputItem } from '../../../../components/common/FormItem/FormItems';
import { SelectTranportType } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';
import { InvoiceHajjType } from '../Types/InvoiceHajjTypes';

type Props = {
  fields: FormListFieldData[];
  name: number;
  index: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  form: FormInstance<any>;
  edit_data?: InvoiceHajjType;
};

export default function TransportInfoSubCom({
  fields,
  name,
  index,
  add,
  remove,
  form,
  edit_data,
}: Props) {
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();

  const is_deleted: 0 | 1 = useWatch(
    ['transport_information', index, 'is_deleted'],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      gutter={[10, 0]}
      align='middle'
    >
      <SelectTranportType
        name={[name, 'transport_type_id']}
        label='Tranport Type'
        size={6}
        disabled={disabled}
      />
      <FormInputItem
        name={[name, 'transport_pickup_place']}
        label='Pickup Place'
        size={6}
        disabled={disabled}
      />
      <FormInputItem
        name={[name, 'transport_dropoff_place']}
        label='Drop Off Place	'
        size={6}
        disabled={disabled}
      />
      <Form.Item
        name={[name, 'transport_pickup_time']}
        label='Pickup Time'
        colon={false}
        style={{ marginInline: '5px' }}
      >
        <TimePicker disabled={disabled} />
      </Form.Item>

      <Form.Item
        label='Dropoff Time'
        name={[name, 'transport_dropoff_time']}
        colon={false}
        style={{ marginInline: '5px' }}
      >
        <TimePicker disabled={disabled} />
      </Form.Item>

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[name, 'is_deleted']} />

      {name === 0 ? (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'transport_information',
                  name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['transport_information', name, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (
                    (edit_data?.transport_information?.length ||
                      edit_data?.transport_information?.length === 0) &&
                    index >= edit_data?.transport_information?.length
                  )
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}
