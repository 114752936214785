import {
  useGetAllOthersRefundByIDQuery,
  useLazyGetAllOthersRefundByIDQuery,
} from '../Api/Endpoints/OthersRefundClientEndpoints';

import React, { useEffect } from 'react';
import { Col, Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { commonProps } from '../../../common/types/commonTypes';

type PropsType = {
  clientID: string;
  setOthersRefund?: (arg: string) => void;
  setClLastBalance?: (arg: number) => void;
  setClientName?: (arg: string) => void;
} & commonProps;

export const SelectOthersRefundClientID = ({
  name,
  label,
  size,
  required,
  disabled,
  clientID,
  setOthersRefund,
  setClLastBalance,
  setClientName,
}: PropsType) => {
  // ================= OthersRefundbyClientID children
  const [fetchOtherRefund, { data: othersRefundByClientID }] =
    useLazyGetAllOthersRefundByIDQuery();

  useEffect(() => {
    if (setClLastBalance && othersRefundByClientID?.data?.length) {
      setClLastBalance(Number(othersRefundByClientID?.data[0].lbalance_amount));
    }
    if (setClientName && othersRefundByClientID?.data?.length) {
      setClientName(othersRefundByClientID?.data[0].client_name);
    }
  }, [othersRefundByClientID?.data]);

  useEffect(() => {
    clientID && fetchOtherRefund(clientID);
  }, [clientID]);

  const selectOthersRefundData = othersRefundByClientID?.data;
  const othersRefundDataChildren: React.ReactNode[] = [];
  if (selectOthersRefundData) {
    for (let i = 0; i < selectOthersRefundData.length; i++) {
      othersRefundDataChildren.push(
        <Select.Option
          key={selectOthersRefundData[i].invoice_id}
          value={selectOthersRefundData[i].invoice_id}
        >
          {`${selectOthersRefundData[i].invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col span={6} xs={24} sm={24} md={24} lg={size ? size : 8}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: 'Select Others Refund Invoice is required',
          },
        ]}
      >
        <Select
          allowClear
          disabled={disabled}
          placeholder={'Select Others Refund Invoice'}
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e: string) => {
            setOthersRefund && setOthersRefund(e);
          }}
        >
          {othersRefundDataChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
