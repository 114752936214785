import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import {
  IAccountSingleVendorBillAdjustment,
  IAccountVendorBillAdjustment,
  IAccountVendorBillAdjustmentFormData,
} from '../../AccountsTypes/AccountsTypes';
import { VENDOR_BILL_ADJUSTMENT } from '../Constants/constants';

export const vendorBillAdjustmentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all vendor_bill_adjustment
    getAllVendorBillAdjustment: build.query<
      HTTPResponse<IAccountVendorBillAdjustment[]>,
      {
        current: number;
        pageSize: number;
        search: string;
        from: string;
        to: string;
      }
    >({
      query: (query) => ({
        url: `/accounts/vendor-bill-adjustment?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [
        { type: 'Vendor_Bill_Adjustment', id: VENDOR_BILL_ADJUSTMENT },
      ],
    }),

    //get all trashed vendor_bill_adjustment
    getAllTrashedVendorBillAdjustment: build.query<
      HTTPResponse<IAccountVendorBillAdjustment[]>,
      void
    >({
      query: () => ({ url: `/accounts/vendor-bill/trashed` }),
      providesTags: () => [
        { type: 'Vendor_Bill_Adjustment', id: VENDOR_BILL_ADJUSTMENT },
      ],
    }),

    //get single vendor_bill_adjustment
    getSingleVendorBillAdjustment: build.query<
      HTTPResponse<IAccountSingleVendorBillAdjustment>,
      number
    >({
      query: (id) => ({ url: `/accounts/vendor-bill-adjustment/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Vendor_Bill_Adjustment', id: id } as const,
      ],
    }),

    //create vendor_bill_adjustment
    createVendorBillAdjustment: build.mutation<
      void,
      IAccountVendorBillAdjustmentFormData
    >({
      query: (body) => ({
        url: `/accounts/vendor-bill-adjustment`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'Vendor Bill Adjustment Added Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'Vendor_Bill_Adjustment', id: VENDOR_BILL_ADJUSTMENT },
      ],
    }),

    //delete vendor_bill_adjustment
    deleteVendorBillAdjustment: build.mutation<
      void,
      { vbilladjust_id: number; created_by: number }
    >({
      query: (body) => ({
        url: `/accounts/vendor-bill-adjustment/${body.vbilladjust_id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification(
            'success',
            'Vendor Bill Adjustment Deleted Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'Vendor_Bill_Adjustment', id: VENDOR_BILL_ADJUSTMENT },
      ],
    }),
  }),
});

export const {
  useGetAllVendorBillAdjustmentQuery,
  useLazyGetAllVendorBillAdjustmentQuery,
  useLazyGetAllTrashedVendorBillAdjustmentQuery,
  useGetSingleVendorBillAdjustmentQuery,
  useCreateVendorBillAdjustmentMutation,
  useDeleteVendorBillAdjustmentMutation,
} = vendorBillAdjustmentEndpoints;
