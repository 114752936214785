import { Form, Input, Button, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { visaDataType } from '../types/visaTypes';
import { useEditVisaMutation } from '../endpoints/visaEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  info: visaDataType;
  setNull: ISetState<visaDataType | null>;
}
const EditVisaTypeModal = ({ info, setNull }: IProps) => {
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      type_name: info.type_name,
    });
  }, [info]);
  const [editVisa, { isError, isSuccess, isLoading }] = useEditVisaMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Visa Type Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as visaDataType;
      values.type_id = info.type_id;
      await editVisa({ ...values });

      setNull(null);
      dispatch(setModal(false));
    });
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  return (
    <div>
      <Form
        labelAlign='left'
        {...layout}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name='type_name'
          label='Enter Type Name :'
          rules={[
            { required: true, message: 'Please input Visa Type name!' },
            { max: 55, message: 'Maximum 55 characters' },
          ]}
        >
          <Input value={info.type_name} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            Update
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default EditVisaTypeModal;
