import { Form, Select } from 'antd';
import { useGetAllDesignationQuery } from '../../designation/endpoints/designationEndpoints';

const DesignationSelect = () => {
  const { data: designationCategory } = useGetAllDesignationQuery();
  return (
    <Form.Item
      name='employee_designation_id'
      label='Designation'
      rules={[{ required: true, message: 'Please input Designation!' }]}
    >
      <Select
        showSearch
        allowClear
        placeholder='Select Designation'
        optionFilterProp='children'
      >
        {designationCategory?.data?.map((item) => {
          return (
            <Select.Option
              key={item.designation_id}
              value={item.designation_id}
            >
              {item.designation_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default DesignationSelect;
