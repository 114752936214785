import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { useGetSingleReceiveQuery } from '../../Api/Endpoints/ReceiveEndpoints';
import { SingleReceiveList } from '../../Utils/Receive/SingleReceiveList';

export const a4sizeStyle: React.CSSProperties = {
  minHeight: '11.5in',
  width: '8.27in',
  fontSize: '11px',
  background: '#fff',
  margin: '10px auto',
};

function ViewReceive() {
  const { id } = useParams();
  const { data: singleReceive } = useGetSingleReceiveQuery(Number(id));
  const { isLoading, data } = useGetSingleReceiveQuery(Number(id));

  const singleReceiveTableData = data?.data;

  const printTime = moment().format('ll LTS');
  const [amount, setAmount] = useState<number>();
  const [name, setName] = useState<string>();
  const [date, setDate] = useState<string>();
  useEffect(() => {
    if (singleReceive?.data && singleReceive?.data[0].received_amount) {
      setAmount(Number(singleReceive?.data[0].received_amount));
      setName(singleReceive.data[0].loan_name);
      setDate(dayjs(singleReceive?.data[0].payment_date).format('DD MMM YYYY'));
    }
  }, [singleReceive?.data]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Loan_${name}_${date}`,
  });

  useHotkeys('ctrl + p', (e) => {
    e.preventDefault();
    handlePrint();
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Dashboard', 'Loan Management', 'View Receive']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/loan/receive'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Receive List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card style={a4sizeStyle} ref={componentRef}>
          <InvoiceHeader />

          <TitleCenter title='Loan Received' />

          <Row justify={'space-between'} style={{ margin: '30px 0' }}>
            <Col>
              <Typography>
                Name :
                {singleReceive?.data?.length &&
                  singleReceive?.data[0]?.authority_name}
              </Typography>
              <Typography>
                Type :
                {singleReceive?.data?.length &&
                  singleReceive?.data[0]?.loan_type}
              </Typography>
            </Col>

            <Typography>
              Date :
              {dayjs(
                singleReceive?.data?.length &&
                  singleReceive?.data[0]?.received_date
              ).format('YYYY-MM-DD')}
            </Typography>
          </Row>

          <>
            <Table
              size='small'
              bordered
              pagination={false}
              columns={SingleReceiveList()}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={singleReceiveTableData}
              loading={{ spinning: isLoading, indicator: loadingIndicator }}
            />

            <Row style={{ marginTop: '25px' }}>
              <Typography.Text strong>
                <NumToWord number={amount || 0} />
              </Typography.Text>
            </Row>
          </>

          {/* ================== signature ===================== */}
          <>
            <Row
              className='signature'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '40px',
              }}
            >
              <Typography.Text
                style={{
                  borderTop: `1px solid black`,
                }}
              >
                Received By
              </Typography.Text>
              <Typography.Text
                style={{
                  borderTop: `1px solid black`,
                }}
              >
                Prepared By
              </Typography.Text>
              <Typography.Text
                style={{
                  borderTop: `1px solid black`,
                }}
              >
                Approved By
              </Typography.Text>
            </Row>

            <Row justify={'center'} style={{ marginTop: '25px' }}>
              <Typography.Text>{printTime}</Typography.Text>
            </Row>
          </>
        </Card>
      </ConfigProvider>
    </div>
  );
}

export default ViewReceive;
