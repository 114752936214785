import { Divider, FormListFieldData, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect } from 'react';
import { ISelectData } from '../../../../common/types/commonTypes';
import { FormInputItem } from '../../../../components/common/FormItem/FormItems';
import {
  SelectDataInput,
  SelectMaharam,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllHajjiTrackingNumberQuery } from '../../../Invoice_Hajj_Pre_Reg/API/Endpoints/invoiceHajjPreRegEndspoint';
import { MinusCircleOutlined } from '@ant-design/icons';

type Props = {
  name: number;
  fields: FormListFieldData[];
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  reason: 'ADD_NEW' | 'EDIT';
};

const HajjInfoInputForm = ({ name, form, remove, reason }: Props) => {
  const { data: haji_info } = useGetAllHajjiTrackingNumberQuery();

  const hajiInfo = haji_info?.data;

  const hajiInfoSelect = hajiInfo?.map((item) => {
    return {
      id: item.haji_info_id,
      title: item.hajiinfo_tracking_number,
    };
  });

  const trackingNo = useWatch(
    ['haji_informations', name, 'transfertrack_tracking_no'],
    form
  );

  const [filterData] =
    hajiInfo?.filter((item) => item.haji_info_id === trackingNo) || [];

  useEffect(() => {
    if (trackingNo) {
      form.setFields([
        {
          name: ['haji_informations', name, 'transfertrack_passport_id'],
          value: filterData?.transfertrack_passport_id,
        },
        {
          name: ['haji_informations', name, 'passport_nid_no'],
          value: filterData?.passport_nid_no,
        },
        {
          name: ['haji_informations', name, 'haji_info_vouchar_no'],
          value: filterData?.haji_info_vouchar_no,
        },

        {
          name: ['haji_informations', name, 'hajiinfo_serial'],
          value: filterData?.hajiinfo_serial,
        },
        {
          name: ['haji_informations', name, 'passport_name'],
          value: filterData?.passport_name,
        },
        {
          name: ['haji_informations', name, 'passport_passport_no'],
          value: filterData?.passport_passport_no,
        },
        {
          name: ['haji_informations', name, 'passport_mobile_no'],
          value: filterData?.passport_mobile_no,
        },

        {
          name: ['haji_informations', name, 'passport_email'],
          value: filterData?.passport_email,
        },

        {
          name: ['haji_informations', name, 'hajj_info_maharam_id'],
          value: filterData?.hajj_info_maharam_id,
        },
      ]);
    }
  }, [trackingNo, filterData]);

  const alReadySelected = useWatch(['haji_informations'], form)?.map(
    (item: any) => item?.hajiinfo_tracking_number
  );

  return (
    <Row
      className='border'
      style={{ display: 'flex', marginBottom: 10, padding: 10 }}
      gutter={[5, 0]}
    >
      <Divider>Person - {name + 1}</Divider>
      <SelectDataInput
        required
        size={4}
        data={hajiInfoSelect as ISelectData[]}
        label='Tracking No'
        name={[name, 'transfertrack_tracking_no']}
        rules={
          [
            //   {
            //     validator: (_, value) => {
            //       console.log(value);
            //       if (value && alReadySelected.includes(value)) {
            //         return Promise.reject('Same tracking number are not allowed');
            //       }
            //       return Promise.resolve();
            //     },
            //   },
          ]
        }
      />

      <FormInputItem
        hidden
        size={4}
        name={[name, 'transfertrack_passport_id']}
        label='Passport ID'
      />
      <FormInputItem
        size={4}
        name={[name, 'passport_nid_no']}
        label='Passport'
      />
      <FormInputItem size={4} name={[name, 'hajiinfo_name']} label='Name' />
      <FormInputItem
        size={4}
        label='Mobile'
        name={[name, 'passport_mobile_no']}
      />
      {/* <DateInput size={4} name={[name, 'hajiinfo_dob']} label='Date of birth' /> */}
      <FormInputItem
        size={4}
        name={[name, 'haji_info_vouchar_no']}
        label='Voucher No'
      />
      <FormInputItem size={4} name={[name, 'passport_nid_no']} label='NID' />
      <FormInputItem
        size={4}
        name={[name, 'hajiinfo_tracking_number']}
        label='Tracking No'
      />

      <FormInputItem
        size={4}
        name={[name, 'hajiinfo_serial']}
        label='Serial No'
      />

      <FormInputItem
        size={4}
        name={[name, 'passport_name']}
        label='Passport Name'
      />

      <FormInputItem
        size={4}
        name={[name, 'passport_passport_no']}
        label='Passport No'
      />

      <FormInputItem
        size={4}
        name={[name, 'passport_email']}
        label='Passport Email'
      />

      <SelectMaharam
        name={[name, 'transfertrack_maharam_id']}
        label='Maharam'
        size={3}
        mdSize={8}
        smSize={12}
        disabled
      />
      <MinusCircleOutlined
        style={{ marginLeft: 10 }}
        onClick={() => remove(name)}
      />
    </Row>
  );
};

export default HajjInfoInputForm;
