import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Tabs, Tag } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import ClientLedgers from '../../../../Reports/ledgers/pages/ClientLedgers';
import { useGetSingleClientsQuery } from '../../api/endpoints/clientEndpoints';
import ClientDetailInvoice from './ClientDetailInvoice';
import ClientDetailPassport from './ClientDetailPassport';
import ClientDetailPayment from './ClientDetailPayment';
import ClientDetailQuotation from './ClientDetailQuotation';
import ClientDetailRefundProduct from './ClientDetailRefundProduct';

const ClientDetails = () => {
  const { id } = useParams();

  const { isLoading, data: clients } = useGetSingleClientsQuery(id as string);

  return (
    <div>
      <div>
        <BreadCrumb arrOfOption={['View Client Details']} />

        <Col style={{ marginBottom: '1rem' }}>
          <Link to='/clients'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to Client List
            </Button>
          </Link>
        </Col>
      </div>

      <div className='card-container'>
        <Tabs
          type='card'
          items={[
            {
              label: 'Details',
              key: 'Details',
              children: isLoading ? (
                <Row justify='center' align='middle'>
                  <LoadingIndicator />
                </Row>
              ) : (
                <Descriptions
                  size='small'
                  title='CLIENT DETAILS'
                  bordered
                  column={1}
                >
                  {clients?.data?.client_type == 'INDIVIDUAL' ? (
                    <DescriptionsItem label='Name'>
                      {clients?.data?.client_name}
                    </DescriptionsItem>
                  ) : (
                    <DescriptionsItem label='Company Name'>
                      {clients?.data?.client_name}
                    </DescriptionsItem>
                  )}
                  <DescriptionsItem label='Client Type'>
                    {clients?.data?.client_type}
                  </DescriptionsItem>

                  {clients?.data?.client_lbalance &&
                  clients?.data?.client_lbalance < 0 ? (
                    <DescriptionsItem
                      label='Client Due'
                      style={{ color: 'red' }}
                    >
                      {Math.abs(clients?.data?.client_lbalance)}
                    </DescriptionsItem>
                  ) : (
                    <DescriptionsItem
                      label='Client Advance'
                      style={{ color: 'green' }}
                    >
                      {clients?.data?.client_lbalance}
                    </DescriptionsItem>
                  )}

                  {clients?.data?.client_type == 'INDIVIDUAL' && (
                    <DescriptionsItem label='Gender'>
                      {clients.data.client_gender}
                    </DescriptionsItem>
                  )}

                  {clients?.data?.client_email && (
                    <DescriptionsItem label='Email'>
                      {clients?.data?.client_email}
                    </DescriptionsItem>
                  )}

                  {clients?.data?.client_mobile && (
                    <DescriptionsItem label='Mobile'>
                      {clients?.data?.client_mobile}
                    </DescriptionsItem>
                  )}

                  {clients?.data?.client_address && (
                    <DescriptionsItem label='Address'>
                      {clients?.data?.client_address}
                    </DescriptionsItem>
                  )}

                  {clients?.data?.client_designation && (
                    <DescriptionsItem label='Designation'>
                      {clients?.data?.client_designation}
                    </DescriptionsItem>
                  )}
                  {clients?.data?.client_trade_license && (
                    <DescriptionsItem label='Trade License'>
                      {clients?.data?.client_trade_license}
                    </DescriptionsItem>
                  )}

                  <DescriptionsItem label='Create Date'>
                    {clients?.data?.client_created_date &&
                      new Date(
                        clients?.data?.client_created_date
                      ).toLocaleDateString()}
                  </DescriptionsItem>

                  <DescriptionsItem label='Client Source'>
                    {clients?.data?.client_source}
                  </DescriptionsItem>
                  <DescriptionsItem label='Client Commission'>
                    {clients?.data?.client_commission}
                  </DescriptionsItem>
                  <DescriptionsItem label='Airline Commission'>
                    {clients?.data?.client_airline_commissions
                      ?.map((item) => {
                        return `${item.airline_name} -> (${parseFloat(
                          item.ca_commission
                        )}%)`;
                      })
                      ?.map((item) => (
                        <>
                          <li>{item}</li>
                        </>
                      ))}
                  </DescriptionsItem>

                  <DescriptionsItem label='Status'>
                    {
                      <Tag
                        color={
                          clients?.data?.client_activity_status === 0
                            ? 'red'
                            : 'green'
                        }
                        key={clients?.data?.client_activity_status}
                      >
                        {clients?.data?.client_activity_status === 0
                          ? 'Inactive'
                          : 'Active'}
                      </Tag>
                    }
                  </DescriptionsItem>
                </Descriptions>
              ),
            },
            {
              label: 'Invoice',
              key: 'Invoice',
              children: <ClientDetailInvoice id={id as string} />,
            },
            {
              label: 'Payments',
              key: 'Payments',
              children: <ClientDetailPayment id={id as string} />,
            },
            {
              label: 'Quotation',
              key: 'Quotation',
              children: <ClientDetailQuotation id={id as string} />,
            },
            {
              label: 'Refund Product',
              key: 'Refund Product',
              children: <ClientDetailRefundProduct id={id as string} />,
            },

            {
              label: 'List of Upload Passports',
              key: 'List of Upload Passports',
              children: <ClientDetailPassport id={id as string} />,
            },
            {
              label: 'Clients Ledger',
              key: 'Clients Ledger',
              children: <ClientLedgers />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ClientDetails;
