import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import {
  SelectCity,
  SelectCountry,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';

const PlacesInfo = () => {
  const [getCityByCId, setCityByCId] = useState<number>(0);
  const handlechange = (e: number) => {
    setCityByCId(e);
  };
  return (
    <>
      <Form.List
        name='place_info'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <React.Fragment key={index}>
                <Card style={{ marginBottom: '5px' }}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={6}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Country Name is required',
                          },
                        ]}
                      >
                        <SelectCountry
                          onChange={(e) => handlechange(Number(e))}
                          required
                          name={[field.name, 'place_country_id']}
                          size={24}
                          mdSize={24}
                          smSize={24}
                          label='Country'
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={6}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'City Name is required',
                          },
                        ]}
                      >
                        <SelectCity
                          required
                          getCityByCId={getCityByCId}
                          name={[field.name, 'place_city_id']}
                          size={24}
                          label='City'
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={6}
                    >
                      <Form.Item
                        label='Place'
                        name={[field.name, 'place_name']}
                        rules={[
                          {
                            required: true,
                            message: 'place Name is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={2}
                    >
                      <Form.Item label='Action'>
                        {index === 0 ? (
                          <Button
                            type='primary'
                            onClick={() => add()}
                          >
                            <PlusOutlined />
                            Add More
                          </Button>
                        ) : (
                          <Button
                            type='primary'
                            danger
                            onClick={() => remove(field.name)}
                          >
                            <MinusOutlined />
                            Remove
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </React.Fragment>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default PlacesInfo;
