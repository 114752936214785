import { Button, Form, Modal } from 'antd';
import React, { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import AddPassport from '../../modules/Passport_MGT/Pages/AddPassport';

type Props = {
  setClick: (arg: boolean) => void;
  setInstancPassportSelect:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
};

const CreatePassportModal = ({ setClick, setInstancPassportSelect }: Props) => {
  const [createPassportModal, setCreatePassportModal] = useState(false);
  const user = useAppSelector((state) => state.user);

  //   submit form
  const [form] = Form.useForm();

  return (
    <>
      <Button
        onClick={() => {
          setCreatePassportModal(!createPassportModal);
          setClick(true);
        }}
        style={{ width: '100%' }}
      >
        Add Passport
      </Button>
      <Modal
        width={1000}
        style={{ top: 20 }}
        open={createPassportModal}
        onOk={() => {
          form.submit();
          setClick(false);
        }}
        onCancel={() => {
          setCreatePassportModal(false);
          setClick(false);
        }}
        footer={false}
      >
        <AddPassport
          useAsModal
          setCreatePassportModal={setCreatePassportModal}
          setClick={setClick}
          setInstancPassportSelect={setInstancPassportSelect}
        />
      </Modal>
    </>
  );
};

export default CreatePassportModal;
