import { Col, Form, Select, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetTrackingNoByClientIdQuery,
  useLazyTrackingNoByGroupIdQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import {
  IGetTrackingNoByGroup,
  IOpenFrom,
  ITrackingNoGetAll,
} from '../../Types/HajjiManagement.interface';

type Props = {
  previousTrackNo?: string[];
  form?: FormInstance<any>;
  openFrom: IOpenFrom;
  fromClientID?: string;
};

const ViewPassportByGroupID = ({
  previousTrackNo,
  form,
  openFrom,
  fromClientID,
}: Props) => {
  // ================== hajji info ==================

  const [getTrackingNoByClientId, { data: trackingList, isLoading }] =
    useLazyTrackingNoByGroupIdQuery();

  useEffect(() => {
    if (fromClientID) getTrackingNoByClientId(fromClientID);
  }, [fromClientID]);

  const [selected, setSelected] = useState<any>([]);

  const handleSelect = (e: string | number) => {
    setSelected(e);
  };

  const newArray = trackingList?.data?.filter((entry) =>
    selected.includes(entry.hajiinfo_tracking_number)
  );

  const columns: ColumnsType<IGetTrackingNoByGroup> = [
    {
      title: 'SL',
      render: (_, data, index) => {
        return <> {index + 1} </>;
      },
    },
    {
      title: 'Passport Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
    },
    {
      title: 'Voucher No',
      dataIndex: 'haji_info_vouchar_no',
      key: 'haji_info_vouchar_no',
    },
    {
      title: 'Gender',
      dataIndex: 'hajiinfo_gender',
      key: 'hajiinfo_gender',
    },
    {
      title: 'Hajj Serial',
      dataIndex: 'hajiinfo_serial',
      key: 'hajiinfo_serial',
    },
    {
      title: 'Serial No',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_nid_no',
      key: 'passport_nid_no',
    },
  ];

  return (
    <div>
      <FormHeaderTitle title='Haji Information 🕌' />

      <Col xs={24} sm={24} md={8} lg={8}>
        <Form.Item name={'ctrcknumber_number'}>
          <Select
            onChange={(e) => handleSelect(e)}
            clearIcon
            placeholder={'Select hajji by tracking no.'}
            showSearch
            mode='multiple'
            allowClear={true}
            style={{ padding: '0', margin: '0', border: '0', width: '100%' }}
            optionFilterProp='roleMobile'
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            loading={isLoading || false}
            options={trackingList?.data?.map((item, index) => ({
              value: item.hajiinfo_tracking_number,
              label: item.hajiinfo_tracking_number,
            }))}
          />
        </Form.Item>
      </Col>

      <Table
        size='small'
        rowKey={(e) => e.hajiinfo_tracking_number}
        bordered
        className='invoiceBillingTable mt-5'
        rowClassName={'invoiceBillingTd'}
        columns={columns}
        dataSource={newArray}
        pagination={false}
        loading={isLoading}
        style={{ marginTop: '25px' }}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ViewPassportByGroupID;
