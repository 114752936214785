import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import { ISelectData } from '../../../../../common/types/commonTypes';
import {
  FormInputItem,
  NumberInput,
} from '../../../../../components/common/FormItem/FormItems';
import {
  SelectDataInput,
  SelectVendors,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllTourPlacesQuery } from '../endpoints/tourTicketEndpoints';

const CommonInfoForm = () => {
  // ============= get all tour places
  const { data: tourPlaceData } = useGetAllTourPlacesQuery();
  const tourPlaces = tourPlaceData?.data;
  const selectTourPlaceData = tourPlaces?.map((item) => {
    return { title: item.place_name, id: item.place_id };
  });

  return (
    <Form.List
      name='ticket_info'
      initialValue={[{}]}
    >
      {(fields, { add, remove }) =>
        fields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <div className='border p-5'>
                <Row
                  key={index}
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                >
                  <SelectDataInput
                    data={selectTourPlaceData as ISelectData[]}
                    label='Place'
                    name={[field.name, 'itinerary_place_id']}
                    required
                    placeholder='Select place'
                    size={10}
                    mdSize={10}
                    smSize={10}
                  />

                  <FormInputItem
                    label='Particular'
                    name={[field.name, 'itinerary_particular']}
                    required
                    size={10}
                    mdSize={10}
                    smSize={10}
                  />

                  <Col
                    xs={24}
                    sm={4}
                    md={4}
                    lg={4}
                  >
                    <Form.Item label=' '>
                      {index === 0 ? (
                        <Button
                          type='primary'
                          onClick={() => add()}
                        >
                          <PlusOutlined />
                        </Button>
                      ) : (
                        <Button
                          type='primary'
                          danger
                          onClick={() => remove(field.name)}
                        >
                          <MinusOutlined />
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <>
                  {/* ****************************************** */}
                  <>
                    <Form.List
                      name={[field.name, 'vendors']}
                      initialValue={[{}]}
                    >
                      {(fieldsz, { add, remove }) =>
                        fieldsz.map((fieldz, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Row
                                key={index}
                                gutter={[10, 0]}
                                align='bottom'
                              >
                                <SelectVendors
                                  ticketInfoName={field.name}
                                  vendorInfoName={fieldz.name}
                                  required
                                  label='Vendor'
                                  name={[fieldz.name, 'itnrvendor_vendor_id']}
                                  size={10}
                                  mdSize={10}
                                  smSize={10}
                                />

                                <NumberInput
                                  required
                                  label='Cost Price'
                                  name={[fieldz.name, 'itnrvendor_cost_price']}
                                  size={10}
                                  mdSize={10}
                                  smSize={10}
                                  min='0'
                                  maxChar={14}
                                />
                                <Col
                                  xs={24}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                >
                                  <Form.Item label=' '>
                                    {index === 0 ? (
                                      <Button
                                        type='primary'
                                        onClick={() => add()}
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    ) : (
                                      <Button
                                        type='primary'
                                        danger
                                        onClick={() => remove(fieldz.name)}
                                      >
                                        <MinusOutlined />
                                      </Button>
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </React.Fragment>
                          );
                        })
                      }
                    </Form.List>
                  </>
                </>
              </div>
            </React.Fragment>
          );
        })
      }
    </Form.List>
  );
};

export default CommonInfoForm;
