import { Col, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import {
  FormButton,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { useCreateAuthorityMutation } from '../../Api/Endpoints/AuthorityEndpoints';
import { LoanAuthorityFormData } from '../../LoanTypes/LoanTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

type Props = {};

const AddAuthorityModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();

  const [addAuthority, { isLoading }] = useCreateAuthorityMutation();

  const onFinish = async (values: LoanAuthorityFormData) => {
    const body: LoanAuthorityFormData = {
      ...values,
      created_by: user?.user_id as number,
    };
    await addAuthority(body);
    dispatch(setModal(false));
    form.resetFields();
  };

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <FormInputItem
            label='Name'
            name='name'
            required
            size={12}
            mdSize={12}
          />

          <FormInputItem
            label='Contact'
            name='contact'
            size={12}
            mdSize={12}
            required
          />
          <Col
            span={6}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <Form.Item
              label='Address'
              name='address'
            >
              <TextArea
                placeholder='Address'
                maxLength={55}
              />
            </Form.Item>
          </Col>
          <FormButton
            label={'Create Authority'}
            loading={isLoading}
          />
        </Row>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default AddAuthorityModal;
