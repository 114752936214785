export const CHEQUE_LOAN = 'CHEQUE_LOAN';
export const CHEQUE_PAYMENT = 'CHEQUE_PAYMENT';
export const CHEQUE_RECEIVE = 'CHEQUE_RECEIVE';
export const MONEY_RECEIPT = 'MONEY_RECEIPT';

export const chequeTags = [
  'Cheque_Loan',
  'Cheque_Payment',
  'Cheque_Receive',
  'Loan',
  'Vendor_Payment',
  'Others_Refund_Client',
  'Money_Receipt',
];
