import { Form, Col, Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { IAllInvocieType } from '../types/AgentMoneyReceiptTypes';
import { queryType } from '../../../Configuration/Tour/Ticket/pages/TourTicket';

type CommonProps = {
  itineraryType?: queryType;
  agentID?: number;
  data?:
    | {
        id: number;
        title: string;
      }[]
    | any;
  disabled?: boolean;
  defaultValue?: number | string;
  handlechange?: (e: number) => void;
  headID?: number;
  isHeadChanged?: (arg: number) => void;
  label?: string;
  mode?: 'multiple' | 'tags' | undefined;
  name?: NamePath;
  onChange?: React.Dispatch<React.SetStateAction<number | string>>;
  placeholder?: string;
  required?: boolean;
  size?: number;
  mdSize?: number;
  smSize?: number;
  small?: boolean;
  setInvoiceNumbers?: (arg: string[]) => void;
  invoiceData: IAllInvocieType[] | undefined;
  setInvoiceId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
};
export const SelectInvoiceByAgentID = ({
  name,
  label,
  size,
  smSize,
  mdSize,
  required,
  disabled,
  agentID,
  mode,
  setSearch,
  setInvoiceNumbers,
  invoiceData,
  setInvoiceId,
}: CommonProps) => {
  // ================= InvoicebyAgentID children =================

  const handleSearch = (value: string) => {
    if (setSearch) setSearch(value);
  };

  const selectInvoiceData = invoiceData;
  const invoiceDataChildren: React.ReactNode[] = [];
  if (selectInvoiceData) {
    for (let i = 0; i < selectInvoiceData.length; i++) {
      invoiceDataChildren.push(
        <Select.Option
          key={selectInvoiceData[i].invoice_id}
          value={selectInvoiceData[i].invoice_id}
        >
          {`${selectInvoiceData[i].invoice_no}`}
        </Select.Option>
      );
    }
  }
  return (
    <Col
      span={6}
      xs={24}
      sm={smSize ? smSize : 24}
      md={mdSize ? mdSize : 24}
      lg={size ? size : 8}
    >
      <Form.Item
        name={name}
        label={label}
        rules={[
          { required: required, message: 'Select Invoice No. is required' },
        ]}
      >
        <Select
          onSearch={handleSearch}
          disabled={disabled}
          placeholder={'Select Invoice No.'}
          showSearch
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e: number) => {
            setInvoiceId(e);
          }}
          mode={mode}
        >
          {invoiceDataChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
