import { Button, Form, Input, Radio, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  ITransportFormData,
  ITransportType,
} from '../types/transportTypeTypes';
import { useEditTransportTypesMutation } from '../endpoints/transportTypeEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  TransportTypeNameList: string[] | undefined;
  info: ITransportType;
  setNull: ISetState<ITransportType | null>;
}
const TransportTypeEditModal = ({
  info,
  setNull,
  TransportTypeNameList,
}: IProps) => {
  const dispatch = useAppDispatch();
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ttype_name: info.ttype_name,
      ttype_status: info.ttype_status,
    });
  }, [info]);
  const [editTransportType, { isError, isSuccess, isLoading }] =
    useEditTransportTypesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Treansport Type has been Edited');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const handleSubmit = async (values: ITransportFormData) => {
    values.ttype_id = info.ttype_id;
    assyncWrapper(async () => {
      await editTransportType(values);
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <div>
      <Form
        labelAlign='left'
        {...layout}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name='ttype_name'
          label='Transport Type Name: '
          rules={[
            { required: true, message: 'Please input Transport type name!' },
            { max: 85, message: 'Maximum 85 characters' },
            {
              validator(_, value) {
                if (value) {
                  if (
                    TransportTypeNameList?.filter(
                      (item) => item !== info.ttype_name
                    ).includes(value)
                  ) {
                    return Promise.reject('Department Name is already Exist');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder='Edit transport type name' />
        </Form.Item>

        <Form.Item
          label='Transport Type Status'
          name='ttype_status'
          rules={[{ required: true, message: 'Please select status' }]}
        >
          <Radio.Group>
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Inactive</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
          <Button
            disabled={modalSlice.modalLoading}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default TransportTypeEditModal;
