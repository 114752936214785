import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance, Rule } from 'antd/lib/form';
import { useState } from 'react';
import CreateCommonTourItinearyModal from '../../../Configuration/Tour/Ticket/component/CreateCommonTourItineraryModal';
import { useGetAllTicketQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import { queryType } from '../../../Configuration/Tour/Ticket/pages/TourTicket';

type Props = {
  label: queryType;
  name: NamePath;
  form?: FormInstance<any>;
  isRequired?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<number | undefined>>;
  rules?: Rule[] | undefined;
  size?: number;
  setInstanceTransportSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};

export const SelectTourItinerary = ({
  label,
  name,
  form,
  onChange,
  isRequired,
  rules,
  size,
  setInstanceTransportSelect,
}: Props) => {
  const { data } = useGetAllTicketQuery(label);
  const selectData = data?.data;

  const title = (label: string) => {
    if (label == 'transport') {
      return 'Add new tour transport';
    } else if (label == 'food') {
      return 'Add new tour food';
    } else if (label == 'other-transport') {
      return 'Add new other transport';
    }
  };

  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={
            selectData[i].itinerary_id +
            ' ' +
            selectData[i].itinerary_particular
          }
          value={selectData[i].itinerary_id}
        >
          {selectData[i].itinerary_particular}
        </Select.Option>
      );
    }
  }
  const [click, setClick] = useState<boolean>();
  return (
    <Col
      xs={24}
      sm={12}
      md={8}
      lg={size ? size : 6}
    >
      <Form.Item
        name={name}
        label={`Tour ${label}`}
        required={isRequired ? isRequired : false}
        rules={[
          {
            required: isRequired || false,
            message: `${label} is required!`,
          },

          ...(rules || []),
        ]}
      >
        <Select
          allowClear
          dropdownMatchSelectWidth={180}
          placeholder={`Select ${label}`}
          showSearch
          onChange={(e) => onChange && onChange(e)}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={(menu) => (
            <>
              <CreateCommonTourItinearyModal
                queryType={label}
                setClick={setClick}
                modalTitle={title(label)}
                isPlusButton
                setInstanceTransportSelect={setInstanceTransportSelect}
              />

              <Divider style={{ margin: '8px 0' }} />

              {menu}
            </>
          )}
          open={click === true ? false : undefined}
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
