import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IVisawiseProfitLoss } from '../types/visaWiseProfitLossTypes';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { Link } from 'react-router-dom';

export const VisaWiseProfitLossColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IVisawiseProfitLoss> => {
  // const searchColumnProps = useGetSearchColumnProps<IVisawiseProfitLoss>();
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'invoice_sales_date',
      key: 'invoice_sales_date',
      render: (_, record) => {
        return `${dayjs(record.invoice_sales_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Sale Price',
      dataIndex: 'costitem_sale_price',
      key: 'costitem_sale_price',
      align: 'right',
    },
    {
      title: 'Cost Price',
      dataIndex: 'costitem_cost_price',
      key: 'costitem_cost_price',
      align: 'right',
      render: (_, record) => {
        return (
          Number(record.costitem_cost_price) * Number(record.billing_quantity)
        );
      },
    },
    {
      title: 'Profit/Loss',
      dataIndex: 'profit_loss',
      key: 'profit_loss',
      align: 'right',
      render: (_, record) => {
        return (
          Number(record.costitem_sale_price) -
          Number(record.costitem_cost_price) * Number(record.billing_quantity)
        );
      },
    },
  ];
};
