import { Form, Input, Button, Radio, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import {
  selectCommon,
  setModal,
} from '../../../../../common/slices/commonSlice';
import { ISetState } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import { useEditTourGroupMutation } from '../endpoints/tourGroupEndpoints';
import {
  ITourGroupDataType,
  TourdataEditForm,
  TourdataForm,
} from '../types/TourTypes';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  info: TourdataForm;
  setNull: ISetState<ITourGroupDataType | null>;
  //   setNull: any;
}

const TourGroupEditModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const modalSlice = useSelector(selectCommon);

  useEffect(() => {
    form.setFieldsValue({
      group_name: info.group_name,
      group_maximum_pax_allowed: info.group_maximum_pax_allowed,
      group_created_by: info.group_created_by,
      group_status: info.group_status,
    });
  }, [info]);

  const [editTourGroup, { isError, isSuccess, isLoading }] =
    useEditTourGroupMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Tour Group Name Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const user = useSelector(selectUser);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as TourdataEditForm;
      values.group_updated_by = user?.user_id;
      await editTourGroup({ body: { ...values }, id: info.group_id });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  //loading indicator
  if (isLoading) {
    return loadingIndicator;
  }
  return (
    <Form form={form} onFinish={handleSubmit} {...layout} labelAlign='left'>
      <Form.Item
        name='group_name'
        label='Group Name'
        rules={[
          { required: true, message: 'Please input Group name!' },
          { max: 65, message: 'Maximum 65 characters' },
        ]}
      >
        <Input
          onChange={(e) =>
            setNull({
              ...info,
              group_name: e.target.value,
            } as Required<ITourGroupDataType>)
          }
          value={info.group_name}
        />
      </Form.Item>
      <Form.Item
        name='group_maximum_pax_allowed'
        label='Maximum Pax allowed'
        rules={[
          { required: true, message: 'Please input Pax allowed!' },
          { max: 65, message: 'Maximum 65 characters' },
        ]}
      >
        <Input
          onChange={(e) =>
            setNull({
              ...info,
              group_maximum_pax_allowed: e.target.value,
            } as Required<ITourGroupDataType>)
          }
          value={info.group_maximum_pax_allowed}
        />
      </Form.Item>
      <Form.Item name='group_status' label='Status'>
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          disabled={modalSlice.modalLoading}
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TourGroupEditModal;
