import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateRoomTypesMutation } from '../endpoints/roomTypesEndpoints';
import { IRoomTypeFormData } from '../types/roomTypesTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const RoomTypeAddModal = ({
  roomTypeNameList,
}: {
  roomTypeNameList: string[];
}) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const [addRoomTypes, { isError, isSuccess, isLoading }] =
    useCreateRoomTypesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('RoomTypes Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IRoomTypeFormData) => {
    const body: IRoomTypeFormData = {
      ...values,
      rtype_created_by: user?.user_id as number,
    };
    await addRoomTypes(body);
    dispatch(setModal(false));
    form.resetFields();
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        name='rtype_name'
        rules={[
          { required: true, message: 'Please input room type name!' },
          { max: 85, message: 'Maximum 85 characters' },
          {
            validator(_, value) {
              if (value) {
                if (roomTypeNameList.includes(value)) {
                  return Promise.reject('Department Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter room type name' />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit{' '}
        </Button>
      </Form.Item>

      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default RoomTypeAddModal;
