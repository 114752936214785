import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';

import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAgent,
  SelectClients,
  SelectEmployee,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllAgentProfileQuery } from '../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
type Props = {
  form?: FormInstance<any>;
  disabled?: boolean;
};

export default function InvNonComFormHeader({ form }: Props) {
  const { nonComissionEditId } = useParams();
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceAgnetSelect, setInstanceAgentSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  useEffect(() => {
    if (instanceClientsSelect) {
      form?.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgnetSelect) {
      form?.setFieldValue('invoice_agent_id', instanceAgnetSelect);
    } else if (instanceSalesEmpSelect) {
      form?.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    }
  }, [instanceAgnetSelect, instanceClientsSelect, instanceSalesEmpSelect]);
  // GET ALL AGENTS
  const { data } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = data?.data;
  return (
    <div className='border p-5'>
      <Row gutter={[8, 0]}>
        <SelectClients
          label='Search Client:'
          name='invoice_combclient_id'
          required
          size={4}
          mdSize={8}
          smSize={12}
          disabled={!!nonComissionEditId}
          setInstanceClientSelect={setInstanceClientSelect}
        />
        <SelectEmployee
          label='Sales By:'
          name='invoice_sales_man_id'
          required
          size={4}
          setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
        />
        <FormInputItem
          label='Invoice No:'
          name='invoice_no'
          required
          readOnly
          size={4}
        />
        <DateInput
          label='Sales Date:'
          name='invoice_sales_date'
          required
          size={4}
          disableFeatureDate
        />
        <DateInput label='Due Date:' name='invoice_due_date' size={4} />
        <SelectAgent
          label='Select Agent:'
          name={'invoice_agent_id'}
          size={4}
          getAllAgentProfile={getAllAgentProfile || []}
          setInstanceAgentSelect={setInstanceAgentSelect}
          isPercent
        />
      </Row>
    </div>
  );
}
