import { CloseOutlined } from '@ant-design/icons';
import { Divider, FormListOperation, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectProduct,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
};

const UrmrahRefundInputItems = ({ subField, subOpt, form }: Props) => {
  // Client Charge Calculation------------------------------

  const billing_remaining_quantity = useWatch(
    ['billing_info', subField.name, 'billing_remaining_quantity'],
    form
  );

  const refund_quantity = useWatch(
    ['billing_info', subField.name, 'refund_quantity'],
    form
  );

  const client_unit_price = useWatch(
    ['billing_info', subField.name, 'billing_unit_price'],
    form
  );

  const client_charge = useWatch(
    ['billing_info', subField.name, 'client_charge'],
    form
  );

  const client_refund =
    (Fixed2(client_unit_price) - Fixed2(client_charge)) *
    Fixed2(refund_quantity);

  // Vendor Charge Calculation------------------------------

  const vendor_charge = useWatch(
    ['billing_info', subField.name, 'vendor_charge'],
    form
  );

  const vendor_unit_price = useWatch(
    ['billing_info', subField.name, 'billing_cost_price'],
    form
  );

  const vendor_refund =
    (Fixed2(vendor_unit_price) - Fixed2(vendor_charge)) *
    Fixed2(refund_quantity);

  useEffect(() => {
    form.setFields([
      {
        name: ['billing_info', subField.name, 'client_refund'],
        value: Fixed2(client_refund),
      },
    ]);

    form.setFields([
      {
        name: ['billing_info', subField.name, 'vendor_refund'],
        value: Fixed2(vendor_refund),
      },
    ]);
  }, [client_refund, vendor_refund]);

  return (
    <Row className='border' key={subField.key} gutter={10}>
      <Divider>Refund - {subField.key + 1}</Divider>

      <FormInput
        name={[subField.name, 'billing_id']}
        label='Billing Id'
        readonly
        hidden
        size={6}
      />
      <SelectProduct
        name={[subField.name, 'billing_product_id']}
        label='Product'
        disabled
        size={6}
      />

      <FormInput
        name={[subField.name, 'billing_quantity']}
        label='Tot. Qty.'
        readonly
        size={6}
      />

      <FormInput
        name={[subField.name, 'billing_remaining_quantity']}
        label='Rem. Qty.'
        readonly
        size={6}
      />

      <FormInput
        name={[subField.name, 'billing_cost_price']}
        label='Cost Price'
        readonly
        size={6}
      />

      <NumberInput
        name={[subField.name, 'refund_quantity']}
        label='Refund Quantity'
        size={6}
        required
        rules={[
          {
            validator: (_, value) => {
              if (value > Number(billing_remaining_quantity || 0)) {
                return Promise.reject('Not more than Rem. Qty.');
              }
              return Promise.resolve();
            },
          },
        ]}
      />

      <FormInput
        name={[subField.name, 'billing_unit_price']}
        label='Client Unit Price'
        readonly
        size={6}
      />
      <NumberInput
        name={[subField.name, 'client_charge']}
        label='Client Charge'
        size={6}
      />
      <NumberInput
        name={[subField.name, 'client_refund']}
        label='Client Refund'
        size={6}
      />

      <SelectVendors
        name={[subField.name, 'comb_vendor']}
        label='Vendor'
        disabled
        size={6}
      />
      <FormInput
        name={[subField.name, 'billing_cost_price']}
        label='Vendor Unit Price'
        readonly
        size={6}
      />
      <NumberInput
        name={[subField.name, 'vendor_charge']}
        label='Vendor Charge'
        size={6}
      />
      <NumberInput
        name={[subField.name, 'vendor_refund']}
        label='Vendor Refund'
        size={5}
      />

      <CloseOutlined
        onClick={() => {
          subOpt.remove(subField.name);
        }}
      />
    </Row>
  );
};

export default UrmrahRefundInputItems;
