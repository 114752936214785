import { Table } from 'antd';
import { pagination } from '../../../../../common/utils/common.utils';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useGetAllClientDetailRefundQuery } from '../../api/endpoints/clientEndpoints';
import {
  IClientAirTicketRefundType,
  IClientOtherRefundType,
} from '../../types/clientInterfaces';
import {
  clientDetailsAirTicketRefundUtils,
  clientDetailsOtherRefundUtils,
} from '../../utils/utils_tsx/clientDetailsRefundUtils';

type Props = { id: string };

const ClientDetailRefundProduct = ({ id }: Props) => {
  const { data, isLoading } = useGetAllClientDetailRefundQuery(id, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturnAirTicket: IClientAirTicketRefundType[] = [];
      const dataToReturnOtherRefund: IClientOtherRefundType[] = [];
      if (data) {
        for (let i = 0; i < data.clientAirticketRefund.length; i++) {
          const element = data.clientAirticketRefund[i];
          dataToReturnAirTicket.push({
            ...element,
            key: i + 1,
          });
        }
      }
      if (data) {
        for (let i = 0; i < data.clientOtherRefund.length; i++) {
          const element = data.clientOtherRefund[i];
          dataToReturnOtherRefund.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return {
        ...cache,
        data: { dataToReturnAirTicket, dataToReturnOtherRefund },
      };
    },
  });
  return (
    <>
      <FormHeaderTitle margintop={1} title='Airticket Refunds' />
      <Table
        columns={clientDetailsAirTicketRefundUtils()}
        bordered
        dataSource={data.dataToReturnAirTicket}
        size='small'
        pagination={pagination(data.dataToReturnAirTicket.length)}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />

      <FormHeaderTitle title='Other Refunds' />
      <Table
        size='small'
        columns={clientDetailsOtherRefundUtils()}
        bordered
        dataSource={data.dataToReturnOtherRefund}
        pagination={pagination(data.dataToReturnOtherRefund.length)}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </>
  );
};

export default ClientDetailRefundProduct;
