import { message } from 'antd';
import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  IAgentIncentiveData,
  IAgentIncentiveIncomeFormData,
  IDeleteClientIncentive,
  ISelectAgent,
  IViewAgentIncentive,
} from '../../../AccountsTypes/AccountsTypes';
import { INCENTIVE_INCOME } from '../../../Api/Constants/constants';

export const client_IncentiveIncomeEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // Select Agent or Get All Agent
    selectAgent: build.query<HTTPResponse<ISelectAgent[]>, string>({
      query: (string) => ({
        url: `/agent-profile/view_all?search=${string}`,
      }),
      providesTags: () => [{ type: 'AgentProfile' }],
    }),

    // get agent all incentive_income
    getAgentIncentiveIncomes: build.query<
      HTTPResponse<IAgentIncentiveData[]>,
      { trash: number; current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/agent-profile/incentive?trash=${arg.trash}&page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Incentive_Income', id: INCENTIVE_INCOME }],
    }),

    // get agent incentive income by id
    getViewAgentIncentiveIncome: build.query<
      HTTPResponse<IViewAgentIncentive[]>,
      number
    >({
      query: (id) => ({ url: `/agent-profile/incentive/${id}` }),
      providesTags: () => [{ type: 'Incentive_Income', id: INCENTIVE_INCOME }],
    }),

    //create agent incentive income
    createAgentIncentiveIncome: build.mutation<
      HTTPResponse<void>,
      IAgentIncentiveIncomeFormData
    >({
      query: (body) => ({
        url: `/agent-profile/incentive`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),

    //edit agent incentive income
    editAgentIncentiveIncome: build.mutation<void, IViewAgentIncentive>({
      query: (body) => ({
        url: `/agent-profile/incentive/${body.incentive_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),

    //delete agent incentive income
    deleteAgentIncentiveIncome: build.mutation<void, IDeleteClientIncentive>({
      query: (body) => ({
        url: `/agent-profile/incentive/${body.id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Incentive Income Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
      ],
    }),
  }),
});

export const {
  useSelectAgentQuery,
  useGetAgentIncentiveIncomesQuery,
  useCreateAgentIncentiveIncomeMutation,
  useLazyGetViewAgentIncentiveIncomeQuery,
  useGetViewAgentIncentiveIncomeQuery,
  useEditAgentIncentiveIncomeMutation,
  useDeleteAgentIncentiveIncomeMutation,
} = client_IncentiveIncomeEndpoints;
