import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TOTALDUEADVANCEAGENTS } from '../../Api/constants';
import { ITotal_Due_Advance_AgentDataType } from '../types/Total_Due_Advance_ClientTypes';

export const Total_Due_Advance_Agent_Endpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all total due_advance of clients
    getAllDueAdvanceAgents: build.query<
      HTTPResponse<ITotal_Due_Advance_AgentDataType[]>,
      {
        agent_id: string | number;
        query: string;
      }
    >({
      query: ({ agent_id, query }) => ({
        url: `/report/due-advance/agents/${agent_id}${query}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceAgents',
          id: TOTALDUEADVANCEAGENTS,
        },
      ],
    }),
  }),
});

export const { useLazyGetAllDueAdvanceAgentsQuery } =
  Total_Due_Advance_Agent_Endpoints;
