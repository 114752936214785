import { ColumnsType } from 'antd/lib/table';
import { IAirTicketInfoByNo } from '../../../RefundTypes/RefundTypes';

export const VendorRefundChargeUtils = (): ColumnsType<IAirTicketInfoByNo> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },

    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (_, data) => Number(data.airticket_purchase_price),
    },

    {
      title: 'Refund Charge By Vendor',
      key: 'vrefund_charge_amount',
      dataIndex: 'vrefund_charge_amount',
    },
  ];
};
