import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { InvoiceHajjType } from '../Types/InvoiceHajjTypes';
import HotelInfoSubCom from './HotelInfoSubCom';

type Props = { form: FormInstance<any>; edit_data?: InvoiceHajjType };

export default function NewAddhajjFormHotelInfo({ form, edit_data }: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Hotel Information 🏨' />

      <Form.List name='hotel_information' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map(({ key, name }, index) => (
            <HotelInfoSubCom
              add={add}
              fields={fields}
              form={form}
              index={index}
              name={name}
              remove={remove}
              edit_data={edit_data}
              key={key}
            />
          ))
        }
      </Form.List>
    </div>
  );
}
