import { Button, Form, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  FormButton,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  useGetHajiInfoByTrakingNoMutation,
  useLazyGetPreRegTrakingNoQuery,
  usePostCancelHajiPreRegMutation,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { ITrackingNoGetAll } from '../../Types/HajjiManagement.interface';
import Table, { ColumnsType } from 'antd/es/table';
import { SelectDataInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useWatch } from 'antd/es/form/Form';
import { ArrowLeftOutlined } from '@ant-design/icons';

type Props = {};

const PostCancelPreReg = (props: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const [search, setSearch] = useState<string>('');
  const [totalCharge, setTotalCharge] = useState<number>(0);
  const [govtCharge, setGovtCharge] = useState<number>(0);
  const ticket_numbers = useWatch(['ctrcknumber_number'], form);
  const navigate = useNavigate();

  // ============= @Query==================
  const [post, { isError, isSuccess, isLoading, error }] =
    usePostCancelHajiPreRegMutation();

  const [getPreRegTrakingNo, { data: allTrakingNo }] =
    useLazyGetPreRegTrakingNoQuery();

  const [getHajiInfo, { data: hajiInfo, isLoading: isGetTrakingNoLoading }] =
    useGetHajiInfoByTrakingNoMutation();

  const handleViewHajiInfo = () => {
    if (ticket_numbers && ticket_numbers?.length) {
      getHajiInfo(ticket_numbers);
    }
  };

  // refactch api
  useEffect(() => {
    getPreRegTrakingNo(search);
  }, [search]);

  // ============ set total charge
  useEffect(() => {
    form.setFieldValue('cancel_office_charge', totalCharge - govtCharge);
  }, [totalCharge, govtCharge]);

  // ================ form submit hanlde
  const onFinish = (values: {
    cancel_govt_charge: number;
    cancel_office_charge: number;
    cancel_total_charge: number;
    ctrcknumber_number: string[];
  }) => {
    const {
      cancel_govt_charge,
      cancel_office_charge,
      cancel_total_charge,
      ctrcknumber_number,
    } = values;

    if (!ctrcknumber_number?.length) {
      return message.error('Pleace select a haji by tracking no');
    }

    const bodyValue = {
      cancel_created_by: user?.user_id as number,
      cancel_total_charge: cancel_total_charge,
      cancel_govt_charge: cancel_govt_charge,
      cancel_office_charge: cancel_office_charge,
      tracking_no: ctrcknumber_number,
    };

    post(bodyValue);
  };

  const trackingNoSelect = allTrakingNo?.data?.map((item) => {
    return {
      id: item.hajiinfo_tracking_number,
      title: item.hajiinfo_tracking_number,
    };
  });

  // ============== notification
  const nevigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Cancel Pre Reg successfully');
      nevigate('/haji/transfer/pre_registration_cancel_list');
    }
    if (isError) {
      message.error('Thare was an error');
    }
  }, [isLoading]);

  const columns: ColumnsType<ITrackingNoGetAll> = [
    {
      title: 'SL',
      render: (_, data, index) => {
        return <> {index + 1} </>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'hajiinfo_name',
      key: 'hajiinfo_name',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },
    {
      title: ' Voucher No',
      dataIndex: 'haji_info_vouchar_no',
      key: 'haji_info_vouchar_no',
    },
    {
      title: 'NID',
      dataIndex: 'hajiinfo_nid',
      key: 'hajiinfo_nid',
    },
    {
      title: 'Serial No',
      dataIndex: 'hajiinfo_serial',
      key: 'hajiinfo_serial',
    },
    {
      title: 'Gender',
      dataIndex: 'hajiinfo_gender',
      key: 'hajiinfo_gender',
    },
  ];

  return (
    <>
      <BreadCrumb arrOfOption={['Create Cancel Pre Registration']} />
      <Form onFinish={onFinish} layout='vertical' form={form}>
        <Row
          style={{
            paddingTop: '15px',
            marginBottom: '20px',
          }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
        >
          <Button
            style={{ marginBottom: '20px' }}
            type='primary'
            onClick={(e) => {
              navigate(-1);
            }}
          >
            <ArrowLeftOutlined /> Return To List
          </Button>
          <Row
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          >
            <SelectDataInput
              required={true}
              name={['ctrcknumber_number']}
              onSearch={(value) => {
                setSearch(value);
              }}
              placeholder='Select tracking no.'
              label='Tracking No'
              data={trackingNoSelect || []}
              loading={isLoading}
              mode='multiple'
              allowClear={true}
            />

            <Button
              disabled={ticket_numbers?.length ? false : true}
              type='primary'
              onClick={handleViewHajiInfo}
              loading={isGetTrakingNoLoading ? true : false}
            >
              Haji information
            </Button>
          </Row>
          <FormHeaderTitle title='Haji Information 🕌' />
          <NumberInput
            label='Total Charge'
            name={'cancel_total_charge'}
            onChange={(value) => setTotalCharge(Number(value))}
            required
            min='0'
            maxChar={10}
          />
          <NumberInput
            label='Govt Charge'
            name={'cancel_govt_charge'}
            onChange={(value) => setGovtCharge(Number(value))}
            required
            min='0'
            maxChar={10}
          />
          <NumberInput
            label='Office Charge'
            name={'cancel_office_charge'}
            readOnly
            min='0'
            maxChar={10}
          />
        </Row>

        <Table
          size='small'
          bordered
          className='invoiceBillingTable mt-5'
          rowClassName={'invoiceBillingTd'}
          columns={columns}
          dataSource={hajiInfo?.data?.map((item, index) => ({
            ...item,
            key: index,
          }))}
          pagination={false}
          loading={isLoading}
          style={{ marginTop: '25px' }}
          scroll={{ x: true }}
        />

        <FormButton label='CANCEL PRE REG HAJJI' loading={isLoading} />
      </Form>
    </>
  );
};

export default PostCancelPreReg;
