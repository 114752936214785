import { Button, Col, Form, Row } from 'antd';
import React from 'react';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { SelectRoomType } from '../../../components/common/FormItem/SelectCustomFeilds';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'rc-field-form';
import { IHotel_information } from '../Types/InvoiceUmrahTypes';

type Props = {
  name: number;
  index: number;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  hotel_information?: IHotel_information[];
};

export default function InvoiceUmrahHotelListItems({
  name,
  index,
  add,
  remove,
  form,
  hotel_information,
}: Props) {
  const { id } = useParams();
  const is_deleted: 0 | 1 = useWatch(
    ['hotel_information', index, 'is_deleted'],
    form
  );
  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      gutter={[10, 10]}
    >
      <>
        <FormInputItem
          name={[name, 'hotel_name']}
          label='Hotel Name'
          size={6}
        />

        <DateInput
          name={[name, 'hotel_check_in_date']}
          label='Check In Date'
          size={6}
        />

        <DateInput
          name={[name, 'hotel_check_out_date']}
          label='Check Out Date'
          size={5}
        />

        <SelectRoomType
          name={[name, 'hotel_room_type_id']}
          label='Room type'
          size={5}
        />

        {/* HOTEL DELETE DETECT BY THIS is_deleted FIELD */}
        {id && <BillingIsDeleted name={[name, 'is_delete']} />}
      </>

      <Form.Item label=' '>
        {index === 0 ? (
          <Col lg={1}>
            <Form.Item label={false}>
              <Button type='primary' onClick={() => add()}>
                <PlusOutlined />
              </Button>
            </Form.Item>
          </Col>
        ) : (
          <Col lg={1}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'hotel_information',
                  name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['hotel_information', name, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (
                    hotel_information?.length &&
                    index >= hotel_information.length
                  )
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Col>
        )}
      </Form.Item>
    </Row>
  );
}
