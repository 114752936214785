import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { CLIENTWISEPASSENGER } from '../../Api/constants';
import { IClientWisePassenDataType } from '../types/clientWisePassengerTypes';

export const clientWisePassengerEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all PreRegistration
    getCleintWisePassenger: build.query<
      HTTPResponse<IClientWisePassenDataType[]>,
      {
        invoice_combclient_id: string;
        query: string;
      }
    >({
      query: ({ invoice_combclient_id, query }) => ({
        url: `/report/client-wise-passenger-list/${invoice_combclient_id}${query}`,
      }),
      providesTags: [{ type: 'ClientWisePassenger', id: CLIENTWISEPASSENGER }],
    }),
  }),
});

export const { useLazyGetCleintWisePassengerQuery } =
  clientWisePassengerEndpoints;
