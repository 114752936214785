import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  CompanyDataType,
  ICompanyEditFormData,
  ICompanyFormData,
} from '../types/companyTypes';

export const COMPANIES = 'COMPANIES';
export const companiesEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //  get all companies
    getAllCompanies: build.query<HTTPResponse<CompanyDataType[]>, void>({
      query: () => ({ url: `/configuration/companies/view-all` }),
      providesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),

    //  get all companies with pagination
    getAllCompaniesWithPag: build.query<
      HTTPResponse<CompanyDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/companies?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),

    //create companies
    createCompanies: build.mutation<void, ICompanyFormData>({
      query: (body) => ({
        url: `/configuration/companies/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),

    //edit companies
    editCompanies: build.mutation<void, ICompanyEditFormData>({
      query: (body) => ({
        url: `/configuration/companies/update/${body.company_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),

    //delete companies
    deleteCompanies: build.mutation<void, CompanyDataType>({
      query: (body) => ({
        url: `/configuration/companies/delete/${body.company_id}`,
        method: 'DELETE',
        body: { deleted_by: body.company_created_by },
      }),

      invalidatesTags: () => [{ type: 'Companies', id: COMPANIES }],
    }),
  }),
});

export const {
  useGetAllCompaniesQuery,
  useCreateCompaniesMutation,
  useEditCompaniesMutation,
  useDeleteCompaniesMutation,
  useGetAllCompaniesWithPagQuery,
} = companiesEndpoints;
