import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { IPreregistrationApiDataType } from '../types/preRegistration';
import { Link } from 'react-router-dom';

export const preRegistrationColumn =
  (): ColumnsType<IPreregistrationApiDataType> => {
    const [page, setPage] = useState(1);

    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        render: (value, item, index) => {
          return <>{(page - 1) * 10 + index + 1}</>;
        },
      },
      {
        title: 'Client Name',
        dataIndex: 'client_name',
        key: 'client_name',
        render: (_, record) => (
          <Link
            to={
              record.invoice_client_id
                ? `/clients/details/client-${record.invoice_client_id}`
                : `/combineClients/details/${record.invoice_combined_id}`
            }
          >
            {record.client_name}
          </Link>
        ),
      },
      {
        title: 'Total Haj',
        dataIndex: 'total_haj',
        key: 'total_haj',
        align: 'center',
      },
      {
        title: 'Haji NID',
        dataIndex: 'hajiinfo_nid',
        key: 'hajiinfo_nid',
        align: 'center',
      },
      {
        title: 'Haji Name',
        dataIndex: 'hajiinfo_name',
        key: 'hajiinfo_name',
        align: 'center',
      },
      {
        title: 'Tracking ID.',
        dataIndex: 'hajiinfo_tracking_number',
        key: 'hajiinfo_tracking_number',
        align: 'center',
      },
      {
        title: 'Vouchar No',
        dataIndex: 'haji_info_vouchar_no',
        key: 'haji_info_vouchar_no',
        align: 'center',
      },
      {
        title: 'Group',
        dataIndex: 'group_name',
        key: 'group_name',
        align: 'center',
      },
    ];
  };
