import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import {
  DateInput,
  FormButton,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { IAccountVendorBillAdjustmentFormData } from '../../AccountsTypes/AccountsTypes';
import { useCreateVendorBillAdjustmentMutation } from '../../Api/Endpoints/vendorBillAdjustmentEndpoints';
import Select_Vendor from '../../components/Select_Vendor';

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Vendor_Bill_Add_Modal = ({ setIsModalOpen }: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const [balance, setBalance] = useState<number>(0);
  const [amount, setAmount] = useState<number | null>();
  const [adjustmentType, setAdjustmentType] = useState<string>('INCREASE');

  const [addVendorBill, { isError, isLoading, isSuccess }] =
    useCreateVendorBillAdjustmentMutation();

  useEffect(() => {
    if (isSuccess) {
      setBalance(0);
      setAmount(0);
      setBalanceValue(0);
      form.resetFields();
      form.setFieldValue('bill_create_date', dayjs());
      setIsModalOpen(false);
    }

    isError && toasterNotification('error', 'Something went wrong');
  }, [isError, isSuccess]);

  useEffect(() => {
    if (
      balance !== undefined &&
      amount !== undefined &&
      adjustmentType &&
      amount !== null
    ) {
      if (adjustmentType == 'DECREASE') {
        form.setFieldValue('new_balance', balance - amount);
      } else if (adjustmentType == 'INCREASE') {
        form.setFieldValue('new_balance', balance + amount);
      }
    }
  }, [balance, amount, adjustmentType]);

  const handleSubmit = async (values: IAccountVendorBillAdjustmentFormData) => {
    const body: IAccountVendorBillAdjustmentFormData = {
      ...values,
      bill_create_date: dayjs(values.bill_create_date).format('YYYY-MM-DD'),
      bill_created_by: user?.user_id as number,
    };
    await addVendorBill(body);
  };

  const setBalanceValue = (arg: number) => {
    form.setFieldValue('due_advance', Math.abs(arg));
    setBalance(arg);
  };

  useEffect(() => {
    form.setFieldValue('bill_create_date', dayjs());
  }, []);
  return (
    <div>
      <Form layout='vertical' form={form} onFinish={handleSubmit}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Select_Vendor
            show_balance={true}
            setBalance={setBalanceValue}
            form={form}
          />

          <Col span={8} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: `Adjustment Type is required!`,
                },
              ]}
              name='bill_type'
              label='Adjustment Type'
            >
              <Select
                placeholder={'Select Adjustment Type'}
                onSelect={(e: string) => {
                  setAdjustmentType(e);
                }}
                allowClear
              >
                <Select.Option key={1} value={'INCREASE'}>
                  Increase
                </Select.Option>
                <Select.Option key={2} value={'DECREASE'}>
                  Decrease
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              name='bill_amount'
              label='Amount'
              rules={[
                {
                  required: true,
                  message: `Amount is required!`,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder='Enter Amount'
                onChange={(e: number | null) => {
                  setAmount(Number(e));
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              name='new_balance'
              rules={[{ required: true }]}
              label='New Balance: '
            >
              <Input
                placeholder='Vendor Balance'
                readOnly
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <DateInput
            required
            name='bill_create_date'
            label='Payment Date'
            size={12}
          />

          <TextAreaInput name='bill_note' label='Note' size={24} />

          <FormButton
            label={'Create Vendor Bill Adjustment'}
            loading={isLoading}
          />
        </Row>
        {isLoading && <LoadingIndicator />}
      </Form>
    </div>
  );
};

export default Vendor_Bill_Add_Modal;
