type Props = {
  topHeight: number;
  footerHeight: number;
  pageHeight: number;
  items: {
    height: number;
    identifier: string;
  }[];
};

export default function getInvoiceViewPageController({
  topHeight,
  footerHeight,
  pageHeight,
  items,
}: Props) {
  // initialize variables
  let totalHeight = topHeight;
  const result =
    items.map((item, index) => {
      if (index === 0) {
        // update total height
        totalHeight += item.height;
        return {
          identifier: item.identifier,
          marginTop: 0,
          bottomIdentifier: items[index + 1].identifier,
        };
      } else {
        // get current page
        const currentPage = Math.floor(totalHeight / pageHeight);
        // get current page content end position
        const currentPositionOfPage = totalHeight % pageHeight;
        // remaining height of page
        const remainingHeight = pageHeight - currentPositionOfPage;
        // new height of total content
        const newHeight = totalHeight + item.height;
        // if item height getting long of a4 page we will just place it

        if (item.height >= 11) {
          // update total height
          totalHeight += item.height;
          return {
            identifier: item.identifier,
            marginTop: 0,
            bottomIdentifier: items[index + 1]?.identifier,
          };
        } else if (item.height > remainingHeight) {
          // update total height
          totalHeight += remainingHeight + 0.5 + item.height;
          return {
            identifier: item.identifier,
            marginTop: remainingHeight + 0.5, // 0.5 for page top margin
            bottomIdentifier: items[index + 1]?.identifier,
          };
        } else if (item.height < remainingHeight) {
          // update total height
          totalHeight += item.height;
          return {
            identifier: item.identifier,
            marginTop: 0,
            bottomIdentifier: items[index + 1]?.identifier,
          };
        }
      }
    }) || [];

  return result;
}
