import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { ISalesManCommissionTableData } from '../types/salesManCommissionTypes';

const salesManCommissionColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ISalesManCommissionTableData> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },

    {
      title: 'Date',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (_, data) => dayjs(data.create_date).format('DD-MM-YYYY'),
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
                }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Sales By',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },
    {
      title: 'Sales Price',
      dataIndex: 'sales_price',
      key: 'sales_price',
      align: 'right',
    },
    {
      title: 'Collected Amount',
      dataIndex: 'client_payment',
      key: 'client_payment',
      align: 'right',
      render(_, data) {
        return Number(data.client_payment);
      },
    },
    {
      title: 'Due',
      dataIndex: 'client_payment',
      key: 'client_payment',
      align: 'right',
      render(value, record, index) {
        return record?.sales_price - Number(record?.client_payment || 0);
      },
    },
  ];
};

export default salesManCommissionColumn;
