import { Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAgent,
  SelectClients,
  SelectEmployee,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllAgentProfileQuery } from '../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { ISelectClientDetails } from '../types/invoiceAirTicketTypes';
type Props = {
  form: FormInstance<any>;
};

export default function AirticketHeader({ form }: Props) {
  /* set ClientName instance when create new one */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceAgnetSelect, setInstanceAgentSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const { airticketInvoiceId } = useParams();
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgnetSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgnetSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    }
  }, [instanceAgnetSelect, instanceSalesEmpSelect, instanceClientsSelect]);
  // SET AGENT PERCENTAGE VALUE
  const { data } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = data?.data;

  // @set invoice_no
  const {
    data: invoiceIdData,
    isLoading,
    isSuccess,
  } = useGetInvoiceNoQuery('AIT');
  useEffect(() => {
    if (isSuccess) {
      form.setFieldValue('invoice_no', invoiceIdData?.data);
    }
  }, [invoiceIdData, isSuccess]);

  const [clientDetails, setClientDetails] = useState<ISelectClientDetails>();

  const getPrevFiledValue = form.getFieldValue('invoice_walking_customer_name');

  return (
    <Row className='border p-5' gutter={[8, 0]}>
      {isLoading && <LoadingIndicator />}
      <SelectClients
        label='Search Client:'
        name='invoice_combclient_id'
        required
        size={4}
        mdSize={8}
        xsSize={5}
        smSize={12}
        disabled={!!airticketInvoiceId}
        setClientDetails={setClientDetails}
        setInstanceClientSelect={setInstanceClientSelect}
      />
      {(clientDetails?.client_walking_customer === 1 || getPrevFiledValue) && (
        <FormInput
          size={4}
          mdSize={8}
          smSize={12}
          label='Client Name'
          name='invoice_walking_customer_name'
        />
      )}

      <SelectEmployee
        label='Sales By:'
        name='invoice_sales_man_id'
        required
        size={4}
        setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
      />
      <FormInputItem
        readOnly
        required
        label='Invoice No:'
        name='invoice_no'
        size={4}
      />

      <DateInput
        label='Sales Date:'
        name='invoice_sales_date'
        required
        size={4}
        disableFeatureDate
      />
      <DateInput label='Due Date:' name='invoice_due_date' size={4} />

      <SelectAgent
        label='Select Agent:'
        name={'invoice_agent_id'}
        size={4}
        getAllAgentProfile={getAllAgentProfile || []}
        setInstanceAgentSelect={setInstanceAgentSelect}
        isPercent
      />
    </Row>
  );
}
