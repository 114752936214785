import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { DATEWISEEXPENSE } from '../../Api/constants';
import {
  IDateWiseExpenseDataType,
  IDateWiseExpenseFormDataType,
} from '../types/dateWiseExpenseReportType';

export const DateWiseExpenseReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all DateWiseExpenseReport
    getDateWiseExpenseReport: build.query<
      HTTPResponse<IDateWiseExpenseDataType[]>,
      IDateWiseExpenseFormDataType
    >({
      query: (body) => ({
        url: `/report/expense-report?${body.date_range}`,
      }),
      providesTags: [{ type: 'DateWiseExpenseReport', id: DATEWISEEXPENSE }],
    }),
  }),
});

export const { useLazyGetDateWiseExpenseReportQuery } =
  DateWiseExpenseReportEndpoints;
