import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteGroupMutation } from '../endpoints/groupEndpoints';
import { GroupDataType } from '../types/GroupsTypes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<GroupDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const groupCollumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<GroupDataType> => {
  const searchColumnProps = useGetSearchColumnProps<GroupDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const handleClick = (record: GroupDataType) => {
    setEditInfo(record);
    showModal();
  };
  const [deleteGroup, { isError, isSuccess, isLoading }] =
    useDeleteGroupMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Group Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (values: GroupDataType, index: number) => {
    setColumnIndex(index);
    await deleteGroup({ ...values, group_updated_by: user?.user_id });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Group name',
      dataIndex: 'group_name',
      key: 'group_name',
      ...searchColumnProps('group_name'),
    },
    {
      title: 'Group type',
      dataIndex: 'group_type',
      key: 'group_type',
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}
          {permission?.['delete:any'] && record.agency_id && (
            <Button size='small' type='primary' danger loading={isLoading}>
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
