import { ColumnsType } from 'antd/lib/table';
import { IOthersRefundSingleVendorInfo } from '../../../RefundTypes/RefundTypes';

export const OthersRefundClientSingleViewUtils = (
  type: 'client' | 'vendor'
): ColumnsType<IOthersRefundSingleVendorInfo> => {
  return [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },

    {
      title: 'Quantity',
      dataIndex: 'vrefund_quantity',
      key: 'vrefund_quantity',
    },

    {
      title: 'Refund Charge',
      align: 'right',
      key:
        type === 'client' ? 'vrefund_client_refund_charge' : 'vrefund_charge',
      dataIndex:
        type === 'client' ? 'vrefund_client_refund_charge' : 'vrefund_charge',
        render: (data) => {
          return <>{Number(data) || 0}</>;
        },
    },
    {
      title: 'Refund Amount',
      align: 'right',
      key:
        type === 'client' ? 'vrefund_client_refund_amount' : 'vrefund_amount',
      dataIndex:
        type === 'client' ? 'vrefund_client_refund_amount' : 'vrefund_amount',

      render: (data) => {
        return <>{Number(data) || 0}</>;
      },
    },

    // {
    //   title: 'Net Refund Amount',
    //   key:
    //     type === 'client'
    //       ? 'net_client_refund_amount'
    //       : 'net_vendor_refund_amount',
    //   render: (_, record) =>
    //     type === 'vendor' ? (
    //       <>
    //         {(
    //           Number(record.vrefund_amount) - Number(record.vrefund_charge)
    //         ).toFixed(2)}
    //       </>
    //     ) : (
    //       <>
    //         {(
    //           Number(record.vrefund_client_refund_amount) -
    //           Number(record.vrefund_client_refund_charge)
    //         ).toFixed(2)}
    //       </>
    //     ),
    // },
  ];
};
