import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Select, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  NumberInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectInvoiceByClientID,
  SelectOptionsInput,
  SelectTicketByInvoiceID,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useCreatePartialRefundMutation,
  useLazyGetPartialRefundInfoQuery,
} from '../../Api/Endpoints/PartialRefundEndpoints';
import {
  IPartialRefundFormItem,
  IPartialRefundSubmitBody,
} from '../../RefundTypes/RefundTypes';

type Props = {};

const AddPartialRefunds = (props: Props) => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const isVendorChanged = useRef(false);
  const [form] = Form.useForm();
  const [clientId, setClientId] = useState<string>();
  const [clientPayType, setClientPayType] = useState<string>('ADJUST');
  const [vendorPayType, setVendorPayType] = useState<string>('ADJUST');
  const [paymentMethod, setPaymentMethod] = useState<string>();

  const invoice_id = useWatch('select_invoice_id', form);
  const ticketId = useWatch('airticket_id', form);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  const [getAllPartialRefund, { data: airticketInfo }] =
    useLazyGetPartialRefundInfoQuery();

  const [createPartialRefund, { isError, isLoading, isSuccess }] =
    useCreatePartialRefundMutation();

  useEffect(() => {
    form.setFieldsValue({ airticket_id: undefined });
  }, [invoice_id]);

  useEffect(() => {
    form.setFieldValue('refund_date', dayjs());
    form.setFieldValue('vprfnd_payment_type', 'ADJUST');
    form.setFieldValue('client_payment_type', 'ADJUST');
  }, []);

  const handleSubmit = () => {
    getAllPartialRefund({ airticket_id: ticketId, invoice_id: invoice_id });
  };

  useEffect(() => {
    if (ticketId === null) {
      form.setFieldValue('ticket_no', undefined);
      form.setFieldValue('vprfnd_refund_vendor_id', undefined);
      form.setFieldValue('vprfnd_base_fare_amount', undefined);
      form.setFieldValue('client_total_amount', undefined);
    }
  }, []);

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (airticketInfo?.data) {
      const {
        airticket_id,
        airticket_ticket_no,
        invoice_no,
        client_name,
        client_price,
        vendor_price,
        vendor_name,
        airticket_tax,
        vendor_id,
        airticket_base_fare,
      } = airticketInfo?.data;
      let vendors;
      if (airticketInfo?.data?.vendor_id) {
        vendors = `vendor-${airticketInfo?.data?.vendor_id}`;
      } else if (airticketInfo?.data?.invoice_combined_id) {
        vendors = `combined-${airticketInfo?.data?.invoice_combined_id}`;
      }

      form.setFieldValue('ticket_no', airticket_ticket_no);
      form.setFieldValue('vprfnd_tax', Fixed2(airticket_tax));
      form.setFieldValue('vprfnd_refund_vendor_id', vendors);
      form.setFieldValue(
        'vprfnd_base_fare_amount',
        Fixed2(airticket_base_fare)
      );
      form.setFieldValue('client_total_amount', Fixed2(client_price));
    }
  }, [airticketInfo]);

  const handleFinish = async (value: IPartialRefundSubmitBody) => {
    const {
      airticket_id,
      client_charge_amount,
      client_id,
      client_account_id,
      client_note,
      client_payment_type,
      client_return_amount,
      client_total_amount,
      refund_date,
      vprfnd_refund_vendor_id,
      vprfnd_account_id,
      ticket_no,
      prfnd_payment_method,
      vendor_charge_amount,
      vprfnd_payment_type,
      vprfnd_payment_method,
      vprfnd_used_base_fare,
      vprfnd_base_fare_amount,
      vprfnd_penalties,
      vprfnd_remaining_tax,
      vendor_refundable_amount,
      vprfnd_ait,
      vprfnd_remaining_base_fare,
      vprfnd_tax,
      vprfnd_total_commission,
    } = value;

    const body: IPartialRefundFormItem = {
      invoice_id: Number(invoice_id),
      comb_client: clientId as string,
      created_by: user?.user_id as number,
      prfnd_account_id: client_account_id,
      prfnd_charge_amount: client_charge_amount as string,
      prfnd_return_amount: Fixed2(client_return_amount),
      prfnd_total_amount: client_total_amount,
      prfnd_profit_amount:
        Fixed2(vendor_refundable_amount) - Fixed2(client_return_amount),
      date: dayjs(refund_date).format('YYYY-MM-DD'),
      note: client_note,
      prfnd_payment_type:
        client_payment_type === 'ADJUST' ? 'ADJUST' : 'MONEY_RETURN',
      prfnd_payment_method: prfnd_payment_method,
      vendor_refund_info: [
        {
          vprfnd_airticket_id: airticketInfo?.data?.airticket_id as number,
          comb_vendor: vprfnd_refund_vendor_id,
          vprfnd_ticket_no: ticket_no,
          vendor_refundable_amount,
          vprfnd_base_fare_amount,
          vprfnd_payment_method,
          vprfnd_used_airticket_tax,
          vprfnd_used_base_fare,
          vprfnd_account_id,
          vprfnd_ait,
          vprfnd_payment_type,
          vprfnd_penalties,
          vprfnd_remaining_tax,
          vprfnd_remaining_base_fare,
          vprfnd_tax,
          vprfnd_total_commission,
        },
      ],
    };

    await createPartialRefund(body).then((res: any) => {
      if (res?.data?.refund_id) {
        navigate(`/refund/partial/view/${res?.data?.refund_id}`),
          { state: location.pathname };
      } else {
        message.error(res?.data);
      }
    });
  };

  const client_id = useWatch('client_id', form);

  useEffect(() => {
    if (client_id) {
      form.resetFields(['select_invoice_id', 'airticket_id']);
    }
  }, [client_id]);

  // VENDOR CHARGE AMOUNT CALCULATION ......................
  const vprfnd_base_fare_amount = useWatch('vprfnd_base_fare_amount', form);
  const usages_fare = useWatch('vprfnd_used_base_fare', form);
  const remaining_tax = useWatch('vprfnd_remaining_tax', form);
  const penalties = useWatch('vprfnd_penalties', form) || 0;

  const remaining_amount = vprfnd_base_fare_amount - usages_fare;

  const commission = (remaining_amount * 7) / 100;
  const ait = (remaining_amount + remaining_tax) * 0.003;

  const refundable_amount =
    remaining_amount - commission + remaining_tax - penalties + ait;

  useEffect(() => {
    form.setFieldsValue({
      vprfnd_remaining_base_fare: remaining_amount || undefined,
    });
    form.setFieldsValue({
      vprfnd_total_commission: Math.round(commission) || undefined,
    });

    form.setFieldsValue({ vprfnd_ait: Math.round(ait) || undefined });

    form.setFieldsValue({
      vendor_refundable_amount: Math.round(refundable_amount) || undefined,
      client_return_amount: Math.round(refundable_amount) || undefined,
    });
  }, [remaining_amount, ait, refundable_amount]);

  // CLIENT CHARGE AMOUNT CALCULATION ......................
  const client_total_amount = useWatch('client_total_amount', form);
  const client_return_amount = useWatch('client_return_amount', form);

  const total_client_charge_amount =
    Fixed2(client_total_amount) - Fixed2(client_return_amount);

  useEffect(() => {
    if (client_return_amount > 0) {
      form.setFieldsValue({ client_charge_amount: total_client_charge_amount });
    } else {
      form.setFieldsValue({ client_charge_amount: undefined });
    }
  }, [total_client_charge_amount]);

  // Tax Calculation
  const vprfnd_tax = useWatch('vprfnd_tax', form);
  const vprfnd_used_airticket_tax = useWatch('vprfnd_used_airticket_tax', form);

  const remaining_tax_count =
    Fixed2(vprfnd_tax) - Fixed2(vprfnd_used_airticket_tax);

  useEffect(() => {
    form.setFieldsValue({ vprfnd_remaining_tax: remaining_tax_count });
  }, [remaining_tax_count]);

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Create Partial Refund']} />
      <Link to='/refund/history/partial'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to List
        </Button>
      </Link>

      <Form onFinish={handleFinish} layout='vertical' form={form}>
        <Card>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <SelectClients
              name='client_id'
              label='Select Client'
              required
              size={6}
              onChange={(e) => {
                setClientId(e as string);
              }}
              offDropDown
            />

            <SelectInvoiceByClientID
              clientId={String(clientId)}
              allowClear
              label='Select Invoice'
              name={'select_invoice_id'}
              required={true}
              size={6}
            />

            <SelectTicketByInvoiceID
              invoice_id={invoice_id}
              label='Select Ticket'
              name={'airticket_id'}
              required={true}
              size={6}
            />

            <DateInput name={'refund_date'} size={6} label='Date' />
          </Row>
          <Row style={{ float: 'right' }}>
            <Button
              onClick={handleSubmit}
              type='primary'
              disabled={ticketId ? false : true}
            >
              Search
            </Button>
          </Row>
        </Card>

        <Card style={{ marginTop: 25 }}>
          <FormHeaderTitle title='Vendor Info' />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} justify={'center'}>
            <Col span={8}>
              <FormInput
                size={24}
                label='Ticket No.'
                name={'ticket_no'}
                readonly
                required={airticketInfo?.data ? true : false}
              />
              <FormInput
                size={24}
                placeholder='Base Fare Amount'
                label='Base Fare Amount'
                name={'vprfnd_base_fare_amount'}
                readonly={true}
                required={airticketInfo?.data ? true : false}
              />
              <NumberInput
                size={24}
                placeholder='Used Base Fare'
                label='Used Base Fare'
                name={'vprfnd_used_base_fare'}
                required={airticketInfo?.data ? true : false}
              />
              <FormInput
                size={24}
                placeholder='Tax'
                label='Tax'
                name={'vprfnd_tax'}
                readonly={true}
                required={airticketInfo?.data ? true : false}
              />
              <NumberInput
                size={24}
                placeholder='Used Tax'
                label='Used Tax'
                name={'vprfnd_used_airticket_tax'}
              />

              <SelectVendors
                size={24}
                label='Vendor'
                name={'vprfnd_refund_vendor_id'}
                required={airticketInfo?.data ? true : false}
                disabled={true}
              />
              <SelectOptionsInput
                size={24}
                label='Pay Type'
                options={['ADJUST', 'MONEY RETURN']}
                name={'vprfnd_payment_type'}
                required={airticketInfo?.data ? true : false}
                onSelect={(e) => {
                  setClientPayType(e);
                }}
              />

              {clientPayType !== 'ADJUST' && (
                <>
                  <SelectPaymentMethod
                    name={'vprfnd_payment_method'}
                    label='Payment Method'
                    size={24}
                    required={airticketInfo?.data ? true : false}
                    onChange={(value: string) => {
                      setPaymentMethod(value);
                      if (!isVendorChanged.current)
                        isVendorChanged.current = true;
                    }}
                  />
                  <Col span={6} xs={24} sm={24} md={24} lg={24}>
                    <Form.Item
                      name={'vprfnd_account_id'}
                      label='Account'
                      rules={[
                        {
                          required: airticketInfo?.data ? true : false,
                          message: `Account is required!`,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder={'Select Account'}
                        optionFilterProp='children'
                      >
                        {accountsByPayment?.data?.map(
                          (item: IaccountsByMethod) => {
                            return (
                              <Select.Option
                                key={item.account_id}
                                value={item.account_id}
                              >
                                {`${item.account_name}`}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {/* </Row> */}
            </Col>

            <Col span={8} style={{ borderLeft: '1px solid #606060' }}>
              <NumberInput
                size={24}
                label='Remaining Base Fare'
                name={'vprfnd_remaining_base_fare'}
                readOnly
                required={airticketInfo?.data ? true : false}
              />
              <NumberInput
                size={24}
                placeholder='Total Commission'
                label='Total Commission (7%)'
                name={'vprfnd_total_commission'}
                readOnly
                required={airticketInfo?.data ? true : false}
              />

              <NumberInput
                size={24}
                placeholder='Remaining Tax'
                label='Remaining Tax'
                name={'vprfnd_remaining_tax'}
                required={airticketInfo?.data ? true : false}
              />

              <NumberInput
                size={24}
                placeholder='Penalties'
                label='Penalties'
                name={'vprfnd_penalties'}
                required={airticketInfo?.data ? true : false}
              />

              <NumberInput
                size={24}
                placeholder='AIT'
                label='AIT'
                name={'vprfnd_ait'}
                readOnly
                required={airticketInfo?.data ? true : false}
              />
              <NumberInput
                size={24}
                placeholder='Refundable Amount'
                label='Refundable Amount'
                name={'vendor_refundable_amount'}
                readOnly
                required={airticketInfo?.data ? true : false}
              />
            </Col>
          </Row>
        </Card>

        <Card style={{ marginTop: 25 }}>
          <FormHeaderTitle title='Client Info' />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <FormInput
              size={6}
              placeholder='Total Amout'
              label='Total Amount'
              name={'client_total_amount'}
              readonly={true}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Return Amout'
              label='Return Amount'
              name={'client_return_amount'}
              required={airticketInfo?.data ? true : false}
            />
            <FormInput
              size={6}
              placeholder='Charge Amout'
              label='Charge Amount'
              name={'client_charge_amount'}
              required={airticketInfo?.data ? true : false}
            />
            <SelectOptionsInput
              size={6}
              label='Pay Type'
              options={['ADJUST', 'MONEY RETURN']}
              name={'client_payment_type'}
              required={airticketInfo?.data ? true : false}
              onSelect={(e) => {
                setVendorPayType(e);
              }}
            />

            {vendorPayType !== 'ADJUST' && (
              <>
                <SelectPaymentMethod
                  name={'prfnd_payment_method'}
                  label='Payment Method'
                  size={6}
                  required={airticketInfo?.data ? true : false}
                  onChange={(value: string) => {
                    setPaymentMethod(value);
                    if (!isVendorChanged.current)
                      isVendorChanged.current = true;
                  }}
                />
                <Col span={6} xs={24} sm={24} md={24} lg={6}>
                  <Form.Item
                    name={'client_account_id'}
                    label='Account'
                    rules={[
                      {
                        required: airticketInfo?.data ? true : false,
                        message: `Account is required!`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder={'Select Account'}
                      optionFilterProp='children'
                    >
                      {accountsByPayment?.data?.map(
                        (item: IaccountsByMethod) => {
                          return (
                            <Select.Option
                              key={item.account_id}
                              value={item.account_id}
                            >
                              {`${item.account_name}`}
                            </Select.Option>
                          );
                        }
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <TextAreaInput label='Note' size={8} name={'client_note'} />
          </Row>
        </Card>

        <Row style={{ float: 'right', marginTop: 15 }}>
          <Button loading={isLoading} htmlType='submit' type='primary'>
            Submit
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default AddPartialRefunds;
