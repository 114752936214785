import { Button, FormInstance, Modal } from 'antd';
import { useState } from 'react';
import TicketInfoUmrah from '../Components/TicketInfoUmrah';

type Props = { form: FormInstance<any>; name: number; index: number };

export default function AddTicketInfo({ form, name, index }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type='primary' onClick={showModal}>
        Ticket Info
      </Button>
      <Modal
        title='Add Ticket Info'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={610}
      >
        <TicketInfoUmrah form={form} index={index} name={name} />
      </Modal>
    </>
  );
}
