import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { HajiTransferInPostType } from '../../Types/HajjiManagement.interface';
import HajjInfoInputForm from './HajjInfoInputForm';

type Props = {
  form: FormInstance<any>;
  reason: 'ADD_NEW' | 'EDIT';
  editableDataTransferIn?: HTTPResponse<HajiTransferInPostType>;
};

export default function HajiInfoList({
  form,
  reason,
  editableDataTransferIn,
}: Props) {
  return (
    <Row
      style={{
        padding: '15px ',
        marginBottom: '30px',
      }}
      gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
    >
      <Form.List name='haji_informations' initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <HajjInfoInputForm
                key={key}
                name={name}
                fields={fields}
                add={add}
                remove={remove}
                form={form}
                reason={reason}
              />
            ))}

            <Form.Item>
              <Button
                type='primary'
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add New Hajji Information
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Row>
  );
}
