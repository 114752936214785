import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { ACTIVITYLOGS } from '../../Api/constants';
import { IActivityDataType } from '../types/activityLogsTypes';

export const activityLogsEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all activities
    getActivityLogs: build.query<
      HTTPResponse<IActivityDataType[]>,
      {
        id: string | number;
        from_date: any;
        to_date: any;
        current?: number;
        pageSize?: number;
      }
    >({
      query: (arg) => ({
        url: `report/audit/${arg.id || 'all'}?from_date=${
          arg.from_date
        }&to_date=${arg.to_date}&page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: [{ type: 'ActivityLogs', id: ACTIVITYLOGS }],
    }),
  }),
});

export const { useGetActivityLogsQuery } = activityLogsEndPoints;
