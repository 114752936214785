import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormListAddRemove from '../../../../common/utils/FormListAddRemove';
import FoodForm from './FoodForm';
import { IInvoiceTourFood } from '../../Types/InvoiceTourTypes';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';

type Props = {
  form: FormInstance<any>;
  invComClientInfo?: string;
  tourFood?: IInvoiceTourFood[] | undefined;
};

const InvoiceTourFoodForm = ({ form, invComClientInfo, tourFood }: Props) => {
  return (
    <>
      <Form.List
        name='tourFoods'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
                gutter={[10, 0]}
              >
                <Col md={23}>
                  <FoodForm
                    form={form}
                    field={field}
                    invComClientInfo={invComClientInfo}
                    tourFood={tourFood}
                    index={index}
                  />
                </Col>
                <Col md={1}>
                  <BillingIsDeleted name={[field.name, 'food_is_deleted']} />
                  <FormListAddRemove
                    add={add}
                    index={index}
                    name={field.name}
                    remove={remove}
                    dataLength={tourFood?.length}
                    form={form}
                    NamePath={['tourFoods', field.name, 'food_is_deleted']}
                  />
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default InvoiceTourFoodForm;
