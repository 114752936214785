import { Row, Table } from 'antd';
import { pagination } from '../../../common/utils/common.utils';

type Props = {
  subtotal?: number;
  discount?: number;
  net_total?: number;
};

const QuotationSubTotal = ({ discount, net_total, subtotal }: Props) => {
  const billingInfoData = [
    {
      key: '1',
      name: 'Sub Total',
      value: subtotal,
    },
    {
      key: '2',
      name: 'Discount',
      value: discount,
    },
    {
      key: '3',
      name: 'Net Total',
      value: net_total,
    },
  ];
  const billingInfoColumn = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '150px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '120px',
    },
  ];
  return (
    <Row justify='end' className='mt-10'>
      <Table
        size='small'
        bordered
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={billingInfoData}
        columns={billingInfoColumn}
        showHeader={false}
        pagination={false}
        style={{
          width: '220px',
          fontStyle: 'changeFont',
        }}
        locale={{ emptyText: ' ' }}
      />
    </Row>
  );
};

export default QuotationSubTotal;
