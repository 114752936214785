import { CloseOutlined } from '@ant-design/icons';
import { Divider, FormListOperation, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  FormInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectVendors } from '../../../../components/common/FormItem/SelectCustomFeilds';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
};

const ReIssueInputItems = ({ subField, subOpt, form }: Props) => {
  // Client Charge Calculation------------------------------

  const sales_price = useWatch(
    ['ticket_info', subField.name, 'airticket_client_price'],
    form
  );
  const client_charge = useWatch(
    ['ticket_info', subField.name, 'client_charge'],
    form
  );
  const client_refund = Fixed2(sales_price) - Fixed2(client_charge);

  // Vendor Charge Calculation------------------------------

  const purchase_price = useWatch(
    ['ticket_info', subField.name, 'airticket_purchase_price'],
    form
  );
  const vendor_charge = useWatch(
    ['ticket_info', subField.name, 'vendor_charge'],
    form
  );
  const vendor_refund = Fixed2(purchase_price) - Fixed2(vendor_charge);

  useEffect(() => {
    if (client_refund) {
      form.setFields([
        {
          name: ['ticket_info', subField.name, 'client_refund'],
          value: Fixed2(client_refund),
        },
      ]);
    }
    if (vendor_refund) {
      form.setFields([
        {
          name: ['ticket_info', subField.name, 'vendor_refund'],
          value: Fixed2(vendor_refund),
        },
      ]);
    }
  }, [client_refund, vendor_refund]);

  return (
    <Row className='border' key={subField.key} gutter={10}>
      <Divider>Refund - {subField.key + 1}</Divider>
      <FormInput
        hidden
        name={[subField.name, 'airticket_id']}
        label='Ticket Id'
        readonly
        size={6}
      />
      <FormInput
        name={[subField.name, 'airticket_ticket_no']}
        label='Ticket No.'
        readonly
        size={6}
      />

      <FormInput
        name={[subField.name, 'airticket_client_price']}
        label='Sales Price'
        readonly
        size={6}
      />
      <NumberInput
        name={[subField.name, 'client_charge']}
        label='Client Charge'
        size={6}
      />
      <NumberInput
        name={[subField.name, 'client_refund']}
        label='Client Refund'
        size={6}
      />
      <SelectVendors
        name={[subField.name, 'comb_vendor']}
        label='Vendor'
        disabled
        size={6}
      />

      <FormInput
        name={[subField.name, 'airticket_purchase_price']}
        label='Purchase Price'
        readonly
        size={6}
      />
      <NumberInput
        name={[subField.name, 'vendor_charge']}
        label='Vendor Charge'
        size={6}
      />
      <NumberInput
        name={[subField.name, 'vendor_refund']}
        label='Vendor Refund'
        size={5}
      />

      <CloseOutlined
        onClick={() => {
          subOpt.remove(subField.name);
        }}
      />
    </Row>
  );
};

export default ReIssueInputItems;
