import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TOTALDUEADVANCEVENDORS } from '../../Api/constants';
import { ITotal_Due_Advance_VendorDataType } from '../types/Total_Due_Advance_VendorTypes';

export const total_due_advance_vendorEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all total due_advance of vendors
    getAllDueAdvanceVendors: build.query<
      HTTPResponse<ITotal_Due_Advance_VendorDataType[]>,
      {
        vendor_id: string | number;
        query: string;
      }
    >({
      query: ({ vendor_id, query }) => ({
        url: `/report/vendor-advance-due/${vendor_id}${query}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceVendors',
          id: TOTALDUEADVANCEVENDORS,
        },
      ],
    }),
  }),
});

export const { useLazyGetAllDueAdvanceVendorsQuery } =
  total_due_advance_vendorEndpoints;
