import { Modal, Table } from 'antd';
import { IGetAllAdvanceReturn } from '../Types/MoneyReceiptTypes';
import dayjs from 'dayjs';
import { pagination } from '../../../common/utils/common.utils';

type Props = {
  open: boolean;
  onCancel: () => void;
  advrData?: IGetAllAdvanceReturn;
};

const ViewAdvanceModal = ({ open, onCancel, advrData }: Props) => {
  const billingInfoData = [
    {
      key: '1',
      name: 'Vendor name',
      value: advrData?.client_name,
    },
    {
      key: '2',
      name: 'Advance Amount',
      value: advrData?.advr_amount,
    },
    {
      key: '3',
      name: 'Return date',
      value: dayjs(advrData?.advr_payment_date).format('DD-MM-YYYY'),
    },
    {
      key: '4',
      name: 'Return Note',
      value: advrData?.advr_note,
    },
  ];
  const billingInfoColumn = [
    {
      dataIndex: 'name',
      key: 'name',
    },
    {
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={false}
        width={600}
        title='Advance Return Details'
      >
        <Table
          className='invoiceBillingTable'
          bordered
          rowClassName={'invoiceBillingTd'}
          dataSource={billingInfoData}
          columns={billingInfoColumn}
          showHeader={false}
          size='small'
          pagination={pagination(billingInfoData.length)}
        />
      </Modal>
    </>
  );
};

export default ViewAdvanceModal;
