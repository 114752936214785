import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  ExpenseHeadDataType,
  ExpenseHeadDelete,
  IExpenseHeadEditFormData,
  IExpenseHeadFormData,
} from '../types/ExpenseHeadTypes';

const EXPENSEHEAD = 'EXPENSEHEAD';
export const expenseHeadEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all expense head
    getAllExpenseHead: build.query<HTTPResponse<ExpenseHeadDataType[]>, void>({
      query: () => ({ url: `/configuration/expense_head/read` }),
      providesTags: () => [{ type: 'ExpenseHead', id: EXPENSEHEAD }],
    }),

    //get all expense head
    getAllExpenseHeadWithPag: build.query<
      HTTPResponse<ExpenseHeadDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/expense_head?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'ExpenseHead', id: EXPENSEHEAD }],
    }),

    //create expense head
    createExpenseHead: build.mutation<void, IExpenseHeadFormData>({
      query: (body) => ({
        url: `/configuration/expense_head/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ExpenseHead', id: EXPENSEHEAD }],
    }),

    //edit expense head
    editExpenseHead: build.mutation<void, IExpenseHeadEditFormData>({
      query: (body) => ({
        url: `/configuration/expense_head/update/${body.head_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ExpenseHead', id: EXPENSEHEAD }],
    }),

    //delete expense head
    deleteExpenseHead: build.mutation<void, ExpenseHeadDelete>({
      query: (body) => ({
        url: `/configuration/expense_head/delete/${body.head_id}`,
        method: 'DELETE',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ExpenseHead', id: EXPENSEHEAD }],
    }),
  }),
});

export const {
  useGetAllExpenseHeadQuery,
  useCreateExpenseHeadMutation,
  useEditExpenseHeadMutation,
  useDeleteExpenseHeadMutation,
  useGetAllExpenseHeadWithPagQuery,
} = expenseHeadEndpoints;
