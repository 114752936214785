import { Button, Card, Form, Input, Radio } from 'antd';
import React, { useState } from 'react';
import { IFeedback } from '../types/FeedbackTypes';

type Props = {
  setFormData: React.Dispatch<React.SetStateAction<{}>>;
  formData: {};
  submitToApis: (data: IFeedback) => void;
  prev: () => void;
};

const Feedback_survey = ({ prev, submitToApis }: Props) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = (values: IFeedback) => {
    submitToApis({ ...values });
  };

  const radioOptions = [
    { label: 'Poor', value: 'poor' },
    { label: 'Below Average', value: 'below average' },
    { label: 'Average', value: 'average' },
    { label: 'Good', value: 'good' },
    { label: 'Excellent', value: 'excellent' },
  ];

  const radioOptionsValues = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];
  return (
    <div>
      {/* <h1>UX and UI Design Survey</h1> */}
      <Form
        form={form}
        name='survey-form'
        onFinish={onFinish}
        layout='vertical'
      >
        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_user_experience'
            label='আপনি আমাদের সফ্টওয়্যারটির সামগ্রিক ব্যবহারকারীর অভিজ্ঞতাকে কীভাবে রেট করবেন?'
          >
            <Radio.Group options={radioOptions} />
          </Form.Item>
        </Card>

        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_customer_support'
            label='সফ্টওয়্যার ব্যবহার করার সময় আপনি যে গ্রাহক সহায়তা পেয়েছেন তাতে আপনি কতটা সন্তুষ্ট?'
          >
            <Radio.Group options={radioOptions} />
          </Form.Item>
        </Card>

        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_software_update'
            label='আপনি কীভাবে সফ্টওয়্যার আপডেট এবং আপগ্রেডের ফ্রিকোয়েন্সি এবং গুণমানকে রেট করবেন?'
          >
            <Radio.Group options={radioOptions} />
          </Form.Item>
        </Card>

        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_satisfied'
            label='সফ্টওয়্যার দ্বারা অফার করা কাস্টমাইজেশন বিকল্প নিয়ে আপনি কতটা সন্তুষ্ট?'
          >
            <Radio.Group options={radioOptions} />
          </Form.Item>
        </Card>

        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_refer_other'
            label='১ থেকে ৫ এর মধ্যে, আপনি অন্যদের কাছে আমাদের সফ্টওয়্যার সুপারিশ করার কতটা সম্ভাবনা?'
          >
            <Radio.Group options={radioOptionsValues} />
          </Form.Item>
        </Card>
        <Card style={{ marginBottom: 5 }}>
          <Form.Item
            style={{ padding: 0, margin: 0 }}
            name='fd_most_useful_features'
            label='সফ্টওয়্যারটির কোন বৈশিষ্ট্যগুলি আপনি সবচেয়ে মূল্যবান বা প্রায়শই ব্যবহৃত বলে মনে করেন?'
          >
            <Input
              placeholder='Please write the features name'
              maxLength={10}
            />
          </Form.Item>
        </Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={submitting}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Feedback_survey;
