import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { IHeadWiseExpenseReportDataType } from '../types/headWiseExpenseReportTypes';

export const HeadWiseExpenseReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IHeadWiseExpenseReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Date',
      dataIndex: 'expense_date',
      key: 'expense_date',
      render: (_, record) => {
        return `${dayjs(record.expense_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Voucher No.',
      dataIndex: 'expense_vouchar_no',
      key: 'expense_vouchar_no',
    },
    {
      title: 'Expense',
      dataIndex: 'head_expense',
      key: 'head_expense',
    },

    {
      title: 'Amount',
      dataIndex: 'expense_total_amount',
      key: 'expense_total_amount',
      align: 'right',
      render: (_, data) => Number(data.expense_total_amount),
    },

    {
      title: 'Note',
      dataIndex: 'expense_note',
      key: 'expense_note',
    },
  ];
};
