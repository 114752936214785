import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../../common/utils/ToasterNotification';
import {
  IPlaceSendDataType,
  IPlaceTourGetDataType,
} from '../types/PlaceTourTypes';

const tourPlaceTag = 'place-tour';
const PLACE = 'PLACES';
export const PlaceTourEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all cities
    getAllPlaces: build.query<HTTPResponse<IPlaceTourGetDataType[]>, void>({
      query: () => ({ url: `/tour-ittineray/places/get-all` }),
      providesTags: () => [{ type: tourPlaceTag, id: PLACE }],
    }),

    //get all cities with pagination
    getAllPlacesWithPagination: build.query<
      HTTPResponse<IPlaceTourGetDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/tour-ittineray/places?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: tourPlaceTag, id: PLACE }],
    }),

    //create Place
    createTourPlace: build.mutation<void, IPlaceSendDataType[]>({
      query: (body) => ({
        url: `/tour-ittineray/places/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: tourPlaceTag, id: PLACE }],
    }),

    // // edit Place
    editTourPlace: build.mutation<
      void,
      { body: IPlaceSendDataType; id?: number | string }
    >({
      query: ({ body, id }) => ({
        url: `/tour-ittineray/places/update/${id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: tourPlaceTag, id: PLACE }],
    }),

    //delete Place
    deleteTourPlace: build.mutation<void, IPlaceTourGetDataType>({
      query: (body) => ({
        url: `/tour-ittineray/places/delete/${body.place_id}`,
        method: 'DELETE',
        body: { deleted_by: body.place_created_by },
      }),
      invalidatesTags: () => [{ type: tourPlaceTag, id: PLACE }],
    }),
  }),
});
export const {
  useGetAllPlacesQuery,
  useCreateTourPlaceMutation,
  useDeleteTourPlaceMutation,
  useEditTourPlaceMutation,
  useGetAllPlacesWithPaginationQuery,
} = PlaceTourEndPoints;
