import { Button, Col, Form, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../../../components/common/spinner/LoadingIndicator';
import {
  IViewAgentIncentive,
  IaccountsByMethod,
} from '../../../../AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../../Api/Endpoints/accountsEndpoints';
import Select_Agent from '../../components/Select_Agent';
import {
  useEditAgentIncentiveIncomeMutation,
  useLazyGetViewAgentIncentiveIncomeQuery,
} from '../../endpoints/agent_IncentiveIncomeEndpoints';

type Props = {
  id?: number;
};

function Agent_incentive_Edit_Modal({ id }: Props) {
  const [form] = Form.useForm();

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  const user = useSelector(selectUser);

  //control Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Get Data and Set Field

  const [fetchData, { data, isLoading: infoLoading, isSuccess: dataSuccess }] =
    useLazyGetViewAgentIncentiveIncomeQuery();

  const info = data?.data && data?.data[0];

  useEffect(() => {
    if (info || dataSuccess) {
      form.setFieldsValue({
        ...info,
        agent_id: String(info?.agent_id),
        date: dayjs(info?.incentive_created_date),
        incentive_note: info?.incentive_note,
        type_id: info?.incentive_account_category_id,
        account_id: info?.incentive_account_id,
        note: info?.incentive_note,
      });

      if (info?.incentive_account_category_id) {
        setPaymentMethod(info.incentive_account_category_id.toString());
      }
    }
  }, [dataSuccess, info]);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);

  //Edit Data Submit

  const [editIncentive, { isError, isSuccess, isLoading }] =
    useEditAgentIncentiveIncomeMutation();

  const handleSubmit = async (values: any) => {
    const body: IViewAgentIncentive = {
      ...values,
      amount: values.incentive_amount,
      incentive_id: Number(info?.incentive_id),
      date: dayjs(values.date).format('YYYY-MM-DD'),
      incentive_created_by: user?.user_id as number,
    };

    await editIncentive(body);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  const showModal = () => {
    setIsModalOpen(true);
    fetchData(Number(id));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type='primary' size='small' onClick={showModal}>
        Edit
      </Button>

      <Modal
        title='Edit Agent Incentive Income'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {infoLoading && <LoadingIndicator />}
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Select_Agent name='agent_id' disabled />

            <NumberInput
              min='0'
              name='incentive_amount'
              label='Amount'
              maxChar={14}
              required
              size={12}
            />

            <SelectPaymentMethod
              name='type_id'
              label='Payment Method'
              size={12}
              onChange={(value: string) => {
                setPaymentMethod(value);
                if (!isChanged.current) isChanged.current = true;
              }}
            />
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                name='account_id'
                label='Account'
                rules={[
                  {
                    required: true,
                    message: `Account is required!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={'Select Account'}
                  optionFilterProp='children'
                >
                  {accountsByPayment?.data?.map(
                    (item: IaccountsByMethod, index) => {
                      return (
                        <Select.Option key={index} value={item.account_id}>
                          {`${item.account_name}`}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>

            <DateInput name='date' label='Date' size={12} />
            <TextAreaInput name='note' label='Note' size={24} />
          </Row>

          <FormButton loading={isLoading} label='Create Incentive Income' />
        </Form>{' '}
      </Modal>
    </>
  );
}

export default Agent_incentive_Edit_Modal;
