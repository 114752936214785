import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TOTALDUEADVANCECOMBINED } from '../../Api/constants';
import {
  ITotal_Due_AdvanceCombinedDataType,
  ITotal_Due_Advance_EndPointData,
} from '../types/Total_Due_Advanced_CombinedTypes';

export const Total_Due_Advance_CombinedEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all total due_advance of vendors
    getAllDueAdvanceCombined: build.query<
      HTTPResponse<ITotal_Due_AdvanceCombinedDataType[]>,
      {
        combine_id: number | string;
        query: string;
      }
    >({
      query: ({ combine_id, query }) => ({
        url: `/report/due-advance/combined/${combine_id}${query}`,
      }),
      providesTags: [
        {
          type: 'TotalDueAdvanceCombined',
          id: TOTALDUEADVANCECOMBINED,
        },
      ],
    }),
  }),
});

export const { useLazyGetAllDueAdvanceCombinedQuery } =
  Total_Due_Advance_CombinedEndpoints;
