import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { REFUNDREPORT } from '../../Api/constants';
import {
  IRefundReportClientDataTypes,
  IRefundReportFormDataType,
  IRefundReportVendorDataTypes,
} from '../types/refundReportTypes';

export const refundReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all RefundReport Client
    getAllRefundReportClient: build.query<
      HTTPResponse<IRefundReportClientDataTypes[]>,
      { date_range: string; currentPage: number; currentSize: number }
    >({
      query: ({ date_range, currentPage, currentSize }) => ({
        url: `/report/refund-report-client?${date_range}&page=${currentPage}&size=${currentSize}`,
      }),
      providesTags: [{ type: 'RefundReport', id: REFUNDREPORT }],
    }),

    // get all Refund Report Vendor
    getAllRefundReportVendor: build.query<
      HTTPResponse<IRefundReportVendorDataTypes[]>,
      { date_range: string; currentPage: number; currentSize: number }
    >({
      query: ({ date_range, currentPage, currentSize }) => ({
        url: `/report/refund-report-vendor?${date_range}&page=${currentPage}&size=${currentSize}`,
      }),
      providesTags: [{ type: 'RefundReport', id: REFUNDREPORT }],
    }),
  }),
});

export const {
  useLazyGetAllRefundReportClientQuery,
  useLazyGetAllRefundReportVendorQuery,
} = refundReportEndpoints;
