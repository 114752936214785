import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useParams } from 'react-router';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { ICostBillingInfo } from '../../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types';
import { IInvoiceTourBilling } from '../../Types/InvoiceTourTypes';
import TourBillingForm from './TourBillingForm';
import FormListAddRemove from '../../../../common/utils/FormListAddRemove';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';

type Props = {
  form: FormInstance<any>;
  billInitialValues?: ICostBillingInfo[];
  isCostAdd?: boolean;
  tourBillingsLength?: number | undefined;
  tourBilling?: IInvoiceTourBilling[] | undefined;
};
const TourBillingInfo = ({
  billInitialValues,
  form,
  isCostAdd,
  tourBillingsLength,
  tourBilling,
}: Props) => {
  const { id } = useParams();
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Tour Billing Information 💵' />
      <Form.List
        name='tourBilling'
        initialValue={[billInitialValues ? billInitialValues : {}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
                gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
              >
                <Col md={23}>
                  <TourBillingForm
                    form={form}
                    field={field}
                    billInitialValues={billInitialValues}
                    isCostAdd={isCostAdd}
                    index={index}
                    tourBilling={tourBilling}
                  />
                </Col>
                <Col md={1}>
                  <BillingIsDeleted name={[field.name, 'is_deleted']} />
                  <FormListAddRemove
                    add={add}
                    index={index}
                    name={field.name}
                    remove={remove}
                    form={form}
                    dataLength={tourBillingsLength}
                    NamePath={['tourBilling', field.name, 'is_deleted']}
                  />
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default TourBillingInfo;
