import { Button, Col, DatePicker, Form, InputNumber, Row, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { IBalanceTransferFormData } from '../../AccountsTypes/AccountsTypes';
import { useCreateBalanceTransferMutation } from '../../Api/Endpoints/balanceTransferEndpoints';
import Select_Account from '../../components/Select_Account';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useNavigate } from 'react-router';

type Props = {};

const Add_Balance_Transfer_Modal = (props: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [lastBalance, setLastBalance] = useState<number>();

  const user = useSelector(selectUser);
  const [addBalanceTransfer, { isError, isLoading, isSuccess, data }] =
    useCreateBalanceTransferMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.success('Balance Transfer Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isError, isSuccess]);

  const amount = Form.useWatch('transfer_amount', form);
  const transferCharge = Form.useWatch('transfer_charge', form);

  useEffect(() => {
    if (amount || transferCharge)
      form.setFieldValue('total_amount', amount + (transferCharge || 0));
    else {
      form.setFieldValue('total_amount', undefined);
    }
  }, [amount, transferCharge]);

  const handleSubmit = async (values: IBalanceTransferFormData) => {
    const id = values.transfer_from_id.toString().split('/');

    const body: IBalanceTransferFormData = {
      ...values,
      transfer_from_id: Number(id[1]),
      transfer_amount: Number(values.transfer_amount),
      transfer_created_by: user?.user_id as number,
      transfer_date: dayjs(values.transfer_date).format('YYYY-MM-DD'),
    };

    await addBalanceTransfer(body).then((res) => {});
  };
  useEffect(() => {
    if (isSuccess) {
      navigate('/accounts/balance_transfer/view/' + data?.data, {
        state: location.pathname,
      });

      dispatch(setModal(false));
      form.resetFields();
      form.setFieldsValue({
        payroll_pay_type: 1,
        payroll_date: dayjs(),
      });
    }
  }, [isLoading, isError, isSuccess, data]);

  useEffect(() => {
    form.setFieldValue('transfer_date', dayjs());
  }, []);

  return (
    <Form layout='vertical' form={form} onFinish={handleSubmit}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Select_Account
          required={true}
          placeholder='Select Transfer From Account'
          setLastBalance={setLastBalance}
        />

        <Col span={8} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            rules={[
              { required: true, message: 'Transfer Amount is Required!!' },
            ]}
            name='transfer_amount'
            label='Amount: '
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Please Enter your Amount'
            />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} sm={24} md={24} lg={12}>
          <Form.Item name='transfer_charge' label='Transfer Charge: '>
            <InputNumber
              style={{ width: '100%' }}
              placeholder='Enter your transfer charge'
            />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='total_amount'
            label='Total Amount: '
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value, callback) {
                  if (lastBalance && lastBalance < value) {
                    return Promise.reject('Insuficient Balance!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber style={{ width: '100%' }} readOnly />
          </Form.Item>
        </Col>

        <Col span={8} xs={24} sm={24} md={24} lg={12}>
          <Form.Item
            name='transfer_date'
            label='Date: '
            rules={[{ required: true, message: 'Date is Required!!' }]}
          >
            <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
          </Form.Item>
        </Col>
        <Col span={8} xs={24} sm={24} md={24} lg={24}>
          <Form.Item name='transfer_note' label='Note: '>
            <TextArea maxLength={255} placeholder='Enter your message' />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={isLoading}>
          Add Balance Transfer
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default Add_Balance_Transfer_Modal;
