import { Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAgent,
  SelectClients,
  SelectEmployee,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllAgentProfileQuery } from '../../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { useLazyGetClLastBalanceQuery } from '../../../Client/Client/api/endpoints/clientEndpoints';
import SelectTourGroup from '../Components/SelectTourGroup';
import { useGetInvoiceNoQuery } from '../../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { Fixed2 } from '../../../../common/utils/common.utils';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

type Props = {
  isEdit?: boolean;
  form: FormInstance<any>;
  invoice_net_totalFieldName: NamePath;
  invoice_agent_com_amountFieldName: NamePath;
};

function TourHeaderForm({
  isEdit,
  form,
  invoice_net_totalFieldName,
  invoice_agent_com_amountFieldName,
}: Props) {
  /* set client name instance when new one create */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceAgnetSelect, setInstanceAgentSelect] = useState<number>();
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    }
  }, [instanceClientsSelect]);
  useEffect(() => {
    if (instanceAgnetSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgnetSelect);
    }
  }, [instanceAgnetSelect]);

  /* set Tour Group Name Instance when create new one */
  const [selectGroupName, setGroupName] = useState<number>();

  useEffect(() => {
    if (selectGroupName) {
      form.setFieldValue('itour_group_id', selectGroupName);
    }
  }, [selectGroupName]);
  //============ Generate invoice No
  const { data: invoiceNo, isLoading } = useGetInvoiceNoQuery('ITP');
  useEffect(() => {
    form.setFieldValue('invoice_no', invoiceNo?.data);
    form.setFieldValue('invoice_sales_date', dayjs());
  }, [invoiceNo]);

  // get client last balance
  const clientId = useWatch(['invoice_combclient_id'], form);

  const [getClLastBalance, { data: clientLBlance, isFetching }] =
    useLazyGetClLastBalanceQuery();

  useEffect(() => {
    if (clientId) {
      getClLastBalance(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    form?.setFieldsValue({
      invoice_client_previous_due: clientLBlance?.data?.client_prev_due,
      client_present_balance: clientLBlance?.data?.client_last_balance,
    });
  }, [isFetching, clientId]);
  useEffect(() => {
    form?.setFieldValue('invoice_sales_date', dayjs());
  }, []);
  return (
    <>
      {isLoading ? <LoadingIndicator /> : ''}
      <Row className='border p-5 my-20' gutter={[10, 0]} align='middle'>
        <SelectClients
          name='invoice_combclient_id'
          label='Select Client :'
          required
          disabled={isEdit ? true : false}
          placeholder='Select client'
          size={3}
          mdSize={8}
          smSize={12}
          setInstanceClientSelect={setInstanceClientSelect}
        />

        <SelectEmployee
          name='invoice_sales_man_id'
          label='Sales By'
          required
          size={3}
          mdSize={8}
          smSize={12}
        />

        <DateInput
          label='Sales Date'
          name='invoice_sales_date'
          required
          size={2}
          mdSize={8}
          smSize={12}
          disableFeatureDate
        />
        <DateInput label='Due Date:' name='invoice_due_date' size={2} />
        <FormInputItem
          label='Invoice no'
          name='invoice_no'
          size={2}
          mdSize={8}
          smSize={12}
          readOnly
        />
        <SelectTourGroup
          name='itour_group_id'
          size={2}
          label='Group'
          placeholder='Select Group'
          setGroupName={setGroupName}
        />

        <NumberInput
          label='day'
          name='itour_day'
          size={2}
          required
          maxChar={3}
          minChar={0}
        />
        <NumberInput
          label='Night'
          name='itour_night'
          size={2}
          required
          maxChar={3}
          minChar={0}
        />

        <DateInput
          label='Journey Date'
          name='tour_invoice_journey_date'
          required
          size={4}
          rangePicker
        />
        <SelectAgent
          label='Agent'
          name={'invoice_agent_id'}
          size={2}
          setInstanceAgentSelect={setInstanceAgentSelect}
          isPercent
        />
      </Row>
    </>
  );
}

export default TourHeaderForm;
