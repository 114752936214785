import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { SingleReceiveDataType } from '../../LoanTypes/LoanTypes';

export const SingleReceiveList = (): ColumnsType<SingleReceiveDataType> => {
  return [
    {
      title: 'Date',
      key: 'loan_date',
      render: (_, record) => {
        return `${dayjs(record.payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Authority Name',
      dataIndex: 'authority_name',
      key: 'authority_name',
    },
    {
      title: 'Loan Name',
      dataIndex: 'loan_name',
      key: 'loan_name',
    },

    {
      title: 'Loan Type',
      dataIndex: 'loan_type',
      key: 'loan_type',
    },
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
    },

    {
      title: 'Loan Amount',
      dataIndex: 'loan_amount',
      key: 'loan_amount',
      render: (loan_amount) => {
        return Number(loan_amount) || 0;
      },
    },

    {
      title: 'Received Amount',
      dataIndex: 'received_amount',
      key: 'received_amount',
      render: (received_amount) => {
        return Number(received_amount) || 0;
      },
    },

    {
      title: 'Due Amount',
      dataIndex: 'received_amount',
      key: 'received_amount',
      render: (_, data) => {
        return Number(data.loan_due_amount) || 0;
      },
    },
  ];
};
