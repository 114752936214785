import { AgentProfileDataType } from '../../../Client/Agents_Profile/types/agentProfileTypes';

type Props = {
  allAgents: AgentProfileDataType[] | undefined;
  invoice_agent_id?: number;
};

export function getAgentCommissionRate({ allAgents, invoice_agent_id }: Props) {
  return (
    allAgents?.find((item) => item?.agent_id === invoice_agent_id)
      ?.agent_commission_rate || 0
  );
}
