import { Table } from 'antd';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAllPassportClientQuery } from '../../api/endpoints/clientEndpoints';
import { IClientPassportData } from '../../types/clientInterfaces';
import { clientPassportUtil } from '../../utils/utils_tsx/clientPassportUtil';
import { pagination } from '../../../../../common/utils/common.utils';

type Props = { id: any };

const ClientDetailPassport = ({ id }: Props) => {
  const { data, isLoading } = useGetAllPassportClientQuery(id, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IClientPassportData[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });
  return (
    <div>
      <Table
        size='small'
        bordered
        columns={clientPassportUtil()}
        dataSource={data}
        pagination={pagination(data?.length)}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ClientDetailPassport;
