import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  IAccommodationCreateDataType,
  IAccommodationGetDataType,
  IAccommodationVendorDataType,
  ICitiesGetDataType,
  IRoomGetDataType,
} from '../types/accommodationTypes';

const accommodatonTag = 'Accommodation';
const ACCOMMODATION = 'ACCOMMODATION';
export const accommodationEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAccommodation: build.query<
      HTTPResponse<IAccommodationGetDataType[]>,
      void
    >({
      query: () => ({ url: `/tour-ittineray/accommodation/get-all` }),
      providesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    getAllAccommodationWithPag: build.query<
      HTTPResponse<IAccommodationGetDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/tour-ittineray/accommodation?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //get all accommodation vendor
    getAllAccomVendorInfo: build.query<
      HTTPResponse<IAccommodationVendorDataType[]>,
      number
    >({
      query: (accVenId) => ({
        url: `/tour-ittineray/accm-vendor/info/${accVenId}`,
      }),
      providesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //get all cities by countryId
    getAllCities: build.query<HTTPResponse<ICitiesGetDataType[]>, number>({
      query: (id) => ({ url: `/tour-ittineray/cities/by-country/${id}` }),
      providesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //get allRoom type
    getAllRoomType: build.query<HTTPResponse<IRoomGetDataType[]>, void>({
      query: () => ({ url: `/configuration/room-types/get-all` }),
      providesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //create  Accommodation
    createTourAccommodation: build.mutation<
      HTTPResponse<any>,
      IAccommodationCreateDataType[]
    >({
      query: (body) => ({
        url: `/tour-ittineray/accommodation/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //edit accommodation
    editTourAccommodation: build.mutation<
      void,
      { body: IAccommodationCreateDataType; id?: number | string }
    >({
      query: ({ body, id }) => ({
        url: `/tour-ittineray/accommodation/update/${id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),

    //delete accommodation
    deleteTourAccommodation: build.mutation<void, IAccommodationGetDataType>({
      query: (body) => ({
        url: `/tour-ittineray/accommodation/delete/${body.accommodation_id}`,
        method: 'DELETE',
        body: { deleted_by: body.accommodation_created_by },
      }),

      invalidatesTags: () => [{ type: accommodatonTag, id: ACCOMMODATION }],
    }),
  }),
});
export const {
  useGetAllAccommodationQuery,
  useGetAllCitiesQuery,
  useGetAllRoomTypeQuery,
  useCreateTourAccommodationMutation,
  useDeleteTourAccommodationMutation,
  useEditTourAccommodationMutation,
  useGetAllAccomVendorInfoQuery,
  useLazyGetAllAccomVendorInfoQuery,
  useGetAllAccommodationWithPagQuery,
} = accommodationEndpoints;
