import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { AITREPORT } from '../../Api/constants';
import { IAitReportDataType } from '../types/aitReportTypes';

export const AitReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all AitReport
    getAitReport: build.query<
      HTTPResponse<IAitReportDataType[]>,
      {
        vendor_id: string;
        query: string;
      }
    >({
      query: ({ vendor_id, query }) => ({
        url: `/report/ait-report/${vendor_id}${query}`,
      }),
      providesTags: [{ type: 'GDSReport', id: AITREPORT }],
    }),
  }),
});

export const { useLazyGetAitReportQuery } = AitReportEndpoints;
