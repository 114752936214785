import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  DesignationDataType,
  IDesignationEditFormData,
  IDesignationFormData,
} from '../types/designationTypes';

const DESIGNATION = 'DESIGNATION';
export const designationEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all designation

    getAllDesignation: build.query<HTTPResponse<DesignationDataType[]>, void>({
      query: () => ({ url: `/configuration/designations/get-all` }),
      providesTags: () => [{ type: 'Designation', id: DESIGNATION }],
    }),

    //get all designation with pagination

    getAllDesignationWithPagination: build.query<
      HTTPResponse<DesignationDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/designations?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Designation', id: DESIGNATION }],
    }),

    // create designation

    createDesignation: build.mutation<void, IDesignationFormData>({
      query: (body) => ({
        url: `/configuration/designations/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Designation', id: DESIGNATION }],
    }),

    //edit designation

    editDesignation: build.mutation<void, IDesignationEditFormData>({
      query: (body) => ({
        url: `/configuration/designations/edit/${body.designation_id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Designation', id: DESIGNATION }],
    }),

    //delete designation
    deleteDesignation: build.mutation<void, DesignationDataType>({
      query: (body) => ({
        url: `/configuration/designations/delete/${body.designation_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),
      invalidatesTags: () => [{ type: 'Designation', id: DESIGNATION }],
    }),
  }),
});

export const {
  useGetAllDesignationQuery,
  useCreateDesignationMutation,
  useEditDesignationMutation,
  useDeleteDesignationMutation,
  useGetAllDesignationWithPaginationQuery,
} = designationEndpoints;
