import { Button, Col, Divider, Form, FormInstance, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { NamePath } from 'antd/es/form/interface';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  SelectAgent,
  SelectAirport,
  SelectClients,
  SelectEmployee,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import HajjiGroupAddModal from '../../../Invoice_Hajj_Pre_Reg/Modals/HajjiGroupAddModal';
import { useGetAllAgentProfileQuery } from '../../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { useLazyGetClLastBalanceQuery } from '../../../Client/Client/api/endpoints/clientEndpoints';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { useGetAllGroupsQuery } from '../../../Configuration/group/endpoints/groupEndpoints';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {
  invoice_net_totalFieldName: NamePath;
  invoice_agent_com_amountFieldName: NamePath;
  form: FormInstance<any>;
};

export default function NewAddHajjFormTop({
  form,
  invoice_agent_com_amountFieldName,
  invoice_net_totalFieldName,
}: Props) {
  /* set client Name Instance when create */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceGroupSelect, setInstanceGroupSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const [instanceAgentSelect, setInstanceAgentSelect] = useState<number>();
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgentSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgentSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceSalesEmpSelect);
    } else if (instanceGroupSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceGroupSelect);
    }
  }, [
    instanceClientsSelect,
    instanceAgentSelect,
    instanceSalesEmpSelect,
    instanceGroupSelect,
  ]);
  const { data: groups } = useGetAllGroupsQuery();
  const { id } = useParams();
  const client_id = useWatch('invoice_combclient_id', form);
  const selectGroup = groups?.data?.filter((el) => el.group_type === 'HAJJ');
  const groupChildrens: React.ReactNode[] = [];
  if (selectGroup) {
    for (let i = 0; i < selectGroup.length; i++) {
      groupChildrens.push(
        <Select.Option
          key={
            selectGroup[i].group_name + '  ' + selectGroup[i].group_id + ' ' + i
          }
          value={selectGroup[i].group_id}
        >
          {`${selectGroup[i].group_name}`}
        </Select.Option>
      );
    }
  }

  /* getLastBalance and Previous Due By ClientId */
  const [getLastBalance, { data: clientLBlance, isFetching }] =
    useLazyGetClLastBalanceQuery();

  useEffect(() => {
    if (client_id !== undefined) {
      getLastBalance(client_id);
    }
  }, [client_id]);
  useEffect(() => {
    form?.setFieldsValue({
      invoice_client_previous_due: Fixed2(clientLBlance?.data?.client_prev_due),
      client_present_balance: Fixed2(clientLBlance?.data?.client_last_balance),
    });
  }, [isFetching]);
  const [click, setClick] = useState<boolean>();
  return (
    <Row className='border p-5 my-20' gutter={[10, 10]} align='middle'>
      <SelectClients
        name='invoice_combclient_id'
        label='Select Client :'
        required
        disabled={id ? true : false}
        placeholder='Select client'
        size={4}
        mdSize={8}
        smSize={12}
        setInstanceClientSelect={setInstanceClientSelect}
      />
      <SelectEmployee
        label='Sales By:'
        name='invoice_sales_man_id'
        required
        size={4}
        setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
      />
      <Col xs={24} sm={12} md={8} lg={3}>
        <Form.Item name='invoice_haji_group_id' label='Group Name'>
          <Select
            allowClear
            placeholder={'Select Group'}
            showSearch
            optionFilterProp='roleMobile'
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                <HajjiGroupAddModal
                  setClick={setClick}
                  setInstanceGroupSelect={setInstanceGroupSelect}
                />

                <Divider style={{ margin: '8px 0' }} />
                {menu}
              </>
            )}
            open={click === true ? false : undefined}
          >
            {groupChildrens}
          </Select>
        </Form.Item>
      </Col>
      <FormInputItem
        label='Invoice No:'
        name='invoice_no'
        required
        readOnly
        size={3}
      />
      <DateInput
        label='Sales Date:'
        name='invoice_sales_date'
        required
        size={3}
        disableFeatureDate
      />
      <DateInput label='Due Date:' name='invoice_due_date' size={2} />
      <DateInput
        label='Hajj Session:'
        year
        name='invoice_hajj_session'
        required
        size={2}
      />
      <SelectAgent
        label='Select Agent:'
        name={'invoice_agent_id'}
        size={3}
        setInstanceAgentSelect={setInstanceAgentSelect}
        isPercent
      />
    </Row>
  );
}
