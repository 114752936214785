import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useEditClientCategoryMutation } from '../endpoints/clientCategoryEndpoints';
import {
  ClientCategoryDataType,
  IClientCategoryEditFormData,
} from '../types/clientCategoryTypes';

interface IProps {
  clientCategory: string[];
  info: ClientCategoryDataType;
  setNull: ISetState<ClientCategoryDataType | null>;
}
const ClientEditModal = ({ info, setNull, clientCategory }: IProps) => {
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { category_title, category_prefix, category_id } = info;

  useEffect(() => {
    form.setFieldsValue({
      category_title: info.category_title,
      category_prefix: info.category_prefix,
    });
  }, [info]);
  const [editClientCategory, { isError, isSuccess, isLoading }] =
    useEditClientCategoryMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Client Category Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleOnSubmit = async () => {
    assyncWrapper(async () => {
      const values =
        (await form.getFieldsValue()) as IClientCategoryEditFormData;

      await editClientCategory({ ...values, category_id });
      setNull(null);
      dispatch(setModal(false));
    });
  };

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
  );
  return (
    <div>
      <Form
        layout='horizontal'
        labelAlign='left'
        {...layout}
        name='nest-messages'
        onFinish={handleOnSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        {/* Same as */}

        <Form.Item
          name='category_title'
          label='Enter Category Name :'
          rules={[{ required: true, max: 55 }]}
        >
          <Input value={category_title} />
        </Form.Item>

        <Form.Item
          name='category_prefix'
          label='Category Prefix :'
          rules={[
            { required: true, max: 10 },
            {
              required: true,
              message: 'client prefix required',
            },
            {
              validator(_, value) {
                if (value) {
                  if (
                    !clientCategory
                      .filter((item) => item !== info.category_prefix)
                      .includes(value)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('same category prefix not allowed');
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input value={category_prefix} />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
          <Button type='primary' htmlType='submit' loading={isLoading}>
            {modalSlice.modalLoading ? antIcon : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default ClientEditModal;
