import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  NumberInput,
  TextAreaInput,
} from '../../../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../../../components/common/paymentMethod/SelectPaymentMethod';
import LoadingIndicator from '../../../../../../components/common/spinner/LoadingIndicator';
import {
  IAgentIncentiveIncomeFormData,
  IaccountsByMethod,
} from '../../../../AccountsTypes/AccountsTypes';
import { useLazyGetAllAccountsByTypeQuery } from '../../../../Api/Endpoints/accountsEndpoints';
import Select_Agent from '../../components/Select_Agent';
import { useCreateAgentIncentiveIncomeMutation } from '../../endpoints/agent_IncentiveIncomeEndpoints';

type Props = {
  type?: string;
  permission?: IPermission;
};

function Agent_incentive_Add_Modal({ permission }: Props) {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  // Control Modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);

  // Create Agent Incentive income

  const [addIncentive, { isError, isSuccess, isLoading, data }] =
    useCreateAgentIncentiveIncomeMutation();

  const handleSubmit = async (values: IAgentIncentiveIncomeFormData) => {
    const body = {
      ...values,
      agent_id: Number(values.agent_id),
      date: dayjs(values.date).format('YYYY-MM-DD'),
      incentive_created_by: user?.user_id as number,
    };
    await addIncentive(body);
  };

  let resData: any = data;
  const resID = resData?.incentive_id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/accounts/incentive/agent/view/${resID}`, {
        state: location.pathname,
      });

      form.resetFields();
      setIsModalOpen(false);
      form.setFieldValue('date', dayjs());
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <>
      {permission?.[crud.create] && (
        <Button
          icon={<PlusOutlined />}
          type='primary'
          onClick={showModal}
        >
          Agent Incentive Income
        </Button>
      )}

      <Modal
        title='Add Agent Incentive Income'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
        >
          {isLoading ? <LoadingIndicator /> : ''}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
            <Select_Agent name='agent_id' />

            <NumberInput
              min='0'
              name='amount'
              label='Amount'
              maxChar={14}
              required
              size={12}
            />

            <SelectPaymentMethod
              name='type_id'
              label='Payment Method'
              required
              size={12}
              onChange={(value: string) => {
                setPaymentMethod(value);
                if (!isChanged.current) isChanged.current = true;
              }}
            />
            <Col
              span={6}
              xs={24}
              sm={24}
              md={24}
              lg={12}
            >
              <Form.Item
                name='account_id'
                label='Account'
                rules={[
                  {
                    required: true,
                    message: `Account is required!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={'Select Account'}
                  optionFilterProp='children'
                >
                  {accountsByPayment?.data?.map(
                    (item: IaccountsByMethod, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={item.account_id}
                        >
                          {`${item.account_name}`}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>

            <DateInput
              name='date'
              label='Date'
              size={12}
            />
            <TextAreaInput
              name='note'
              label='Note'
              size={24}
            />
          </Row>

          <FormButton
            loading={isLoading}
            label='Create Incentive Income'
          />
        </Form>{' '}
      </Modal>
    </>
  );
}

export default Agent_incentive_Add_Modal;
