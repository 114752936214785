import { Button, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Link } from 'react-router-dom';
import ReportsHeader from '../../../../components/common/ReportsHeader';
interface DataType {
  key: string;
  serial: number;
  invoice_id: number;
  invoice_date: string;
  customer_name: string;
  purchase_price: number;
  options: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'SL.',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_id',
    key: 'invoice_id',
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoice_date',
    key: 'invoice_date',
  },
  {
    title: 'Customer Name',
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: 'Purchase Price',
    dataIndex: 'purchase_price',
    key: 'purchase_price',
  },
  {
    title: 'Options',
    dataIndex: 'options',
    key: 'options',
    render: () => (
      <Button
        type='primary'
        style={{ background: 'green' }}
      >
        <Link to=''>View </Link>
      </Button>
    ),
  },
];

const data: DataType[] = [
  {
    key: '1',
    serial: 1,
    invoice_id: 32,
    invoice_date: '2022-05-14',
    customer_name: 'ASDASD',
    purchase_price: 100,
    options: 'aseda',
  },
];

type Props = {};

const InvoiceVenDet = (props: Props) => {
  return (
    <div>
      <ReportsHeader date_range />
      <Table
        size='small'
        bordered
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default InvoiceVenDet;
