import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ITotal_Due_Advance_VendorDataType } from '../types/Total_Due_Advance_VendorTypes';
import { Link } from 'react-router-dom';

const { Text } = Typography;
export const Total_Due_Advance_VendorUtils = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_VendorDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (curr, rec) => {
        return (
          <Link to={`/reports/vendor_ledger?id=${rec.vendor_id}`}>{curr}</Link>
        );
      },
    },
    {
      title: 'Mobile No.',
      dataIndex: 'vendor_mobile',
      key: 'vendor_mobile',
      render: (value) => value || 'N/A',
    },
    {
      title: 'Email',
      dataIndex: 'vendor_email',
      key: 'vendor_email',
      render: (value) => value || 'N/A',
    },
    {
      title: 'Present Due',
      dataIndex: 'lbalance_amount',
      key: 'lbalance_amount',
      align: 'right',
      render: (_, record) => {
        return (
          <Text type='danger'>
            {Number(record.vtrxn_lbalance) < 0
              ? Fixed2(Math.abs(Number(record.vtrxn_lbalance)))
              : '00'}
          </Text>
        );
      },
    },
    {
      title: 'Present Advance',
      dataIndex: 'lbalance_amount',
      key: 'lbalance_amount',
      align: 'right',
      render: (_, record) => {
        return (
          <Text type='success'>
            {Number(record.vtrxn_lbalance) > 0
              ? Number(record.vtrxn_lbalance)
              : '00'}
          </Text>
        );
      },
    },

    {
      title: 'Last Balance',
      dataIndex: 'vendor_lbalance',
      key: 'vendor_lbalance',
      align: 'center',
      render: (amount) => {
        return (
          <p
            style={{
              color:
                Number(amount) === 0
                  ? '#18b4e9'
                  : Number(amount) > 0
                  ? 'green'
                  : 'red',
            }}
          >
            {Fixed2(Math.abs(Number(amount)))}
          </p>
        );
      },
    },
    {
      title: 'Credit Limit',
      dataIndex: 'vendor_credit_limit',
      key: 'vendor_credit_limit',
      align: 'center',
      render: (_, record) =>
        record.vendor_credit_limit ? record.vendor_credit_limit : 'N/A',
    },
  ];
};
