import React from 'react';
import {
  FormInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import Deduction_isdeleted from './Deduction_isdeleted';
import { useWatch } from 'antd/es/form/Form';

type IProps = {
  onChange: () => void;
  name: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  form: FormInstance<any>;
  editOrAdd: 'ADD' | 'EDIT';
  deductionLength: number | undefined;
  index: number;
};
const Deductions = ({
  onChange,
  name,
  remove,
  add,
  form,
  editOrAdd,
  deductionLength,
  index,
}: IProps) => {
  const is_deleted: 0 | 1 = useWatch(
    ['payroll_deductions', index, 'is_deleted'],
    form
  );
  return (
    <Row
      align={'middle'}
      gutter={3}
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
    >
      <NumberInput
        name={[name, 'pd_amount']}
        label='Deductions Amount'
        size={10}
        min='0'
        maxChar={10}
        minChar={0}
        onChange={onChange}
      />
      <FormInput
        name={[name, 'pd_reason']}
        label='Deductions Reasons'
        size={10}
      />
      {/* deduction is deleted check */}
      <Deduction_isdeleted name={[name, 'is_deleted']} />
      <Col lg={2}>
        {' '}
        <Form.Item label={[]}>
          {name === 0 ? (
            <Col>
              <Button
                type='dashed'
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              ></Button>
            </Col>
          ) : (
            <Col>
              <Button
                className='ml-2'
                onClick={() => {
                  const prev = form.getFieldValue([
                    'payroll_deductions',
                    name,
                    'is_deleted',
                  ]);
                  form.setFieldValue(
                    ['payroll_deductions', name, 'is_deleted'],
                    Number(!prev)
                  );
                  if (editOrAdd === 'EDIT') {
                    if (deductionLength && index >= deductionLength)
                      remove(name);
                  } else {
                    remove(name);
                  }
                }}
                block
                icon={<MinusCircleOutlined />}
                danger
              ></Button>
            </Col>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Deductions;
