import { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { useCreatePassportStatusMutation } from '../endpoints/passportStatusEndpoints';
import { IPassportStatusFormData } from '../types/PassportStatusTypes';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';

const AddNewStatusModal = ({
  passStatusNameList,
}: {
  passStatusNameList: string[];
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const [addPassportStatus, { isError, isSuccess, isLoading }] =
    useCreatePassportStatusMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Passport Status Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IPassportStatusFormData) => {
    const body: IPassportStatusFormData = {
      ...values,
      pstatus_created_by: user?.user_id as number,
    };
    await addPassportStatus(body);
    dispatch(setModal(false));
    form.resetFields();
  };

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  //loading indicator
  if (isLoading) {
    return loadingIndicator;
  }
  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout='horizontal'
      labelAlign='left'
      {...layout}
    >
      <Form.Item
        rules={[
          { required: true, message: 'Status Title is required!!' },
          {
            validator(_, value) {
              if (value) {
                if (passStatusNameList.includes(value)) {
                  return Promise.reject('PassportStatus Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
        name='pstatus_name'
        label='Status Title:'
      >
        <Input placeholder='Enter Status Title' />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNewStatusModal;
