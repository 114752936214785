import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { setModal } from '../../../../common/slices/commonSlice';
import { ExpenseHeadDataType } from '../types/ExpenseHeadTypes';
import { useEditExpenseHeadMutation } from '../Endpoints/expenseHeadEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  exSubHeadNaList: string[];
  info: ExpenseHeadDataType;
  setNull: ISetState<ExpenseHeadDataType | null>;
}

const EditHeadModal = ({ info, setNull, exSubHeadNaList }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(info);
  }, [info]);
  const [editProduct, { isError, isSuccess, isLoading }] =
    useEditExpenseHeadMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Expense Head Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as ExpenseHeadDataType;
      values.head_id = info.head_id;
      await editProduct({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      form={form}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Head Name'
        name='head_name'
        rules={[
          { required: true, message: 'Please enter head name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (exSubHeadNaList.includes(value.trim())) {
                  return Promise.reject('Expense Head Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter Head Name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Update
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default EditHeadModal;
