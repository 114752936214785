import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { COUNTRYWISEREPORT } from '../../Api/constants';
import {
  ICountryWiseReportApiDataType,
  ICountryWiseReportFormDataType,
} from '../types/countryWiseReportTypes';

export const countryWiseReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all CountryWiseReport
    getCountryWiseReport: build.query<
      HTTPResponse<ICountryWiseReportApiDataType[]>,
      ICountryWiseReportFormDataType
    >({
      query: (body) => ({
        url: `/report/country-wise-report/${body.country_id}?${body.date_range}`,
      }),
      providesTags: [{ type: 'CountryWiseReport', id: COUNTRYWISEREPORT }],
    }),
  }),
});

export const { useLazyGetCountryWiseReportQuery } = countryWiseReportEndpoints;
