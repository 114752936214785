import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

interface IError {
  message: string;
  type: string;
}

interface IState {
  error: IError | null;
  isAccountModal: boolean;
  isClientModal: boolean;
  isCombineModal: boolean;
  isVendorModal: boolean;
  modalLoading: boolean;
  defaultLoading: boolean;
}

const initialState: IState = {
  error: null,
  isAccountModal: false,
  isClientModal: false,
  isCombineModal: false,
  isVendorModal: false,
  modalLoading: false,
  defaultLoading: false,
};

const accountModalSlice = createSlice({
  name: 'accountModalSlice',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IError>) {
      state.error = action.payload;
    },

    setAccountModal(state, action: PayloadAction<boolean>) {
      state.isAccountModal = action.payload;
    },
    setClientModal(state, action: PayloadAction<boolean>) {
      state.isClientModal = action.payload;
    },
    setCombineModal(state, action: PayloadAction<boolean>) {
      state.isCombineModal = action.payload;
    },
    setVendorModal(state, action: PayloadAction<boolean>) {
      state.isVendorModal = action.payload;
    },

    setModalLoading(state, action: PayloadAction<boolean>) {
      state.modalLoading = action.payload;
    },

    setDefaultLoading(state, action: PayloadAction<boolean>) {
      state.defaultLoading = action.payload;
    },
  },
});

export const {
  setError,
  setModalLoading,
  setDefaultLoading,
  setAccountModal,
  setClientModal,
  setCombineModal,
  setVendorModal,
} = accountModalSlice.actions;

export const commonAccount = (state: RootState) => state.accountModalSlice;

export default accountModalSlice.reducer;
