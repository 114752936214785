import { Form, Select } from 'antd';
import { useGetAllBloodGroupsQuery } from '../endpoints/employeeEndpoints';
import { IBlood } from '../types/employeeTypes';

const BloodSelect = () => {
  const { data: bloodGroupCategory } = useGetAllBloodGroupsQuery();

  return (
    <Form.Item name='employee_bloodgroup_id' label='Blood Group'>
      <Select
        showSearch
        allowClear
        placeholder='Select Blood Group'
        optionFilterProp='children'
      >
        {bloodGroupCategory?.data?.map((item: IBlood) => {
          return (
            <Select.Option key={item.group_id} value={item.group_id}>
              {item.group_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default BloodSelect;
