import { Button, Form, Input, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { setModal } from '../../../../common/slices/commonSlice';
import { GroupDataType } from '../types/GroupsTypes';
import { useEditGroupMutation } from '../endpoints/groupEndpoints';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  groupNameList: string[];
  info: GroupDataType;
  setNull: ISetState<GroupDataType | null>;
}

const EditGroupModal = ({ info, setNull, groupNameList }: IProps) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<GroupDataType>(info);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(info);
  }, [info]);
  const [editGroup, { isError, isSuccess, isLoading }] = useEditGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Group Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as GroupDataType;
      values.group_id = info.group_id;
      await editGroup({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      form={form}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Group Type'
        name='group_type'
        rules={[{ required: true, message: 'Please select group type!' }]}
      >
        <Select
          onChange={(e) => setInputValue({ ...inputValue, group_type: e })}
          placeholder='Select Type'
          allowClear
          value={inputValue.group_type}
        >
          <Select.Option value='HAJJ'>HAJJ</Select.Option>
          <Select.Option value='UMRAH'>UMRAH</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Group Name'
        name='group_name'
        rules={[
          { required: true, message: 'Please enter Group name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (
                  groupNameList
                    .filter((item) => item !== info.group_name)
                    .includes(value)
                ) {
                  return Promise.reject('Group Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input
          value={inputValue.group_name}
          onChange={(e) =>
            setInputValue({ ...inputValue, group_name: e.target.value })
          }
          placeholder='Enter Group name'
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Update
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default EditGroupModal;
