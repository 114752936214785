import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { formatNumber } from '../../../../common/utils/common.utils';
import { IVendorLedger } from '../types/vendorLedgerTypes';

export const VendorLedgerUtils = (
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  },
  isForPrint?: boolean
): ColumnsType<IVendorLedger> => {
  return [
    {
      title: 'SL.',
      align: 'center',
      fixed: 'left',
      width: 40,
      render: (_, data, index) => (
        <>
          {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Trxn Date',
      key: 'vtrxn_created_at',
      fixed: 'left',
      render: (_, record) => {
        return `${dayjs(record.vtrxn_created_at).format('DD-MM-YYYY')}`;
      },
      align: 'center',
      width: 100,
    },
    {
      title: 'Voucher No.',
      dataIndex: 'vtrxn_voucher',
      key: 'vtrxn_voucher',
      width: 100,
    },

    {
      title: 'Ticket No',
      dataIndex: 'vtrxn_airticket_no',
      key: 'vtrxn_airticket_no',
      render: (_, data) => (
        <span>
          {data?.vtrxn_airticket_no?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'PNR',
      dataIndex: 'vtrxn_pnr',
      key: 'vtrxn_pnr',
      render: (_, data) => (
        <span>
          {data?.vtrxn_pnr?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Pax Name',
      dataIndex: 'vtrxn_pax',
      key: 'vtrxn_pax',
      render: (_, data) => (
        <span>
          {data?.vtrxn_pax?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Routes',
      dataIndex: 'vtrxn_route',
      key: 'vtrxn_route',
      align: 'center',
      width: 100,
    },

    {
      title: 'Pay Method',
      dataIndex: 'vtrxn_pay_type',
      key: 'vtrxn_pay_type',
      align: 'center',
    },
    {
      title: 'Particulars',
      dataIndex: 'trxntype_name',
      key: 'trxntype_name',
      width: 170,
    },
    {
      title: 'Debit',
      key: 'vtrxn_amount',
      align: 'right',
      render: (_, data) => (
        <>
          {data.vtrxn_type === 'DEBIT' ? (
            <p>
              <span style={{ color: 'red' }}>
                {formatNumber(data.vtrxn_amount)}
              </span>
            </p>
          ) : (
            '0.00'
          )}
        </>
      ),
    },
    {
      title: 'Credit',
      key: 'vtrxn_amount',
      align: 'right',
      render: (_, data) => (
        <>
          {data.vtrxn_type === 'CREDIT' ? (
            <p>
              <span style={{ color: 'green' }}>
                {formatNumber(data.vtrxn_amount)}
              </span>
            </p>
          ) : (
            '0.00'
          )}
        </>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'vtrxn_lbalance',
      key: 'vtrxn_lbalance',
      align: 'right',
      sorter: (a, b) => Number(a.vtrxn_lbalance) - Number(b.vtrxn_lbalance),
      render: (amount) => {
        return isForPrint ? (
          <Typography.Text
            style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}
          >
            {Number(amount) === 0 ? '' : Number(amount) > 0 ? '' : '-'}
            {formatNumber(amount)}
          </Typography.Text>
        ) : (
          <Typography.Text
            style={{ color: parseInt(amount) > 0 ? 'green' : 'red' }}
          >
            {formatNumber(amount)}
          </Typography.Text>
        );
      },
    },

    {
      title: 'Note',
      dataIndex: 'vtrxn_note',
      key: 'vtrxn_note',
      render: (column, data) => (
        <span>
          {data?.vtrxn_note?.split('\n')?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Created Date',
      key: 'vtrxn_created_date',
      render: (_, record) => {
        return `${dayjs(record.vtrxn_created_date).format('DD-MM-YYYY')}`;
      },
      align: 'center',
      width: 100,
    },
  ];
};
