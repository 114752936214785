import { Col, Input, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePostEditAppConfigMutation } from '../../endpoint/appConfigEndpoint';
import { IGetAppConfig, IUpdateAppConfig } from '../../type/appConfig_Type';

type Props = {
  configDetails: IGetAppConfig | undefined;
};

const AuthoritySignature = ({ configDetails }: Props) => {
  const [value, setValue] = useState<string>();
  const [postEdit] = usePostEditAppConfigMutation();
  const defaultValue = configDetails?.tac_inv_as;

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleSubmit = () => {
    const body: IGetAppConfig = {
      ...configDetails,
      tac_inv_as: value,
    };

    postEdit(body);
  };

  return (
    <Row align={'middle'} justify={'space-between'} gutter={12}>
      <Col>
        <Typography.Text strong>Authority Signature: </Typography.Text>
      </Col>
      <Col>
        <Input
          size='small'
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          onBlur={handleSubmit}
          onPressEnter={handleSubmit}
        />
      </Col>
    </Row>
  );
};

export default AuthoritySignature;
