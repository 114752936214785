import { message } from 'antd';
import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  IAccountInvestments,
  IAccountInvestmentsEditFormData,
  IAccountInvestmentsFormData,
  IViewAccountInvestments,
} from '../../AccountsTypes/AccountsTypes';
import { BALANCE_STATUS, INVESTMENTS } from '../Constants/constants';

export const investmentsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all investments
    getAllInvestments: build.query<
      HTTPResponse<IAccountInvestments[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/accounts/investments?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),
    // get investments by id
    getViewInvestments: build.query<
      HTTPResponse<IViewAccountInvestments[]>,
      number
    >({
      query: (body) => ({ url: `/accounts/investments/${body}` }),
      providesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),
    // get all trashed investments
    getAllTrashedInvestments: build.query<
      HTTPResponse<IAccountInvestments[]>,
      void
    >({
      query: () => ({ url: `/accounts/investments/trash` }),
      providesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),

    //create investments
    createInvestment: build.mutation<void, IAccountInvestmentsFormData>({
      query: (body) => ({
        url: `/accounts/investments`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        await assyncWrapper(async () => {
          message.success('Investment Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Investments', id: INVESTMENTS },
        { type: 'Balance_Status', id: BALANCE_STATUS },
      ],
    }),

    //edit investment
    editInvestment: build.mutation<void, IAccountInvestmentsEditFormData>({
      query: (body) => ({
        url: `/accounts/investments/${body.investment_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          message.success('Investment Edited Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),

    //delete investment
    deleteInvestment: build.mutation<void, IAccountInvestments>({
      query: (body) => ({
        url: `/accounts/investments/${body.investment_id}`,
        method: 'DELETE',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Investment Deleted Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),

    //restore investment
    restoreInvestment: build.mutation<void, IAccountInvestments>({
      query: (body) => ({
        url: `/accounts/investments/restore/${body.investment_id}`,
        method: 'PATCH',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Investment Restored Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Investments', id: INVESTMENTS }],
    }),
  }),
});

export const {
  useGetAllInvestmentsQuery,
  useLazyGetAllTrashedInvestmentsQuery,
  useCreateInvestmentMutation,
  useEditInvestmentMutation,
  useDeleteInvestmentMutation,
  useRestoreInvestmentMutation,
  useGetViewInvestmentsQuery,
} = investmentsEndpoints;
