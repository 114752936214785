import { useLocation } from 'react-router-dom';
import { Button, Form, Input, Select, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateGroupMutation } from '../endpoints/groupEndpoints';
import { IGroupFormData } from '../types/GroupsTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const AddNewGroup = ({
  setIsModalOpen,
  groupNameList,
  isShowGroupName,
  setClick,
  setInstanceGroupSelect,
}: {
  isShowGroupName?: boolean;
  groupNameList?: string[];
  setClick?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setInstanceGroupSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const location = useLocation().pathname;

  const [form] = Form.useForm();
  const [addGroup, { isError, data: groupData, isSuccess, isLoading }] =
    useCreateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Group Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IGroupFormData) => {
    const body: IGroupFormData = {
      ...values,
      group_created_by: user?.user_id as number,
    };
    await addGroup(body);
    dispatch(setModal(false));
    form.resetFields();
    closeModal();
    setClick && setClick(false);
  };
  useEffect(() => {
    setInstanceGroupSelect && setInstanceGroupSelect(groupData?.data?.group_id);
  }, [groupData?.data?.group_id]);
  const closeModal = () => {
    setIsModalOpen && setIsModalOpen(false);
  };
  useEffect(() => {
    if (location.includes('invoicehajj')) {
      form.setFieldValue('group_type', 'HAJJ');
    } else {
      form.setFieldValue('group_type', 'UMRAH');
    }
  }, [location]);
  return (
    <Form
      layout='horizontal'
      form={form}
      labelAlign='left'
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Group Type'
        name='group_type'
        rules={[{ required: true, message: 'Please select group type!' }]}
      >
        <Select
          showSearch
          placeholder='Select Group Type'
          allowClear
        >
          <Select.Option
            key={1}
            value='HAJJ'
          >
            HAJJ
          </Select.Option>
          <Select.Option
            key={2}
            value='UMRAH'
          >
            UMRAH
          </Select.Option>
        </Select>
      </Form.Item>

      {isShowGroupName && (
        <Form.Item
          label='Group Name'
          name='group_name'
          rules={[
            { required: true, message: 'Please enter group name!' },
            { max: 75, message: 'Maximum 75 characters' },
            {
              validator(_, value) {
                if (value) {
                  if (groupNameList?.includes(value)) {
                    return Promise.reject('Group Name is already Exist');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder='Enter Group Name' />
        </Form.Item>
      )}

      {!isShowGroupName && (
        <Form.Item
          label='Group Name'
          name='group_name'
          rules={[
            { required: true, message: 'Please enter group name!' },
            { max: 75, message: 'Maximum 75 characters' },
          ]}
        >
          <Input placeholder='Enter Group Name' />
        </Form.Item>
      )}

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default AddNewGroup;
