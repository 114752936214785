import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  ConfigProvider,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  theme,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { ReceiptHeader } from '../../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import NumToWord from '../../../../components/number_to_words/NumToWord';
import { IViewAccountNonInvoiceIncome } from '../../AccountsTypes/AccountsTypes';
import { useGetViewNonInvoiceIncomeQuery } from '../../Api/Endpoints/nonInvoiceIncomeEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
const ViewNonInvoiceIncome = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetViewNonInvoiceIncomeQuery(Number(id));

  const { Text, Title } = Typography;
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Non-InvoiceIncome`,
    pageStyle: '',
    // '@page { size: auto; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 40px !important; } }',
  });
  const a4sizeStyle: React.CSSProperties = {
    minHeight: '11.5in',
    width: '8.27in',
    fontSize: '11px',
    background: '#fff',
    margin: '0px auto',
  };
  const column: ColumnsType<IViewAccountNonInvoiceIncome> = [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => index + 1,
    },

    {
      title: 'Date',
      key: 'nonincome_created_date',
      render: (_, record) => {
        return `${dayjs(record.nonincome_created_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Transaction Type',
      key: 'acctype_name',
      dataIndex: 'acctype_name',
    },
    {
      title: 'Transaction Details',
      key: 'transaction_details',
      render: (_, record) =>
        ` ${record.account_name}  ${
          record.account_branch_name ? ', ' + record.account_branch_name : ''
        }`,
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Cheque No',
      dataIndex: 'nonincome_cheque_no',
      key: 'nonincome_cheque_no',
    },
    {
      title: 'Receipt/Trans No',
      dataIndex: 'nonincome_receipt_no',
      key: 'nonincome_receipt_no',
    },
    {
      title: 'Amount',
      dataIndex: 'nonincome_amount',
      key: 'nonincome_amount',
      render: (_, record) => Number(record.nonincome_amount),
    },
  ];
  return (
    <>
      <BreadCrumb
        arrOfOption={['Dashboard', 'Accounts', 'View BalanceTransfer']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/accounts/noninvoice'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Non-InvoiceIncome List
          </Button>
        </Link>
        <Button
          type='primary'
          onClick={handlePrint}
        >
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Card
          hoverable
          ref={componentRef}
          style={a4sizeStyle}
        >
          <ReceiptHeader></ReceiptHeader>
          <Row
            justify='center'
            style={{ margin: '35px 0' }}
          >
            <Tag>
              <Title
                style={{
                  fontSize: '15px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
                level={4}
              >
                Non-InvoiceIncome
              </Title>
            </Tag>
          </Row>

          <div style={{ minHeight: '750px' }}>
            <Table
              size='small'
              bordered
              pagination={false}
              columns={column}
              dataSource={data?.data}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              loading={{ spinning: isLoading, indicator: <LoadingIndicator /> }}
            />
            <Row
              justify={'space-around'}
              align={'middle'}
              style={{
                margin: '25px 0',
              }}
            >
              <Typography
                style={{
                  fontSize: '12px',
                  fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                {data?.data && (
                  <NumToWord
                    number={Number(
                      (data?.data && data?.data[0].nonincome_amount) || 0
                    )}
                  />
                )}
              </Typography>
            </Row>
          </div>

          {/* ================== signature ===================== */}
          <Row
            className='signature'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
          >
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Received By
            </Typography.Text>
            <Typography.Text
              style={{
                borderTop: '1px solid black',
                color: 'black',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Approved By
            </Typography.Text>
          </Row>
          <Row justify={'center'}>
            <Typography.Text
              style={{
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              Copyright © {dayjs().format('YYYY')} M360 ICT
            </Typography.Text>
          </Row>
        </Card>
      </ConfigProvider>
    </>
  );
};

export default ViewNonInvoiceIncome;
