import { api } from '../../../../app/baseQuery';
import { IPaginationSD } from '../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { expire } from '../../../../common/utils/constants';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { CHEQUE_RECEIVE } from '../../../Cheque_Management/api/Constants/constants';
import { DASHBOARD } from '../../../Dashboard/Api/Constants/Constants';
import {
  ILoanDeleteRestoreBody,
  ReceivedEditFormData,
  ReceivedFormData,
  ReceivedLoans,
  SingleReceiveDataType,
  ViewLoanReceiveDataType,
} from '../../LoanTypes/LoanTypes';
import { AUTHORITY, RECEIVE } from '../Constants/constants';

export const ReceiveEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all receive

    getReceive: build.query<
      HTTPResponse<ViewLoanReceiveDataType[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `/loan-management/received?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: [{ type: 'Receive', id: RECEIVE }],
    }),

    // get single receive by loan-id
    getSingleReceive: build.query<
      HTTPResponse<SingleReceiveDataType[]>,
      number
    >({
      query: (id) => ({ url: `/loan-management/received/${id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: 'Receive', id: id } as const,
        { type: 'Receive', id: RECEIVE },
      ],
    }),

    // get all received loans
    getReceivedAuthority: build.query<HTTPResponse<ReceivedLoans[]>, number>({
      query: (id) => ({ url: `/loan-management/received/loans/${id}` }),
      providesTags: [
        { type: 'Authority', id: AUTHORITY },
        { type: 'Receive', id: RECEIVE },
      ],
    }),

    //create receive loan
    createReceive: build.mutation<void, ReceivedFormData>({
      query: (body) => ({
        url: '/loan-management/received',
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Receive Created Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Receive', id: RECEIVE },
        { type: 'Cheque_Receive', id: CHEQUE_RECEIVE },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //edit receive loan
    editReceive: build.mutation<void, ReceivedEditFormData>({
      query: (body) => ({
        url: `/loan-management/received/${body.received_id}`,
        method: 'PATCH',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification('success', 'Receive Edited Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Receive', id: RECEIVE },
        { type: 'Cheque_Receive', id: CHEQUE_RECEIVE },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),

    //delete receive
    deleteReceive: build.mutation<
      void,
      { body: ILoanDeleteRestoreBody; id: number }
    >({
      query: (body) => ({
        url: `/loan-management/received/${body.id}`,
        method: 'DELETE',
        body: body.body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          toasterNotification('success', 'Receive Deleted Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Receive', id: RECEIVE },
        { type: 'Accounts' },
        { type: 'Dashboard', id: DASHBOARD },
      ],
    }),
  }),
});

export const {
  useLazyGetReceiveQuery,
  useGetReceiveQuery,
  useGetSingleReceiveQuery,
  useLazyGetReceivedAuthorityQuery,
  useCreateReceiveMutation,
  useEditReceiveMutation,
  useDeleteReceiveMutation,
} = ReceiveEndpoints;
