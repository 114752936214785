import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IClientPassportData } from '../../types/clientInterfaces';
import { IPermission } from '../../../../../common/types/commonTypes';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';

type Props = {};

export const clientPassportUtil = (
  permission?: IPermission
): ColumnsType<IClientPassportData> => {
  const searchColumnProps = useGetSearchColumnProps<IClientPassportData>();

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Passport No.',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
      ...searchColumnProps('passport_passport_no'),
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      ...searchColumnProps('passport_name'),
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      ...searchColumnProps('passport_mobile_no'),
    },
    {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
      ...searchColumnProps('passport_email'),
    },
    {
      title: 'Date of Birth',
      key: 'passport_date_of_birth',
      render: (_, record) => {
        return (
          record.passport_date_of_birth &&
          `${dayjs(record.passport_date_of_birth).format('DD MMM YYYY')}`
        );
      },
    },
    {
      title: 'Date of Issue',
      key: 'passport_date_of_issue',
      render: (_, record) => {
        return (
          record.passport_date_of_issue &&
          `${dayjs(record.passport_date_of_issue).format('DD MMM YYYY')}`
        );
      },
    },
    {
      title: 'Date of Expire',
      key: 'passport_date_of_expire',
      render: (_, record) => {
        return (
          record.passport_date_of_expire &&
          `${dayjs(record.passport_date_of_expire).format('DD MMM YYYY')}`
        );
      },
    },
  ];
};
