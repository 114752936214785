import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
interface DataType {
  key: string;
  serial: number;
  return_type: string;
  invoice_id: number;
  product: string;
  ticket_no?: number;
  invoice_quantity: number;
  return_quantity: number;
  return_price: number;
  date: any;
}

const columns1: ColumnsType<DataType> = [
  {
    title: 'SL.',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Return Type',
    dataIndex: 'return_type',
    key: 'return_type',
  },
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_id',
    key: 'invoice_id',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Ticket No.',
    dataIndex: 'ticket_no',
    key: 'ticket_no',
  },
  {
    title: 'Invoice Quantity',
    dataIndex: 'invoice_quantity',
    key: 'invoice_quantity',
  },
  {
    title: 'Return Quantity',
    dataIndex: 'return_quantity',
    key: 'return_quantity',
  },
  {
    title: 'Return Price',
    dataIndex: 'return_price',
    key: 'return_price',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

const columns2: ColumnsType<DataType> = [
  {
    title: 'SL.',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Return Type',
    dataIndex: 'return_type',
    key: 'return_type',
  },
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_id',
    key: 'invoice_id',
  },
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },

  {
    title: 'Invoice Quantity',
    dataIndex: 'invoice_quantity',
    key: 'invoice_quantity',
  },
  {
    title: 'Return Quantity',
    dataIndex: 'return_quantity',
    key: 'return_quantity',
  },
  {
    title: 'Return Price',
    dataIndex: 'return_price',
    key: 'return_price',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
];

const data1: DataType[] = [
  {
    key: '1',
    serial: 1,
    return_type: '2022-05-15',
    invoice_id: 24145,
    product: '1230',
    ticket_no: 1234,
    invoice_quantity: 122,
    return_quantity: 1234,
    return_price: 1234,
    date: 'aseda',
  },
];

const data2: DataType[] = [
  {
    key: '1',
    serial: 1,
    return_type: '2022-05-15',
    invoice_id: 24145,
    product: '1230',
    invoice_quantity: 122,
    return_quantity: 1234,
    return_price: 1234,
    date: 'aseda',
  },
];

type Props = {};

const RefundProductVenDet = (props: Props) => {
  return (
    <>
      <div>
        <h1>Airticket Refunds</h1>
        <Table
          size='small'
          bordered
          columns={columns1}
          dataSource={data1}
          scroll={{ x: 400 }}
        />
      </div>
      <div>
        <h1>Other Refunds</h1>
        <Table
          size='small'
          bordered
          columns={columns2}
          dataSource={data2}
          scroll={{ x: 400 }}
        />
      </div>
    </>
  );
};

export default RefundProductVenDet;
