import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  IGetHajjCancelReg,
  IGetHajjRegTrackingNo,
  IPostHajjRegCancel,
} from '../../Types/HajjiManagement.interface';

export const CancelHajjRegEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    postHajjCancelReg: build.mutation<HTTPResponse<void>, IPostHajjRegCancel>({
      query: (arg) => ({
        url: `/hajji_management/cancel_hajj_reg`,
        body: arg,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'hajji-reg-cancel' }],
    }),

    getHajjCancelRegTrackingNo: build.query<
      HTTPResponse<IGetHajjRegTrackingNo[]>,
      string
    >({
      query: (arg) => ({
        url: `/hajji_management/hajj_haji_tracking_no/${arg}`,
      }),
      providesTags: () => [{ type: 'hajji-reg-cancel' }],
    }),

    getAllHajjCancelReg: build.query<
      HTTPResponse<IGetHajjCancelReg[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/hajji_management/cancel_hajj_reg?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'hajji-reg-cancel' }],
    }),

    deleteHajjCancelReg: build.mutation<
      HTTPResponse<IGetHajjCancelReg[]>,
      { id: number; deleted_by?: string | number }
    >({
      query: ({ id, deleted_by }) => ({
        url: `/hajji_management/cancel_hajj_reg/${id}`,
        method: 'DELETE',
        body: { deleted_by },
      }),
      invalidatesTags: () => [{ type: 'hajji-reg-cancel' }],
    }),
  }),
});

export const {
  usePostHajjCancelRegMutation,
  useGetHajjCancelRegTrackingNoQuery,
  useGetAllHajjCancelRegQuery,
  useDeleteHajjCancelRegMutation,
} = CancelHajjRegEndpoint;
