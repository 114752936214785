import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import InvoiceListHeader from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteInvoiceNonComissionMutation,
  useGetAllNonCommissionQuery,
  useLazyGetAllNonCommissionQuery,
} from '../../api/endpoints/airTicketNonCommissionEndpoints';

type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoiceNonCom = ({ permission, addMRPermission }: Props) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  // LINKS
  const createLink = '/noncomairticket/add';
  const viewLink = '/noncomairticket/details';
  const editLink = '/noncomairticket/edit';

  const [query, setQuery] = useState<string>('');
  // GET ALL DATA AND TRASH
  const {
    data,
    isLoading: isLoadingData,
    refetch: getAllInvoices,
    isFetching,
  } = useGetAllNonCommissionQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const allData = data?.data;
  const [
    fetchTrashed,
    { data: trash, isUninitialized, isLoading: isLoadingTrash },
  ] = useLazyGetAllNonCommissionQuery();
  const trashData = trash?.data;

  const [dataSource, setDataSource] = useState(allData);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    setDataSource(allData);
    setCount(data?.count || 0);
  }, [allData]);

  // ==================== SET TABLE DATA ===========================
  const [isTrash, setIsTrash] = useState<boolean>(false);

  useEffect(() => {
    if (isTrash) {
      setDataSource(trashData);
      isUninitialized && fetchTrashed('?trash=1');
    } else {
      setDataSource(allData);
    }
  }, [isTrash]);

  // ACTIONS
  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteInvoiceNonComissionMutation();

  const user = useSelector(selectUser);

  useEffect(() => {
    if (isSuccess) {
      message.success('Invoice Deleted Successfully!');
      setColumnIndex(null);
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);

  // @ONClICK
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      user_id: user?.user_id,
      id,
    });
  };

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice', 'Non Commission']} />

      <InvoiceListHeader
        InvocieModalTitle={'Invoice Non Commission'}
        data={data?.data}
        setIsTrash={setIsTrash}
        editLink={editLink}
        createLink={createLink}
        handleOnDelete={handleOnDelete}
        isTrash={isTrash}
        permission={permission}
        viewLink={viewLink}
        setDataSource={setDataSource}
        dataSource={dataSource}
        trashData={trash?.data}
        isLoading={isLoadingData || isFetching}
        deleteLoading={isLoading}
        addMRPermission={addMRPermission}
        refetch={refetch}
        setCount={setCount}
        count={count}
        setQuery={setQuery}
        setDate={setDate}
        setSearch={setSearch}
        columnIndex={columnIndex}
      />
    </>
  );
};

export default ListOfInvoiceNonCom;
