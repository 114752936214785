import { Button, Space, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  crud,
  IPermission,
  ISetState,
} from '../../../../common/types/commonTypes';
import { useDeleteAuthorityMutation } from '../../Api/Endpoints/AuthorityEndpoints';
import { LoanAuthorityDataType } from '../../LoanTypes/LoanTypes';
import dayjs from 'dayjs';
import { selectUser } from '../../../../auth/states/userSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const ViewAuthorityList = ({
  setEditInfo,
  showModal,
  permission,
  pagination,
}: {
  setEditInfo: ISetState<LoanAuthorityDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<LoanAuthorityDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);
  const setEditAccount = (value: LoanAuthorityDataType) => {
    setEditInfo(value);
    showModal();
  };

  const [deleteAuthority, { isLoading, isSuccess, isError }] =
    useDeleteAuthorityMutation();

  const handleDelete = async (values: LoanAuthorityDataType, index: number) => {
    setColumnIndex(index);
    await deleteAuthority({
      authority_id: values.authority_id,
      deleted_by: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Name',
      dataIndex: 'authority_name',
      key: 'authority_name',
    },

    {
      title: 'Contact',
      dataIndex: 'authority_mobile',
      key: 'authority_mobile',
    },
    {
      title: 'Address',
      dataIndex: 'authority_address',
      key: 'authority_address',
    },

    {
      title: 'Date',
      key: 'loan_date',
      render: (_, record) => {
        return `${dayjs(record.authority_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.[crud.update] && (
            <Button
              size='small'
              type='primary'
              onClick={() => setEditAccount(record)}
            >
              Edit
            </Button>
          )}
          {permission?.[crud.delete] && (
            <Button
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() => handleDelete(record, index)}
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
