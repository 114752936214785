import { Button, Col, Form, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import {
  DateInput,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import {
  IAccountCompanies,
  IAccountNonInvoiceIncomeFormData,
  IaccountsByMethod,
} from '../../AccountsTypes/AccountsTypes';
import {
  useGetAllCompaniesQuery,
  useLazyGetAllAccountsByTypeQuery,
} from '../../Api/Endpoints/accountsEndpoints';
import { useCreateAllNonInvoiceIncomeMutation } from '../../Api/Endpoints/nonInvoiceIncomeEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

type Props = {};

function Non_Invoice_Income_Add_Modal({}: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const { data: companies } = useGetAllCompaniesQuery();

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const isChanged = useRef(false);

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();
  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isChanged.current) {
      form.setFieldsValue({ account_id: undefined });

      return;
    }
  }, [paymentMethod]);

  const [addNonInvoiceIncome, { isError, isLoading }] =
    useCreateAllNonInvoiceIncomeMutation();

  const handleSubmit = async (values: IAccountNonInvoiceIncomeFormData) => {
    const body: IAccountNonInvoiceIncomeFormData = {
      ...values,
      date: dayjs(values.date).format('YYYY-MM-DD'),
      noninvoice_created_by: user?.user_id as number,
    };

    await addNonInvoiceIncome(body);
    dispatch(setModal(false));
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  return (
    <>
      <Form layout='vertical' form={form} onFinish={handleSubmit}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={6} xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              name='company_id'
              label='Company'
              rules={[
                {
                  required: true,
                  message: `Company name is required!`,
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                placeholder={'Select Company'}
                optionFilterProp='children'
              >
                {companies?.data?.map((item: IAccountCompanies, index) => {
                  return (
                    <Select.Option key={index} value={item.company_id}>
                      {`${item.company_name}`}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <SelectPaymentMethod
            name='type_id'
            label='Payment Method'
            size={12}
            onChange={(value: string) => {
              setPaymentMethod(value);
              if (!isChanged.current) isChanged.current = true;
            }}
            // cheque
          />
          {Number(paymentMethod) !== 4 && (
            <Col span={6} xs={24} sm={24} md={24} lg={12}>
              <Form.Item
                name='account_id'
                label='Account'
                rules={[
                  {
                    required: true,
                    message: `Account is required!`,
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={'Select Account'}
                  optionFilterProp='children'
                >
                  {accountsByPayment?.data?.map(
                    (item: IaccountsByMethod, index) => {
                      return (
                        <Select.Option key={index} value={item.account_id}>
                          {`${item.account_name}`}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}

          {Number(paymentMethod) == 4 && (
            <>
              <FormInputItem name='cheque_no' label='Cheque No.' size={12} />
              <FormInputItem
                name='receipt_no'
                label='Receipt/Trans No.'
                size={12}
              />
            </>
          )}
          <NumberInput
            name='amount'
            label='Amount'
            required
            size={12}
            min='0'
            maxChar={14}
          />

          <DateInput required name='date' label='Date' size={12} />
          <TextAreaInput name='note' label='Note' size={24} />
        </Row>

        <Form.Item>
          <Button htmlType='submit' type='primary' loading={isLoading}>
            Add Non-Invoice Income
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </>
  );
}

export default Non_Invoice_Income_Add_Modal;
