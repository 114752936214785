import { CloseOutlined } from '@ant-design/icons';
import { Divider, FormListOperation, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import {
  FormInput,
  FormInputItem,
  NumberInput,
} from '../../../FormItem/FormItems';
import { SelectVendors } from '../../../FormItem/SelectCustomFeilds';

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form: FormInstance<any>;
};

const InvoiceVoidInputs = ({ subField, subOpt, form }: Props) => {
  const ticket_no = useWatch(
    ['invoice_vendors', subField.name, 'airticket_ticket_no'],
    form
  );

  return (
    <Row className='border' key={subField.key} gutter={10}>
      <Divider orientation='left'>Ticket No - ({ticket_no})</Divider>

      <SelectVendors
        name={[subField.name, 'comb_vendor']}
        label='Vendor'
        disabled
        size={6}
      />

      <FormInputItem
        name={[subField.name, 'airticket_id']}
        label='Ticket No.'
        size={6}
        readOnly
        hidden
      />

      <FormInputItem
        name={[subField.name, 'airticket_discount_total']}
        label='Discount'
        size={6}
        readOnly
        hidden
      />

      <FormInput
        name={[subField.name, 'sales']}
        label='Sales'
        readonly
        size={6}
      />
      <FormInput
        name={[subField.name, 'cost_price']}
        label='Purchase'
        readonly
        size={6}
      />

      <NumberInput
        name={[subField.name, 'vendor_charge']}
        label='Vendor Charge'
        size={5}
      />

      <CloseOutlined
        onClick={() => {
          subOpt.remove(subField.name);
        }}
      />
    </Row>
  );
};

export default InvoiceVoidInputs;
