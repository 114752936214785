import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React from 'react';
import { ISalesReportItemAndSalesMandataType } from '../types/salesReportItemAndSalesmanTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

const SalesReportItemAndSalesManColumn = (
  queryData: {
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  },
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined
): ColumnsType<ISalesReportItemAndSalesMandataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Sales Date',
      dataIndex: 'sales_date',
      key: 'sales_date',
      render: (_, record) => {
        return `${dayjs(record.sales_date).format('DD-MM-YYYY')}`;
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                '/' +
                'details' +
                '/' +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },
    {
      title: 'Sales By',
      dataIndex: 'sales_by',
      key: 'sales_by',
      render: (_, record) => (
        <Link
          to={`/reports/sales_man_collection_report?${paramData?.date_range}&employee_id=${record.invoice_sales_man_id}`}
        >
          {' '}
          {record.sales_by}
        </Link>
      ),
    },
    {
      title: 'Sales Amount',
      dataIndex: 'sales_price',
      key: 'sales_price',
      align: 'right',
      render: (_, record) => Number(record.sales_price),
    },
  ];
};

export default SalesReportItemAndSalesManColumn;
