import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';
import { SelectCountry } from '../../../../../components/common/FormItem/SelectCustomFeilds';

const CitiesInfo = () => {
  return (
    <>
      <Form.List
        name='cities_info'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <React.Fragment key={index}>
                <Card style={{ marginBottom: '5px' }}>
                  <Row gutter={[5, 0]}>
                    <SelectCountry
                      required
                      name={[field.name, 'city_country_id']}
                      size={10}
                      mdSize={24}
                      smSize={24}
                      label='Country'
                    />

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                    >
                      <Form.Item
                        label='City'
                        name={[field.name, 'city_name']}
                        rules={[
                          {
                            required: true,
                            message: 'city Name is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={2}
                    >
                      <Form.Item label='Action'>
                        {index === 0 ? (
                          <Button
                            type='primary'
                            onClick={() => add()}
                          >
                            <PlusOutlined />
                            Add More
                          </Button>
                        ) : (
                          <Button
                            type='primary'
                            danger
                            onClick={() => remove(field.name)}
                          >
                            <MinusOutlined />
                            Remove
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </React.Fragment>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default CitiesInfo;
