import {
  Button,
  Popconfirm,
  Select,
  Space,
  Tag,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { useDeleteDesignationMutation } from '../endpoints/designationEndpoints';
import { DesignationDataType } from '../types/designationTypes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<DesignationDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const columns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<DesignationDataType> => {
  const searchColumnProps = useGetSearchColumnProps<DesignationDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  /**
   * handle click
   */
  const handleClick = (record: DesignationDataType) => {
    setEditInfo(record);
    showModal();
  };

  /**
   * handle delete
   */
  const [deleteDesignation, { isError, isSuccess, isLoading }] =
    useDeleteDesignationMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Designation Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: DesignationDataType, index: number) => {
    setColumnIndex(index);
    await deleteDesignation({ ...values, deleted_by: user?.user_id as number });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
    },
    {
      title: 'Designation Name',
      dataIndex: 'designation_name',
      key: 'designation_name',

      ...searchColumnProps('designation_name'),
    },
    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
