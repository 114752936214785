import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import { queryType } from '../pages/TourTicket';
import {
  IItineraryVendors,
  TicketInfo,
  ITourPlaces,
  ITourTicketDataType,
} from '../types/TicketTypes';
const tourPlacesTag = 'tour-places';
const tourTicketTag = 'Ticket';
const TICKET = 'TICKET';
export const tourTicketEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTicket: build.query<HTTPResponse<ITourTicketDataType[]>, queryType>({
      query: (queryType) => ({
        url: `/tour-ittineray/${queryType}/get-all`,
      }),
      providesTags: () => [{ type: tourTicketTag, id: TICKET }],
    }),

    getAllTicketWithPagination: build.query<
      HTTPResponse<ITourTicketDataType[]>,
      { query: string; current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/tour-ittineray/${arg.query}/get-all?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: tourTicketTag, id: TICKET }],
    }),

    getAllTourPlaces: build.query<HTTPResponse<ITourPlaces[]>, void>({
      query: () => ({ url: `/tour-ittineray/places/get-all` }),
      providesTags: () => [tourPlacesTag],
    }),

    getItineraryVendorsInfo: build.query<
      HTTPResponse<IItineraryVendors[]>,
      number
    >({
      query: (itineraryId) => ({
        url: `/tour-ittineray/vendor/info/${itineraryId}`,
      }),
      providesTags: () => [tourPlacesTag],
    }),
    //create ticket
    createTourTicket: build.mutation<
      any,
      { body: TicketInfo[]; queryType: queryType }
    >({
      query: ({ body, queryType }) => ({
        url: `/tour-ittineray/${queryType}/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: tourTicketTag, id: TICKET }],
    }),

    // edit Ticket
    editTourTicket: build.mutation<
      void,
      { body: TicketInfo; id?: number | string; queryType: string }
    >({
      query: ({ body, id }) => ({
        url: `/tour-ittineray/ticket/update/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [{ type: tourTicketTag, id: TICKET }],
    }),
    //delete Ticket
    deleteTourTicket: build.mutation<
      void,
      { body: ITourTicketDataType; queryType: string }
    >({
      query: ({ body }) => ({
        url: `/tour-ittineray/ticket/delete/${body.itinerary_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),
      invalidatesTags: () => [{ type: tourTicketTag, id: TICKET }],
    }),
  }),
});
export const {
  useGetAllTicketQuery,
  useCreateTourTicketMutation,
  useEditTourTicketMutation,
  useGetAllTourPlacesQuery,
  useDeleteTourTicketMutation,
  useGetItineraryVendorsInfoQuery,
  useLazyGetItineraryVendorsInfoQuery,
  useGetAllTicketWithPaginationQuery,
} = tourTicketEndpoints;
