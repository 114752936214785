import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Popconfirm, Row } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { rulesFunc } from '../../../common/utils/common.utils';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { SelectRoomType } from '../../../components/common/FormItem/SelectCustomFeilds';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';
import { useParams } from 'react-router';
import { useWatch } from 'antd/es/form/Form';
import InvoiceUmrahHotelListItems from './InvoiceUmrahHotelListItems';
import { IHotel_information } from '../Types/InvoiceUmrahTypes';

type Props = {
  form: FormInstance<any>;
  hotel_information?: IHotel_information[];
};

export default function NewAddUmrahFormHotelInfo({
  form,
  hotel_information,
}: Props) {
  const { id } = useParams();
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Hotel Information 🏨' />
      <Form.List name='hotel_information' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map(({ key, name, ...restField }, index) => {
            return (
              <>
                <InvoiceUmrahHotelListItems
                  add={add}
                  form={form}
                  index={index}
                  name={name}
                  remove={remove}
                  key={key}
                  hotel_information={hotel_information}
                />
                {fields.length > 1 && fields.length - 1 !== index && (
                  <Divider />
                )}
              </>
            );
          })
        }
      </Form.List>
    </div>
  );
}
