import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  IRoomTypeEditFormData,
  IRoomTypeFormData,
  RoomTypeDataType,
} from '../types/roomTypesTypes';

const ROOMTYPES = 'ROOMTYPES';

export const roomTypeEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all room types

    getAllRoomTypes: build.query<HTTPResponse<RoomTypeDataType[]>, void>({
      query: () => ({ url: `/configuration/room-types/get-all` }),
      providesTags: () => [{ type: 'RoomTypes', id: ROOMTYPES }],
    }),

    // get all room types with pagination

    getAllRoomTypesWithPagination: build.query<
      HTTPResponse<RoomTypeDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `configuration/room-types?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'RoomTypes', id: ROOMTYPES }],
    }),

    //create room types
    createRoomTypes: build.mutation<void, IRoomTypeFormData>({
      query: (body) => ({
        url: `/configuration/room-types/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'RoomTypes', id: ROOMTYPES }],
    }),

    //edit room types

    editRoomTypes: build.mutation<void, IRoomTypeEditFormData>({
      query: (body) => ({
        url: `/configuration/room-types/edit/${body.rtype_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'RoomTypes', id: ROOMTYPES }],
    }),

    //delete room types
    deleteRoomTypes: build.mutation<void, RoomTypeDataType>({
      query: (body) => ({
        url: `/configuration/room-types/delete/${body.rtype_id}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),

      invalidatesTags: () => [{ type: 'RoomTypes', id: ROOMTYPES }],
    }),
  }),
});

export const {
  useGetAllRoomTypesQuery,
  useCreateRoomTypesMutation,
  useEditRoomTypesMutation,
  useDeleteRoomTypesMutation,
  useGetAllRoomTypesWithPaginationQuery,
} = roomTypeEndPoints;
