import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Radio, Row, message } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import { ISelectData } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  FormButton,
  FormInputItem,
  NumberInput,
} from '../../../../../components/common/FormItem/FormItems';

import {
  SelectDataInput,
  SelectVendors,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';
import {
  useEditTourTicketMutation,
  useGetAllTourPlacesQuery,
} from '../endpoints/tourTicketEndpoints';
import { TicketInfo } from '../types/TicketTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { queryType } from '../pages/TourTicket';
interface IProps {
  queryType: queryType;
  info: TicketInfo;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  editTitle?: string;
}
const EditTourTicket = ({
  info,
  setIsModalOpen,
  isModalOpen,
  queryType,
  editTitle,
}: IProps) => {
  const [form] = Form.useForm();

  // ============ modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // ========= set previous form value
  const prevVendorInfo =
    info?.itinerary_vendor &&
    info?.itinerary_vendor.map((item) => {
      return {
        itnrvendor_vendor_id: 'vendor-' + item.vendor_id,
        itnrvendor_cost_price: Number(item.itnrvendor_cost_price),
      };
    });

  useEffect(() => {
    form.setFieldsValue({
      itinerary_status: info.itinerary_status,
      itinerary_place_id: info.itinerary_place_id,
      itinerary_particular: info?.itinerary_particular,
    });
    form.setFields([
      {
        name: 'vendors',
        value: prevVendorInfo,
      },
    ]);
  }, [info]);

  // Edit data
  const [updateTicket, { isError, isSuccess, isLoading: deleteLoading }] =
    useEditTourTicketMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success(`${queryType} Edited Successfully`);
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const user = useSelector(selectUser);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as TicketInfo;
      values.itinerary_updated_by = user?.user_id as number;
      updateTicket({
        body: values,
        id: info?.itinerary_id,
        queryType: queryType,
      });
      setIsModalOpen(false);
    });
  };

  // ============= get all tour places
  const { data: tourPlaceData, isLoading } = useGetAllTourPlacesQuery();
  const tourPlaces = tourPlaceData?.data;
  const selectTourPlaceData = tourPlaces?.map((item) => {
    return { title: item.place_name, id: item.place_id };
  });
  return (
    <Modal
      title={editTitle || 'Update Tour Itineraries'}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={handleSubmit}
        wrapperCol={{ span: 23 }}
      >
        <>
          <Row>
            <SelectDataInput
              data={selectTourPlaceData as ISelectData[]}
              label='Place'
              name={'itinerary_place_id'}
              required
              placeholder='Select place'
            />

            <FormInputItem
              label='Particular'
              name={'itinerary_particular'}
              required
            />
          </Row>

          <Form.List name={'vendors'}>
            {(fieldsz, { add, remove }) =>
              fieldsz.map((fieldz, index) => {
                return (
                  <Row key={index}>
                    <SelectVendors
                      required
                      label='Vendor'
                      name={[fieldz.name, 'itnrvendor_vendor_id']}
                    />

                    <NumberInput
                      required
                      label='Cost Price'
                      name={[fieldz.name, 'itnrvendor_cost_price']}
                      min='0'
                      maxChar={14}
                    />
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                    >
                      <Form.Item label='Action'>
                        {index === 0 ? (
                          <Button onClick={() => add()}>
                            <PlusOutlined />
                          </Button>
                        ) : (
                          <Button
                            danger
                            onClick={() => remove(fieldz.name)}
                          >
                            <MinusOutlined />
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })
            }
          </Form.List>

          <Form.Item
            name='itinerary_status'
            label='Status'
          >
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <FormButton label={`Edit ${queryType}`} />
        </>
      </Form>
      {isLoading || (deleteLoading && <LoadingIndicator />)}
    </Modal>
  );
};

export default EditTourTicket;
