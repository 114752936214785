import { Col, Form, Modal, Row, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  TableParams,
  handleTableChange,
} from '../../../common/utils/common.utils';
import { getDataWithIndex } from '../../../helpers/getDataWithIndex';
import { useLazyGetAllChequesQuery } from '../api/Endpoints/AllChequeManagementEndpoints';
import {
  setBounceModal,
  setDepositModal,
  setReturnModal,
} from '../states/chequeSlice';
import { IAllChequeDataType } from '../types/ChequeManagementTypes';
import { Cheque_Column, getChequeType } from '../utils/Cheque_Column';

import { SelectDataInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import Cheque_Update_Modal from '../modals/Common_Modals/Cheque_Update_Modal';
import { commonCheque } from '../states/chequeSlice';

const Cheque_Management = () => {
  const [status, setStatus] = useState('PENDING');
  const dispatch = useAppDispatch();
  const modalSlice = useAppSelector(commonCheque);

  const [fetchAllCheque, { data: allCheque, isLoading, isFetching }] =
    useLazyGetAllChequesQuery();
  const [depositInfo, setDepositInfo] = useState<IAllChequeDataType | null>(
    null
  );

  const [selectedChequeType, setSelectedChequeType] = useState(null);

  const showDepositModal = () => {
    dispatch(setDepositModal(true));
  };
  const showBounceModal = () => {
    dispatch(setBounceModal(true));
  };
  const showReturnModal = () => {
    dispatch(setReturnModal(true));
  };

  const handleCancel = () => {
    dispatch(setDepositModal(false));
    dispatch(setBounceModal(false));
    dispatch(setReturnModal(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setDepositModal(false));
      dispatch(setBounceModal(false));
      dispatch(setReturnModal(false));
    };
  }, []);

  const uniqueChequeTypes = Array.from(
    new Set(allCheque?.data?.map((cheque) => cheque.cheque_type))
  );

  // Function to handle cheque type selection
  const handleChequeTypeChange = (value: any) => {
    setSelectedChequeType(value);
  };
  const filteredCheques = selectedChequeType
    ? allCheque?.data?.filter(
      (cheque) => cheque.cheque_type === selectedChequeType
    )
    : allCheque?.data;

  //pagination
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(allCheque?.count || 0);
  }, [allCheque]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 50,
      total: count,
    },
  });
  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    fetchAllCheque({ query: query ? `${query}&status=${status}` : '' });
  };

  useEffect(() => {
    fetchAllCheque({ query: `?status=${status}` });
  }, [status]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });
  return (
    <div>
      <BreadCrumb
        arrOfOption={['Cheque', 'All Cheque']}
        refetch={() =>
          refetch({
            query: `?page=${queryData.current}&size=${queryData.pageSize}`,
          })
        }
        reloaderSize='small'
      />

      <Modal
        title='Change Cheque Status'
        open={modalSlice.isDepositModal}
        onCancel={handleCancel}
        footer={false}
        onOk={handleCancel}
      >
        {depositInfo && <Cheque_Update_Modal info={depositInfo} />}
      </Modal>

      <Row style={{ marginBottom: '10px' }}>
        <SelectDataInput
          onChange={(value) => setStatus(value.toString())}
          defaultValue={'PENDING'}
          size={4}
          data={[
            { title: 'PENDING', id: 'PENDING' },
            { title: 'DEPOSIT', id: 'DEPOSIT' },
            { title: 'BOUNCE', id: 'BOUNCE' },
            { title: 'RETURN', id: 'RETURN' },
          ]}
        />

        <Col
          style={{ marginLeft: '10px' }}
          span={6}
          xs={24}
          sm={24}
          md={24}
          lg={6}
        >
          <Form.Item name='account_id'>
            <Select
              showSearch
              allowClear
              placeholder={'Select Cheque Type'}
              optionFilterProp='children'
              onChange={handleChequeTypeChange}
            >
              {uniqueChequeTypes?.map((item: any, index) => {
                return (
                  <Select.Option key={index} value={item}>
                    {getChequeType(item)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        dataSource={getDataWithIndex(filteredCheques)}
        columns={Cheque_Column(setDepositInfo, showDepositModal)}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        pagination={count > 50 ? tableParams.pagination : false}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
      />
    </div>
  );
};

export default Cheque_Management;
