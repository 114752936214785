import dayjs from 'dayjs';
import { ColumnsType } from 'antd/lib/table';
import { IUserLoginDataType } from '../types/userLoginTypes';

export const UserLoginColumn = (): ColumnsType<IUserLoginDataType> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'User full name',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
    },

    {
      title: 'User username',
      dataIndex: 'user_username',
      key: 'user_username',
    },
    {
      title: 'User mobile',
      dataIndex: 'user_mobile',
      key: 'user_mobile',
    },
    {
      title: 'User role',
      dataIndex: 'user_role',
      key: 'user_role',
    },
    {
      title: 'Login date/time',
      dataIndex: 'login_date_and_time',
      key: 'login_date_and_time',
      render: (_, record) => {
        return `${dayjs(record.login_date_and_time).format(
          'DD MMM YYYY'
        )} /${dayjs(record.login_date_and_time).format('hh:mm A')}`;
      },
    },

    {
      title: 'Logout date/time',
      dataIndex: 'logout_date_and_time',
      key: 'logout_date_and_time',
      render: (_, record) => {
        return `${dayjs(record.logout_date_and_time).format(
          'DD MMM YYYY'
        )} /${dayjs(record.logout_date_and_time).format('hh:mm A')}`;
      },
    },

    {
      title: 'Login ip',
      dataIndex: 'login_ip_address',
      key: 'login_ip_address',
    },
  ];
};
