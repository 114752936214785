import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../../../common/types/commonTypes';
import { IClientIncentiveIncomeFormData } from '../../../../AccountsTypes/AccountsTypes';
import { useDeleteClientIncentiveIncomeMutation } from '../../endpoints/client_IncentiveIncomeEndpoints';
import Client_incentive_Edit_Modal from '../../modal/client/Client_incentive_Edit_Modal';
import { useEffect, useState } from 'react';

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
  checkButtonState: boolean;
  permission: IPermission | undefined;
};

export const client_IncentiveIncomeColumn = ({
  pagination,
  checkButtonState,
  permission,
}: Props): ColumnsType<IClientIncentiveIncomeFormData> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);

  const [
    deleteIncentive,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteClientIncentiveIncomeMutation();

  const handleDelete = async (
    values: IClientIncentiveIncomeFormData,
    index: number
  ) => {
    const body = {
      incentive_deleted_by: user?.user_id,
      id: values.incentive_id,
    };
    setColumnIndex(index);
    await deleteIncentive(body);
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
    }
  }, [isSuccess]);
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'incentive_created_date',
      render: (_, record) => {
        return `${dayjs(record.date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Vouchar No',
      key: 'incentive_vouchar_no',
      dataIndex: 'incentive_vouchar_no',
    },
    {
      title: 'Transaction Details',
      key: 'transaction_details',
      render: (_, record) =>
        record.adjust_with_bill == 'NO' ? (
          ` ${record.account_name}  ${
            record.account_branch_name ? ', ' + record.account_branch_name : ''
          }`
        ) : (
          <>Bill Adjustment</>
        ),
    },
    {
      title: 'Amount',
      dataIndex: 'incentive_amount',
      key: 'incentive_amount',
    },
    {
      title: 'Note',
      dataIndex: 'incentive_note',
      key: 'incentive_note',
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {!checkButtonState && (
            <Link
              to={`/accounts/incentive/client/view/${record.incentive_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}
          {!checkButtonState && permission?.[crud.update] && (
            <Client_incentive_Edit_Modal id={record.incentive_id} />
          )}

          {permission?.[crud.delete] && record.incentive_is_deleted === 0 && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
