import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { IOnlineChargeReportDataTypes } from '../types/onlineTransactionCharge';

export const OnlineTransactionChargeReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all PassportStatusReport
    getAllTransactionChargeReport: build.query<
      HTTPResponse<IOnlineChargeReportDataTypes[]>,
      {
        query: string;
      }
    >({
      query: ({ query }) => ({
        url: `/report/online-trxn-charge${query}`,
      }),
      providesTags: [{ type: 'OnlineTransactionChargeReport' }],
    }),
  }),
});

export const { useLazyGetAllTransactionChargeReportQuery } =
  OnlineTransactionChargeReportEndpoints;
