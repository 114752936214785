import { Form, Input, Button, Radio, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { ProductDataType } from '../types/productTypes';
import CategorySelect from '../components/CategorySelect';
import { useEditProductsMutation } from '../endpoints/productsEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  info: ProductDataType;
  setNull: ISetState<ProductDataType | null>;
}

const ProductEditModal = ({ info, setNull }: any) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const modalSlice = useSelector(selectCommon);

  useEffect(() => {
    form.setFieldsValue({
      product_name: info.product_name,
      product_category_id: info.product_category_id,
      product_status: info.product_status,
    });
  }, [info]);

  const [editProduct, { isError, isSuccess, isLoading }] =
    useEditProductsMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Product Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as ProductDataType;
      values.product_id = info.product_id;
      await editProduct({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form form={form} onFinish={handleSubmit} {...layout} labelAlign='left'>
      <Form.Item
        name='product_name'
        label='Product Name'
        rules={[
          { required: true, message: 'Please input Product name!' },
          { max: 65, message: 'Maximum 65 characters' },
        ]}
      >
        <Input placeholder='Enter product name' />
      </Form.Item>

      <CategorySelect />

      <Form.Item name='product_status'>
        <Radio.Group>
          <Radio value={1}>Active</Radio>
          <Radio value={0}>Inactive</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          disabled={modalSlice.modalLoading}
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>

      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default ProductEditModal;
