import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, message } from 'antd';
import Lottie from 'lottie-react';
import plane from '../../components/images/61111-plane.json';
import {
  useChangePasswordMutation,
  useLazyGetOTPQuery,
  useVerifyOTPMutation,
} from '../../app/baseQuery';
import { useNavigate } from 'react-router';

interface EmailFormValues {
  email: string;
}

interface OTPFormValues {
  otp: string;
}

interface NewPasswordFormValues {
  password: string;
}

const ForgotPassword = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const navigate = useNavigate();

  const [
    getOTP,
    { isSuccess: OtpSuccess, isError: OtpError, isLoading: OtpLoading },
  ] = useLazyGetOTPQuery();

  const [verifyOTP, { isError: verifyErr, isSuccess: verifySucc }] =
    useVerifyOTPMutation();

  const [changePass, { isError: passErr, isSuccess: passSucc }] =
    useChangePasswordMutation();

  const handleSubmitEmail = (values: EmailFormValues) => {
    const formEmail = values.email;
    setEmail(formEmail);
    getOTP(formEmail);
  };

  useEffect(() => {
    if (OtpSuccess) {
      setStep(2);
    } else if (OtpError) {
      message.error('Something went to wrong');
    }
  }, [OtpSuccess, OtpError]);

  const handleSubmitOTP = (values: OTPFormValues) => {
    const otp = values.otp;
    setOtp(otp);
    const body = {
      otp: otp,
      email: email,
    };
    verifyOTP({ body });
  };

  useEffect(() => {
    if (verifySucc) {
      setStep(3);
    } else if (verifyErr) {
      message.error('Something Went Wrong');
    }
  }, [verifySucc, verifyErr]);

  const handleSubmitNewPassword = (values: NewPasswordFormValues) => {
    const body = {
      otp: otp,
      email: email,
      password: values.password,
    };
    changePass({ body });
  };

  useEffect(() => {
    if (passSucc) {
      message.success('Password Change Successfully');
      navigate('../');
    } else if (passErr) {
      message.error('Something Went Wrong');
    }
  }, [passSucc, passErr]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Form<EmailFormValues> name='emailForm' onFinish={handleSubmitEmail}>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input placeholder='Enter your email' />
            </Form.Item>
            <Form.Item>
              <Button loading={OtpLoading} type='primary' htmlType='submit'>
                Get OTP
              </Button>
            </Form.Item>
          </Form>
        );
      case 2:
        return (
          <Form<OTPFormValues> name='otpForm' onFinish={handleSubmitOTP}>
            <h5>Check your email inbox, We send an OTP </h5>
            <Form.Item
              name='otp'
              rules={[
                {
                  required: true,
                  message: 'Please enter the OTP',
                },
              ]}
            >
              <Input placeholder='Enter the OTP' />
            </Form.Item>
            <Form.Item>
              <Button loading={verifySucc} type='primary' htmlType='submit'>
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
        );
      case 3:
        return (
          <Form<NewPasswordFormValues>
            name='newPasswordForm'
            onFinish={handleSubmitNewPassword}
          >
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },

                {
                  validator: (_, value) => {
                    if (value && value.length < 8) {
                      return Promise.reject(
                        'Password must be at least 8 characters long'
                      );
                    }
                    const pattern = /^(?=.*[!@#$%^&*])/;

                    if (value && !pattern.test(value)) {
                      return Promise.reject(
                        'password must contain at least one special character and number'
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder='Enter New Password' />
            </Form.Item>

            <Form.Item
              name='confirm'
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder='Confirm New Password' />
            </Form.Item>
            <Form.Item>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Row style={{ marginTop: '10px' }} justify='center'>
        <Lottie animationData={plane} style={{ maxWidth: '300px' }} />
      </Row>

      {renderStep()}
    </div>
  );
};

export default ForgotPassword;
