import { message } from 'antd';
import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import { expire } from '../../../../../common/utils/constants';
import {
  AgentProfileDataType,
  AgentProfileRestoreDataType
} from '../../types/agentProfileTypes';

const agent_profile = 'agent_profile';

export const agentProfileEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // ThIS API USE FOR SELECT ONLY
    getAllAgentProfile: build.query<HTTPResponse<AgentProfileDataType[]>, void>(
      {
        query: () => ({ url: `/agent-profile/agents` }),
        providesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
      }
    ),

    // THIS API USE FOR GET ALL LIST WITH PAGINATION AND SEARCH
    getAllAgentProfileList: build.query<
      HTTPResponse<AgentProfileDataType[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/agent-profile/get-all?page=${arg.current}&size=${arg.pageSize}&search=${arg.search}`,
      }),
      providesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
    }),

    // get all trashed Agents
    getAllTrashedAgents: build.query<
      HTTPResponse<AgentProfileDataType[]>,
      void
    >({
      query: () => ({ url: `agent-profile/agent-trash` }),
      providesTags: [{ type: 'AgentProfile', id: agent_profile }],
    }),

    // get single agent profile by agent id
    getSingleAgentProfile: build.query<
      HTTPResponse<AgentProfileDataType[]>,
      number
    >({
      query: (agent_id) => ({ url: `agent-profile/agent/${agent_id}` }),
      keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, agent_id) => [
        { type: 'AgentProfile', id: agent_profile } as const,
      ],
    }),

    //create agent profile
    createAgentProfile: build.mutation<
      HTTPResponse<{ data: number }>,
      FormData
    >({
      query: (body) => ({
        url: `agent-profile/create`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Create Agent Profile Successfully');
      },
      invalidatesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
    }),

    //Edit agent profile
    editAgentProfile: build.mutation<void, { data: FormData; id: number }>({
      query: ({ data, id }) => ({
        url: `agent-profile/agent/${id}`,
        method: 'PATCH',
        body: data,
      }),
      onQueryStarted: async (arg, { queryFulfilled }) => {
        await queryFulfilled;
        message.success('Agent Profile Edited Successfully');
      },
      invalidatesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
    }),

    //delete client Category
    deleteAgentProfile: build.mutation<void, AgentProfileDataType>({
      query: (body) => ({
        url: `agent-profile/agent/${body.agent_id}`,
        method: 'DELETE',
        body: body,
      }),

      onQueryStarted: async (body, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Agent Profile Deleted Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
    }),

    //restoreAgent
    restoreAgentProfile: build.mutation<void, AgentProfileRestoreDataType>({
      query: ({ agent_id, updated_by }) => ({
        url: `agent-profile/agent-restore/${agent_id}`,
        method: 'PATCH',
        body: { updated_by },
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Restore agent profile succussfuly');
        });
      },
      invalidatesTags: () => [{ type: 'AgentProfile', id: agent_profile }],
    }),
  }),
});

export const {
  useGetAllAgentProfileQuery,
  useGetSingleAgentProfileQuery,
  useLazyGetSingleAgentProfileQuery,
  useCreateAgentProfileMutation,
  useEditAgentProfileMutation,
  useDeleteAgentProfileMutation,
  useRestoreAgentProfileMutation,
  useLazyGetAllTrashedAgentsQuery,
  useGetAllAgentProfileListQuery,
} = agentProfileEndpoints;
