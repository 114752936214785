import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { setModal } from '../../../../common/slices/commonSlice';
import { MaharamDataType } from '../types/MaharamTypes';
import { useEditMaharamMutation } from '../endpoints/maharamEndpoints';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  maharamNameList: string[];
  info: MaharamDataType;
  setNull: ISetState<MaharamDataType | null>;
}

const EditMaharamModal = ({ info, setNull, maharamNameList }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ maharam_name: info.maharam_name });
  }, [info]);

  const [editMaharam, { isError, isSuccess, isLoading }] =
    useEditMaharamMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Maharam Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as MaharamDataType;
      values.maharam_id = info.maharam_id;
      await editMaharam({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  //loading indicator
  if (isLoading) {
    return loadingIndicator;
  }
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      form={form}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Maharam Name'
        name='maharam_name'
        rules={[
          { required: true, message: 'Please enter Maharam name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (maharamNameList.includes(value)) {
                  return Promise.reject('MaharamName Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter Maharam name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditMaharamModal;
