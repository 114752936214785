import { Button, Card, Timeline, Row, Spin } from 'antd';
import { useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useGetAirTicketLogsQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

type Props = {};

function AirticketRefundLogs({}: Props) {
  const { id } = useParams();

  const { isLoading, data: getAirticketLogs } = useGetAirTicketLogsQuery(
    Number(id)
  );
  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'AirTicket Refund Logs']} />
      <Link to='/refund/history/airticket'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to AirTicket Refund List
        </Button>
      </Link>

      <Card>
        <Timeline mode='left'>
          {getAirticketLogs?.data?.map((item, index) => {
            return (
              <Timeline.Item
                key={index}
                label={item.atrfndlog_date && item.atrfndlog_date.slice(0, 10)}
              >
                {item.atrfndlog_content + ' by ' + item.created_by}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Card>

      {isLoading && <LoadingIndicator />}
    </div>
  );
}

export default AirticketRefundLogs;
