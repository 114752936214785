import React, { useState } from 'react';
import TaxReport from './TaxReport';
import OtherTaxReport from './OtherTaxReport';

const TaxReportsToggle = () => {
  const [value, setValue] = useState<number>(1);
  return (
    <>
      {value === 1 ? (
        <OtherTaxReport value={value} setValue={setValue} />
      ) : (
        <TaxReport value={value} setValue={setValue} />
      )}
    </>
  );
};

export default TaxReportsToggle;
