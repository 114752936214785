import { Breadcrumb, Descriptions, Tabs, Tag } from 'antd';
import 'antd/dist/reset.css';
import styled from 'styled-components';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import InvoiceVenDet from './InvoiceVenDet';
import PaymentsVenDet from './PaymentsVenDet';
import RefundProductVenDet from './RefundProductVenDet';
import RefundTransactionVenDet from './RefundTransactionVenDet';
import VendorLedgers from '../../../Reports/ledgers/pages/VendorLedgers';

const { TabPane } = Tabs;

type Props = {};
interface clientDataType {
  key: number;
  vendor_id: number;
  name: string;
  working_category: string[];
  working_country: string;
  company: string;
  email: string;
  mobile: number;
  address: string;
  date: string;
  status: string;
}
const data: clientDataType[] = [
  {
    key: 1,
    vendor_id: 1021442,
    name: 'saidur',
    working_category: ['US Bangla', 'Omrah full package'],
    working_country: 'Bangladesh',
    address: 'Brahmanbaria',
    company: 'manager',
    email: 'tau@gmail.com',
    date: '25-08-2016',
    mobile: 8801881544760,
    status: 'ACTIVE',
  },
];

const VendorDetails = (props: Props) => {
  return (
    <TableStyle>
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>List Of Vendor</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='card-container'>
        <Tabs type='card'>
          <TabPane tab='Details' key='1'>
            <Descriptions size='small' title='Vendor Info' bordered column={1}>
              <DescriptionsItem label='Vendor ID'>
                {data[0].vendor_id}
              </DescriptionsItem>
              <DescriptionsItem label='Name'>{data[0].name}</DescriptionsItem>
              <DescriptionsItem label='Working Category'>
                {data[0].working_category}
              </DescriptionsItem>
              <DescriptionsItem label='Working Country'>
                {data[0].working_country}
              </DescriptionsItem>
              <DescriptionsItem label='Company'>
                {data[0].company}
              </DescriptionsItem>
              <DescriptionsItem label='Email'>{data[0].email}</DescriptionsItem>
              <DescriptionsItem label='Mobile'>
                {data[0].mobile}
              </DescriptionsItem>
              <DescriptionsItem label='Address'>
                {data[0].company}
              </DescriptionsItem>
              <DescriptionsItem label='Date'>{data[0].date}</DescriptionsItem>
              <DescriptionsItem label='Status'>
                {<Tag color={'green'}>{data[0].status}</Tag>}
              </DescriptionsItem>
            </Descriptions>
          </TabPane>
          <TabPane tab='Invoice' key='2'>
            <InvoiceVenDet />
          </TabPane>
          <TabPane tab='Payments' key='3'>
            <PaymentsVenDet />
          </TabPane>
          <TabPane tab='Refund Product' key='4'>
            <RefundProductVenDet />
          </TabPane>
          <TabPane tab='Refund Transaction' key='5'>
            <RefundTransactionVenDet />
          </TabPane>
          <TabPane tab='Vendor Ledger' key='6'>
            <VendorLedgers />
          </TabPane>
        </Tabs>
      </div>
    </TableStyle>
  );
};

export default VendorDetails;

const TableStyle = styled.div`
  .ddd {
    @media only screen and (max-width: 200px) {
      width: 80%;
    }
  }
`;
