import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';

interface DataType {
  key: string;
  serial: number;
  date: string;
  vouchar_no: number;
  total_refund: number;
  refund_charge: number;
  refund_amount: number;
  refund_type: string;
  refund_by: string;
}
const columns: ColumnsType<DataType> = [
  {
    title: 'SL.',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Vouchar',
    dataIndex: 'vouchar_no',
    key: 'vouchar_no',
  },
  {
    title: 'Total Refund',
    dataIndex: 'total_refund',
    key: 'total_refund',
  },
  {
    title: 'Refund Charge',
    dataIndex: 'refund_charge',
    key: 'refund_charge',
  },
  {
    title: 'Refund Amount',
    dataIndex: 'refund_amount',
    key: 'refund_amount',
  },

  {
    title: 'Refund Type',
    dataIndex: 'refund_type',
    key: 'refund_type',
  },
  {
    title: 'Refund By',
    dataIndex: 'refund_by',
    key: 'refund_by',
  },
];
const data: DataType[] = [
  {
    key: '1',
    serial: 1,
    date: '2022-05-15',
    vouchar_no: 1234,
    total_refund: 24145,
    refund_charge: 1230,
    refund_amount: 1234,
    refund_type: 'aseda',
    refund_by: 'aseda',
  },
];

type Props = {};

const RefundTransactionVenDet = (props: Props) => {
  return (
    <div>
      <Table
         bordered
        size='small'
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default RefundTransactionVenDet;
