import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { TICKETWISEPASSENGERLIST } from '../../Api/constants';
import {
  IGroupWisePassengerDataType,
  IGroupWisePassengerFormDataType,
} from '../types/groupWisePassengerListTypes';

export const groupWisePassengerListEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all GroupWiseProfitLoss
    getGroupWisePassenerList: build.query<
      HTTPResponse<IGroupWisePassengerDataType[]>,
      { group_id: number; query: string }
    >({
      query: ({ group_id, query }) => ({
        url: `report/group-wise-passenger-list/${group_id}${query}`,
      }),
      providesTags: [
        { type: 'GroupWisePassenerList', id: TICKETWISEPASSENGERLIST },
      ],
    }),
  }),
});

export const { useLazyGetGroupWisePassenerListQuery } =
  groupWisePassengerListEndpoints;
