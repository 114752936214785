import { Col, Divider, Form, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectAgent } from '../../../components/common/FormItem/SelectCustomFeilds';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectClients,
  SelectEmployee,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import {
  useGetAllClientsQuery,
  useLazyGetClLastBalanceQuery,
} from '../../../modules/Client/Client/api/endpoints/clientEndpoints';
import { useGetAllGroupsQuery } from '../../Configuration/group/endpoints/groupEndpoints';
import HajjiGroupAddModal from '../Modals/HajjiGroupAddModal';
import { useWatch } from 'antd/es/form/Form';
import { NamePath } from 'antd/es/form/interface';

type Props = {
  clientOnChange?: Function;
  form: FormInstance<any>;
  invoice_net_totalFieldName: NamePath;
  invoice_agent_com_amountFieldName: NamePath;
};

function InvoiceHajjPreRegHeader({
  form,
  invoice_agent_com_amountFieldName,
  invoice_net_totalFieldName,
}: Props) {
  /* set Group name when InstanceCreate */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceGroupSelect, setInstanceGroupSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const [instanceAgentSelect, setInstanceAgentSelect] = useState<number>();
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgentSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgentSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceSalesEmpSelect);
    } else if (instanceGroupSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceGroupSelect);
    }
  }, [
    instanceClientsSelect,
    instanceAgentSelect,
    instanceSalesEmpSelect,
    instanceGroupSelect,
  ]);

  const [click, setClick] = useState<boolean>();
  const { id } = useParams();
  const client_id = useWatch('invoice_combclient_id', form);
  // get client last balance
  const [getLastBalance, { data: clientLBlance, isFetching }] =
    useLazyGetClLastBalanceQuery();

  useEffect(() => {
    if (client_id !== undefined) {
      getLastBalance(client_id);
    }
  }, [client_id]);
  useEffect(() => {
    form?.setFieldsValue({
      invoice_client_previous_due: clientLBlance?.data?.client_prev_due,
      client_present_balance: clientLBlance?.data?.client_last_balance,
    });
  }, [isFetching]);

  // =========================== clients select
  const { data: clients } = useGetAllClientsQuery({}); // when implement search use lazy query
  const selectClients = clients?.data;
  const clientsChildren: React.ReactNode[] = [];
  if (selectClients) {
    for (let i = 0; i < selectClients.length; i++) {
      clientsChildren.push(
        <Select.Option
          key={
            selectClients[i].client_name +
            '  ' +
            selectClients[i].client_id +
            ' ' +
            i
          }
          value={selectClients[i].client_id}
        >
          {selectClients[i].client_name +
            '  ' +
            selectClients[i].client_entry_id}
        </Select.Option>
      );
    }
  }
  // =========================== group select
  const { data: groups } = useGetAllGroupsQuery();
  const selectGroup = groups?.data;
  const groupChildrens: React.ReactNode[] = [];
  if (selectGroup) {
    for (let i = 0; i < selectGroup.length; i++) {
      groupChildrens.push(
        <Select.Option
          title=' '
          key={
            selectGroup[i].group_name + '  ' + selectGroup[i].group_id + ' ' + i
          }
          value={selectGroup[i].group_id}
        >
          {selectGroup[i].group_name}
        </Select.Option>
      );
    }
  }

  return (
    <Row className='border p-5 my-20' gutter={[10, 0]}>
      <SelectClients
        name='invoice_combclient_id'
        label='Select Client :'
        required
        disabled={id ? true : false}
        placeholder='Select client'
        size={4}
        mdSize={8}
        smSize={12}
        setInstanceClientSelect={setInstanceClientSelect}
      />

      <SelectEmployee
        name='invoice_sales_man_id'
        label='Sales By'
        required
        size={3}
        setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
      />
      {/* ========= Group Select ====== */}
      <Col span={24} xs={24} sm={12} md={8} lg={4}>
        <Form.Item
          className='commonBox'
          name='invoice_haji_group_id'
          label='Group Name '
        >
          <Select
            allowClear
            placeholder={'Select Group'}
            showSearch
            optionFilterProp='roleMobile'
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                <HajjiGroupAddModal
                  setClick={setClick}
                  setInstanceGroupSelect={setInstanceGroupSelect}
                />

                <Divider style={{ margin: '8px 0' }} />
                {menu}
              </>
            )}
            open={click === true ? false : undefined}
          >
            {groupChildrens}
          </Select>
        </Form.Item>
      </Col>
      <FormInputItem label='Invoice no:' name='invoice_no' readOnly size={3} />

      <DateInput
        label='Sales Date'
        name='invoice_sales_date'
        required
        size={3}
        disableFeatureDate
      />
      <DateInput label='Due Date:' name='invoice_due_date' size={3} />
      <SelectAgent
        label='Select Agent:'
        name={'invoice_agent_id'}
        isPercent
        size={4}
        setInstanceAgentSelect={setInstanceAgentSelect}
      />
    </Row>
  );
}

export default InvoiceHajjPreRegHeader;
