import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectOptionsInput,
  SelectPassport,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetSinglePassportQuery } from '../../Passport_MGT/Api/Endpoints/PassportEndpoints';
import { Ipassport_information } from '../Type/invoiceVisa.interface';
import BillingIsDeleted from './BillingIsDeleted';

interface Props {
  name: number;
  form: FormInstance<any>;
  passportInfo: Ipassport_information[] | undefined;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  formName: string | undefined;
}

const PassportInformation = ({
  name,
  form,
  passportInfo,
  remove,
  add,
  formName,
}: Props) => {
  const { id } = useParams();

  const is_deleted: 0 | 1 = useWatch(
    [formName || 'passport_information', name, 'is_deleted'],
    form
  );
  const passPortId = useWatch(
    [formName || 'passport_information', name, 'passport_id'],
    form
  );
  const [getSinglePassport, { data: singlePassport }] =
    useLazyGetSinglePassportQuery();

  useEffect(() => {
    if (passPortId) getSinglePassport(passPortId, true);
  }, [passPortId]);

  // set form value by Passport Id and setField undefined when passportId is undefined
  useEffect(() => {
    if (passPortId) {
      form.setFields([
        {
          name: [formName || 'passport_information', name, 'passport_name'],
          value: singlePassport?.data?.name,
        },

        {
          name: [
            formName || 'passport_information',
            name,
            'passport_person_type',
          ],
          value: singlePassport?.data?.passport_person_type,
        },

        {
          name: [formName || 'passport_information', name, 'passport_email'],
          value: singlePassport?.data?.email,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_mobile_no',
          ],
          value:
            singlePassport?.data?.mobile_no?.split('-')[1] ||
            singlePassport?.data?.mobile_no,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_birth',
          ],
          value: singlePassport?.data?.date_of_birth
            ? dayjs(singlePassport?.data?.date_of_birth)
            : undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_issue',
          ],
          value: singlePassport?.data?.date_of_issue
            ? dayjs(singlePassport?.data?.date_of_issue)
            : undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_expire',
          ],
          value: singlePassport?.data?.date_of_expire
            ? dayjs(singlePassport?.data?.date_of_expire)
            : undefined,
        },
      ]);
    } else {
      form.setFields([
        {
          name: [formName || 'passport_information', name, 'passport_name'],
          value: undefined,
        },
        {
          name: [formName || 'passport_information', name, 'passport_email'],
          value: undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_person_type',
          ],
          value: undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_mobile_no',
          ],
          value: undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_birth',
          ],
          value: undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_issue',
          ],
          value: undefined,
        },
        {
          name: [
            formName || 'passport_information',
            name,
            'passport_date_of_expire',
          ],
          value: undefined,
        },
      ]);
    }
  }, [singlePassport, passPortId]);

  return (
    <Row
      gutter={[5, 0]}
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
    >
      <SelectPassport
        name={[name, 'passport_id']}
        label='Passport No'
        size={4}
        placeholder='select passport'
      />
      <FormInputItem name={[name, 'passport_name']} label='Name' size={4} />

      <SelectOptionsInput
        options={['Adult', 'Child', 'Infant']}
        name={[name, 'passport_person_type']}
        label='Pax Type'
        size={4}
      />

      <FormInputItem
        label='Contact No.'
        name={[name, 'passport_mobile_no']}
        size={4}
        readOnly
      />

      <FormInputItem
        name={[name, 'passport_email']}
        label='Email'
        size={4}
        inputType={'email'}
        readOnly
      />

      <DateInput
        name={[name, 'passport_date_of_birth']}
        label='Date of birth'
        size={4}
        disabled
      />
      <DateInput
        name={[name, 'passport_date_of_issue']}
        label='Date of issue'
        size={4}
        disabled
      />
      <DateInput
        name={[name, 'passport_date_of_expire']}
        label='Date of expire'
        size={4}
        disabled
      />

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[name, 'is_deleted']} />

      {name === 0 ? (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'passport_information',
                  name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['passport_information', name, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (passportInfo?.length && name >= passportInfo.length)
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default PassportInformation;
