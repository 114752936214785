import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { IMaharamFormData } from '../types/MaharamTypes';
import { useCreateMaharamMutation } from '../endpoints/maharamEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';

const AddNewMaharam = ({ maharamNameList }: { maharamNameList: string[] }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [form] = Form.useForm();

  const [addMaharam, { isError, isSuccess, isLoading }] =
    useCreateMaharamMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success('Maharam Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IMaharamFormData) => {
    const body: IMaharamFormData = {
      ...values,
      maharam_created_by: user?.user_id as number,
    };
    await addMaharam(body);
    dispatch(setModal(false));
    form.resetFields();
  };
  return (
    <Form
      layout='horizontal'
      form={form}
      labelAlign='left'
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Maharam Name'
        name='maharam_name'
        rules={[
          { required: true, message: 'Please enter maharam name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (maharamNameList.includes(value)) {
                  return Promise.reject('MaharamName Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter Maharam Name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNewMaharam;
