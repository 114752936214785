import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { USERLOGINHISTORY } from '../../Api/constants';
import { IUserLoginDataType } from '../types/userLoginTypes';

export const userLoginEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all userLogin History
    getUserLoginHistory: build.query<
      HTTPResponse<IUserLoginDataType[]>,
      {
        date_range: string;
        user_id: number;
        currentPage: number;
        currentSize: number;
      }
    >({
      query: ({ date_range, user_id, currentPage, currentSize }) => ({
        url: `/report/user-login-history?${date_range}&page=${currentPage}&size=${currentSize}`,
        method: 'POST',
        body: {
          user_id: user_id,
        },
      }),
      providesTags: [{ type: 'UserLoginHistory', id: USERLOGINHISTORY }],
    }),

    // reset database
    resetDatabase: build.mutation<
      HTTPResponse<void>,
      { pass: string; deleted_by: number }
    >({
      query: (body) => ({
        url: `/databse/reset?pass=${body}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [
        'invoices',
        'clients',
        'vendors',
        'accounts',
        'money-receipt',
      ],
    }),
  }),
});

export const {
  useLazyGetUserLoginHistoryQuery,
  useGetUserLoginHistoryQuery,
  useResetDatabaseMutation,
} = userLoginEndpoints;
