import { Alert, Form, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import AirticketTicketDetails from '../Components/AgencyWiseCalculation/AirticketTicketDetails';
import AirticketFlightDetails from '../Components/AirticketFlightDetails';
import AirticketHeader from '../Components/AirticketHeader';
import AirticketPaxPassportDetails from '../Components/AirticketPaxPassportDetails';
import PostOverview from '../Components/PostOverview';
import { useLazyGetDataByPnrQuery } from '../api/endpoints/airticketInvoiceEndpoints';
import { AirTicketInvoiceData } from '../types/invoiceAirTicketTypes';
import { formatePNRDetails } from './GetPNRFormetter';
import { Fixed2 } from '../../../common/utils/common.utils';

type Props = {};

export type AitCalculationProps = {
  commissionType?: 'manual' | 'percentage';
  isAitManual?: boolean;
  isTaxManual?: boolean;
};

const NewInvoiceAirTicket = ({}: Props) => {
  //  @STATES
  const [invoices, setInvoices] = useState<AirTicketInvoiceData[]>([]);
  const [editIndex, setEditIndex] = useState<number | undefined>();

  const [form] = useForm();
  const getHeightRef = useRef(null);

  const auto_pnr_toggle = useWatch('auto_pnr_toggle', form);

  // @Get Data by PNR
  const inputtedPNR = Form.useWatch('airticket_pnr', form);
  const [
    getPnrDetails,
    { data: pnrData, isLoading, isFetching, isSuccess, isError },
  ] = useLazyGetDataByPnrQuery();

  const pnr_invoice_salesDate = pnrData?.data?.invoice_sales_date;

  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  useEffect(() => {
    if (inputtedPNR?.length === 6 && auto_pnr_toggle == 1) {
      getPnrDetails(inputtedPNR);
    }
  }, [inputtedPNR, auto_pnr_toggle]);

  useEffect(() => {
    if (isSuccess && pnrData?.data?.ticket_details) {
      setInvoices(formatePNRDetails(pnrData?.data, selectAirport));
      form.setFieldsValue({
        invoice_sales_date: dayjs(pnr_invoice_salesDate),
      });
      form.setFieldsValue({
        invoice_sales_man_id: pnrData?.data?.invoice_sales_man_id,
      });
    }
  }, [isSuccess, pnrData]);

  // @SET INITIAL VALUES
  useEffect(() => {
    form.setFieldsValue({
      airticket_discount_type: 'amount',
      airticket_ait_from: 'Profit',
      airticket_other_bonus_type: 'amount',
      invoice_sales_date: dayjs(),
      airticket_issue_date: dayjs(),
      dailCode: '+88',
    });
  }, []);

  //======================== @HANDLE CALCULATION ========================

  // @HANDLE SUBMIT
  const handleSubmit = (e: any) => {
    if (invoices.length >= 20) {
      return message.error('Max 20 Items ');
    }
    if (!(editIndex || editIndex === 0)) {
      // SET DATA TO STATE
      setInvoices((prev) => [...prev, e]);
    } else {
      // @Handle Edit
      setInvoices(
        invoices.map((item, index) => {
          if (index === editIndex) {
            return e;
          }
          return item;
        })
      );
      setEditIndex(undefined);
    }

    // AFTER ADD GET SCREEN TOP
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // GET OLD DATA FOR SET PREVIEWS DATA
    const getOldData = form.getFieldsValue([
      'invoice_no',
      'invoice_sales_man_id',
      'invoice_combclient_id',
      'invoice_agent_id',
    ]);

    // RESET FORM
    form.resetFields();

    // SET PREVIEWS DATA
    form.setFieldsValue({
      airticket_discount_type: 'amount',
      airticket_ait_from: 'Profit',
      airticket_other_bonus_type: 'amount',
      invoice_sales_date: pnr_invoice_salesDate
        ? dayjs(pnr_invoice_salesDate)
        : dayjs(),
      airticket_issue_date: dayjs(),
      invoice_no: getOldData?.invoice_no,
      invoice_sales_man_id: getOldData?.invoice_sales_man_id,
      invoice_combclient_id: getOldData?.invoice_combclient_id,
      invoice_agent_id: getOldData?.invoice_agent_id,
    });
  };

  return (
    <ClientsStyle>
      <BreadCrumb arrOfOption={['Invoice (Air Ticket)']} />

      {(isLoading || isFetching) && <LoadingIndicator />}

      {/* AIR TICKET DETAILS */}
      <div className='separator'>
        <div className='left_side' ref={getHeightRef}>
          <Form
            disabled={isLoading || isFetching}
            form={form}
            labelAlign='left'
            layout='vertical'
            onFinish={handleSubmit}
            onFinishFailed={(value) => {
              message.error(
                `Enter all required (${value.errorFields.length}) Fields`
              );
            }}
          >
            {/* INVOICE HEADER */}
            <AirticketHeader form={form} />

            {editIndex || editIndex === 0 ? (
              <>
                <br />
                <Alert
                  message={`Update Active -- ${invoices[editIndex]?.airticket_ticket_no}`}
                />
              </>
            ) : (
              ''
            )}

            <AirticketTicketDetails
              form={form}
              invoices={invoices}
              editIndex={editIndex}
            />

            {/* PAX AND PASSPORT DETAILS */}
            <AirticketPaxPassportDetails form={form} formName='pax_passport' />

            {/* FLIGHT DETAILS */}
            <AirticketFlightDetails form={form} />

            <FormButton
              label={!(editIndex || editIndex === 0) ? 'Add New' : 'Update'}
            />
          </Form>
        </div>

        <div className='right_side'>
          {/* @OVERVIEW BEFORE POST */}
          <PostOverview
            invoices={invoices}
            form={form}
            setInvoices={setInvoices}
            setEditIndex={setEditIndex}
            editIndex={editIndex}
          />
        </div>
      </div>
    </ClientsStyle>
  );
};
export default NewInvoiceAirTicket;

const ClientsStyle = styled.div`
  .separator {
    display: flex;
    gap: 25px;
  }

  .left_side {
    width: 70%;
  }

  .right_side {
    width: 25%;
    position: fixed;
    right: 10px;
  }

  .separator {
    @media only screen and (max-width: 1207px) {
      flex-direction: column !important;
    }
  }
  .left_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
    }
    @media only screen and (min-width: 1208px) {
      width: 67% !important;
    }
    @media only screen and (min-width: 1400px) {
      width: 68% !important;
    }
    @media only screen and (min-width: 1500px) {
      width: 69% !important;
    }
    @media only screen and (min-width: 1600px) {
      width: 70% !important;
    }
  }
  .right_side {
    @media only screen and (max-width: 1207px) {
      width: 100% !important;
      position: static;
    }
    @media only screen and (max-height: 920px) {
      position: sticky;
    }
  }

  .body_left {
    width: 100%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .body_right {
    width: 100%;
    margin-left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .commonBox {
    margin-bottom: 1rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
  .invoice_calculation {
    @media only screen and (max-width: 990px) {
      flex-direction: column !important;
    }
  }
  .invoice_calculation_right {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
      margin-left: 0px !important;
    }
  }
  .invoice_calculation_left {
    @media only screen and (max-width: 990px) {
      width: 100% !important;
    }
  }
`; // 242
