import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { ICountryWiseReportApiDataType } from '../types/countryWiseReportTypes';
import { Link } from 'react-router-dom';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';

export const countryWiseReportColumn =
  (): ColumnsType<ICountryWiseReportApiDataType> => {
    return [
      {
        title: 'SL.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Invoice',
        dataIndex: 'invoice_no',
        key: 'invoice_no',
        render: (_, data) => {
          const { invoice_no, invoice_category_id, invoice_id } = data;
          return (
            <>
              <Link
                to={`../${
                  findInvoiceRoute(invoice_category_id) +
                  '/' +
                  'details' +
                  '/' +
                  invoice_id
                }`}
              >
                {invoice_no}
              </Link>
            </>
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'passport_name',
        key: 'passport_name',
      },
      {
        title: 'Passport No',
        dataIndex: 'passport_passport_no',
        key: 'passport_passport_no',
      },

      {
        title: 'Date of Birth',
        dataIndex: 'passport_date_of_birth',
        key: 'passport_date_of_birth',
        render: (_, record) =>
          record.passport_date_of_birth
            ? dayjs(record.passport_date_of_birth).format('DD-MMM-YYYY')
            : undefined,
      },
      {
        title: 'Country',
        key: 'country_name',
        dataIndex: 'country_name',
      },
    ];
  };
