import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import { ITourGroupDataType, TourdataForm } from '../types/TourTypes';

const tourTagType = 'Tour';
const TOUR = 'TOUR';
export const tourGroupEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTourGroup: build.query<HTTPResponse<ITourGroupDataType[]>, void>({
      query: () => ({ url: `/tour-ittineray/group/get-all` }),
      providesTags: () => [{ type: tourTagType, id: TOUR }],
    }),

    getAllTourGroupWithPagination: build.query<
      HTTPResponse<ITourGroupDataType[]>,
      { current: number; pageSize: number; searchTerm: string }
    >({
      query: (arg) => ({
        url: `/tour-ittineray/group?page=${arg.current}&size=${arg.pageSize}&search=${arg.searchTerm}`,
      }),
      providesTags: () => [{ type: tourTagType, id: TOUR }],
    }),

    //create TourGroup
    createTourGroup: build.mutation<HTTPResponse<void>, TourdataForm[]>({
      query: (body) => ({
        url: `/tour-ittineray/group/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: tourTagType, id: TOUR }],
    }),

    //edit TourGroup
    editTourGroup: build.mutation<
      void,
      { body: TourdataForm; id?: number | string }
    >({
      query: ({ body, id }) => ({
        url: `/tour-ittineray/group/update/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [{ type: tourTagType, id: TOUR }],
    }),

    //delete TourGroup
    deleteGroupTour: build.mutation<void, TourdataForm>({
      query: (body) => ({
        url: `/tour-ittineray/group/delete/${body.group_id}`,
        method: 'DELETE',
        body: { deleted_by: body.group_updated_by },
      }),
      invalidatesTags: () => [{ type: tourTagType, id: TOUR }],
    }),
  }),
});
export const {
  useGetAllTourGroupQuery,
  useCreateTourGroupMutation,
  useEditTourGroupMutation,
  useDeleteGroupTourMutation,
  useGetAllTourGroupWithPaginationQuery,
} = tourGroupEndpoints;
