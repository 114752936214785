import { Button, Popconfirm, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IAccountClientBillAdjustment } from '../../AccountsTypes/AccountsTypes';
import { useDeleteClientBillAdjustmentMutation } from '../../Api/Endpoints/clientBillAdjustmentEndpoints';

export const ListOfClientBillAdjustementColumn = ({
  permission,
  pagination,
}: {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAccountClientBillAdjustment> => {
  const user = useSelector(selectUser);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [
    deleteClientBill,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteClientBillAdjustmentMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
      toasterNotification('error', 'Something went wrong');
    }
  }, [isDeleteError, isSuccess]);

  const handleDelete = async (
    values: IAccountClientBillAdjustment,
    index: number
  ) => {
    setColumnIndex(index);
    const body = {
      cbilladjust_id: values.cbilladjust_id,
      deleted_by: user?.user_id as number,
    };
    await deleteClientBill(body);
  };

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'cbilladjust_create_date',
      render: (_, record) => {
        return `${dayjs(record.cbilladjust_create_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Adjustment No.',
      dataIndex: 'cbilladjust_vouchar_no',
      key: 'cbilladjust_vouchar_no',
    },
    {
      title: 'Client Name',
      key: 'client_name',

      render: (_, record) => {
        return `${record.client_name}`;
      },
    },
    {
      title: 'Type',
      key: 'cbilladjust_type',
      render: (_, record) => (
        <>
          {
            <Tag
              color={record.cbilladjust_type == 'INCREASE' ? 'green' : 'red'}
            >
              {record.cbilladjust_type}
            </Tag>
          }
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'cbilladjust_amount',
      key: 'cbilladjust_amount',
      render: (_, record) => Number(record.cbilladjust_amount),
    },

    {
      title: 'Note',
      dataIndex: 'cbilladjust_note',
      key: 'cbilladjust_note',
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['read:any'] && record.cbilladjust_is_deleted == 0 && (
            <Link
              to={`/accounts/client_bill_adjustment/view/${record.cbilladjust_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.delete] && record.cbilladjust_is_deleted === 0 && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
