import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FormButton,
  FormInputItem,
  MobileInput,
} from '../../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { SelectRoles } from '../components/SelectRoles';
import {
  useGetUserByIdQuery,
  useUpdateUserMutation,
} from '../endpoints/userEndpoints';
import { IUserFormData } from '../types/user.types';

type Props = {};

const EditUser = (props: Props) => {
  const pervUserId = useParams().user_id;
  const [form] = Form.useForm();

  const user = useGetUserByIdQuery(Number(pervUserId));
  const userData = user.data?.data;

  useEffect(() => {
    form.setFieldValue('country_code', '880');

    form.setFieldsValue({
      role_id: userData?.user_role_id,
      full_name: userData?.user_full_name,
      email: userData?.user_email,
      mobile: userData?.user_mobile,
      username: userData?.user_username,
    });
  }, [userData]);

  const navigate = useNavigate();

  const [updateUser, { isLoading, isSuccess }] = useUpdateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate('/user/view');
    }
  }, [isLoading]);

  const onFinish = async (values: IUserFormData) => {
    const body: IUserFormData = {
      ...values,
    };

    await updateUser({ body, id: pervUserId as string });
  };
  return (
    <div>
      <BreadCrumb arrOfOption={['User', 'Update User']} />
      <Space>
        <Link to='/user/view'>
          <Button
            type='primary'
            style={{ marginBottom: '1rem' }}
          >
            <ArrowLeftOutlined />
            Return to Users List
          </Button>
        </Link>
      </Space>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <SelectRoles
            name='role_id'
            label='Select Role'
            required
            size={6}
          />
          <FormInputItem
            required
            name='full_name'
            label='Full Name'
            size={6}
          />
          <FormInputItem
            label='Email'
            name='email'
            size={6}
            inputType='email'
          />
          <MobileInput
            label='Mobile'
            name='mobile'
            size={6}
            countryCode
            countryCodeFormName={'country_code'}
          />
          <FormInputItem
            required
            name='username'
            label='User Name'
            size={6}
          />

          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
          >
            <Form.Item
              label='Current Password'
              name='current_password'
              rules={[{ required: true, message: 'Password is required!!' }]}
            >
              <Input.Password autoComplete='new-password' placeholder='Enter your current password' />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={6}
            lg={6}
          >
            <Form.Item
              label='New Password'
              name='password'
              rules={[{ required: true, message: 'Password is required!!' }]}
            >
              <Input.Password autoComplete='new-password' placeholder='Enter your new password' />
            </Form.Item>
          </Col>
        </Row>
        <FormButton
          label='Create User'
          size={2}
        />
      </Form>
    </div>
  );
};

export default EditUser;
