import { Button, Drawer, Form } from 'antd';
import { useState } from 'react';
import { useGetRoleByIDQuery } from '../endpoints/userEndpoints';
import PermissionViewer from './PermissionViewer';

type Props = { role_id: number };

export default function ViewRoleDrawer({ role_id }: Props) {
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const { data } = useGetRoleByIDQuery(role_id);



  


  return (
    <>
      <Button
        type='primary'
        onClick={showDrawer}
        size='small'
        style={{ marginRight: 15 }}
      >
        View
      </Button>
      <Drawer
        title='View role permission'
        width={window.innerWidth <= 576 ? 300 : 720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onCancel}>Cancel</Button>}
      >
        {data?.data?.role_permissions && <PermissionViewer data={data?.data} />}
      </Drawer>
    </>
  );
}
