import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateVisaMutation } from '../endpoints/visaEndpoints';
import { IVisaFormData } from '../types/visaTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const AddVisaModal = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user);

  const [form] = Form.useForm();
  const [addVisa, { isError, isSuccess, isLoading }] = useCreateVisaMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Visa Type Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleOnSubmit = async (values: IVisaFormData) => {
    const data: IVisaFormData = {
      ...values,
      type_created_by: user?.user_id as number,
    };
    await addVisa(data);
    dispatch(setModal(false));
    form.resetFields();
  };

  //

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  return (
    <Form
      labelAlign='left'
      {...layout}
      form={form}
      onFinish={handleOnSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item
        name='type_name'
        label='Enter Visa Type Name :'
        rules={[
          { required: true, message: 'Please input Visa Type name!' },
          { max: 55, message: 'Maximum 55 characters' },
        ]}
      >
        <Input placeholder='Type Name' />
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default AddVisaModal;
