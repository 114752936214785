import { Row, Table } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { setModal } from '../../../../common/slices/commonSlice';
import AddNewStatusModal from '../modals/AddNewPassportStatus';
import EditStatusModal from '../modals/EditPassportStatus';
import { PassportStatusDataType } from '../types/PassportStatusTypes';
import { passportStatusCollumns } from '../utils/utils';
import { useGetAllPassportStatusQuery } from '../endpoints/passportStatusEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { perProps } from '../../../../common/types/commonTypes';
import { pagination } from '../../../../common/utils/common.utils';

const PassportStatus = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<PassportStatusDataType | null>(null);

  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    data: passportStatus,
    refetch,
  } = useGetAllPassportStatusQuery(undefined, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: PassportStatusDataType[] = [];
      const passStatusNameList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          passStatusNameList.push(element.pstatus_name);
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, passStatusNameList } };
    },
  });

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Passport Status']}
        refetch={refetch}
        reloaderSize='small'
      />
      <CommonTableHeader
        width
        align
        modalTitle={
          editInfo ? 'Edit Passport Status' : 'Add New Passport Status'
        }
        title='Passport Status'
        button_text='Add New Passport Status'
        permission={permission?.['create:any']}
        element={
          editInfo ? (
            <EditStatusModal
              info={editInfo}
              passStatusNameList={passportStatus.passStatusNameList}
              setNull={setEditInfo}
            />
          ) : (
            <AddNewStatusModal
              passStatusNameList={passportStatus.passStatusNameList}
            />
          )
        }
        setNull={setEditInfo}
      />
      <Row justify={'center'}>
        <Table
          size='small'
          bordered
          style={{ width: 900 }}
          pagination={pagination(passportStatus.dataToReturn.length)}
          columns={passportStatusCollumns(setEditInfo, showModal, permission)}
          dataSource={passportStatus.dataToReturn}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
        />
      </Row>
    </div>
  );
};

export default PassportStatus;
