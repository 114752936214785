import {
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Fixed2 } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormInput,
  FormInputItem,
  NumberInput,
  NumberInputCurrencyFormat,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectAirport,
  SelectOptionsInput,
  SelectProductWiseVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useGetAllAirlinesQuery } from '../../../Configuration/airline/Endpoints/airlineEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useGetProductWiseVendorsAndCombinedQuery } from '../../../vendor/api/endpoints/vendorEndpoints';
import { useLazyTicketIsExistQuery } from '../../api/endpoints/airticketInvoiceEndpoints';
import { AitCalculationProps } from '../../pages/NewInvoiceAirTicket';
import {
  AirTicketInvoiceData,
  SeperateValueObject,
} from '../../types/invoiceAirTicketTypes';
import {
  setAit,
  setCommission,
  setTax,
} from '../../utils/ts/handleCalculation';
import InvoicesCountryCharge from '../../utils/tsx/InvoicesCountryCharge';
import TaxesMultipleField from './TaxesMultipleField';

type Props = {
  form: FormInstance<any>;
  invoices: AirTicketInvoiceData[];
  editIndex?: number;
};

export default function AirticketTicketDetails({
  form,
  invoices,
  editIndex,
}: Props) {
  const [vendorChange, setVendorChange] = useState(false);
  const { airticketInvoiceId } = useParams();

  const handleTicketNo = useWatch('airticket_ticket_no', form);
  const airticket_comvendor = useWatch(['airticket_comvendor'], form);
  let airticket_gross_fare = useWatch(['airticket_gross_fare'], form) | 0;
  let airticket_base_fare = useWatch(['airticket_base_fare'], form);
  const airFrom = useWatch(['airticket_ait_from'], form);
  const ait = useWatch(['airticket_ait'], form);
  let airticket_extra_fee = useWatch(['airticket_extra_fee'], form);
  let airticket_other_expense = useWatch(['airticket_other_expense'], form);
  let airticket_vat = useWatch(['airticket_vat'], form);

  const commissionAmount = useWatch(
    ['airticket_commission_percent_total'],
    form
  );

  // AIT AND COMMISSION
  let airTicketClientPrice = airticket_gross_fare;
  if (airFrom === 'Client') {
    airTicketClientPrice += ait;
  }

  /* ----------------------------taxes total calculations------------ */
  const yr_total = useWatch('taxes_commission', form);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let newTotal = 0;
    if (yr_total?.length) {
      yr_total.forEach((item: any) => {
        if (item?.airline_commission) {
          newTotal += Fixed2(item.airline_commission);
        }
      });
    }
    setTotal(newTotal);
  }, [yr_total]);

  useEffect(() => {
    form.setFieldValue('total_taxes_commission', Fixed2(total));
  }, [total]);

  // NET COMMISSION
  const netCommission = Math.round(commissionAmount + Fixed2(total) - ait) | 0;

  let profit = netCommission;

  // EXTRA FEE
  if (airticket_extra_fee) {
    airTicketClientPrice += airticket_extra_fee;
    profit += airticket_extra_fee;
  }

  // OTHER EXPENSE
  if (airticket_other_expense) {
    airticket_base_fare += airticket_other_expense;
    profit -= airticket_other_expense;
  }

  // VAT
  if (airticket_vat) {
    profit -= airticket_vat;
  }

  // SET FIELD VALUES
  form.setFieldValue('airticket_client_price', airTicketClientPrice | 0);
  form.setFieldValue(
    'airticket_purchase_price',
    airticket_gross_fare - netCommission
  );

  useEffect(() => {
    if (netCommission)
      form.setFieldValue('airticket_net_commssion', netCommission);
  }, [netCommission]);

  const profitCalculation =
    airTicketClientPrice - (airticket_gross_fare - netCommission);

  form.setFieldValue('airticket_profit', profitCalculation.toFixed(2));

  // VALIDATE INVOICE NO
  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();

  const vendorIdTitle: SeperateValueObject[] = [];
  const { data: vendorData, refetch: refetchVendorData } =
    useGetProductWiseVendorsAndCombinedQuery(106);

  const vendorAllData = vendorData?.data;
  vendorAllData?.forEach((em) =>
    vendorIdTitle.push({
      id: em.vendor_id,
      title: em.vendor_name,
      commission: em?.commission_rate,
      optionalField: em?.vendor_type,
    })
  );

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue('airticket_ticket_no');
        if (ticket) {
          form.validateFields(['airticket_ticket_no']);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);

  const airelineData = useGetAllAirlinesQuery();
  const aireline = airelineData?.data?.data;
  const airLineId: string[] = [];
  const airlineTitleAndId: SeperateValueObject[] = [];
  aireline?.forEach((el) => {
    airlineTitleAndId.push({ id: el.airline_id, title: el.airline_name });
    airLineId.push(String(el.airline_id));
  });

  useEffect(() => {
    let airticket_airline_id;
    if (airlineTitleAndId.length) {
      const airlineFind = airlineTitleAndId.find(
        (item) => item.title.split('-').slice(-1)[0] === handleTicketNo
      );
      if (airlineFind?.id) {
        airticket_airline_id = Fixed2(airlineFind?.id);
      } else {
        airticket_airline_id = form.getFieldValue('airticket_airline_id');
      }
    } else {
      airticket_airline_id = undefined;
    }
    form.setFieldsValue({ airticket_airline_id });
  }, [handleTicketNo]);

  const [vendorSelectsInstanct, setInstanceVendorSelect] = useState<number>();
  useEffect(() => {
    if (vendorSelectsInstanct) {
      form.setFieldValue(
        'airticket_comvendor',
        'vendor-' + vendorSelectsInstanct
      );
    }
  }, [vendorSelectsInstanct]);

  const airticket_commission_percent_from_server = form.getFieldValue(
    'airticket_commission_percent_from_server'
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (airticket_comvendor) {
      const vendorCommission = vendorIdTitle?.filter(
        (el) =>
          (airticket_comvendor as string).split('-')[0] === el?.optionalField &&
          el?.id == (airticket_comvendor as string).split('-')[1]
      )[0];

      if (airticketInvoiceId && !vendorChange) {
        form.setFieldsValue({
          airticket_commission_percent: vendorCommission?.commission
            ? airticket_commission_percent_from_server
            : 0,
        });
      } else {
        form.setFieldsValue({
          airticket_commission_percent: vendorCommission?.commission
            ? vendorCommission?.commission
            : 0,
        });
      }

      timeoutId = setTimeout(() => {
        handleCalculation({
          commissionType: 'percentage',
          isAitManual: true,
          isTaxManual: true,
        });
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [vendorChange]);

  // @HANDLE CALCULATION
  const handleCalculation = ({
    commissionType,
    isAitManual,
    isTaxManual,
  }: AitCalculationProps) => {
    const airticket_gross_fare = Fixed2(
      form.getFieldValue('airticket_gross_fare')
    );
    const airticket_base_fare = Fixed2(
      form.getFieldValue('airticket_base_fare')
    );

    // @SET AIT
    if (airticket_gross_fare) {
      if (!isAitManual) setAit(airticket_gross_fare, form);
    }

    // @SET TAX
    if (
      (airticket_base_fare || airticket_base_fare == 0) &&
      (airticket_gross_fare || airticket_gross_fare == 0)
    ) {
      setTax({ form });

      // @SET COMMISSION
      setCommission({ commissionType, form });
    }

    // @SET PROFIT
  };

  const airticket_pnr = useWatch('airticket_pnr', form)?.length === 6;

  useEffect(() => {
    form.setFieldsValue({ auto_pnr_toggle: 0 });
  }, []);

  return (
    <>
      <div className='border p-5 mt-10'>
        <FormHeaderTitle title='Ticket Details  🎟️' />
        <Row gutter={[8, 0]} style={{ width: '100%' }}>
          <FormInput
            name='airticket_ticket_no'
            label='Ticket No:'
            required
            size={6}
            mdSize={8}
            smSize={12}
            rules={[
              {
                validator: async (_, value) => {
                  if (
                    (editIndex || editIndex === 0) &&
                    invoices?.length &&
                    value
                  ) {
                    if (invoices[editIndex].airticket_ticket_no === value) {
                      return Promise.resolve();
                    }
                  } else if (invoices?.length && value) {
                    const find = invoices.find(
                      (item) => item?.airticket_ticket_no === value
                    )?.airticket_ticket_no;
                    if (find) {
                      return Promise.reject('Ticket already exist in list!');
                    }
                  } else if (ticketIsExisting?.data && value) {
                    return Promise.reject('Ticket already exist!');
                  } else return Promise.resolve();
                },
              },
            ]}
          />

          <NumberInputCurrencyFormat
            label='Gross Fare (Sale)'
            name='airticket_gross_fare'
            required
            size={4}
            mdSize={8}
            smSize={12}
            onChange={() => handleCalculation({})}
            maxChar={14}
          />

          <NumberInputCurrencyFormat
            label='Base Fare (Buy)'
            name='airticket_base_fare'
            required
            size={4}
            mdSize={8}
            smSize={12}
            onChange={() =>
              handleCalculation({
                commissionType: 'percentage',
                isAitManual: true,
                isTaxManual: true,
              })
            }
            maxChar={14}
          />

          <SelectProductWiseVendors
            setInstanceVendorSelect={setInstanceVendorSelect}
            label='Vendor:'
            onChange={(e) => setVendorChange(!vendorChange)}
            productId={106}
            name='airticket_comvendor'
            required
            dependencies={['invoice_combclient_id']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value: string) {
                  if (getFieldValue('invoice_combclient_id') === value) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                },
                message: "Client and vendor can't be same!",
              }),
            ]}
            size={6}
            isPercentage
          />

          <NumberInput
            label='Commission %'
            name='airticket_commission_percent'
            placeholder='commission in %'
            size={4}
            onChange={() =>
              handleCalculation({
                commissionType: 'percentage',
                isAitManual: true,
                isTaxManual: true,
              })
            }
            required
            min='0'
            maxChar={2}
          />

          <NumberInput
            label='Commission'
            name='airticket_commission_percent_total'
            placeholder='commission Amount'
            required
            size={6}
            smSize={12}
            min='0'
            maxChar={14}
          />

          <NumberInput
            label='Taxes Commission'
            name='total_taxes_commission'
            readOnly
            size={6}
            min='0'
            maxChar={14}
          />

          <Col lg={6} md={8} sm={12}>
            <Form.Item
              label='AIT:'
              name='airticket_ait'
              required
              rules={[
                {
                  validator: (_, value) => {
                    if (value || value === 0) {
                      value = value + '';
                      const maximum = '9'.repeat(14 || 0);

                      if (Fixed2(value || 0) <= Fixed2(maximum)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(`Maximum ${14} digit allowed`);
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                onChange={() => {
                  handleCalculation({ isAitManual: true, isTaxManual: true });
                }}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                addonBefore={
                  <Form.Item
                    className='commonBox'
                    name={['airticket_ait_from']}
                    style={{ padding: 0, margin: 0 }}
                  >
                    <Select
                      onSelect={(e: 'Client' | 'Profit') => {
                        handleCalculation({
                          isAitManual: true,
                          isTaxManual: true,
                          commissionType: 'manual',
                        });
                      }}
                      bordered={false}
                      dropdownMatchSelectWidth={100}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <Select.Option value='Client'>Client</Select.Option>
                      <Select.Option value='Profit'>Profit</Select.Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </Col>

          <NumberInput
            label='Net Commission'
            required
            name='airticket_net_commssion'
            readOnly
            size={6}
            onChange={() =>
              handleCalculation({ isAitManual: true, isTaxManual: true })
            }
            min='0'
            maxChar={14}
          />

          <SelectAirline
            label={'Airline:'}
            name='airticket_airline_id'
            required
            size={6}
          />
          <SelectAirport
            label='Route/Sector:'
            name='airticket_route_or_sector'
            mode='multiple'
            size={6}
            form={form}
            fullNamePath={['airticket_route_or_sector']}
          />

          <FormInputItem
            labelExtra={
              <Row gutter={3} align={'middle'} style={{ position: 'relative' }}>
                <Col>
                  <Row justify={'center'} gutter={6} align={'middle'}>
                    <Col>
                      <Typography.Text>PNR</Typography.Text>
                    </Col>
                    <Col hidden={!airticket_pnr ? true : false}>
                      <Tooltip title='Please update the Auto fetching PNR by selecting either Yes or No.'>
                        Import?
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

                <Col
                  hidden={!airticket_pnr ? true : false}
                  span={13}
                  prefixCls='myCol'
                >
                  <Form.Item prefixCls='myRadio' name={'auto_pnr_toggle'}>
                    <Radio.Group
                      style={{
                        display: 'flex',
                      }}
                      size='small'
                      name='radiogroup'
                    >
                      <Radio prefixCls='custom_radio' value={0}>
                        No
                      </Radio>
                      <Radio prefixCls='custom_radio' value={1}>
                        Yes
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            }
            name='airticket_pnr'
            size={6}
            placeholder='PNR:'
          />

          <SelectOptionsInput
            label='GDS :'
            name='airticket_gds_id'
            options={['Sabre', 'Galileo', 'Amadeus']}
            size={6}
            allowClear={true}
          />
        </Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
          className={'invoice_calculation'}
        >
          <Row
            className='invoice_calculation_left'
            style={{ width: '80%' }}
            gutter={[8, 0]}
          >
            <NumberInput
              name='airticket_discount_total'
              placeholder='Discount'
              style={{ width: '100%' }}
              label={'Discount:'}
              size={6}
            />

            <NumberInput
              label='Extra Fee: '
              name='airticket_extra_fee'
              size={6}
              onChange={() =>
                handleCalculation({ isAitManual: true, isTaxManual: true })
              }
              min='0'
            />

            <NumberInput
              label='Tax:'
              name='airticket_tax'
              size={6}
              min='0'
              readOnly
              maxChar={14}
              onChange={() => {
                handleCalculation({ isAitManual: true, isTaxManual: true });
              }}
            />

            <NumberInput
              label='Segment:'
              name='airticket_segment'
              placeholder='Enter Segment Number'
              size={6}
              min='0'
              maxChar={10}
            />

            <DateInput
              label='Issue Date:'
              name='airticket_issue_date'
              size={6}
            />
            <DateInput
              label='Journey Date:'
              name='airticket_journey_date'
              size={6}
            />
            <DateInput
              label='Return Date:'
              name='airticket_return_date'
              size={6}
            />

            <SelectOptionsInput
              label='Select air ticket class'
              name='airticket_classes'
              options={['Economy', 'Premium Economy', 'Business', 'First']}
              size={6}
            />
            <Col sm={24} md={24} lg={24}>
              <Form.List name='taxes_commission' initialValue={[{}]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index, arr) => (
                      <>
                        <TaxesMultipleField
                          name={name}
                          remove={remove}
                          add={add}
                          key={key}
                          form={form}
                          index={index}
                          length={arr.length}
                        />
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>

          <Row
            style={{
              width: '30%',
              marginLeft: '20px',
              marginBottom: '20px',
              border: '1px solid lightgray',
              padding: '0px 10px',
              borderRadius: '10px',
              height: '260px',
            }}
            className='invoice_calculation_right'
          >
            <NumberInput
              readOnly
              label='Client Price:'
              name='airticket_client_price'
              placeholder='Client price here'
              size={24}
              min='0'
              maxChar={14}
            />
            <NumberInput
              readOnly
              label='Purchase Price:'
              name='airticket_purchase_price'
              placeholder='Purchase price here'
              size={24}
              min='0'
              maxChar={14}
            />
            <NumberInput
              readOnly
              label='Profit:'
              name='airticket_profit'
              placeholder='Profit here'
              size={24}
              min='0'
              maxChar={14}
            />
          </Row>
        </div>
      </div>
      {/* INVOICE COUNTRY CHARGE */}
      <InvoicesCountryCharge
        gutter={8}
        form={form}
        bd_name={'airticket_bd_charge'}
        es_name={'airticket_es_charge'}
        ut_name={'airticket_ut_charge'}
        xt_name={'airticket_xt_charge'}
        e5_name={'airticket_e5_charge'}
        g4_name={'airticket_g4_charge'}
        ow_name={'airticket_ow_charge'}
        p7_name={'airticket_p7_charge'}
        p8_name={'airticket_p8_charge'}
        pz_name={'airticket_pz_charge'}
        qa_name={'airticket_qa_charge'}
        r9_name={'airticket_r9_charge'}
        handleCalculation={handleCalculation}
      />
    </>
  );
} // 720
