export function PhoneParser(params: string) {
  function formatMobileNumbers(params: string) {
    try {
      let parsedParams;
      try {
        parsedParams = JSON.parse(params);
      } catch {
        const phoneParts = params.split('-');
        if (phoneParts.length !== 2) {
          throw new Error('Invalid input format.');
        }
        return `(+${phoneParts[0]})-${phoneParts[1]}`;
      }
      if (!Array.isArray(parsedParams)) {
        const phoneParts = params.split('-');
        if (phoneParts.length !== 2) {
          throw new Error('Invalid input format.');
        }
        return `(+${phoneParts[0]})-${phoneParts[1]}`;
      }
      if (!Array.isArray(parsedParams[0]) || parsedParams[0].length !== 2) {
        throw new Error(
          'Invalid input format. Expected an array of arrays or "88-324324" format.'
        );
      }
      const [code, phone] = parsedParams[0];
      if (typeof code !== 'string' || typeof phone !== 'string') {
        throw new Error(
          'Invalid input format. Code and phone must be strings.'
        );
      }
      return phone ? `(+${code})-${phone}` : 'N/A';
    } catch (error) {
      return '';
    }
  }

  return formatMobileNumbers(String(params));
}

export function PhoneParser1(params: string) {
  function extractPhoneNumber(parsedParams: any) {
    if (
      Array.isArray(parsedParams) &&
      Array.isArray(parsedParams[0]) &&
      parsedParams[0].length === 2
    ) {
      const [code, phone] = parsedParams[0];
      if (typeof phone === 'string') {
        return phone;
      }
    }
    return '';
  }

  try {
    const parsedParams = JSON.parse(params);
    const phoneNumber = extractPhoneNumber(parsedParams);
    return phoneNumber;
  } catch (error) {
    return '';
  }
}
