import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import {
  IAccountOpeningBalance,
  IAccountOpeningBalanceFormData,
  IClientOpeningBalance,
  IClientOpeningBalanceFormData,
  ICombineClientOpeningBalanceFormData,
  ICombineOpeningBalance,
  IGetAllOpeningBalance,
  IVendorOpeningBalance,
  IVendorOpeningBalanceFormData,
} from '../../AccountsTypes/AccountsTypes';
import { api } from '../../../../app/baseQuery';
import { OPENING_BALANCE } from '../Constants/constants';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../Reports/Api/constants';
import { message } from 'antd';

export const openingBalanceEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get accounts opening balance

    getAllOpeningBalance: build.query<
      HTTPResponse<IGetAllOpeningBalance[]>,
      string
    >({
      query: (query) => ({
        url: `/accounts/opening-balance?${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),
    deleteOpeningBalance: build.mutation<
      HTTPResponse<IGetAllOpeningBalance[]>,
      { id: number; body: { delete_by: number } }
    >({
      query: ({ body, id }) => ({
        url: `/accounts/opening-balance/${id}`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error('Something Happened Wrong');
        }
      },
    }),
    getAccountsOpeningBalance: build.query<
      HTTPResponse<IAccountOpeningBalance[]>,
      string
    >({
      query: (query) => ({
        url: `/accounts/account-opening${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),
    // get client opening balance

    getClientOpeningBalance: build.query<
      HTTPResponse<IClientOpeningBalance[]>,
      string
    >({
      query: (query) => ({
        url: `/accounts/client-opening${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),

    // get combine opening balance
    getCombineOpeningBalance: build.query<
      HTTPResponse<ICombineOpeningBalance[]>,
      string
    >({
      query: (query) => ({
        url: `/accounts/combine-opening${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Opening_Balance', id: 'OPENING_BALANCE' }],
    }),

    // get vendor opening balance

    getVendorOpeningBalance: build.query<
      HTTPResponse<IVendorOpeningBalance[]>,
      string
    >({
      query: (query) => ({
        url: `/accounts/vendor-opening${query ? query : ''}`,
      }),
      providesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),

    //create accounts opening balance
    createAccountsOpeningBalance: build.mutation<
      void,
      IAccountOpeningBalanceFormData
    >({
      query: (body) => ({
        url: `/accounts/account-opening`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: (arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Account Opening Balance Added Successfully');
        });
      },
      invalidatesTags: () => [
        { type: 'Opening_Balance', id: OPENING_BALANCE },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),

    //create client opening balance
    createClientOpeningBalance: build.mutation<
      void,
      IClientOpeningBalanceFormData
    >({
      query: (body) => ({
        url: `/accounts/client-opening`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Client Opening Balance Added Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),

    // create combine client opening balance
    createCombineClientOpeningBalance: build.mutation<
      void,
      ICombineClientOpeningBalanceFormData
    >({
      query: (body) => ({
        url: '/accounts/combine-opening',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [
        { type: 'Opening_Balance', id: 'OPENING_BALANCE' },
      ],
    }),

    //create vendor opening balance
    createVendorOpeningBalance: build.mutation<
      void,
      IVendorOpeningBalanceFormData
    >({
      query: (body) => ({
        url: `/accounts/vendor-opening`,
        method: 'POST',
        body: body,
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success('Vendor Opening Balance Added Successfully');
        });
      },
      invalidatesTags: () => [{ type: 'Opening_Balance', id: OPENING_BALANCE }],
    }),
  }),
});

export const {
  useDeleteOpeningBalanceMutation,
  useLazyGetAllOpeningBalanceQuery,
  useGetAccountsOpeningBalanceQuery,
  useLazyGetAccountsOpeningBalanceQuery,
  useGetClientOpeningBalanceQuery,
  useLazyGetClientOpeningBalanceQuery,
  useGetVendorOpeningBalanceQuery,
  useLazyGetVendorOpeningBalanceQuery,
  useGetCombineOpeningBalanceQuery,
  useLazyGetCombineOpeningBalanceQuery,
  useCreateAccountsOpeningBalanceMutation,
  useCreateClientOpeningBalanceMutation,
  useCreateVendorOpeningBalanceMutation,
  useCreateCombineClientOpeningBalanceMutation,
} = openingBalanceEndpoints;
