import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetSingleClientBillAdjustmentQuery } from '../../Api/Endpoints/clientBillAdjustmentEndpoints';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};

const Client_Bill_Adjustment_View = (props: Props) => {
  const { id } = useParams();

  const { isLoading, data: clientBillView } =
    useGetSingleClientBillAdjustmentQuery(Number(id));

  return (
    <Style>
      <BreadCrumb
        arrOfOption={['Accounts', 'Client Bill Adjustment Details']}
      />
      <Link to='/accounts/bill_adjustment'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Client Bill Adjustement
        </Button>
      </Link>
      <Card title='Client Bill Adjustment Details'>
        <Descriptions size='small' bordered column={1}>
          <DescriptionsItem label='Client Name :'>
            {clientBillView?.data?.client_name}
          </DescriptionsItem>

          <DescriptionsItem label='Adjustment Type :	'>
            {clientBillView?.data?.cbilladjust_type}
          </DescriptionsItem>
          <DescriptionsItem label='Amount :	'>
            {Fixed2(clientBillView?.data?.cbilladjust_amount)}
          </DescriptionsItem>
          <DescriptionsItem label='Payment date :	'>
            {clientBillView?.data?.date as string}
            {dayjs(clientBillView?.data?.cbilladjust_create_date).format(
              'DD-MM-YYYY'
            )}
          </DescriptionsItem>
          <DescriptionsItem label='Payment Note :	'>
            {clientBillView?.data?.cbilladjust_note}
          </DescriptionsItem>
        </Descriptions>
      </Card>
      {isLoading && loadingIndicator}
    </Style>
  );
};

export default Client_Bill_Adjustment_View;
const Style = styled.div``;
