import { Col, Form, FormListFieldData, Row, TimePicker } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import {
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectTranportType,
  SelectVendors,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetItineraryVendorsInfoQuery } from '../../../Configuration/Tour/Ticket/endpoints/tourTicketEndpoints';
import { SelectTourItinerary } from '../Components/SelectTourItinerary';
import { useParams } from 'react-router';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  invComClientInfo: string | undefined;
  index: number;
};
const TransportForm = ({ field, form, invComClientInfo, index }: Props) => {
  const { id } = useParams();
  //setField vendor name
  const [instanceVendorSelect, setInstanceVendorSelect] = useState<number>();
  const [instanceTransportSelect, setInstanceTransportSelect] =
    useState<number>();
  /* if any field select other field are auto required */
  const transport_itinerary_id = useWatch(
    ['tourTransports', field.name, 'transport_itinerary_id'],
    form
  );
  const transport_cost_price = useWatch(
    ['tourTransports', field.name, 'transport_cost_price'],
    form
  );
  const transport_comvendor_id = useWatch(
    ['tourTransports', field.name, 'transport_comvendor_id'],
    form
  );
  const transport_description = useWatch(
    ['tourTransports', field.name, 'transport_description'],
    form
  );

  const transport_type_id = useWatch(
    ['tourTransports', field.name, 'transport_type_id'],
    form
  );

  const transport_picup_place = useWatch(
    ['tourTransports', field.name, 'transport_picup_place'],
    form
  );

  const transport_picup_time = useWatch(
    ['tourTransports', field.name, 'transport_picup_time'],
    form
  );

  const transport_dropoff_place = useWatch(
    ['tourTransports', field.name, 'transport_dropoff_place'],
    form
  );

  const ttype_name = useWatch(
    ['tourTransports', field.name, 'ttype_name'],
    form
  );

  const transport_dropoff_time = useWatch(
    ['tourTransports', field.name, 'transport_dropoff_time'],
    form
  );

  const isRequired =
    transport_comvendor_id ||
    transport_cost_price ||
    transport_description ||
    transport_itinerary_id ||
    transport_type_id ||
    transport_picup_place ||
    transport_picup_time ||
    transport_dropoff_place ||
    ttype_name ||
    transport_dropoff_time;

  // itinerary
  const [itineraryId, setItineraryId] = useState<number>();
  const [vendorId, setVendorId] = useState<number>();
  const [getItineraryVendorsInfo, { data: vendors, isFetching, isSuccess }] =
    useLazyGetItineraryVendorsInfoQuery();

  useEffect(() => {
    if (!id) {
      const selectVendor = vendors?.data?.find(
        (item) => item.itnrvendor_vendor_id === vendorId
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourTransports', field.name, 'transport_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourTransports', field.name, 'transport_cost_price'],
            value: undefined,
          },
        ]);
      }
    }

    //this only work for when editing
    if (
      (id && transport_comvendor_id && transport_itinerary_id && itineraryId) ||
      (id && transport_comvendor_id && transport_itinerary_id && vendorId)
    ) {
      const selectVendor = vendors?.data?.find(
        (item) =>
          item.itnrvendor_vendor_id ===
          Number(transport_comvendor_id?.toString().split('-')[1])
      );

      if (selectVendor?.itnrvendor_cost_price) {
        form.setFields([
          {
            name: ['tourTransports', field.name, 'transport_cost_price'],
            value: Number(selectVendor?.itnrvendor_cost_price),
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['tourTransports', field.name, 'transport_cost_price'],
            value: undefined,
          },
        ]);
      }
    }
  }, [
    transport_itinerary_id,
    transport_comvendor_id,
    vendors?.data,
    vendorId,
    itineraryId,
  ]);

  useEffect(() => {
    if (transport_itinerary_id) {
      getItineraryVendorsInfo(transport_itinerary_id);
    }
  }, [transport_itinerary_id]);

  //setField vendor name
  useEffect(() => {
    if (instanceVendorSelect) {
      form.setFieldValue(
        ['tourTransports', field.name, 'transport_comvendor_id'],
        'vendor-' + instanceVendorSelect
      );
    } else if (instanceTransportSelect) {
      form.setFieldValue(
        ['tourTransports', field.name, 'transport_itinerary_id'],
        instanceTransportSelect
      );
    }
  }, [instanceVendorSelect, instanceTransportSelect]);

  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const transport_is_deleted: 0 | 1 = useWatch(
    ['tourTransports', index, 'transport_is_deleted'],
    form
  );
  useEffect(() => {
    if (transport_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [transport_is_deleted]);
  return (
    <>
      <SelectTourItinerary
        label={'transport'}
        name={[field.name, 'transport_itinerary_id']}
        onChange={(value) => {
          setItineraryId(Number(value));
        }}
        isRequired={isRequired}
        size={5}
        setInstanceTransportSelect={setInstanceTransportSelect}
      />

      <FormInputItem
        label='Description'
        name={[field.name, 'transport_description']}
        size={5}
      />

      <NumberInput
        label='Cost Price'
        name={[field.name, 'transport_cost_price']}
        size={5}
        maxChar={14}
        minChar={0}
        required={isRequired}
      />

      <SelectTranportType
        name={[field.name, 'transport_type_id']}
        label='Transport Type'
        size={5}
      />

      <FormInputItem
        name={[field.name, 'transport_picup_place']}
        label='Pickup Place	'
        size={4}
      />

      <Col xs={24} sm={12} md={8} lg={5}>
        <Form.Item
          name={[field.name, 'transport_picup_time']}
          label='Pickup Time'
          colon={false}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <FormInputItem
        name={[field.name, 'transport_dropoff_place']}
        label='Drop Off Place	'
        size={5}
      />

      <Col xs={24} sm={12} md={8} lg={5}>
        <Form.Item
          label='Drop Off Time'
          name={[field.name, 'transport_dropoff_time']}
          colon={false}
        >
          <TimePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>

      <SelectVendors
        label='Vendor'
        size={5}
        name={[field.name, 'transport_comvendor_id']}
        onChange={(e) => setVendorId(Number(e?.toString().split('-')[1]))}
        required={isRequired}
        dependencies={['invoice_combclient_id']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (getFieldValue('invoice_combclient_id')) {
                if (getFieldValue('invoice_combclient_id') === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorSelect}
      />
    </>
  );
};

export default TransportForm;
