import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { PASSPORTWISEREPORT } from '../../Api/constants';
import { IPassportWiseReportDataType } from '../types/passportWiseReportTypes';

export const passportWiseReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all PassportWiseReport
    getAllPassportWiseReport: build.query<
      HTTPResponse<IPassportWiseReportDataType[]>,
      {
        haji_info_passport_id: number | string;
        query: string;
      }
    >({
      query: ({ haji_info_passport_id, query }) => ({
        url: `/report/passport-wise-report/${haji_info_passport_id}${query}`,
      }),
      providesTags: [{ type: 'PassportWiseReport', id: PASSPORTWISEREPORT }],
    }),

    getExcelsPassportWise: build.query<
      any,
      {
        client_id: string;
      }
    >({
      query: ({ client_id }) => ({
        url: `/report/passport/passportWiseReport/${client_id}`,

        responseHandler: async (response) => {
          const filename = `passportWiseReport.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
      providesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        { type: 'invoice-visa' },
      ],
    }),
  }),
});

export const {
  useLazyGetAllPassportWiseReportQuery,
  useLazyGetExcelsPassportWiseQuery,
} = passportWiseReportEndpoints;
