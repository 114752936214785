import { ColumnsType } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { IPayrollReportDataType } from '../types/payrollReportTypes';

const PayrollReportColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IPayrollReportDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },

    {
      title: 'Vouchar No.',
      dataIndex: 'payroll_vouchar_no',
      key: 'payroll_vouchar_no',
    },
    {
      title: 'Salaries Date',
      dataIndex: 'payroll_date',
      key: 'payroll_date',
      render: (_, record) => {
        return `${dayjs(record.payroll_date).format('DD-MM-YYYY')}`;
      },
    },

    {
      title: 'Employee Name',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
    },

    {
      title: 'Salaries Amount',
      dataIndex: 'payroll_net_amount',
      key: 'payroll_net_amount',
    },
    {
      title: 'Pass Salaries',
      dataIndex: 'payroll_pass_salaries',
      key: 'payroll_pass_salaries',
    },

    {
      title: 'Preset Salaries',
      dataIndex: 'payroll_preset_salaries',
      key: 'payroll_preset_salaries',
    },

    // {
    //   title: 'Designation',
    //   dataIndex: 'designation_name',
    //   key: 'designation_name',
    // },

    {
      title: 'Note',
      dataIndex: 'payroll_note',
      key: 'payroll_note',
    },
  ];
};

export default PayrollReportColumn;
