import { Divider, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import BillingAndPassInfoForm from './BillingAndPassInfoForm';
import FormHeaderTitle from './FormHeaderTitle';
import { IBillingInfo } from '../Type/invoiceVisa.interface';

type Props = {
  form: FormInstance<any>;
  billing_information?: IBillingInfo[];
  conditionalCostVendor?: boolean;
};

export default function BillingFormList({
  form,
  billing_information,
  conditionalCostVendor,
}: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Billing & Passport Information' />
      <Form.List name='billing_information' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
                gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
              >
                <BillingAndPassInfoForm
                  form={form}
                  add={add}
                  field={field}
                  index={index}
                  remove={remove}
                  billing_information={billing_information}
                  conditionalCostVendor={conditionalCostVendor}
                />

                {fields.length > 1 && <Divider />}
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
}
