import { Button, Form, Input, message } from 'antd';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateClientCategoryMutation } from '../endpoints/clientCategoryEndpoints';
import { IClientCategoryAddInfo } from '../types/clientCategoryTypes';
import { useEffect } from 'react';

const ClientCategoryAddModal = ({
  clientCategory,
}: {
  clientCategory: string[];
}) => {
  const dispatch = useAppDispatch();

  const user = useSelector((state: RootState) => state.user);

  const user_id = user?.user_id;

  const [form] = Form.useForm();
  const [addClientCategory, { isError, isSuccess, isLoading }] =
    useCreateClientCategoryMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Client Category Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  //
  const handleOnSubmit = async (values: IClientCategoryAddInfo) => {
    values.category_created_by = user_id as number;
    await addClientCategory(values);
    dispatch(setModal(false));
    form.resetFields();
  };

  //

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      {...layout}
      name='nest-messages'
      onFinish={handleOnSubmit}
      validateMessages={validateMessages}
      form={form}
    >
      <Form.Item
        name='category_title'
        label='Enter Category Name :'
        rules={[
          { required: true, message: 'Please enter Category name' },
          {
            max: 55,
            message: 'Name cannot exceed 55 characters',
          },
        ]}
      >
        <Input placeholder='Air Tickets' />
      </Form.Item>

      <Form.Item
        name='category_prefix'
        label='Category Prefix :'
        rules={[
          { required: true, message: 'Please enter Category Prefix' },
          {
            max: 10,
            message: 'Name cannot exceed 10 characters',
          },
          {
            validator(_, value) {
              if (value) {
                if (!clientCategory.includes(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('same category prefix not allowed');
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='AT' />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClientCategoryAddModal;
