import { useState } from 'react';
import ClientRefund from './ClientRefund';
import RefundRefund from './VendorRefund';
const RefundReport = () => {
  const [value, setValue] = useState(1);
  return (
    <>
      {value === 1 ? (
        <ClientRefund
          setValue={setValue}
          value={value}
        />
      ) : (
        <RefundRefund
          setValue={setValue}
          value={value}
        />
      )}
    </>
  );
};

export default RefundReport;
