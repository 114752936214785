import { Button, Popconfirm, Space, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IAccountVendorBillAdjustment } from '../../AccountsTypes/AccountsTypes';
import { useDeleteVendorBillAdjustmentMutation } from '../../Api/Endpoints/vendorBillAdjustmentEndpoints';

type Props = {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const ListOfVendorBillAdjustementColumn = ({
  permission,
  pagination,
}: Props): ColumnsType<IAccountVendorBillAdjustment> => {
  const user = useSelector(selectUser);
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [
    deleteVendorBill,
    { isError: isDeleteError, isLoading: isDeleteLoading, isSuccess },
  ] = useDeleteVendorBillAdjustmentMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isDeleteError) {
      setColumnIndex(null);
      toasterNotification('error', 'Something went wrong');
    }
  }, [isDeleteError, isSuccess]);

  const handleDelete = async (
    values: IAccountVendorBillAdjustment,
    index: number
  ) => {
    setColumnIndex(index);
    const body = {
      vbilladjust_id: values.vbilladjust_id,
      created_by: user?.user_id as number,
    };
    await deleteVendorBill(body);
  };

  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Date',
      key: 'vbilladjust_create_date',
      render: (_, record) => {
        return `${dayjs(record.vbilladjust_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Adjustment No',
      dataIndex: 'vbilladjust_vouchar_no',
      key: 'vbilladjust_vouchar_no',
    },
    {
      title: '  Vendor Name',
      key: 'vendor_name',

      render: (_, record) => {
        return `${record.vendor_name}`;
      },
    },
    {
      title: 'Type',
      key: 'cbilladjust_type',
      render: (_, record) => (
        <>
          {
            <Tag
              color={record.vbilladjust_type == 'INCREASE' ? 'green' : 'red'}
            >
              {record.vbilladjust_type}
            </Tag>
          }
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'vbilladjust_amount',
      key: 'vbilladjust_amount',
    },

    { title: 'Note', dataIndex: 'vbilladjust_note', key: 'vbilladjust_note' },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['read:any'] && record.vbilladjust_is_deleted == 0 && (
            <Link
              to={`/accounts/vendor_bill_adjustment/view/${record.vbilladjust_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.[crud.delete] && record.vbilladjust_is_deleted === 0 && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
