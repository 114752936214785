export const AUTHORITY = 'AUTHORITY';
export const LOAN = 'LOAN';
export const PAYMENT = 'PAYMENT';
export const RECEIVE = 'RECEIVE';

export const loanTags = [
  'Authority',
  'Loan',
  'Payment',
  'Receive',
  'Cheque_Loan',
];
