import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import styled from 'styled-components';

import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { IPermission } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { pagination } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteTransferInMutation,
  useLazyGetAllTransferInQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import {
  IAllTransferInData,
  ITranferInHajiInfo,
} from '../../Types/HajjiManagement.interface';

type Props = {
  openFrom: 'TRANSFER_IN' | 'TRANSFER_OUT';
  permission?: IPermission;
};

const TransferOut = ({ openFrom, permission }: Props) => {
  const user = useAppSelector((state) => state.user);
  const [
    fetchTransferData,
    { data: getAllTransferData, isLoading, isFetching },
  ] = useLazyGetAllTransferInQuery();

  useEffect(() => {
    switch (openFrom) {
      case 'TRANSFER_IN':
        fetchTransferData({
          inOrOut: 'in',
        });
        break;
      case 'TRANSFER_OUT':
        fetchTransferData({
          inOrOut: 'out',
        });
        break;

      default:
        break;
    }
  }, [openFrom]);

  // =============== table data ====================
  const [tableData, setTableData] = useState<ITranferInHajiInfo[]>([]);
  const viewHanlde = (record: ITranferInHajiInfo[]) => {
    showModal();
    setTableData(record);
  };
  const [
    deleteTransferIn,
    {
      isLoading: isLoadingTransferInDelete,
      isSuccess: isSuccessTransferInDelete,
    },
  ] = useDeleteTransferInMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isSuccessTransferInDelete) {
      toasterNotification('success', 'Removed a Transfer In');
    }
  }, [isLoadingTransferInDelete]);

  const columns: ColumnsType<IAllTransferInData> = [
    {
      title: 'SL.',
      dataIndex: 'key',
      render: (_, data, index) => {
        return <> {index + 1} </>;
      },
    },

    {
      title: 'Transfer From',
      dataIndex: 'agency_name',
      key: 'agency_name',
    },

    {
      title: 'Transfer Date',
      dataIndex: 'transfer_date',
      key: 'transfer_date',
    },
    {
      title: 'Total Haji',
      dataIndex: 'total_haji',
      render: (_, data) => {
        return <> {data.haji_info.length} </>;
      },
    },

    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (_, record) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Button
              size='small'
              type='primary'
              style={{ backgroundColor: '#00a65a', border: 'none' }}
              onClick={() => viewHanlde(record.haji_info)}
            >
              <Typography style={{ color: '#ffffff' }}>View</Typography>
            </Button>
          )}

          {permission?.['update:any'] && (
            <Link
              to={`/haji/transfer/Out_edit/${record.transfer_id}`}
              state={location.pathname}
            >
              <Button
                size='small'
                type='primary'
                style={{ backgroundColor: '#00c0ef', border: 'none' }}
              >
                Edit
              </Button>
            </Link>
          )}
          {permission?.['update:any'] && (
            <Popconfirm
              title='Are you sure, want to delete?'
              onConfirm={() => {
                deleteTransferIn({
                  id: record.transfer_id,
                  userID: user?.user_id!,
                });
              }}
            >
              <Button
                size='small'
                type='primary'
                style={{ backgroundColor: '#ff0000', border: 'none' }}
                loading={isLoadingTransferInDelete}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const columnsModal: ColumnsType<ITranferInHajiInfo> = [
    {
      title: 'SL',
      key: 'name',
      render: (_, data, index) => {
        return <> {index + 1} </>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'hajiinfo_name',
      key: 'hajiinfo_name',
    },
    {
      title: 'Mobile',

      dataIndex: 'hajiinfo_mobile',
      key: 'hajiinfo_mobile',
    },
    {
      title: 'Date of Birth',
      key: 'hajiinfo_dob',
      render: (_, data) => {
        return <> {dayjs(data.hajiinfo_dob).format('DD-MM-YYYY')} </>;
      },
    },
    {
      title: 'Tracking Number',

      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },

    {
      title: 'NID',
      dataIndex: 'hajiinfo_nid',
      key: 'hajiinfo_nid',
    },
    {
      title: 'Serial Number',

      dataIndex: 'hajiinfo_serial',
      key: 'hajiinfo_serial',
    },
    {
      title: 'Gender',

      dataIndex: 'hajiinfo_gender',
      key: 'hajiinfo_gender',
    },
    {
      title: 'Maharam',

      dataIndex: 'hajiinfo_maharam',
      key: 'hajiinfo_maharam',
    },
  ];

  return (
    <ViewInvoiceStyle>
      <Modal
        width={900}
        title='Transfer History  '
        open={isModalVisible}
        okText={'Submit'}
        cancelText={'Close'}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          bordered
          size='small'
          pagination={pagination(tableData.length)}
          columns={columnsModal}
          dataSource={tableData}
          scroll={{ x: true }}
        />
      </Modal>
      <BreadCrumb
        arrOfOption={[
          openFrom === 'TRANSFER_IN' ? 'Transfer In List' : 'Transfer Out List',
        ]}
        refetch={() => {
          openFrom === 'TRANSFER_IN'
            ? fetchTransferData({ inOrOut: 'in' })
            : fetchTransferData({ inOrOut: 'out' });
        }}
        reloaderSize='small'
      />
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
        style={{ marginBottom: 25 }}
      >
        <Col span={6} xs={24} sm={24} md={24} lg={19}>
          <Link
            to={
              openFrom === 'TRANSFER_IN'
                ? '/haji/transfer/in_add'
                : '/haji/transfer/out_add'
            }
            state={location.pathname}
          >
            <Button type='primary'>Create</Button>
          </Link>
        </Col>
        <Col span={6} xs={24} sm={24} md={24} lg={5}>
          <Input placeholder='🔍 Search' />
        </Col>
      </Row>
      <Table
        size='small'
        bordered
        pagination={
          getAllTransferData?.data && getAllTransferData?.data.length > 20
            ? {
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50'],
              }
            : false
        }
        columns={columns}
        dataSource={getAllTransferData?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
      />
    </ViewInvoiceStyle>
  );
};

export default TransferOut;

const ViewInvoiceStyle = styled.div`
  .transfer-in-list {
    border: 1px solid lightgray;
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 7px !important;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
