import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Space, Table } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { formatDateRange, parseDateRange } from '../../../../auth/utils/lib';
import useQueryParam from '../../../../common/hooks/useQueryParams';
import {
  findColumnsWithNull,
  Fixed2,
  formatAmount,
} from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClientsOnly } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import ClientLedgerTable from '../components/ClientLedgerTable';
import LedgerHeader from '../components/LedgerHeader';
import {
  useGetClientLedgerbyIDQuery,
  useLazyGetExcelQuery,
} from '../endpoints/ledgerEndpoints';
import { ClientLedgerUtils } from '../utils/ClientLedgerUtils';

export const landscapePageStyle = `
   @page { size: a4 landscape; margin: 0mm;  } @media print { body { -webkit-print-color-adjust: exact; } },
    
  `;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ClientLedgers() {
  const [form] = Form.useForm();
  const componentRef = useRef(null);
  const query = useQuery();
  const queryId = query.get('id');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const client_id = useWatch('client_id', form);
  const date_range = useWatch('date_range', form);

  const [dateQuery, setDateQuery, removeUrlParam] = useQueryParam(
    'date_range',
    JSON.stringify(formatDateRange(date_range))
  );
  const [clientQuery, setClientQuery, removeClientParam] = useQueryParam(
    'client_query',
    client_id
  );

  const [fetchExcel] = useLazyGetExcelQuery();

  const {
    data: clientLedger,
    isLoading: clientFetchLoading,
    isFetching: clientLoading,
    refetch,
  } = useGetClientLedgerbyIDQuery(
    {
      ...pagination,
      ...formatDateRange(date_range),
      client_id: client_id,
    },
    { skip: client_id ? false : true }
  );

  const clientDetails = clientLedger?.data?.client;

  const clientInfoData = [
    {
      key: '1',
      name: 'Cleint Name',
      value: clientDetails?.client_name,
    },
    {
      key: '2',
      name: 'Mobile',
      value: clientDetails?.client_mobile,
    },
    {
      key: '3',
      name: 'Email',
      value: clientDetails?.client_email,
    },
    {
      key: '4',
      name: 'Address',
      value: clientDetails?.client_address,
    },
    {
      key: '5',
      name: 'Last Balance',
      value: formatAmount(Fixed2(clientDetails?.client_lbalance)),
    },
  ];

  const clientColumn = [
    {
      dataIndex: 'name',
      key: 'name',
      width: '100px',
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '150px',
    },
  ];

  // Array to store columns with null or falsy values for each row
  const columnsWithNullForEachRow: any[][] = [];

  // Iterate through each object in the array
  clientLedger?.data?.ledgers.forEach((obj: any) => {
    const columnsWithNull = findColumnsWithNull(obj);
    columnsWithNullForEachRow.push(columnsWithNull);
  });

  const clientLedgerOnFinish = async () => {
    refetch();
  };

  useEffect(() => {
    !queryId &&
      form.setFieldsValue({
        date_range: parseDateRange(dateQuery) || [dayjs(), dayjs()],
      });
    !queryId &&
      clientQuery !== 'undefined' &&
      form.setFieldsValue({ client_id: Number(clientQuery) });
  }, []);

  useEffect(() => {
    if (queryId) {
      form.setFieldValue('client_id', Number(queryId));
      form.setFieldValue(
        'date_range',
        parseDateRange(dateQuery) || [dayjs().subtract(1, 'month'), dayjs()]
      );
      message.info('Showing last 30 day records');
    }
  }, [queryId]);

  useEffect(() => {
    setDateQuery(JSON.stringify(formatDateRange(date_range)));
    if (client_id) setClientQuery(client_id);
  }, [date_range, client_id]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  useEffect(() => {
    handlePaginationChange(1, 50);
  }, [client_id]);

  const print_content = (
    <div hidden>
      <CommonViewReport
        printExtraJsx={
          <LedgerHeader
            data={clientLedger?.data?.client}
            date_range={date_range}
          />
        }
        children={
          <ClientLedgerTable
            clientLedger={clientLedger}
            columns={ClientLedgerUtils(pagination)}
            pagination={pagination}
            handlePaginationChange={handlePaginationChange}
            loading={clientFetchLoading || clientLoading}
            isForPrint={true}
          />
        }
        printRef={componentRef}
        title={{ info_title: 'Client Details', title: '' }}
      />
    </div>
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Client_Ledger',
    pageStyle: landscapePageStyle,
  });

  return (
    <>
      {/* @LEDGER CONTENT HERE */}
      <BreadCrumb arrOfOption={['Report', 'Client Ledger']} />
      <div>
        <Row justify={'space-between'}>
          <Col>
            <Space style={{ marginBottom: '1rem' }}>
              <Button type='primary' onClick={handlePrint}>
                <PrinterOutlined />
                Print
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  if (clientLedger?.data?.ledgers.length)
                    fetchExcel({
                      client_id: client_id,
                      query: `?${formatDateRange(date_range)}`,
                      excelApiName: 'client_ledger_excel',
                      excelName: 'client_ledger',
                    });
                }}
              >
                <FileTextOutlined />
                Excel Report
              </Button>
            </Space>
          </Col>
        </Row>

        <Row justify={'space-between'}>
          <Col lg={11} md={24} sm={24} xs={24}>
            <Form layout='vertical' form={form} onFinish={clientLedgerOnFinish}>
              <Row gutter={16}>
                <SelectClientsOnly
                  required
                  name='client_id'
                  size={10}
                  mdSize={10}
                  smSize={10}
                  xsSize={24}
                  label='Select Client'
                  placeholder='Select Client'
                  disabled={queryId ? true : false}
                  offDropDown
                  searchValue={queryId}
                />

                <DateInput
                  label='Date Range'
                  name='date_range'
                  size={10}
                  mdSize={10}
                  rangePicker
                  allowClear={false}
                />

                <Col lg={2} md={2} sm={4} xs={24}>
                  <FormButton
                    label='Search'
                    icon
                    textAlign='left'
                    loading={clientFetchLoading || clientLoading}
                  />
                </Col>
              </Row>
            </Form>
          </Col>

          <Col lg={12} md={24} sm={24} xs={24}>
            {clientDetails && (
              <Row justify={'end'}>
                <Col style={{ maxWidth: 300 }}>
                  <Table
                    size='small'
                    bordered
                    rowKey={(e) => e.key}
                    dataSource={clientInfoData}
                    columns={clientColumn}
                    showHeader={false}
                    className='normal-table'
                    rowClassName={'normal-table-row'}
                    pagination={false}
                    locale={{ emptyText: ' ' }}
                    loading={{
                      spinning: clientFetchLoading || clientLoading,
                      indicator: loadingIndicator,
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <ClientLedgerTable
          clientLedger={clientLedger}
          columns={ClientLedgerUtils(pagination)}
          pagination={pagination}
          handlePaginationChange={handlePaginationChange}
          loading={clientFetchLoading || clientLoading}
        />
      </div>

      {print_content}
    </>
  );
}

export default ClientLedgers;
