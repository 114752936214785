import { Divider, Form, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import FormHeaderTitle from '../../../../modules/Invoice(Visa)/Components/FormHeaderTitle';
import { IBilling_information } from '../../../../modules/InvoiceUmrah/Types/InvoiceUmrahTypes';
import BillingInfoForm from './BillingInfoForm';
import { useAppSelector } from '../../../../app/hooks';

type Props = {
  form: FormInstance<any>;
  billing_information?: IBilling_information[] | any;
  setProductId?: number;
  conditionalCostVendor?: boolean;
};
const InvoiceBillingInfo = ({
  form,
  billing_information,
  setProductId,
  conditionalCostVendor,
}: Props) => {
  const fullList: IBilling_information[] = useWatch(
    ['billing_information'],
    form
  );

  useEffect(() => {
    form.setFieldsValue({
      invoice_sub_total: fullList?.reduce(
        (a, b) => a + (b?.is_deleted === 1 ? 0 : Fixed2(b?.billing_subtotal)),
        0
      ),
    });
  }, [fullList]);

  const role_name = useAppSelector((state) => state.user?.role_name);

  const editPermission = useAppSelector((sate) => sate.user?.role_permissions);
  const editPermissionParse = editPermission
    ? JSON.parse(editPermission)
    : undefined;
  const checkPermission =
    editPermissionParse[role_name!]?.invoice_other_billing;

  const disableEdit = checkPermission;

  const disableRemoveButtonIndex = billing_information?.length;

  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Billing Information 💵' />
      <Form.List name='billing_information' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
                gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
              >
                <BillingInfoForm
                  form={form}
                  field={field}
                  billing_information={billing_information}
                  setProductId={setProductId}
                  add={add}
                  remove={remove}
                  index={index}
                  conditionalCostVendor={conditionalCostVendor}
                  disableRemoveButtonIndex={
                    disableEdit && disableRemoveButtonIndex
                  }
                />

                {fields.length > 1 && <Divider />}
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default InvoiceBillingInfo;
