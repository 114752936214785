import { Button, Form, message, Modal, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { getAirportByItaCode } from '../../../common/utils/common.utils';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import InvoiceTourAccommodationForm from '../Components/Accommodation/InvoiceTourAccommodationForm';
import TourBillingInfo from '../Components/Components/TourBillingInfo';
import InvoiceTourFoodForm from '../Components/Foods/InvoiceTourFoodForm';
import GuideAndTicketForm from '../Components/GuideAndTicket/GuideAndTicketForm';
import InvoiceTourOtherTransportForm from '../Components/OtherTransport/InvoiceTourOtherTransportForm';
import InvoiceTourTransportForm from '../Components/Transport/InvoiceTourTransportForm';
import {
  useAddCostingMutation,
  useLazyGetTourBillingInfoQuery,
} from '../Endpoints/GenerateInvoiceEndpoints';
type Props = { invoiceId: number };
const AddCostingFormModal = ({ invoiceId }: Props) => {
  const [form] = Form.useForm();

  // billling info
  const [fetchBillingQuery, { data: billingInfo, isLoading: fetchLoading }] =
    useLazyGetTourBillingInfoQuery();
  useEffect(() => {
    form.setFieldsValue({
      tourTransports: billingInfo?.data?.tourTransports ? [{}] : null,
      tourFoods: billingInfo?.data?.tourFoods ? [{}] : null,
      tourAccms: billingInfo?.data?.tourAccms ? [{}] : null,
      tourOtherTrans: billingInfo?.data?.tourOtherTrans ? [{}] : null,

      tourBilling: billingInfo?.data?.tourBilling || [{}],
    });
  }, [billingInfo?.data]);

  const [open, setOpen] = useState(false);

  const showModal = () => {
    fetchBillingQuery(invoiceId).then((response) => {
      if (
        !response?.data?.data?.tourTransports &&
        !response?.data?.data?.tourAccms &&
        !response?.data?.data?.tourFoods &&
        !response?.data?.data?.tourGuide &&
        !response?.data?.data?.tourOtherTrans &&
        !response?.data?.data?.tourTicket
      ) {
        message.info('You have no costing to add!!');
        setOpen(false);
      } else {
        setOpen(true);
      }
    });
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // form submit
  const [addCost, { isError, isSuccess, error, isLoading }] =
    useAddCostingMutation();

  if (error) {
  }
  const user = useSelector(selectUser);

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  const onFinish = (values: any) => {
    if (values?.tourTransports) {
      values.tourTransports = values?.tourTransports[0]?.transport_itinerary_id
        ? values?.tourTransports
        : [];
    }

    if (values?.tourFoods) {
      values.tourFoods = values?.tourFoods[0]?.food_itinerary_id
        ? values?.tourFoods
        : [];
    }

    if (values?.tourAccms) {
      values.tourAccms = values?.tourAccms[0]?.accm_itinerary_id
        ? values?.tourAccms
        : [];
    }

    if (values?.tourOtherTrans) {
      values.tourOtherTrans = values?.tourOtherTrans[0]
        ?.other_trans_itinerary_id
        ? values?.tourOtherTrans
        : [];
    }

    values.tourBilling = [...values?.tourBilling].map((item) => {
      return {
        billing_cost_price: Number(item.billing_cost_price),
        billing_profit: Number(item.billing_profit),
        billing_total_sales: Number(item.billing_total_sales),
        billing_numof_room: item.billing_numof_room,
        billing_pax_name: item.billing_pax_name,
        billing_product_id: item.billing_product_id,
        billing_total_pax: item.billing_total_pax,
      };
    });

    const body = {
      ...values,
      invoice_created_by: user?.user_id,
      ticket_route: values?.ticket_route
        ? (getAirportByItaCode(
            values?.ticket_route as string,
            selectAirport
          ) as string[])
        : undefined,
    };

    addCost({ body, id: invoiceId });
  };

  useEffect(() => {
    if (isError) {
      message.error('Thare was an error');
    } else if (isSuccess) {
      message.success('Invoice tour cost has been added');
      handleCancel();
      form.resetFields();
    }
  }, [isLoading]);

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <>
      <Button size='small' type='primary' onClick={showModal}>
        <Typography style={{ color: '#ffffff' }}>Add Costing</Typography>
      </Button>
      <Modal
        width={1220}
        forceRender
        style={{ top: 20 }}
        title='Invoice tour package costing'
        open={open}
        onOk={handleOk}
        okButtonProps={{ loading: isLoading }}
        onCancel={handleCancel}
      >
        {isLoading && <LoadingIndicator />}
        <Form
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {billingInfo?.data?.tourTransports ||
          billingInfo?.data?.tourFoods ||
          billingInfo?.data?.tourAccms ||
          billingInfo?.data?.tourOtherTrans ? (
            <div className='p-5 border'>
              {billingInfo?.data?.tourTransports ? (
                <InvoiceTourTransportForm
                  form={form}
                  invComClientInfo={billingInfo.data.invoice_combclient_id}
                />
              ) : null}
              {billingInfo?.data?.tourFoods ? (
                <InvoiceTourFoodForm
                  form={form}
                  invComClientInfo={billingInfo.data.invoice_combclient_id}
                />
              ) : null}
              {billingInfo?.data?.tourAccms ? (
                <InvoiceTourAccommodationForm
                  form={form}
                  invComClientInfo={billingInfo.data.invoice_combclient_id}
                />
              ) : null}
              {billingInfo?.data?.tourOtherTrans ? (
                <InvoiceTourOtherTransportForm
                  form={form}
                  invComClientInfo={billingInfo.data.invoice_combclient_id}
                />
              ) : null}
            </div>
          ) : null}

          <GuideAndTicketForm
            form={form}
            tourGuide={billingInfo?.data?.tourGuide}
            tourTicket={billingInfo?.data?.tourTicket}
            invComClientInfo={billingInfo?.data?.invoice_combclient_id}
            isCosting
          />

          <TourBillingInfo
            form={form}
            billInitialValues={undefined}
            isCostAdd
            tourBilling={billingInfo?.data?.tourBilling}
          />
        </Form>
      </Modal>
    </>
  );
};

export default AddCostingFormModal;
