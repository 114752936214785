import { Button, Form, Radio, Row, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import { setModal } from '../../../../../common/slices/commonSlice';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import { FormInputItem } from '../../../../../components/common/FormItem/FormItems';
import { SelectCountry } from '../../../../../components/common/FormItem/SelectCustomFeilds';
import { useEditTourCitiesMutation } from '../endpoints/CitiesTourEndPoints';
import { ICitiesGetDataType } from '../types/citiesType';

interface IProps {
  info: ICitiesGetDataType;
}
const CitiesTourEditModal = ({ info }: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  // update Tour Plce data
  const [updateTourCities, { isError, error, isSuccess, isLoading }] =
    useEditTourCitiesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Cities Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const user = useSelector(selectUser);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as ICitiesGetDataType;
      values.city_updated_by = user?.user_id as number;
      updateTourCities({ body: values, id: info?.city_id });
    });

    dispatch(setModal(false));
  };

  useEffect(() => {
    form.setFieldsValue({
      city_name: info.city_name,
      city_country_id: info.city_country_id,
      city_status: info.city_status,
    });
  }, [info]);
  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectCountry
          required
          name={'city_country_id'}
          size={12}
          label='Country'
        />

        <FormInputItem
          label='City'
          name={'city_name'}
          required
          size={12}
        />

        <Form.Item
          name='city_status'
          label='Status'
          required
        >
          <Radio.Group>
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Button
        type='primary'
        htmlType='submit'
      >
        Submit
      </Button>
    </Form>
  );
};

export default CitiesTourEditModal;
