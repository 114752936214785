import { Card, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import PassengerInfoList from './PassengerInfoList';

type Props = { form: FormInstance<any> };

export default function NewAddUmrahFromPassengerInfo({ form }: Props) {
  return (
    <div className='border p-5 '>
      <FormHeaderTitle title='Passenger Information' />
      <Form.List name='passenget_info' initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <PassengerInfoList
                key={key}
                name={name}
                form={form}
                index={index}
                add={add}
                remove={remove}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}
