import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { SALESMANCOMMISSIONREPORT } from '../../Api/constants';
import {
  ITodayPurchasePaymentReport,
  ITodaySalesReport,
} from '../types/totalCollectionTypes';

export const totalCollectionPayments = api.injectEndpoints({
  endpoints: (build) => ({
    todaySalesReport: build.query<HTTPResponse<ITodaySalesReport>, void>({
      query: (body) => ({
        url: `/report/today/sales-report`,
      }),
      providesTags: [
        { type: 'SaleaManCommissionReport', id: SALESMANCOMMISSIONREPORT },
      ],
    }),
    todayPurchasePayments: build.query<
      HTTPResponse<ITodayPurchasePaymentReport>,
      void
    >({
      query: (body) => ({
        url: `/report/today/payment-purchase`,
      }),
      providesTags: [
        { type: 'SaleaManCommissionReport', id: SALESMANCOMMISSIONREPORT },
      ],
    }),

    getExcelDailyCollandPayment: build.query<any, string>({
      query: (query) => ({
        url: `/report/sales/dailyCollectionPayment/${query}`,
        responseHandler: async (response) => {
          const filename = `DailySaleAndPayment.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useTodaySalesReportQuery,
  useTodayPurchasePaymentsQuery,
  useLazyGetExcelDailyCollandPaymentQuery,
} = totalCollectionPayments;
