import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { useState, useEffect } from 'react';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { SelectRoomType } from '../../../components/common/FormItem/SelectCustomFeilds';
import { IHotelInfo } from '../Type/Invoce.other.interface';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';
import { useParams } from 'react-router';

type Props = {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<any>;
  name: any;
  index: number;
  hotel_info: IHotelInfo[] | undefined;
};

function Hotel_Details_List_Info({
  add,
  remove,
  form,
  name,
  index,
  hotel_info,
}: Props) {
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const { id } = useParams();
  const hotel_name = useWatch(['hotel_information', name, 'hotel_name'], form);
  const hotel_reference_no = useWatch(
    ['hotel_information', name, 'hotel_reference_no'],
    form
  );
  const hotel_check_out_date = useWatch(
    ['hotel_information', name, 'hotel_check_out_date'],
    form
  );
  const hotel_check_in_date = useWatch(
    ['hotel_information', name, 'hotel_check_in_date'],
    form
  );
  const hotel_room_type_id = useWatch(
    ['hotel_information', name, 'hotel_room_type_id'],
    form
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      hotel_name ||
      hotel_reference_no ||
      hotel_check_out_date ||
      hotel_check_in_date ||
      hotel_room_type_id
    ) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
      timeoutId = setTimeout(() => {
        form.validateFields([
          ['hotel_information', name, 'hotel_name'],
          ['hotel_information', name, 'hotel_reference_no'],
          ['hotel_information', name, 'hotel_check_out_date'],
          ['hotel_information', name, 'hotel_check_in_date'],
          ['hotel_information', name, 'hotel_room_type_id'],
        ]);
      });
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    hotel_name,
    hotel_reference_no,
    hotel_check_out_date,
    hotel_check_in_date,
    hotel_room_type_id,
  ]);
  const [disabled, setDisabled] = useState(false);
  const hotel_is_deleted: 0 | 1 = useWatch(
    ['hotel_information', index, 'hotel_is_deleted'],
    form
  );

  useEffect(() => {
    if (hotel_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [hotel_is_deleted]);
  return (
    <Row
      style={
        hotel_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
            }
          : { display: 'flex', alignItems: 'center' }
      }
      gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
    >
      <FormInputItem name={[name, 'hotel_name']} label='Hotel Name' size={5} />
      <FormInputItem
        name={[name, 'hotel_reference_no']}
        label='Reference No	'
        size={5}
      />

      <DateInput
        name={[name, 'hotel_check_in_date']}
        label='Check In Date'
        size={5}
      />

      <DateInput
        name={[name, 'hotel_check_out_date']}
        label='Check Out Date'
        size={5}
      />

      <SelectRoomType
        name={[name, 'hotel_room_type_id']}
        label='Room type	'
        size={3}
      />
      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[name, 'hotel_is_deleted']} />
      {index === 0 ? (
        <Button type='primary' onClick={() => add()}>
          <PlusOutlined />
        </Button>
      ) : (
        <Form.Item label={false}>
          <Button
            type='primary'
            danger
            onClick={() => {
              const prev = form.getFieldValue([
                'hotel_information',
                name,
                'hotel_is_deleted',
              ]);
              form.setFieldValue(
                ['hotel_information', name, 'hotel_is_deleted'],
                Number(!prev)
              );
              if (id) {
                if (hotel_info?.length && index >= hotel_info.length)
                  remove(name);
              } else {
                remove(name);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Form.Item>
      )}
    </Row>
  );
}

export default Hotel_Details_List_Info;
