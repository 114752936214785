import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';
import {
  ITransportFormData,
  ITransportType,
  ITransportTypeFormData,
} from '../types/transportTypeTypes';
const TRANSPORTTYPES = 'TRANSPORTTYPES';
export const transportTypeEndPoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all transport types
    getAllTransportTypes: build.query<HTTPResponse<ITransportType[]>, void>({
      query: () => ({ url: `/transport-type/all` }),
      providesTags: () => [{ type: 'TransportTypes', id: TRANSPORTTYPES }],
    }),

    // get all transport types with pagination
    getAllTransportTypesWithPagination: build.query<
      HTTPResponse<ITransportType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/transport-type?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'TransportTypes', id: TRANSPORTTYPES }],
    }),

    //create transport types
    createTransportTypes: build.mutation<void, ITransportTypeFormData>({
      query: (body) => ({
        url: `/transport-type/create`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'TransportTypes', id: TRANSPORTTYPES }],
    }),

    //edit transport types
    editTransportTypes: build.mutation<void, ITransportFormData>({
      query: (body) => ({
        url: `/transport-type/update/${body.ttype_id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'TransportTypes', id: TRANSPORTTYPES }],
    }),

    //delete Transport types
    deleteTransportTypes: build.mutation<
      void,
      { deleteId: number; deleted_by: number }
    >({
      query: (body) => ({
        url: `/transport-type/delete/${body.deleteId}`,
        method: 'DELETE',
        body: { deleted_by: body.deleted_by },
      }),
      invalidatesTags: () => [{ type: 'TransportTypes', id: TRANSPORTTYPES }],
    }),
  }),
});

export const {
  useDeleteTransportTypesMutation,
  useGetAllTransportTypesQuery,
  useCreateTransportTypesMutation,
  useEditTransportTypesMutation,
  useGetAllTransportTypesWithPaginationQuery,
} = transportTypeEndPoints;
