import { Col, Form, Select } from 'antd';
import { useState } from 'react';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { useSelectAgentQuery } from '../endpoints/agent_IncentiveIncomeEndpoints';

type Props = {
  size?: number;
  mdSize?: number;
  smSize?: number;
  name?: string;
  label?: string;
  disabled?: boolean;
  setAgentID?: any;
};

const Select_Agent = ({ size, name, label, disabled, setAgentID }: Props) => {
  const [search, setSearch] = useState('');
  const { data, isLoading } = useSelectAgentQuery(search);

  const agentData = data?.data;

  let timeoutId: NodeJS.Timeout | null = null;

  const handleSearch = (value: string) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setSearch(value);
    }, 300);
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Col span={6} xs={24} sm={24} md={24} lg={size || 12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: `Agent is required!`,
            },
          ]}
          name={name || 'agent_id'}
          label={label || 'Agent'}
        >
          <Select
            disabled={disabled}
            placeholder={`Select Agent`}
            onSearch={handleSearch}
            allowClear
            showSearch
            optionFilterProp='roleMobile'
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {agentData &&
              agentData?.map((item, index) => {
                return (
                  <Select.Option value={`${item.agent_id}`} key={index}>
                    {item.agent_name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default Select_Agent;
