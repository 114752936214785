import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

interface IError {
  message: string;
  type: string;
}
interface IState {
  error: IError | null;
  isDepositModal: boolean;
  isBounceModal: boolean;
  isReturnModal: boolean;
  modalLoading: boolean;
  defaultLoading: boolean;
}
const initialState: IState = {
  error: null,
  isDepositModal: false,
  isBounceModal: false,
  isReturnModal: false,
  modalLoading: false,
  defaultLoading: false,
};

const chequeModalSlice = createSlice({
  name: "chequeModalSlice",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IError>) {
      state.error = action.payload;
    },
    setDepositModal(state, action: PayloadAction<boolean>) {
      state.isDepositModal = action.payload;
    },
    setBounceModal(state, action: PayloadAction<boolean>) {
      state.isBounceModal = action.payload;
    },
    setReturnModal(state, action: PayloadAction<boolean>) {
      state.isReturnModal = action.payload;
    },
  },
});

export const { setError, setDepositModal, setBounceModal, setReturnModal } =
  chequeModalSlice.actions;

export const commonCheque = (state: RootState) => state.chequeModalSlice;
export default chequeModalSlice.reducer;
