import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Hotel_Details_List_Info from './Hotel_Details_List_Info';
import { IHotelInfo } from '../Type/Invoce.other.interface';

type Props = { form: FormInstance<any>; hotel_info?: IHotelInfo[] | undefined };

const HotelInformation = ({ form, hotel_info }: Props) => {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Hotel Information 🏩' />

      <Form.List
        name='hotel_information'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Hotel_Details_List_Info
                hotel_info={hotel_info}
                add={add}
                key={index}
                name={name}
                index={index}
                remove={remove}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default HotelInformation;
