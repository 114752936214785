import { Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import Col from 'antd/lib/grid/col';
import {
  FormButton,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import ViewPassportByTracking from './ViewPassportByTracking';

type Props = {
  onFinish: (values: any) => void;
  form?: FormInstance<any>;
  previousTrackNo?: string[];
  isLoading: boolean;
};

const ClientToClientForm = ({
  onFinish,
  form,
  previousTrackNo,
  isLoading,
}: Props) => {
  const fromClientID = useWatch(['ctransfer_combclient_from'], form);

  return (
    <Form
      className='border p-5 my-20'
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Row justify={'space-between'} align={'middle'} gutter={[10, 0]}>
        <Col
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          span={8}
        >
          <SelectClients
            name={'ctransfer_combclient_from'}
            label='Transfer From'
            placeholder='Select a client'
            size={24}
            mdSize={8}
            smSize={12}
            required
            onChange={() => {
              form?.setFieldValue('ctrcknumber_number', []);
            }}
            offDropDown
          />
          <SelectClients
            name={'ctransfer_combclient_to'}
            label='Transfer To'
            placeholder='Select a client'
            size={24}
            mdSize={8}
            smSize={12}
            required
            offDropDown
            dependencies={['ctransfer_combclient_from']}
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, data) => {
                  const ctransfer_combclient_from = getFieldValue(
                    'ctransfer_combclient_from'
                  );
                  if (data == ctransfer_combclient_from) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: "Transfer From can't be same to Transfer To!",
              }),
            ]}
          />
        </Col>

        <Col
          style={{
            borderLeft: '2px solid lightgray',
            paddingLeft: 10,
            paddingRight: 10,
          }}
          span={8}
        >
          <FormInputItem
            name={'ctransfer_job_name'}
            label='Job Name'
            size={24}
            smSize={12}
          />

          <FormInputItem
            name={'ctransfer_tracking_no'}
            label='Tracking No'
            size={24}
            mdSize={8}
            smSize={12}
          />
        </Col>

        <Col
          style={{ borderLeft: '2px solid lightgray', paddingLeft: 10 }}
          span={8}
        >
          <FormInputItem
            name={'ctransfer_charge'}
            label='Charge'
            size={24}
            mdSize={8}
            smSize={12}
          />

          <Form.Item name='ctransfer_note'>
            <TextArea rows={4} placeholder='Note something' maxLength={255} />
          </Form.Item>
        </Col>
      </Row>

      <ViewPassportByTracking
        fromClientID={fromClientID}
        previousTrackNo={previousTrackNo}
        form={form}
        openFrom='CLIENT_TO_CLIENT'
      />
      <FormButton loading={isLoading} label='Save Transfer Data' />
    </Form>
  );
};

export default ClientToClientForm;
