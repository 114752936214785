import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal } from 'antd';
import { useState } from 'react';
import AddNewGroup from '../../Configuration/group/modals/AddNewGroup';

type Props = {
  setClick: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setInstanceGroupSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};
const HajjiGroupAddModal = ({ setClick, setInstanceGroupSelect }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    setClick(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick(false);
  };
  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={() => showModal()}
        style={{ width: '100%' }}
      />

      <Modal
        title={'Add New Group'}
        open={isModalOpen}
        okText={'Submit'}
        cancelText={'Close'}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {
          <AddNewGroup
            setIsModalOpen={setIsModalOpen}
            setClick={setClick}
            setInstanceGroupSelect={setInstanceGroupSelect}
          />
        }
      </Modal>
    </>
  );
};

export default HajjiGroupAddModal;
