import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';

const TourInfo = () => {
  return (
    <>
      <Form.List name='tour_info' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row key={index} gutter={[10, 0]} align='bottom'>
                <Col xs={10}>
                  <Form.Item
                    label='Group Name'
                    name={[field.name, 'group_name']}
                    rules={[
                      {
                        required: true,
                        message: 'Group Name is required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    label='Maximum Pax'
                    name={[field.name, 'group_maximum_pax_allowed']}
                    rules={[
                      {
                        required: true,
                        message: 'Maximum Pax Allowed is required',
                      },
                      {
                        message: 'Only digits allowed',
                        validator: (_, value) => {
                          if (value) {
                            if (/^[0-9]*$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                'Non-numeric values are not allowed'
                              );
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col xs={2}>
                  <Form.Item>
                    {index === 0 ? (
                      <Button
                        type='primary'
                        onClick={() => add()}
                      >
                        <PlusOutlined />
                      </Button>
                    ) : (
                      <Button
                        type='primary'
                        danger
                        onClick={() => remove(field.name)}
                      >
                        <MinusOutlined />
                      </Button>
                    )}
                  </Form.Item>
                </Col> */}
              </Row>
            );
          })
        }
      </Form.List>
    </>
  );
};

export default TourInfo;
