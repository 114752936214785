import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import AirticketVendorInfo from './AirticketVendorInfo';
import { IAirTicketInfoByNo } from '../../RefundTypes/RefundTypes';
import React from 'react';

type Props = {
  form: FormInstance<any>;
  airticketVendorInitialValues?: IAirTicketInfoByNo[];
};

const AirticketVendorRefund = ({
  airticketVendorInitialValues,
  form,
}: Props) => {


  return (
    <Form.List
      name='vendor_refund_info'
      initialValue={airticketVendorInitialValues || [{}]}
    >
      {(fields, { add, remove }) =>
        fields.map((field, index) => {
          return (
            <React.Fragment key={index}>
              <AirticketVendorInfo
                form={form}
                field={field}
                airticketVendorInitialValues={airticketVendorInitialValues}
              />
            </React.Fragment>
          );
        })
      }
    </Form.List>
  );
};

export default AirticketVendorRefund;
