import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteMaharamMutation } from '../endpoints/maharamEndpoints';
import { MaharamDataType } from '../types/MaharamTypes';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<MaharamDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const maharamCollumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<MaharamDataType> => {
  const searchColumnProps = useGetSearchColumnProps<MaharamDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const handleClick = (record: MaharamDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteMaharam, { isError, isSuccess, isLoading }] =
    useDeleteMaharamMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Maharam Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: MaharamDataType, index: number) => {
    setColumnIndex(index);
    await deleteMaharam({ ...values, maharam_updated_by: user?.user_id });
  };
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Maharam name',
      dataIndex: 'maharam_name',
      key: 'maharam_name',
      ...searchColumnProps('maharam_name'),
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}
          {permission?.['delete:any'] && record.agency_id && (
            <Button
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
