import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateDesignationMutation } from '../endpoints/designationEndpoints';
import { IDesignationFormData } from '../types/designationTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const DesignationAddModal = ({
  designationNameList,
}: {
  designationNameList: string[];
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);

  const [form] = Form.useForm();
  const [addDesignation, { isError, isSuccess, isLoading }] =
    useCreateDesignationMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Designation Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async (values: IDesignationFormData) => {
    const body: IDesignationFormData = {
      ...values,
      designation_created_by: user?.user_id as number,
    };
    await addDesignation(body);
    dispatch(setModal(false));
    form.resetFields();
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout='horizontal'
      labelAlign='left'
      {...layout}
    >
      <Form.Item
        label='Designation Name'
        name='designation_name'
        rules={[
          { required: true, message: 'Please input designation name!' },
          { max: 65, message: 'Maximum 65 characters' },
          {
            validator(_, value) {
              if (value) {
                if (designationNameList.includes(value)) {
                  return Promise.reject('Designation Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter designation name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default DesignationAddModal;
