import { Table } from 'antd';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAllMoneyReceiptClientQuery } from '../../../Client/api/endpoints/clientEndpoints';
import { IClientMoneyReceiptData } from '../../../Client/types/clientInterfaces';
import { clientPaymentUtil } from '../../../Client/utils/utils_tsx/clientPaymentUtil';
import { pagination } from '../../../../../common/utils/common.utils';

type Props = { id: string };

const CombineClientDetailPayment = ({ id }: Props) => {
  const { data, isLoading } = useGetAllMoneyReceiptClientQuery(
    `combined-${id}`,
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const dataToReturn: IClientMoneyReceiptData[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: dataToReturn };
      },
    }
  );
  return (
    <div>
      <Table
        size='small'
        columns={clientPaymentUtil()}
        dataSource={data}
        pagination={pagination(data.length)}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default CombineClientDetailPayment;
