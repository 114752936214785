import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { PassportStatusDataType } from '../types/PassportStatusTypes';
import { useEditPassportStatusMutation } from '../endpoints/passportStatusEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  passStatusNameList: string[];
  info: PassportStatusDataType;
  setNull: ISetState<PassportStatusDataType | null>;
}

const EditStatusModal = ({ info, setNull, passStatusNameList }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const modalSlice = useSelector(selectCommon);

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    form.setFieldsValue({
      pstatus_name: info.pstatus_name,
    });
  }, [info]);

  const [editPassportStatus, { isError, isSuccess, isLoading }] =
    useEditPassportStatusMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Passport Status Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as PassportStatusDataType;
      values.pstatus_id = info.pstatus_id;
      await editPassportStatus({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        rules={[
          { required: true, message: 'Status Title is required!!' },
          {
            validator(_, value) {
              if (value) {
                if (passStatusNameList.includes(value)) {
                  return Promise.reject('PassportStatus Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
        name='pstatus_name'
        label='Status Title:'
      >
        <Input placeholder='Enter Status Title' />
      </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
        <Button
          disabled={modalSlice.modalLoading}
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default EditStatusModal;
