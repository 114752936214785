import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeletePassportSttausMutation } from '../endpoints/passportStatusEndpoints';
import { PassportStatusDataType } from '../types/PassportStatusTypes';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

export const passportStatusCollumns = (
  setEditInfo: ISetState<PassportStatusDataType | null>,
  showModal: () => void,
  permission?: IPermission
): ColumnsType<PassportStatusDataType> => {
  const searchColumnProps = useGetSearchColumnProps<PassportStatusDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const handleClick = (record: PassportStatusDataType) => {
    setEditInfo(record);
    showModal();
  };
  const [deletePassportStatus, { isError, isSuccess, isLoading }] =
    useDeletePassportSttausMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Passport Status Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (
    values: PassportStatusDataType,
    index: number
  ) => {
    setColumnIndex(index);
    await deletePassportStatus({
      statusId: values.pstatus_id,
      deleted_by: user?.user_id as number,
    });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
    },
    {
      title: 'Status',
      dataIndex: 'pstatus_name',
      key: 'pstatus_name',
      ...searchColumnProps('pstatus_name'),
    },
    {
      title: 'Create Date',
      key: 'status_create_date',
      render: (_, record) => {
        return `${dayjs(record.pstatus_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}
          {permission?.['delete:any'] && record.agency_id && (
            <Button
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
