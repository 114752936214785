import { Button, Card, Timeline, Row, Spin } from 'antd';
import { useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useGetOthersRefundClientLogsQuery } from '../../Api/Endpoints/OthersRefundClientEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';

type Props = {};

function OthersRefundClientLogs({}: Props) {
  const { id } = useParams();

  const { isLoading, data: getAirticketLogs } =
    useGetOthersRefundClientLogsQuery(Number(id));
  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Others Refund Logs']} />
      <Link to='/refund/history/others' state={location.pathname}>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to Others Refund List
        </Button>
      </Link>

      {isLoading ? (
        <Row justify='center' align='middle'>
          {loadingIndicator}
        </Row>
      ) : (
        <Card>
          <Timeline mode='left'>
            {getAirticketLogs?.data?.map((item, index) => {
              return (
                <Timeline.Item
                  key={index}
                  label={
                    item.othrfndlog_date && item.othrfndlog_date.slice(0, 10)
                  }
                >
                  {item.othrfndlog_content + ' by ' + item.created_by}
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Card>
      )}
    </div>
  );
}

export default OthersRefundClientLogs;
