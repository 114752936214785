import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IClientWisePassenDataType } from '../types/clientWisePassengerTypes';
import { Link } from 'react-router-dom';

export const clientWisePassColumn = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<IClientWisePassenDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: 'Passport No',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
      render: (_, record) => (
        <Link
          to={`/reports/passport_wise_report?passport_id=${record.passport_id}`}
        >
          {' '}
          {record.passport_passport_no}
        </Link>
      ),
    },

    {
      title: 'Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Mobile No',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'passport_date_of_birth',
      key: 'passport_date_of_birth',
      render: (_, record) => {
        return `${
          record.passport_date_of_birth
            ? dayjs(record.passport_date_of_birth).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Date Of Issue',
      dataIndex: 'passport_date_of_issue',
      key: 'passport_date_of_issue',
      render: (_, record) => {
        return `${
          record.passport_date_of_issue
            ? dayjs(record.passport_date_of_issue).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Date Of Expire',
      dataIndex: 'passport_date_of_expire',
      key: 'passport_date_of_expire',
      render: (_, record) => {
        return `${
          record.passport_date_of_expire
            ? dayjs(record.passport_date_of_expire).format('DD MMM YYYY')
            : ''
        }`;
      },
    },
    {
      title: 'Email',
      dataIndex: 'passport_email',
      key: 'passport_email',
    },
  ];
};
