import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import CountryName from '../../../../../helpers/CountryName';
import { IClientCategory } from '../../../../Configuration/clientCategory/types/clientCategoryTypes';

import {
  BalanceProps,
  CategoryProps,
  GenderProps,
  MarketingProps,
  TypeProps,
} from '../../types/clientTypes';

export const category: CategoryProps[] = [
  {
    category: 'Superadmin',
  },
  {
    category: 'SalesMan',
  },
  {
    category: 'Account',
  },
  {
    category: 'Testing Role',
  },
];

export const type: TypeProps[] = [
  {
    type: 'Individual',
    value: 'INDIVIDUAL',
  },
  {
    type: 'Corporate',
    value: 'CORPORATE',
  },
];

export const gender: GenderProps[] = [
  {
    gender: 'Male',
  },
  {
    gender: 'Female',
  },
];

export const balance: BalanceProps[] = [
  {
    balance: 'Due',
    value: 1,
  },
  {
    balance: 'Advance',
    value: 2,
  },
];

export const marketing: MarketingProps[] = [
  {
    marketing: 'Existing Client',
  },
  {
    marketing: 'Facebook',
  },
  {
    marketing: 'Search Engine Marketing',
  },
  {
    marketing: 'Email Marketing',
  },
  {
    marketing: 'Seo',
  },
  {
    marketing: 'YouTube Marketing',
  },
  {
    marketing: 'Direct Client',
  },
  {
    marketing: 'Instagram Marketing',
  },
  {
    marketing: 'Office NS',
  },
];

export const categoryChildren = (clientCategories: IClientCategory[]) => {
  return clientCategories.map((item) => (
    <Select.Option key={item.category_id} value={Number(item.category_id)}>
      {item.category_title}
    </Select.Option>
  ));
};

export const typeChildren: React.ReactNode[] = [];
for (let i = 0; i < type.length; i++) {
  typeChildren.push(
    <Select.Option key={type[i].type} value={type[i].value}>
      {type[i].type}
    </Select.Option>
  );
}
export const genderChildren: React.ReactNode[] = [];
for (let i = 0; i < gender.length; i++) {
  genderChildren.push(
    <Select.Option key={gender[i].gender} value={gender[i].gender}>
      {gender[i].gender}
    </Select.Option>
  );
}

export const mobileChildren: React.ReactNode[] = [];
for (let i = 0; i < CountryName.length; i++) {
  mobileChildren.push(
    <Select.Option
      key={CountryName[i].isoCode + '  ' + CountryName[i].dialCode}
      value={CountryName[i].dialCode}
    >
      {CountryName[i].isoCode + '  +' + CountryName[i].dialCode}
    </Select.Option>
  );
}

// export const balanceChildren: React.ReactNode[] = [];
// for (let i = 0; i < balance.length; i++) {
//   balanceChildren
//     .push
//     // <Select.Option key={balance[i].value} value={balance[i].value}>{balance[i].balance}</Select.Option>
//     ();
// }

export function add(cb: () => void) {
  return (
    <Button type='primary' onClick={() => cb()}>
      <PlusOutlined />
      Add More
    </Button>
  );
}

export function remove(cb: () => void) {
  return (
    <Button type='primary' danger onClick={() => cb()}>
      <MinusOutlined />
      Remove
    </Button>
  );
}
