/**
 * RTK Query accepts `keepUnusedDataFor` to be in seconds,
 * the properties of the `expire` object are in seconds
 *
 * @property `max` 10 minutes
 * @property `default` 5 minute
 * @property `avarage` 3 minutes
 * @property `min` 1 minutes
 */

export const expire = {
  max: 60 * 10,
  default: 60 * 5,
  avarage: 60 * 3,
  min: 60 * 1,
};

export const VIEW_COMMON = {
  viewInvoice: 'viewInvoice',
  costDetails: 'costDetails',
  payments: 'payments',
  activity: 'activity',
};
