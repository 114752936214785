import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormMobileNumberItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../components/common/FormItem/SelectCustomFeilds';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import CountryName from '../../../helpers/CountryName';
import { useGetAllProductsQuery } from '../../Configuration/Products/endpoints/productsEndpoints';
import { ProductDataType } from '../../Configuration/Products/types/productTypes';
import { useAddVendorMutation } from '../api/endpoints/vendorEndpoints';
import VendorProducts from '../components/common/VendorProducts';
import { IVendorFormData } from '../types/vendor.interfaces';
import { useWatch } from 'antd/es/form/Form';
import { useGetAccountByTypeQuery } from '../api/endpoints/vendorPaymentEndpoints';

type Props = {
  setClick: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setInstantVendor?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const AddVendorModal = ({ setClick, setInstantVendor }: Props) => {
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const { data: accounts, refetch: refetchAccount } = useGetAccountByTypeQuery(
    Number(payMethodCatId)
  );

  const navigate = useNavigate();
  const vendor_opening_balance_pay_type: 'Due' | 'Advance' = useWatch(
    ['vendor_opening_balance_pay_type'],
    form
  );

  const [option, setOption] = useState('');
  const creditLimit = (value: string) => {
    setOption(value);
  };

  const user = useSelector(selectUser);

  const [selectProducts, setSelectProducts] = useState<number[]>([]);
  const { data: products } = useGetAllProductsQuery();

  const [addVendor, { data, isSuccess, isError, isLoading }] =
    useAddVendorMutation();
  useEffect(() => {
    if (data?.data?.vendor_id) {
      setInstantVendor && setInstantVendor(data.data.vendor_id);
    }
  }, [data?.data?.vendor_id, setInstantVendor]);
  // hendle add vendor--------------
  const handleAddVendor = async (values: IVendorFormData) => {
    if (selectProducts.length) {
      values.vendor_registration_date = dayjs(
        values.vendor_registration_date
      ).format('YYYY-MM-DD');
      values.vendor_created_by = user?.user_id as number;
      values.vendor_products_id = selectProducts;

      addVendor(values);
    } else message.error('Products is required!');
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor has been added!');
      setModalOpen(false);
    }
    if (isError) {
      message.error('Vendor can not added!');
    }
  }, [isLoading]);

  // set form value
  useEffect(() => {
    form.setFieldsValue({
      vendor_registration_date: dayjs(),
      dailCode: CountryName[19].dialCode,
    });
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModal = () => {
    setModalOpen(false);
    setClick(false);
  };
  const showAddAgentModal = () => {
    setModalOpen(true);
    setClick(true);
  };
  return (
    <>
      <Modal
        title='Add New Vendor'
        open={modalOpen}
        okText={'Submit'}
        cancelText={'Close'}
        onOk={handleModal}
        onCancel={handleModal}
        footer={null}
        width={1000}
        forceRender
      >
        <div className='p-5 '>
          <Form
            form={form}
            layout='vertical'
            onFinish={handleAddVendor}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <FormInputItem
                name='vendor_name'
                label='Name'
                required
                size={6}
              />
              <FormInputItem
                name='vendor_email'
                label='Email'
                size={6}
                inputType='email'
              />
              <FormMobileNumberItem size={6} />
              <NumberInput
                label='Fixed advance:'
                name='vendor_fixed_advance'
                size={6}
                min='0'
                maxChar={14}
                minChar={0}
              />
              <DateInput
                name={'vendor_registration_date'}
                label='Date: '
                required
                size={6}
              />
              {/* Add Opening Balance */}
              <SelectOptionsInput
                name={'vendor_opening_balance_pay_type'}
                label='Opening Balance Type: '
                options={['Due', 'Advance']}
                size={6}
              />
              {
                <NumberInput
                  name={'vendor_opening_balance'}
                  label='Opening Balance : '
                  size={6}
                />
              }

              {/* {vendor_opening_balance_pay_type === 'Advance' && (
              <>
                <SelectPaymentMethod
                  size={3}
                  required
                  name='opening_payment_type'
                  label='Payment Method'
                  onChange={(value: number) => {
                    setPayMethodCatId(value);
                  }}
                />
                <AccountLastBalanceInput
                  size={3}
                  required
                  accountsLastBalance={accounts ? accounts.data : []}
                  name='opening_account_id'
                  label='Account:'
                />
              </>
            )} */}

              <FormInputItem
                label='Address'
                name='vendor_address'
                size={6}
              />
              {/* Add Vendor Credit Limit */}

              <NumberInput
                name={'vendor_credit_limit'}
                label='Credit Limit : '
                placeholder={'Credit Limit'}
                size={6}
              />

              <VendorProducts
                products={products?.data as ProductDataType[]}
                setSelectProducts={(value) => setSelectProducts(value)}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
              />
            </Row>
            <FormButton
              label='Add vendor'
              loading={isLoading}
            />
          </Form>

          {isLoading && <LoadingIndicator />}
        </div>
      </Modal>
      <Button
        onClick={showAddAgentModal}
        style={{ width: '100%' }}
      >
        <PlusOutlined /> Add Vendor
      </Button>
    </>
  );
};

export default AddVendorModal;
