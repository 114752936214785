import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { EXPENSE } from '../../../Expense/Api/Constants/Constants';
import {
  LOAN,
  PAYMENT,
  RECEIVE,
} from '../../../Loan_Management/Api/Constants/constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { ITransactionHistory } from '../../AccountsTypes/AccountsTypes';
import {
  ACCOUNT_LIST,
  BALANCE_STATUS,
  BALANCE_TRANSFER,
  INCENTIVE_INCOME,
  INVESTMENTS,
  NON_INVOICE_INCOME,
  OPENING_BALANCE,
  TRANSACTION_HISTORY,
} from '../Constants/constants';

export const transactionHistoryEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all transaction history
    getTransactionHistory: build.query<
      HTTPResponse<ITransactionHistory[]>,
      {
        id?: string;
        from: string;
        to: string;
        current: number;
        pageSize: number;
      }
    >({
      query: (arg) => ({
        url: `/accounts/transaction-history/${arg.id || 'all'}?from_date=${
          arg.from
        }&to_date=${arg.to}&page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [
        { type: 'Transaction_History', id: TRANSACTION_HISTORY },
        { type: 'Balance_Status', id: BALANCE_STATUS },
        { type: 'Opening_Balance', id: OPENING_BALANCE },
        { type: 'Accounts', id: ACCOUNT_LIST },
        { type: 'Balance_Transfer', id: BALANCE_TRANSFER },
        { type: 'Investments', id: INVESTMENTS },
        { type: 'Non_Invoice_Income', id: NON_INVOICE_INCOME },
        { type: 'Incentive_Income', id: INCENTIVE_INCOME },
        { type: 'Expense', id: EXPENSE },
        { type: MONEY_RECEIPT_TAG },
        { type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' },
        { type: 'Money_receipt_advance' },
        { type: 'Loan', id: LOAN },
        { type: 'Payment', id: PAYMENT },
        { type: 'Receive', id: RECEIVE },
      ],
    }),
  }),
});

export const { useGetTransactionHistoryQuery } = transactionHistoryEndpoints;
