import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { IClientQuotationData } from '../../types/clientInterfaces';
import { IPermission } from '../../../../../common/types/commonTypes';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { Button, Space } from 'antd';
import { Link } from 'react-router-dom';

type Props = {};

export const clientQuotationUtil = (
  permission?: IPermission
): ColumnsType<IClientQuotationData> => {
  const searchColumnProps = useGetSearchColumnProps<IClientQuotationData>();

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Quotation No.',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
      ...searchColumnProps('quotation_no'),
    },
    {
      title: 'Quotation Date',
      key: 'quotation_create_date',
      render: (_, record) => {
        return `${dayjs(record.quotation_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Net Total',
      key: 'quotation_net_total',
      dataIndex: 'quotation_net_total',
    },
    {
      title: 'Action',
      key: 'operation',

      render: (_, record) => (
        <Space size='small'>
          <Link
            to={`/quotation/details/${record.quotation_id}`}
            state={'/quotation'}
          >
            <Button size='small' type='primary'>
              View
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
};
