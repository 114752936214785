import { Button, FormInstance, Modal } from 'antd';
import { useState } from 'react';
import NewAddHajjFormTicketInfo from '../Components/NewAddHajjFormTicketInfo';

type Props = {
  form: FormInstance<any>;
  name: number;
  index: number;
  disabled?: boolean;
};

export default function AddTicketInfoHajj({
  form,
  name,
  index,
  disabled,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type='primary' onClick={showModal} disabled={disabled}>
        Ticket Info
      </Button>
      <Modal
        title='Add Ticket Info'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={610}
      >
        <NewAddHajjFormTicketInfo form={form} index={index} name={name} />
      </Modal>
    </>
  );
}
