import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import Table, { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { Fixed2 } from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  FormButton,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useGetHajjCancelRegTrackingNoQuery,
  usePostHajjCancelRegMutation,
} from '../../API/Endpoints/CancelHajjRegEndpoint';
import {
  IGetHajjRegTrackingNo,
  IPostHajjRegCancel,
} from '../../Types/HajjiManagement.interface';

type Props = {};

const PostCancelHajjReg = (props: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<any>();

  const navigate = useNavigate();

  const [post, { isError, isSuccess, isLoading, error }] =
    usePostHajjCancelRegMutation();

  const { data: trackingNo, isLoading: trackingNoLoading } =
    useGetHajjCancelRegTrackingNoQuery(search);

  const newTableDataArray = trackingNo?.data?.filter((entry) =>
    selected?.includes(entry?.hajiinfo_tracking_number)
  );

  const cl_total_charge = useWatch('cl_total_charge', form);
  const cl_govt_charge = useWatch('cl_govt_charge', form);

  useEffect(() => {
    form.setFieldValue(
      'cl_office_charge',
      Fixed2(cl_total_charge) - Fixed2(cl_govt_charge)
    );
  }, [cl_total_charge, cl_govt_charge]);

  const onFinish = (values: IPostHajjRegCancel) => {
    const body: IPostHajjRegCancel = {
      ...values,
      created_by: user?.user_id,
    };

    post(body);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Cancel Pre Reg successfully');
      navigate('../haji/transfer/hajj_registration_cancel_list');
    }
    if (isError) {
      message.error('Something happened to wrong');
    }
  }, [isLoading]);

  const columns: ColumnsType<IGetHajjRegTrackingNo> = [
    {
      title: 'SL',
      render: (_, data, index) => {
        return <> {index + 1} </>;
      },
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Passport NID',
      dataIndex: 'passport_nid_no',
      key: 'passport_nid_no',
    },
    {
      title: ' Gender',
      dataIndex: 'hajiinfo_gender',
      key: 'hajiinfo_gender',
    },
    {
      title: 'Serial',
      dataIndex: 'hajiinfo_serial',
      key: 'hajiinfo_serial',
    },
    {
      title: 'Voucher No',
      dataIndex: 'haji_info_vouchar_no',
      key: 'haji_info_vouchar_no',
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },

    {
      title: 'Tracking Number',
      dataIndex: 'hajiinfo_tracking_number',
      key: 'hajiinfo_tracking_number',
    },
  ];

  return (
    <>
      <BreadCrumb arrOfOption={['Create Cancel Pre Registration']} />
      <Form onFinish={onFinish} layout='vertical' form={form}>
        <Row
          style={{
            paddingTop: '15px',
            marginBottom: '20px',
          }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 18 }}
        >
          <Button
            style={{ marginBottom: '20px' }}
            type='primary'
            onClick={(e) => {
              navigate(-1);
            }}
          >
            <ArrowLeftOutlined /> Return To List
          </Button>
          <Row
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          >
            <Col xs={24} sm={24} md={8} lg={6}>
              <Form.Item
                style={{ padding: '0', marginLeft: '10px' }}
                name={'tracking_no'}
                label={'Tracking No'}
                required
              >
                <Select
                  mode={'multiple'}
                  placeholder={'Select tracking no.'}
                  showSearch
                  onSearch={(e) => setSearch(e)}
                  onChange={(e) => setSelected(e)}
                  allowClear={true}
                  style={{ padding: '0', margin: '0', border: '0' }}
                  optionFilterProp='roleMobile'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={trackingNoLoading}
                >
                  {trackingNo?.data?.map((item) => (
                    <Select.Option
                      key={item.hajiinfo_tracking_number}
                      value={item.hajiinfo_tracking_number}
                    >
                      {item.hajiinfo_tracking_number}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormHeaderTitle title='Haji Information 🕌' />

          <NumberInput
            label='Total Charge'
            name={'cl_total_charge'}
            required
            min='0'
            maxChar={10}
          />
          <NumberInput
            label='Govt Charge'
            name={'cl_govt_charge'}
            required
            min='0'
            maxChar={10}
          />
          <NumberInput
            label='Office Charge'
            name={'cl_office_charge'}
            min='0'
            readOnly
            maxChar={10}
          />
        </Row>

        <Table
          size='small'
          bordered
          className='invoiceBillingTable mt-5'
          rowClassName={'invoiceBillingTd'}
          columns={columns}
          dataSource={newTableDataArray}
          pagination={false}
          loading={isLoading}
          style={{ marginTop: '25px' }}
          scroll={{ x: true }}
        />

        <FormButton label='CANCEL PRE REG HAJJI' loading={isLoading} />
      </Form>
    </>
  );
};

export default PostCancelHajjReg;
