import { Row, Steps, message, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCreateFeedbackMutation } from '../api/feedbackEndpoints';
import Feedback_form from '../components/Feedback_form';
import Feedback_survey from '../components/Feedback_survey';
import { IFeedback } from '../types/FeedbackTypes';

type Props = {};

const Feedback = (props: Props) => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState<any>({});
  const navigate = useNavigate();
  const [postFeedback, { isSuccess, isError }] = useCreateFeedbackMutation();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const submitToApis = (data: IFeedback) => {
    const body = {
      ...formData,
      ...data,
    };
    postFeedback(body);
    navigate('../');
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Thank You For Submitting Feedback');
    } else if (isError) {
      message.error('Some thing happened wrong');
    }
  }, [isSuccess, isError]);

  const steps = [
    {
      title: 'Feedback Form',
      content: (
        <Feedback_form
          setCurrent={setCurrent}
          current={current}
          setFormData={setFormData}
          formData={formData}
        />
      ),
    },
    {
      title: 'Feedback Survey',
      content: (
        <Feedback_survey
          setFormData={setFormData}
          formData={formData}
          prev={prev}
          submitToApis={submitToApis}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    marginTop: 16,
  };

  return (
    <Row align={'middle'} justify={'center'}>
      <div style={{ height: '100%' }}>
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        <div style={{ marginTop: 24 }}></div>
      </div>
    </Row>
  );
};

export default Feedback;
