import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { ITotal_Due_Advance_AgentDataType } from '../types/Total_Due_Advance_ClientTypes';

const { Text } = Typography;
export const Total_Due_Advance_AgentUtils = (queryData: {
  isTrash: '0' | '1';
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_AgentDataType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },

    {
      title: 'Agent Name',
      dataIndex: 'agent_name',
      key: 'agent_name',
    },
    {
      title: 'Mobile',
      dataIndex: 'agent_mobile',
      key: 'agent_mobile',
    },
    {
      title: 'Commission',
      dataIndex: 'agent_commission_rate',
      key: 'agent_commission_rate',
      align: 'right',
      render: (_, data) => Fixed2(data.agent_commission_rate),
    },
    {
      title: 'Present Due',
      dataIndex: 'agtrxn_lbalance',
      key: 'agtrxn_lbalance',
      align: 'right',
      render: (agtrxn_lbalance) => {
        if (Fixed2(agtrxn_lbalance) < 0)
          return (
            <Text type='danger' strong>
              {Math.abs(agtrxn_lbalance)}
            </Text>
          );
      },
    },
    {
      title: 'Present Advance',
      dataIndex: 'agtrxn_lbalance',
      key: 'agtrxn_lbalance',
      align: 'right',

      render: (agtrxn_lbalance) => {
        if (Fixed2(agtrxn_lbalance) > 0)
          return (
            <Text type='success' strong>
              {agtrxn_lbalance}
            </Text>
          );
      },
    },

    {
      title: 'Agent Actual Balance',
      dataIndex: 'agent_last_balance',
      key: 'agent_last_balance',
      align: 'right',
    },
  ];
};
