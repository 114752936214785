import { message, notification } from 'antd';
import { TypeOpen } from 'antd/es/message/interface';
import type { NotificationPlacement } from 'antd/es/notification/interface';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const toasterNotification = (
  type: NotificationType,
  detail: string,
  placement?: NotificationPlacement
) => {
  message[type](detail);
};
