import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import CostViewSubTotal from '../../../../components/common/Invoice/ViewInvoiceInfo/CostViewSubTotal';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  IViewBillingInfo,
  IViewHajjiInfo,
  IViewInvoiceDetails,
} from '../../../../common/types/commonInterfaces';
import { ITransport_information } from '../Types/InvoiceHajjTypes';
import {
  HotelInformation,
  TicketInfo,
} from '../../../Invoice(Other)/Type/Invoce.other.interface';

//-------invoiceHajjj details column
const pilgrimsColumn: ColumnsType<IViewHajjiInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Passport',
    dataIndex: 'haji_info_passport_id',
    key: 'haji_info_passport_id',
  },
  { title: 'Name', dataIndex: 'hajiinfo_name', key: 'hajiinfo_name' },
  {
    title: 'Mobile',
    dataIndex: 'hajiinfo_mobile',
    key: 'hajiinfo_mobile',
    align: 'right',
  },

  {
    title: 'Date of birth',
    key: 'hajiinfo_dob',
    align: 'right',
    render: (_, data) => (
      <>
        {' '}
        {data?.hajiinfo_dob &&
          dayjs(data?.hajiinfo_dob).format('D-MM-YYYY')}{' '}
      </>
    ),
  },

  {
    title: 'Tracking',
    dataIndex: 'hajiinfo_tracking_number',
    key: 'hajiinfo_tracking_number',
    align: 'right',
  },
  {
    title: 'Serial',
    dataIndex: 'hajiinfo_serial',
    key: 'hajiinfo_serial',
    align: 'right',
  },
];

const ticketColumn: ColumnsType<TicketInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Ticket No',
    dataIndex: 'ticket_no',
    key: 'ticket_no',
  },
  { title: 'PNR', dataIndex: 'ticket_pnr', key: 'ticket_pnr' },
  {
    title: 'Route',
    dataIndex: 'airline_iata_code',
    key: 'airline_iata_code',
  },
  {
    title: 'Airline',
    dataIndex: 'airline_name',
    key: 'airline_name',
  },
  {
    title: 'Reference No.',
    dataIndex: 'ticket_reference_no',
    key: 'ticket_reference_no',
  },
  {
    title: 'Journey date',
    key: 'ticket_journey_date',
    render: (_, data) => (
      <>
        {' '}
        {data?.ticket_journey_date &&
          dayjs(data?.ticket_journey_date).format('D-MM-YYYY')}{' '}
      </>
    ),
  },
  {
    title: 'Return date',
    key: 'ticket_return_date',
    render: (_, data) => (
      <>
        {' '}
        {data?.ticket_return_date &&
          dayjs(data?.ticket_return_date).format('D-MM-YYYY')}{' '}
      </>
    ),
  },
];
const hotelColumn: ColumnsType<HotelInformation> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Hotel Name',
    dataIndex: 'hotel_name',
    key: 'hotel_name',
    align: 'center',
  },
  {
    title: 'Check In Date',
    dataIndex: 'hotel_check_in_date',
    key: 'hotel_check_in_date',
    align: 'center',
    render: (_, data) => dayjs(data.hotel_check_in_date).format('DD-MM-YYYY'),
  },
  {
    title: 'Check Out Date',
    dataIndex: 'hotel_check_out_date',
    key: 'hotel_check_out_date',
    align: 'center',
    render: (_, data) => dayjs(data.hotel_check_out_date).format('DD-MM-YYYY'),
  },
  {
    title: 'Room Type',
    dataIndex: 'hotel_room_type_id',
    key: 'hotel_room_type_id',
    align: 'center',
  },
];
const transportColumn: ColumnsType<ITransport_information> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Transport type',
    dataIndex: 'ttype_name',
    key: 'ttype_name',
    align: 'center',
  },
  {
    title: 'Pickup place',
    dataIndex: 'transport_pickup_place',
    key: 'transport_pickup_place',
    align: 'center',
  },
  {
    title: 'Pickup time',
    dataIndex: 'transport_pickup_time',
    key: 'transport_pickup_time',
    align: 'center',
  },
  {
    title: 'Drop off place',
    dataIndex: 'transport_dropoff_place',
    key: 'transport_dropoff_place',
    align: 'center',
  },
  {
    title: 'Drop off time',
    dataIndex: 'transport_dropoff_time',
    key: 'transport_dropoff_time',
    align: 'center',
  },
];
const billingInfoColumn: ColumnsType<IViewBillingInfo> = [
  {
    title: 'SL.',
    render: (_, data, index) => <> {index + 1} </>,
  },
  {
    title: 'Name',
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
  },
  {
    title: 'Quantity',
    dataIndex: 'billing_quantity',
    key: 'billing_quantity',
    align: 'right',
  },
  {
    title: 'Unit price',
    dataIndex: 'billing_unit_price',
    key: 'billing_unit_price',
    width: '150px',
    align: 'right',
  },
  {
    title: 'Cost price',
    dataIndex: 'billing_cost_price',
    key: 'billing_cost_price',
    width: '150px',
    align: 'right',
  },
  {
    title: 'Sub total',
    dataIndex: 'billing_subtotal',
    key: 'billing_subtotal',
    width: '120px',
    align: 'right',
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    align: 'center',
  },
];

type Props = {
  invoiceDetails: IViewInvoiceDetails;
};
const ViewInvoiceHajjDetails = ({ invoiceDetails }: Props) => {
  const { haji_information, ticket_information, billing_information } =
    invoiceDetails;

  return (
    <>
      {haji_information?.length ? (
        <>
          <FormHeaderTitle title='Pilgrims Information' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.hajiinfo_tracking_number}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={haji_information}
            columns={pilgrimsColumn}
            pagination={false}
          />
        </>
      ) : (
        ''
      )}
      {ticket_information?.length ? (
        <>
          <FormHeaderTitle title='Ticket Information' />
          <Table
            size='small'
            bordered
            rowKey={(e) => e.ticket_no as string}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={ticket_information}
            columns={ticketColumn}
            pagination={false}
          />
        </>
      ) : (
        ''
      )}
      {invoiceDetails?.transport_information.filter(
        (item) => item?.transport_type_id
      ).length ? (
        <>
          <FormHeaderTitle title='Transport Information' />
          <Table
            size='small'
            bordered
            // rowKey={(e) => e}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.transport_information}
            columns={transportColumn}
            pagination={false}
          />
        </>
      ) : (
        <></>
      )}
      {invoiceDetails?.hotel_information?.length ? (
        <>
          <FormHeaderTitle title='Hotel Information' />
          <Table
            size='small'
            bordered
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={invoiceDetails?.hotel_information}
            columns={hotelColumn}
            pagination={false}
          />
        </>
      ) : (
        ''
      )}
      {billing_information?.length ? (
        <>
          <FormHeaderTitle title='Billing Information' />
          <Table
            size='small'
            bordered
            rowKey={(e) => 1}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={billing_information}
            columns={billingInfoColumn}
            pagination={false}
          />
        </>
      ) : (
        ''
      )}

      <CostViewSubTotal invoice_info={invoiceDetails} />
    </>
  );
};

export default ViewInvoiceHajjDetails;
