import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormListAddRemove from '../../../../common/utils/FormListAddRemove';
import AccommodationForm from './AccommodationForm';
import { IInvoiceTourAccom } from '../../Types/InvoiceTourTypes';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';

type Props = {
  form: FormInstance<any>;
  invComClientInfo?: string;
  tourAccom?: IInvoiceTourAccom[] | undefined;
};

const InvoiceTourAccommodationForm = ({
  form,
  invComClientInfo,
  tourAccom,
}: Props) => {
  return (
    <Form.List
      name='tourAccms'
      initialValue={[{}]}
    >
      {(fields, { add, remove }) =>
        fields.map((field, index) => {
          return (
            <Row
              gutter={[10, 0]}
              key={index}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Col md={23}>
                <AccommodationForm
                  form={form}
                  field={field}
                  invComClientInfo={invComClientInfo}
                  index={index}
                />
              </Col>

              <Col md={1}>
                <BillingIsDeleted name={[field.name, 'accm_is_deleted']} />
                <FormListAddRemove
                  add={add}
                  index={index}
                  name={field.name}
                  remove={remove}
                  dataLength={tourAccom?.length}
                  form={form}
                  NamePath={['tourAccms', index, 'accm_is_deleted']}
                />
              </Col>
            </Row>
          );
        })
      }
    </Form.List>
  );
};

export default InvoiceTourAccommodationForm;
