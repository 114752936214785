import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useEditClientToClientMutation,
  useGetClienttoClientForEditQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import ClientToClientForm from '../../Components/ClientToClientCom/ClientToClientForm';
import { IPosthajjiManagement } from '../../Types/HajjiManagement.interface';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};

const EditClienttoClient = (props: Props) => {
  const [form] = Form.useForm();
  const id = Number(useParams().id);
  const user = useSelector(selectUser);
  const trackingNo = useWatch(['ctransfer_combclient_from'], form);

  //   =========== get previous data
  const { data: dataForEdit } = useGetClienttoClientForEditQuery(id);
  const previousData = dataForEdit?.data;

  //   =============== set form value

  useEffect(() => {
    form.setFieldsValue({
      ctransfer_charge: Fixed2(previousData?.ctransfer_charge),
      ctransfer_combclient_from: previousData?.ctransfer_combclient_from,
      ctransfer_combclient_to: previousData?.ctransfer_combclient_to,
      ctransfer_job_name: previousData?.ctransfer_job_name,
      ctransfer_note: previousData?.ctransfer_note,
      ctransfer_tracking_no: previousData?.ctransfer_tracking_no,
      ctrcknumber_number: previousData?.ctrcknumber_number?.map((item) =>
        Number(item)
      ),
    });
  }, [previousData]);

  // =========== form submit
  const [editHajjiManagement, { isError, isSuccess, isLoading }] =
    useEditClientToClientMutation();
  const onFinish = (values: IPosthajjiManagement) => {
    if (!trackingNo?.length) {
      return toasterNotification(
        'error',
        'Pleace select a hajji passport by tracking number'
      );
    }

    values.ctransfer_created_by = user?.user_id;

    editHajjiManagement({ body: values, id });
  };

  // =========== nevigate to view client to client
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      toasterNotification('success', 'Hajji management updated successfully!');
      navigate('/haji/transfer/client_list');
    }
    if (isError) {
      toasterNotification('error', 'Thare was an error');
    }
  }, [isLoading]);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Update Transfer-In Module (Client 2 client)']}
      />
      {previousData?.ctrcknumber_number ? (
        <ClientToClientForm
          form={form}
          onFinish={onFinish}
          previousTrackNo={previousData?.ctrcknumber_number}
          isLoading={isLoading}
        />
      ) : (
        loadingIndicator
      )}
    </div>
  );
};

export default EditClienttoClient;
