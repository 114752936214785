import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../../../common/utils/common.utils';
import { iconStyle } from '../../../../layout/pages/Sidebar';
import { useBspConsolidateDetailsQuery } from '../../Api/Endpoints/DashboardEndpoints';
import { IConsolidateDetails } from '../../interfaces/dashboard.interfaces';
import DashboardTitle from '../DashboardTitle';

type Props = {
  bsp_period: string;
  setBspPeriod?: React.Dispatch<React.SetStateAction<string>>;
  bspPeriod?: string;
};

const ConsolidateRemittanceModal = ({
  bsp_period,
  bspPeriod,
  setBspPeriod,
}: Props) => {
  const { data, isLoading } = useBspConsolidateDetailsQuery(
    {
      period: bsp_period,
    },
    { skip: bsp_period ? false : true }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const total = data?.data?.reduce((prev, curr) => {
    prev += Number(curr.payment_amount);
    return prev;
  }, 0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setBspPeriod && setBspPeriod('');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setBspPeriod && setBspPeriod('');
  };

  useEffect(() => {
    if (bspPeriod) showModal();
  }, [bspPeriod]);

  const columns: ColumnsType<IConsolidateDetails> = [
    {
      title: 'Payment Date',
      dataIndex: 'payment_date',
      key: 'payment_date',
      align: 'center',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Voucher No',
      dataIndex: 'vouchar_no',
      key: 'vouchar_no',
      align: 'center',
      render: (curr, data) => (
        <Link to={`/vendors/payment/${data.vpay_id}`}>{curr}</Link>
      ),
    },

    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Payment By',
      dataIndex: 'payment_by',
      key: 'payment_by',
      render: (text: string | null) => (text ? text : 'N/A'),
    },
    {
      title: 'Prepared By',
      dataIndex: 'prepared_by',
      key: 'prepared_by',
    },
    {
      align: 'right',
      title: 'Payment Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (curr) => <>{formatAmount(curr)}</>,
    },
  ];

  return (
    <div>
      <FontAwesomeIcon
        onClick={showModal}
        style={{
          ...iconStyle,
          border: '1px solid lightgrey',
          padding: '5px',
          borderRadius: '100%',
          fontSize: '16px',
          cursor: 'pointer',
          display: setBspPeriod ? 'none' : 'block',
        }}
        icon={faPaperclip}
      />
      <Modal
        loading={isLoading}
        title={
          <DashboardTitle
            title={`PAYMENT DETAILS ${bsp_period}`}
            link='/vendors/payment'
          />
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
        width={1000}
      >
        <Table
          columns={columns}
          dataSource={data?.data}
          rowKey='vpay_id'
          pagination={false}
          size='small'
          summary={() => (
            <>
              <Table.Summary.Row className='bold'>
                <Table.Summary.Cell index={0} colSpan={5}>
                  TOTAL
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div className='align-right'>{formatAmount(total)}</div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />
      </Modal>
    </div>
  );
};

export default ConsolidateRemittanceModal;
