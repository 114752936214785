import { Button, Form, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../auth/states/userSlice';
import { useAddClientMutation } from '../../modules/Client/Client/api/endpoints/clientEndpoints';
import Client_Add_Edit from '../../modules/Client/Client/components/common/Client_Add_Edit';
import { IClientAddFormData } from '../../modules/Client/Client/types/clientInterfaces';

type Props = {
  setClick: (arg: boolean) => void;
  setInstanceClientSelect?:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
};

const CreateClientModal = ({ setClick, setInstanceClientSelect }: Props) => {
  const [modal1Open, setModal1Open] = useState(false);

  const user = useSelector(selectUser);

  //   submit form
  const [form] = Form.useForm();
  const [
    addClient,
    { isError, isSuccess, data: clientCreate, isLoading, error },
  ] = useAddClientMutation();

  useEffect(() => {
    if (clientCreate) {
      setInstanceClientSelect &&
        setInstanceClientSelect(clientCreate?.data?.client_id);
    }
  }, [isSuccess]);

  const handle = async (values: IClientAddFormData) => {
    const body = {
      values,
      user_full_name: user?.user_full_name,
      email: values.email || undefined,
    };
    body.values.client_created_by = user?.user_id as number;
    await addClient(body);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setModal1Open(false);
    } else if (isError) {
      message.error('Thare was an error');
    }
  }, [isLoading]);

  return (
    <>
      <Button
        onClick={() => {
          setModal1Open(true);
          setClick(true);
        }}
        loading={isLoading}
        style={{ width: '100%' }}
      >
        Add Client
      </Button>
      <Modal
        forceRender
        title='Add Client Information'
        width={1000}
        style={{ top: 20 }}
        open={modal1Open}
        onOk={(e) => {
          form.submit();
          setClick(false);
        }}
        confirmLoading={isLoading}
        onCancel={() => {
          setModal1Open(false);
          setClick(false);
        }}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={handle}
        >
          <Client_Add_Edit form={form} />
        </Form>
      </Modal>
    </>
  );
};

export default CreateClientModal;
