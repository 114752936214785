import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { CLIENTDISCOUNT } from '../../Api/constants';
import { IClientDiscountDataType } from '../types/ClientDiscountTypes';

export const ClientDiscountEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all ClientDiscount
    getAllClientDiscount: build.query<
      HTTPResponse<IClientDiscountDataType[]>,
      {
        invoice_combclient_id: string;
        query: string;
      }
    >({
      query: ({ query, invoice_combclient_id }) => ({
        url: `/report/client-discount/${invoice_combclient_id}${query}`,
      }),
      providesTags: [{ type: 'ClientDiscount', id: CLIENTDISCOUNT }],
    }),
  }),
});

export const { useLazyGetAllClientDiscountQuery } = ClientDiscountEndpoints;
