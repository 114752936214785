import { Col, Divider, Form, FormInstance, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { NamePath } from 'antd/es/form/interface';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectAgent,
  SelectClients,
  SelectEmployee,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { useLazyGetClLastBalanceQuery } from '../../Client/Client/api/endpoints/clientEndpoints';
import { useGetAllGroupsQuery } from '../../Configuration/group/endpoints/groupEndpoints';
import HajjiGroupAddModal from '../../Invoice_Hajj_Pre_Reg/Modals/HajjiGroupAddModal';

type Props = {
  invoice_net_totalFieldName: NamePath;
  invoice_agent_com_amountFieldName: NamePath;
  form: FormInstance<any>;
};

export default function NewAddUmrahFormTop({
  form,
  invoice_agent_com_amountFieldName,
  invoice_net_totalFieldName,
}: Props) {
  /* set client Name Instance when create */
  const [instanceClientsSelect, setInstanceClientSelect] = useState<number>();
  const [instanceGroupSelect, setInstanceGroupSelect] = useState<number>();
  const [instanceSalesEmpSelect, setInstanceSalesEmpSelect] =
    useState<number>();
  const [instanceAgentSelect, setInstanceAgentSelect] = useState<number>();
  useEffect(() => {
    if (instanceClientsSelect) {
      form.setFieldValue(
        'invoice_combclient_id',
        'client-' + instanceClientsSelect
      );
    } else if (instanceAgentSelect) {
      form.setFieldValue('invoice_agent_id', instanceAgentSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_sales_man_id', instanceSalesEmpSelect);
    } else if (instanceSalesEmpSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceSalesEmpSelect);
    } else if (instanceGroupSelect) {
      form.setFieldValue('invoice_haji_group_id', instanceGroupSelect);
    }
  }, [
    instanceClientsSelect,
    instanceAgentSelect,
    instanceSalesEmpSelect,
    instanceGroupSelect,
  ]);
  const [click, setClick] = useState<boolean>();
  const { data: groups } = useGetAllGroupsQuery();
  const invoice_combclient_id = useWatch(['invoice_combclient_id'], form);

  const selectGroup = groups?.data?.filter((el) => el.group_type === 'UMRAH');

  const groupChildrens: React.ReactNode[] = [];
  if (selectGroup) {
    for (let i = 0; i < selectGroup.length; i++) {
      groupChildrens.push(
        <Select.Option
          key={
            selectGroup[i].group_name + '  ' + selectGroup[i].group_id + ' ' + i
          }
          value={selectGroup[i].group_id}
        >
          {selectGroup[i].group_name}
        </Select.Option>
      );
    }
  }

  // client previous due
  const [clientLastBalance, { data: clientLBlance, isFetching }] =
    useLazyGetClLastBalanceQuery();

  useEffect(() => {
    if (invoice_combclient_id !== undefined) {
      clientLastBalance(invoice_combclient_id);
    }
  }, [invoice_combclient_id]);
  useEffect(() => {
    form?.setFieldsValue({
      invoice_client_previous_due: clientLBlance?.data?.client_prev_due,
      client_present_balance: clientLBlance?.data?.client_last_balance,
    });
  }, [isFetching]);
  const { id } = useParams();
  return (
    <>
      <Row gutter={[10, 10]} className='border p-5 my-20' align='middle'>
        <SelectClients
          name='invoice_combclient_id'
          label='Select Client :'
          required
          disabled={id ? true : false}
          placeholder='Select client'
          size={4}
          mdSize={8}
          smSize={12}
          setInstanceClientSelect={setInstanceClientSelect}
        />

        <SelectEmployee
          label='Sales By:'
          name='invoice_sales_man_id'
          required
          size={4}
          setInstanceSalesEmpSelect={setInstanceSalesEmpSelect}
        />

        <Col xs={24} sm={12} md={8} lg={4}>
          <Form.Item name='invoice_haji_group_id' label='Group Name'>
            <Select
              allowClear
              placeholder={'Select Group'}
              showSearch
              optionFilterProp='roleMobile'
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  <HajjiGroupAddModal
                    setClick={setClick}
                    setInstanceGroupSelect={setInstanceGroupSelect}
                  />
                  <Divider style={{ margin: '8px 0' }} />
                  {menu}
                </>
              )}
              open={click === true ? false : undefined}
            >
              {groupChildrens}
            </Select>
          </Form.Item>
        </Col>

        <FormInputItem
          label='Invoice No:'
          name='invoice_no'
          required
          readOnly
          size={3}
        />
        <DateInput
          label='Sales Date:'
          name='invoice_sales_date'
          required
          size={4}
          disableFeatureDate
        />
        <DateInput label='Due Date:' name='invoice_due_date' size={2} />
        <SelectAgent
          label='Select Agent:'
          name={'invoice_agent_id'}
          isPercent
          size={3}
          setInstanceAgentSelect={setInstanceAgentSelect}
        />
      </Row>
    </>
  );
}
