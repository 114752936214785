import { api } from '../../../../../app/baseQuery';
import { IPaginationSD } from '../../../../../common/types/commonInterfaces';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import { AGENTS_LEDGER } from '../../../../Reports/Api/constants';
import {
  IAgentMoneyReceiptByID,
  IAgentMoneyReceiptDataType,
  IAgentMoneySingleDataTypes,
  IAllInvocieType,
  IPostAgentMoneyReceiptType,
  ISingleAgentType,
} from '../../types/AgentMoneyReceiptTypes';

export const AgentMoneyReceiptEndpoint = api.injectEndpoints({
  endpoints: (build) => ({
    //get all invoice by client ID
    getAllAgentdByID: build.query<
      HTTPResponse<IAgentMoneyReceiptByID[]>,
      number
    >({
      query: (id) => ({ url: `money-receipt/agent-invoice/${id}` }),
      providesTags: [{ type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' }],
    }),

    getAgentByInvoiceID: build.query<HTTPResponse<ISingleAgentType>, number>({
      query: (id) => ({ url: `money-receipt/agent-invoices/${id}` }),
      providesTags: [{ type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' }],
    }),

    //get invoice by search
    getInvoiceBySearch: build.query<HTTPResponse<IAllInvocieType[]>, string>({
      query: (search) => ({
        url: `/money-receipt/agent-invoices?search=${search ? search : ''}`,
      }),
    }),

    getSingleAgentMoneyReceipt: build.query<
      HTTPResponse<IAgentMoneySingleDataTypes>,
      number
    >({
      query: (id) => ({
        url: `/money-receipt/agents-commission-by-recept-id/${id}`,
      }),
      providesTags: [{ type: 'AGENT_RECEIPT' }],
    }),

    //get all money receipt
    getAllAgentMoneyReceipt: build.query<
      HTTPResponse<IAgentMoneyReceiptDataType[]>,
      IPaginationSD
    >({
      query: (query) => ({
        url: `money-receipt/agent-commission?page=${query.current}&size=${query.pageSize}&from_date=${query.from}&to_date=${query.to}&search=${query.search}`,
      }),
      providesTags: [{ type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' }],
    }),

    //post invoice Money Receipt
    postAgentMoneyReceipt: build.mutation<
      HTTPResponse<any>,
      IPostAgentMoneyReceiptType
    >({
      query: (body) => ({
        url: `/money-receipt/agent-money-receipt`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [
        { type: 'AGENT_RECEIPT', id: 'Agent_Money_receipt' },
        AGENTS_LEDGER,
      ],
    }),
  }),
});

export const {
  useGetSingleAgentMoneyReceiptQuery,
  useLazyGetSingleAgentMoneyReceiptQuery,
  useGetAllAgentdByIDQuery,
  useLazyGetAllAgentdByIDQuery,
  usePostAgentMoneyReceiptMutation,
  useGetAllAgentMoneyReceiptQuery,
  useLazyGetAllAgentMoneyReceiptQuery,
  useLazyGetAgentByInvoiceIDQuery,
  useLazyGetInvoiceBySearchQuery, //Get invoice by search
} = AgentMoneyReceiptEndpoint;
