import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { perProps } from '../../../../common/types/commonTypes';
import {
  DateInput,
  FormButton,
  FormInput,
  MobileInput,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import { SelectOptionsInput } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useCreateAgentProfileMutation } from '../api/endpoints/agentProfileEndPoints';
import { IAgentProfileCategory } from '../types/agentProfileTypes';
import FileUpload from '../../../../common/utils/FileUpload';

type Props = {};

const AddAgentProfile = ({ permission }: perProps) => {
  const [openingType, setOpeningType] = useState<string>('None');
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const [agentImg, setAgentImg] = useState('');
  const [nidFront, setNidFront] = useState('');
  const [nidBack, setNidBack] = useState('');

  const [postCreate, { isLoading, isSuccess }] =
    useCreateAgentProfileMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate('../agents/profile');
    }
  }, [isSuccess]);

  const [form] = useForm();

  const handleSubmit = (values: IAgentProfileCategory) => {
    const { agent_image_copy, agent_nid_front, agent_nid_back, ...others } =
      values;

    const formData = new FormData();
    for (const key in others) {
      const value = values[key as keyof IAgentProfileCategory];

      if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    }

    //file inputs for images
    formData.append('agent_image_copy', agentImg);
    formData.append('agent_nid_front', nidFront);
    formData.append('agent_nid_back', nidBack);

    user?.user_id && formData.append('agent_created_by', String(user?.user_id));

    postCreate(formData);
  };
  return (
    <>
      {isLoading && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Agent Profile', 'Create Agent']} />

      <Col lg={8} style={{ marginBottom: '1rem' }}>
        <Link to='../agents/profile'>
          <Button type='primary' icon={<ArrowLeftOutlined />}>
            Return to Recruit Agent List
          </Button>
        </Link>
      </Col>
      <Form
        form={form}
        labelAlign='left'
        layout='vertical'
        onFinish={handleSubmit}
        onFinishFailed={(value) => {
          message.error(
            `Enter all required (${value.errorFields.length}) Fields`
          );
        }}
      >
        <div className='border p-5 mt-10'>
          <Row gutter={5}>
            <FormInput
              placeholder='Agent Name'
              name={'agent_name'}
              label='Agent Name'
              size={6}
              required
            />

            <FormInput
              placeholder='Email'
              name={'agent_email'}
              label='Email'
              size={6}
            />

            <NumberInput
              name='agent_commission_rate'
              label='Commision Rate'
              size={6}
              required
            />

            <MobileInput
              label='Phone No'
              name='agent_mobile'
              size={6}
              rules={[
                {
                  message: 'Invalid number format',
                  validator: (_, value) => {
                    if (
                      !value ||
                      (/^\+?[0-9+]+$/.test(value) &&
                        value.length >= 10 &&
                        value.length <= 16)
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        'Invalid number format. Must be between 10 and 16 characters.'
                      );
                    }
                  },
                },
              ]}
            />

            <FormInput
              placeholder='Address'
              name={'agent_address'}
              label='Agent Address'
              size={6}
            />

            <FormInput
              placeholder='NID No'
              name={'agent_nid_no'}
              label='NID No'
              size={6}
            />

            <FileUpload
              size={6}
              msg=' Agent Photo'
              name='agent_image_copy'
              label='Agent Image'
              onChange={(img) => setAgentImg(img[0]?.originFileObj)}
            />

            <FileUpload
              size={6}
              msg=' Upload NID Front'
              name='agent_nid_front'
              label='Agent ID Card Font Side'
              onChange={(img) => setNidFront(img[0]?.originFileObj)}
            />

            <FileUpload
              size={6}
              msg=' Upload NID Back'
              name='agent_nid_back'
              label='Agent ID Card Back Side'
              onChange={(img) => setNidBack(img[0]?.originFileObj)}
            />

            <DateInput
              name={'agent_date_of_birth'}
              label='Date of birth'
              size={6}
            />

            <SelectOptionsInput
              defaultValue={'None'}
              name={'agent_op_balance_type'}
              label='Opening Balance Type: '
              options={['Due', 'Advance']}
              size={6}
              onSelect={(value: string) => setOpeningType(value)}
              setOpeningType={setOpeningType}
            />

            {(openingType === 'Advance' || openingType === 'Due') && (
              <FormInput
                placeholder='Opening Balance'
                name='agent_op_amount'
                label='Agent Opening Balance'
                size={6}
              />
            )}
          </Row>
          <FormButton label='Create Agent' loading={isLoading} />
        </div>
      </Form>
    </>
  );
};

export default AddAgentProfile;
