import { Card, Col, Row, Skeleton } from 'antd';
import React from 'react';

const ReportCardSkeleton = () => {
  return (
    <Col xl={24} md={24}>
      <Row gutter={[10, 10]}>
        <Col xl={8} md={12} sm={12} xs={24}>
          <Card>
            <Skeleton active paragraph={{ rows: 5 }} />
          </Card>
        </Col>
        <Col xl={8} md={12} sm={12} xs={24}>
          <Card>
            <Skeleton active paragraph={{ rows: 5 }} />
          </Card>
        </Col>
        <Col xl={8} md={12} sm={12} xs={24}>
          <Card>
            <Skeleton active paragraph={{ rows: 5 }} />
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default ReportCardSkeleton;
