import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInput,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectOptionsInput,
  SelectPassport,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { IHajjPreList } from '../../../Invoice(Visa)/Type/invoiceVisa.interface';
import { useCheckTrackingAndSerialMutation } from '../../../Invoice_Hajj_Pre_Reg/API/Endpoints/invoiceHajjPreRegEndspoint';
import { useLazyGetSinglePassportQuery } from '../../../Passport_MGT/Api/Endpoints/PassportEndpoints';
import AddTicketInfoHajj from '../Modals/AddTicketInfoHajj';
import Tracking_No from './Tracking_No';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';
import { useWatch } from 'antd/es/form/Form';
import { useParams } from 'react-router';
import { InvoiceHajjType } from '../Types/InvoiceHajjTypes';

type Props = {
  name: number;
  form: FormInstance<any>;
  index: number;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  edit_data?: InvoiceHajjType;
};

export default function HajjPassengerInfoList({
  name,
  form,
  index,
  add,
  remove,
  edit_data,
}: Props) {
  const [info, setInfo] = useState<IHajjPreList>();
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const [passPortId, setPassPortId] = useState<number>(0);
  const ids = Form.useWatch(
    ['pilgrims_information', name, 'haji_info_passport_id'],
    form
  );

  const [instancePassportSelect, setInstancPassportSelect] = useState<number>();
  const [getSinglePassport, { data: singlePassport }] =
    useLazyGetSinglePassportQuery();
  useEffect(() => {
    if (ids) getSinglePassport(ids, true);
  }, [passPortId, instancePassportSelect, ids]);

  // set form value
  useEffect(() => {
    if (info) {
      form.setFields([
        {
          name: ['pilgrims_information', name, 'hajiinfo_name'],
          value: info?.hajiinfo_name || undefined,
        },
        {
          name: ['pilgrims_information', name, 'hajiinfo_mobile'],
          value: info?.hajiinfo_mobile || undefined,
        },
        {
          name: ['pilgrims_information', name, 'hajiinfo_dob'],
          value: dayjs(info?.hajiinfo_dob) || undefined,
        },

        {
          name: ['pilgrims_information', name, 'hajiinfo_gender'],
          value: info?.hajiinfo_gender || undefined,
        },

        {
          name: ['pilgrims_information', name, 'hajiinfo_serial'],
          value: info?.hajiinfo_serial || undefined,
        },
      ]);
    } else if (singlePassport?.data) {
      form.setFields([
        {
          name: ['pilgrims_information', name, 'hajiinfo_name'],
          value: singlePassport?.data?.name,
        },
        {
          name: ['pilgrims_information', name, 'hajiinfo_mobile'],
          value: singlePassport?.data?.mobile_no
            ? singlePassport?.data?.mobile_no.split('-')[1] ||
              singlePassport?.data?.mobile_no
            : undefined,
        },
        {
          name: ['pilgrims_information', name, 'hajiinfo_dob'],
          value: singlePassport?.data?.date_of_birth
            ? dayjs(singlePassport?.data?.date_of_birth)
            : undefined,
        },
        {
          name: ['pilgrims_information', name, 'hajiinfo_nid'],
          value: singlePassport?.data?.passport_nid_no || undefined,
        },
      ]);
    }
  }, [singlePassport?.data, info]);

  // ============= tracking no ==================
  const [checkValue, setCheckValue] = useState<string>();
  const [checkedValue, { data }] = useCheckTrackingAndSerialMutation();
  useEffect(() => {
    const timerOut = setTimeout(() => {
      checkValue && checkedValue({ data_for: 'tracking', value: checkValue });
    }, 800);
    return () => clearTimeout(timerOut);
  }, [checkValue]);

  // ============= serial no =============
  const [checkSerialNo, setCheckSerialNo] = useState<string>();
  const [checkedSerialNo, { data: serialNo }] =
    useCheckTrackingAndSerialMutation();
  useEffect(() => {
    const timerOut = setTimeout(() => {
      checkSerialNo &&
        checkedSerialNo({ data_for: 'serial', value: checkSerialNo });
    }, 800);
    return () => clearTimeout(timerOut);
  }, [checkSerialNo]);

  useEffect(() => {
    form.setFields([
      {
        name: ['pilgrims_information', name, 'haji_info_passport_id'],
        value: instancePassportSelect,
      },
    ]);
  }, [instancePassportSelect]);

  const is_deleted: 0 | 1 = useWatch(
    ['pilgrims_information', index, 'is_deleted'],
    form
  );

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: '#FFC0CB' } : {}}
      gutter={[5, 5]}
    >
      <Tracking_No
        size={3}
        required
        name={[name, 'hajiinfo_tracking_number']}
        fullNamePath={[
          'pilgrims_information',
          name,
          'hajiinfo_tracking_number',
        ]}
        form={form}
        label='Tracking No'
        validateStatus={data?.data}
        help='Already exist.'
        disabled={disabled}
        rules={[
          {
            validator(rule, value, callback) {
              const fullList = form.getFieldValue(['pilgrims_information']);

              const filtered = fullList.filter((item: any, i: any) => {
                if (value) {
                  if (item.hajiinfo_tracking_number === value) {
                    if (index === i) {
                      return;
                    }
                    return item;
                  }
                }
              });

              if (filtered.length) {
                return Promise.reject('Cannot select duplicate tracking Num!!');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      />

      <FormInputItem
        size={3}
        name={[name, 'hajiinfo_serial']}
        label='Serial No'
        onChange={(value) => setCheckSerialNo(value.toString())}
        validateStatus={serialNo?.data}
        help='Already exist.'
        disabled={disabled}
        rules={[
          {
            validator(rule, value, callback) {
              const fullList = form.getFieldValue(['pilgrims_information']);
              const filtered = fullList.filter((item: any, i: any) => {
                if (value) {
                  if (item.hajiinfo_serial === value) {
                    if (index === i) {
                      return;
                    }
                    return item;
                  }
                }
              });

              if (filtered.length) {
                return Promise.reject('Cannot select duplicate Ticket Num!!');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      />

      <SelectPassport
        name={[name, 'haji_info_passport_id']}
        label='Passport No'
        setPassPortIds={setPassPortId}
        setInstancPassportSelect={setInstancPassportSelect}
        disabled={disabled}
      />

      <FormInputItem
        name={[name, 'hajiinfo_name']}
        label='Name'
        size={3}
        readOnly
        disabled={disabled}
      />

      <FormInput
        label='Mobile:'
        number
        size={3}
        name={[name, 'hajiinfo_mobile']}
        readonly
        disabled={disabled}
      />
      <DateInput
        name={[name, 'hajiinfo_dob']}
        label='Date of Birth'
        size={2}
        readOnly
        disabled={disabled}
      />

      <SelectOptionsInput
        name={[name, 'hajiinfo_gender']}
        options={['MALE', 'FEMALE']}
        size={2}
        label={'Gender'}
      />

      <FormInputItem
        size={2}
        name={[name, 'hajiinfo_nid']}
        label='NID'
        readOnly
        disabled={disabled}
      />

      <Col lg={2} style={{ display: 'flex', justifyContent: 'center' }}>
        <Form.Item label={' '}>
          <AddTicketInfoHajj
            form={form}
            index={index}
            name={name}
            disabled={disabled}
          />
        </Form.Item>
      </Col>

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[name, 'is_deleted']} />

      {name === 0 ? (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button type='primary' onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={' '}>
            <Button
              type='primary'
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  'pilgrims_information',
                  name,
                  'is_deleted',
                ]);
                form.setFieldValue(
                  ['pilgrims_information', name, 'is_deleted'],
                  Number(!prev)
                );
                if (id) {
                  if (
                    edit_data?.pilgrims_information?.length &&
                    index >= edit_data?.pilgrims_information?.length
                  )
                    remove(name);
                } else {
                  remove(name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}
