import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { HEADWISEEXPENSEREPORT } from '../../Api/constants';
import { IHeadWiseExpenseReportDataType } from '../types/headWiseExpenseReportTypes';

export const HeadWiseExpenseReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all HeadWiseExpenseReport
    getAllHeadWiseExpenseReport: build.query<
      HTTPResponse<IHeadWiseExpenseReportDataType[]>,
      {
        head_expense: string;
        query: string;
      }
    >({
      query: ({ head_expense, query }) => ({
        url: `/report/head-wise-expense-report/${head_expense}${query}`,
      }),
      providesTags: [
        { type: 'HeadWiseExpenseReport', id: HEADWISEEXPENSEREPORT },
      ],
    }),
  }),
});

export const { useLazyGetAllHeadWiseExpenseReportQuery } =
  HeadWiseExpenseReportEndpoints;
