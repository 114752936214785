import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

import {
  ClientCategoryDataType,
  IClientCatWithPag,
  IClientCategoryAddInfo,
  IClientCategoryEditInfo,
} from '../types/clientCategoryTypes';

const client_category = 'client_category';
export const clientCategoryEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * get all client Category
     *
     */
    getAllClientCategory: build.query<
      HTTPResponse<ClientCategoryDataType[]>,
      void
    >({
      query: () => ({ url: '/configuration/client-category/get-all' }),
      providesTags: () => [{ type: 'ClientCategory', id: client_category }],
    }),

    getClientCategoryWithPagination: build.query<
      HTTPResponse<IClientCatWithPag>,
      { current: number; pageSize: number; is_deleted: number }
    >({
      query: (arg) => ({
        url: `configuration/client-category?page=${arg.current}&size=${
          arg.pageSize
        }&is_deleted=${arg.is_deleted || 0}`,
      }),
      providesTags: () => [{ type: 'ClientCategory', id: client_category }],
    }),

    //create client category
    createClientCategory: build.mutation<void, IClientCategoryAddInfo>({
      query: (body) => ({
        url: `/configuration/client-category/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ClientCategory', id: client_category }],
    }),
    //Edit Client Category
    editClientCategory: build.mutation<void, IClientCategoryEditInfo>({
      query: (body) => ({
        url: `/configuration/client-category/update/${body.category_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ClientCategory', id: client_category }],
    }),

    //delete client Category

    deleteClientCategory: build.mutation<
      void,
      { body: ClientCategoryDataType; is_delete: 0 | 1 }
    >({
      query: ({ body, is_delete }) => ({
        url: `/configuration/client-category/delete/${
          body.category_id
        }?is_delete=${is_delete || 0}`,
        method: 'DELETE',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'ClientCategory', id: client_category }],
    }),
  }),
});
export const {
  useGetClientCategoryWithPaginationQuery,
  useGetAllClientCategoryQuery,
  useCreateClientCategoryMutation,
  useEditClientCategoryMutation,
  useDeleteClientCategoryMutation,
} = clientCategoryEndpoints;
