import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import { IPassport_info } from '../PassportTypes/PassportTypes';
import PassportInfo from './PassportInfo';

type Props = {
  form: FormInstance<any>;
  passportInitialValues?: IPassport_info[];
  useAsModal: boolean | undefined;
};

const PassportInfoFormList = ({
  passportInitialValues,
  form,
  useAsModal,
}: Props) => {
  const initialValues: IPassport_info[] = [];

  passportInitialValues?.forEach((item) => {
    initialValues.push({
      ...item,
      date_of_birth: item.date_of_birth && dayjs(item.date_of_birth),
      date_of_expire: item.date_of_expire && dayjs(item.date_of_expire),
      date_of_issue: item.date_of_issue && dayjs(item.date_of_issue),
    });
  });
  return (
    <Form.List
      name='passport_info'
      initialValue={
        passportInitialValues?.length
          ? initialValues || [{}]
          : passportInitialValues || [{}]
      }
    >
      {(fields, { add, remove }) =>
        fields.map((field, index) => {
          return (
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} key={index}>
              <PassportInfo
                form={form}
                field={field}
                passportInitialValues={initialValues}
              />
              <Col xs={24} sm={24} md={24} lg={6}>
                <Form.Item label=''>
                  {index === 0 ? (
                    !useAsModal && (
                      <Button
                        type='primary'
                        onClick={() => {
                          fields.length < 15
                            ? add()
                            : toasterNotification(
                                'error',
                                'Cannot create more than 15 passports!!'
                              );
                        }}
                      >
                        <PlusOutlined />
                        Add More
                      </Button>
                    )
                  ) : (
                    <Button
                      type='primary'
                      danger
                      onClick={() => {
                        remove(field.name);
                      }}
                    >
                      <MinusOutlined />
                      Remove
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          );
        })
      }
    </Form.List>
  );
};

export default PassportInfoFormList;
