import React from 'react';
import {
  FormInput,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form';

type IProps = {
  onChange: () => void;
  name: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  arr: FormListFieldData[];
};
const Others = ({ onChange, name, remove, add, arr }: IProps) => {
  return (
    <Row align={'middle'} gutter={3}>
      <NumberInput name={[name, `payroll_other`]} label='Others' size={20} />
      <Col lg={4}>
        {' '}
        <Form.Item label={[]}>
          {name === 0 ? (
            <Col>
              <Button
                disabled={arr.length === 3}
                type='dashed'
                onClick={() => (name <= 3 ? add() : undefined)}
                block
                icon={<PlusOutlined />}
              ></Button>
            </Col>
          ) : (
            <Col>
              <Button
                className='ml-2'
                onClick={() => remove(name)}
                block
                icon={<MinusCircleOutlined />}
                danger
              ></Button>
            </Col>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Others;
