import { Button, Divider, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import { FormInstance } from 'antd/lib/form';
import { IHajiInformation } from '../Types/InvoiceHajjiPre.Types';
import { useCheckTrackingAndSerialMutation } from '../API/Endpoints/invoiceHajjPreRegEndspoint';
import { useWatch } from 'antd/es/form/Form';
import {
  SelectMaharam,
  SelectOptionsInput,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import BillingIsDeleted from '../../Invoice(Visa)/Components/BillingIsDeleted';

type Props = {
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form?: FormInstance<any>;
  name: any;
  index: number;
  value?: IHajiInformation[];
};
const HajjDetails_List_Info = ({
  add,
  remove,
  form,
  name,
  index,
  value,
}: Props) => {
  const { id } = useParams();
  // ============= tracking no
  const [checkValue, setCheckValue] = useState<string>();
  const [checkedValue, { data }] = useCheckTrackingAndSerialMutation();
  useEffect(() => {
    const timerOut = setTimeout(() => {
      checkValue && checkedValue({ data_for: 'tracking', value: checkValue });
    }, 800);
    return () => clearTimeout(timerOut);
  }, [checkValue]);

  // ============= serial no
  const [checkSerialNo, setCheckSerialNo] = useState<string>();
  const [checkedSerialNo, { data: serialNo }] =
    useCheckTrackingAndSerialMutation();
  useEffect(() => {
    const timerOut = setTimeout(() => {
      checkSerialNo &&
        checkedSerialNo({ data_for: 'serial', value: checkSerialNo });
    }, 800);
    return () => clearTimeout(timerOut);
  }, [checkSerialNo]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const hajiinfo_is_deleted: 0 | 1 = useWatch(
    ['haji_information', index, 'hajiinfo_is_deleted'],
    form
  );
  useEffect(() => {
    if (hajiinfo_is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [hajiinfo_is_deleted]);
  return (
    <Row
      key={index}
      style={
        hajiinfo_is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
            }
          : { display: 'flex', alignItems: 'center' }
      }
      gutter={[10, { xs: 8, sm: 16, md: 24, lg: 20 }]}
    >
      <FormInputItem
        size={3}
        name={[name, 'hajiinfo_name']}
        label='Name'
        required
      />
      <FormInputItem
        size={2}
        name={[name, 'hajiinfo_tracking_number']}
        required
        label='Tracking No'
        onChange={(value) => setCheckValue(value.toString())}
        validateStatus={data?.data}
        help='Already exist.'
      />
      <DateInput
        size={2}
        name={[name, 'haji_info_reg_year']}
        label='Pre Reg. Year'
        year
        required
      />
      <FormInputItem size={2} label='Mobile' name={[name, 'hajiinfo_mobile']} />
      <DateInput size={2} name={[name, 'hajiinfo_dob']} label='Date of birth' />
      <FormInputItem size={2} name={[name, 'hajiinfo_nid']} label='NID' />

      <FormInputItem
        size={2}
        name={[name, 'haji_info_vouchar_no']}
        label='Voucher No'
      />

      <FormInputItem
        size={2}
        name={[name, 'hajiinfo_serial']}
        label='Serial No'
        onChange={(value) => setCheckSerialNo(value.toString())}
        validateStatus={serialNo?.data}
        help='Already exist.'
      />
      <SelectOptionsInput
        options={['Male', 'Female']}
        size={2}
        label={'Gender'}
        name={[name, 'hajiinfo_gender']}
      />

      <SelectMaharam
        name={[name, 'haji_info_maharam']}
        label='Maharam'
        size={2}
        smSize={12}
        mdSize={8}
      />

      <DateInput
        size={2}
        name={[name, 'haji_info_possible_year']}
        label='Possible Hajj Year'
        year
      />
      <BillingIsDeleted name={[name, 'hajiinfo_is_deleted']} />
      {index === 0 ? (
        <Button type='primary' onClick={() => add()}>
          <PlusOutlined />
        </Button>
      ) : (
        <Form.Item label={false}>
          <Button
            type='primary'
            danger
            onClick={() => {
              const prev = form?.getFieldValue([
                'haji_information',
                name,
                'hajiinfo_is_deleted',
              ]);
              form?.setFieldValue(
                ['haji_information', name, 'hajiinfo_is_deleted'],
                Number(!prev)
              );
              if (id) {
                if (value?.length && index >= value.length) remove(name);
              } else {
                remove(name);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Form.Item>
      )}

      {/* {fields.length > 1 && <Divider />} */}
    </Row>
  );
};

export default HajjDetails_List_Info;
