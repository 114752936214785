import { Form, Select } from 'antd';
import { useGetAllDepartmentsQuery } from '../../department/endpoints/departmentEndpoints';
import { IDepartmentCategory } from '../../department/types/departmentTypes';
const DepartmentSelect = () => {
  const { data: departmentCategory } = useGetAllDepartmentsQuery();

  return (
    <Form.Item
      label='Department'
      name='employee_department_id'
      rules={[{ required: true, message: 'Please input Department!' }]}
    >
      <Select
        showSearch
        allowClear
        placeholder='Select Department'
        optionFilterProp='children'
      >
        {departmentCategory?.data?.map((item: IDepartmentCategory) => {
          return (
            <Select.Option key={item.department_id} value={item.department_id}>
              {item.department_name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default DepartmentSelect;
