import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { setModal } from '../../../../common/slices/commonSlice';
import { AgencyDataType } from '../types/AgenciesTypes';
import { useEditAgencyMutation } from '../endpoints/agencyEndpoints';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  agencyNameList: string[];
  info: AgencyDataType;
  setNull: ISetState<AgencyDataType | null>;
}

const EditAgencyModal = ({ info, setNull, agencyNameList }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ agency_name: info.agency_name });
  }, [info]);

  const [editAgency, { isError, isSuccess, isLoading }] =
    useEditAgencyMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Agency Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as AgencyDataType;
      values.agency_id = info.agency_id;
      await editAgency({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };
  return (
    <Form
      layout='horizontal'
      labelAlign='left'
      form={form}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Agency Name'
        name='agency_name'
        rules={[
          { required: true, message: 'Please enter Agency name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (agencyNameList.includes(value)) {
                  return Promise.reject('Agency Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter Agency name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Update
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default EditAgencyModal;
