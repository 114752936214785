import type { ColumnsType, TableProps } from 'antd/es/table';
import { Table, Modal } from 'antd';
import React, { useState } from 'react';
import {
  IClToClViewModal,
  IHajjiManagementAll,
} from '../../Types/HajjiManagement.interface';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { pagination } from '../../../../common/utils/common.utils';

type Props = {
  setModal1Open: React.Dispatch<React.SetStateAction<boolean>>;
  modal1Open: boolean;
  modalData?: IClToClViewModal;
};

const ClienttoClientViewModal = ({
  setModal1Open,
  modal1Open,
  modalData,
}: Props) => {
  const columns: ColumnsType<IClToClViewModal> = [
    {
      title: 'Tracking No',
      dataIndex: 'trackingNo',
    },
    {
      title: '	Transfer From',
      dataIndex: 'transferFrom',
    },
    {
      title: 'Transfer To',
      dataIndex: 'transferTo',
    },
    {
      title: '	Transfer Date',
      dataIndex: 'date',
    },
  ];

  return (
    <div>
      <Modal
        title='Transfer History :'
        style={{ top: 20 }}
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        footer={false}
        width={800}
      >
        {modalData ? (
          <Table
            size='small'
            bordered
            columns={columns}
            dataSource={[modalData]}
            pagination={pagination([modalData].length)}
            style={{
              boxShadow:
                'rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px',
            }}
          />
        ) : (
          loadingIndicator
        )}
      </Modal>
    </div>
  );
};

export default ClienttoClientViewModal;
