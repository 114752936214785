import { Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import Col from 'antd/lib/grid/col';
import {
  FormButton,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { SelectHajjigroup } from '../../../../components/common/FormItem/SelectCustomFeilds';
import ViewPassportByTracking from '../ClientToClientCom/ViewPassportByTracking';
import { useWatch } from 'antd/es/form/Form';
import ViewPassportByGroupID from '../ClientToClientCom/ViewPassportByGroupID';

type Props = {
  onFinish: (values: any) => void;
  form?: FormInstance<any>;
  previousTrackNo?: string[];
};

const GrouptoGroupForm = ({ onFinish, form, previousTrackNo }: Props) => {
  const fromId = useWatch(['gtransfer_from'], form);

  return (
    <Form
      className='border p-5 '
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Row gutter={[10, 0]}>
        <Col
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          span={8}
        >
          <SelectHajjigroup
            name={'gtransfer_from'}
            label='Transfer From'
            placeholder='Search Group'
            size={24}
            mdSize={8}
            smSize={12}
            required
          />

          <SelectHajjigroup
            name={'gtransfer_to'}
            label='Transfer To'
            placeholder='Search Group'
            size={24}
            mdSize={8}
            smSize={12}
            required
            dependencies={['gtransfer_from']}
            rules={[
              ({ getFieldValue }) => ({
                validator: (_, data) => {
                  const gtransfer_from = getFieldValue('gtransfer_from');
                  if (data == gtransfer_from) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: "Transfer From can't be same to Transfer To!",
              }),
            ]}
          />
        </Col>

        <Col
          style={{
            borderLeft: '2px solid lightgray',
            paddingLeft: 10,
            paddingRight: 10,
          }}
          span={8}
        >
          <FormInputItem
            name={'gtransfer_job_name'}
            label='Job Name'
            size={24}
            mdSize={8}
            smSize={12}
          />

          <FormInputItem
            name={'gtransfer_tracking_no'}
            label='Tracking No'
            size={24}
          />
        </Col>

        <Col
          style={{ borderLeft: '2px solid lightgray', paddingLeft: 10 }}
          span={8}
        >
          <Form.Item name='gtransfer_note' label='Note'>
            <TextArea rows={4} placeholder='Note something' maxLength={255} />
          </Form.Item>
        </Col>
      </Row>

      <ViewPassportByGroupID
        previousTrackNo={previousTrackNo}
        fromClientID={fromId}
        form={form}
        openFrom={'GROUP_TO_GROUP'}
      />
      <FormButton label='Save Transfer Data' />
    </Form>
  );
};

export default GrouptoGroupForm;
