import { UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Select } from 'antd';
import { useState } from 'react';

import AddRole from './AddRole';

type Props = {};

export default function AddRoleDrawer({}: Props) {
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Button
        type='primary'
        onClick={showDrawer}
        icon={<UsergroupAddOutlined />}
      >
        Add User Role
      </Button>
      <Drawer
        title='Add user role'
        width={window.innerWidth <= 576 ? 300 : 720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={<Button onClick={onCancel}>Cancel</Button>}
      >
        <AddRole
          setOpen={setOpen}
          form={form}
        />
      </Drawer>
    </>
  );
}
