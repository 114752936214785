import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import AccommodationInfo from '../components/AccommodationInfo';
import { useCreateTourAccommodationMutation } from '../endpoints/accommodationEndpoints';
import { IAccommodationParentCreateDataType } from '../types/accommodationTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';

const CreateTourAccommodation = ({
  isPlusButton,
  setClick,
  setInstanceAccommodationSelect,
}: {
  isPlusButton?: boolean;
  setClick?: (arg: boolean) => void;
  setInstanceAccommodationSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  //create accommodation
  const user = useSelector(selectUser);
  const [
    addTourAccommodation,
    { data, isError, isSuccess, isUninitialized, isLoading },
  ] = useCreateTourAccommodationMutation();

  useEffect(() => {
    if (isSuccess) {
      setInstanceAccommodationSelect &&
        setInstanceAccommodationSelect(data?.data);
      message.success('Tour Accommodation Added Successfully');
      setClick && setClick(false);
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async (values: IAccommodationParentCreateDataType) => {
    const body = [...values.accommodation_info];
    const newAccommodation = body?.map((item) => {
      return {
        ...item,
        accommodation_created_by: user?.user_id as number,
      };
    });
    await addTourAccommodation(newAccommodation);
    form.resetFields();
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };

  return (
    <>
      {isPlusButton ? (
        <Button
          style={{
            marginTop: '5px',
            width: '100%',
          }}
          onClick={() => {
            showModal();
            setClick && setClick(true);
          }}
          icon={<PlusOutlined />}
        />
      ) : (
        <Button
          type='primary'
          onClick={showModal}
          style={{
            marginBottom: '22px',
          }}
        >
          {'Create Tour Accommodations'}
        </Button>
      )}

      <Modal
        title='Add new accommodation'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={1050}
      >
        {/* {permission?.['create:any'] && ( */}
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <AccommodationInfo />
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
            loading={isLoading}
          >
            Create Accommodations
          </Button>
        </Form>
        {isLoading && <LoadingIndicator />}
      </Modal>
    </>
  );
};

export default CreateTourAccommodation;
