import { Button, Card, Col, Form, Row } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form/Form';
import Expense_Info from './Expense_info';
import { IAmountTotal } from '../ExpenseTypes/Expensetypes';

type Props = {
  form: FormInstance<any>;
  expenseInitialValues?: IAmountTotal[];
};

const Expense_Info_FormList = ({ expenseInitialValues, form }: Props) => {
  return (
    <div className='my-10'>
      <Form.List
        name='expense_details'
        initialValue={expenseInitialValues || [{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row
                justify={'center'}
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                key={index}
              >
                <Expense_Info
                  form={form}
                  field={field}
                  expenseInitialValues={expenseInitialValues}
                />

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={3}
                  style={{ marginLeft: '40px' }}
                >
                  <Form.Item>
                    {index === 0 ? (
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                      >
                        <PlusOutlined />
                        Add More
                      </Button>
                    ) : (
                      <Button
                        type='primary'
                        danger
                        onClick={() => {
                          remove(field.name);
                        }}
                      >
                        <MinusOutlined />
                        Remove
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default Expense_Info_FormList;
