import { api } from '../../../../../app/baseQuery';
import { HTTPResponse } from '../../../../../common/types/commonTypes';
import { INVALIDATE_CLIENT_VENDOR_LEDGER } from '../../../../Reports/Api/constants';
import {
  ICombineClient,
  ICombineClientView,
  ICombineStatus,
  ISingleCombineClient,
} from '../../types/combineClientTypes';

export const COMBINE_CLIENT = 'CombineClient';

export const clientCombineEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    postCombineClient: build.mutation<
      HTTPResponse<{ invoice_id: number }>,
      ICombineClient
    >({
      query: (body) => ({
        url: '/combine-clinets/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: COMBINE_CLIENT },
        INVALIDATE_CLIENT_VENDOR_LEDGER,
      ],
    }),
    editCombineClient: build.mutation<
      HTTPResponse<void>,
      { body: ICombineClient; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/combine-clinets/update-client/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: COMBINE_CLIENT }],
    }),
    deleteCombineClientOne: build.mutation<
      HTTPResponse<void>,
      {
        body: {
          combine_update_by: number;
        };
        id: string;
      }
    >({
      query: ({ body, id }) => ({
        url: `/combine-clinets/combines/${id}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [{ type: COMBINE_CLIENT }],
    }),

    getAllCombineClient: build.query<
      HTTPResponse<ICombineClientView[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/combine-clinets/combines?page=${arg.current}&size=${
          arg.pageSize
        }&search=${arg.search || ''}`,
      }),
      providesTags: [{ type: COMBINE_CLIENT }, { type: 'invoices' }],
    }),
    /**
     * get a combine excel report
     */
    getExcelReportCombine: build.query<
      HTTPResponse<any>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `/report/combined-Client-all?page=${arg.current}&size=${
          arg.pageSize
        }&search=${arg.search || ''}`,
        responseHandler: async (response) => {
          const filename = `combinedExcel.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
    getAllDeletedCombineClients: build.query<
      HTTPResponse<ICombineClientView[]>,
      void
    >({
      query: () => ({ url: '/combine-clinets/combine-trash' }),
      providesTags: [{ type: COMBINE_CLIENT }],
    }),
    getCombineClientEdit: build.query<HTTPResponse<ICombineClient>, string>({
      query: (id) => ({ url: `/combine-clinets/combine_for_edit/${id}` }),
      providesTags: [{ type: COMBINE_CLIENT }, { type: 'invoices' }],
    }),

    //-------------------masud
    getSingleCombineClients: build.query<
      HTTPResponse<ISingleCombineClient>,
      number
    >({
      query: (id) => ({ url: `/combine-clinets/combine_for_edit/${id}` }),
      // keepUnusedDataFor: expire.min,
      providesTags: (_res, _err, id) => [
        { type: COMBINE_CLIENT, id: id } as const,
      ],
    }),
    updateCombineClientStatus: build.mutation<void, ICombineStatus>({
      query: (body) => ({
        url: `/combine-clinets/combines/${body.combine_id}`,
        method: 'PATCH',
        body: body,
      }),
      // keepUnusedDataFor: expire.min,
      invalidatesTags: () => ['Dashboard', { type: COMBINE_CLIENT } as const],
    }),
    getAllCombBySearch: build.query<HTTPResponse<ICombineClientView[]>, string>(
      {
        query: (search) => ({
          url: `/combine-clinets/view-combine${
            search ? `?search=${search}` : ''
          }`,
        }),
        providesTags: [{ type: COMBINE_CLIENT }, { type: 'invoices' }],
      }
    ),
  }),
});

export const {
  useUpdateCombineClientStatusMutation,
  useGetSingleCombineClientsQuery,
  useLazyGetSingleCombineClientsQuery,
  usePostCombineClientMutation,
  useEditCombineClientMutation,
  useGetAllCombineClientQuery,
  useLazyGetAllCombineClientQuery,
  useDeleteCombineClientOneMutation,
  useGetCombineClientEditQuery,
  useLazyGetCombineClientEditQuery,
  useLazyGetAllDeletedCombineClientsQuery,
  useLazyGetExcelReportCombineQuery,
  useLazyGetAllCombBySearchQuery, // Get combine by search
} = clientCombineEndpoints;
