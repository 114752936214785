import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { NotificationConst } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import { COMBINE_CLIENT } from '../../../Client/combined_clients/api/endpoints/clientCombineEndpoints';
import {
  EXPENSE,
  Expense_LOGS,
} from '../../../Expense/Api/Constants/Constants';
import {
  LOAN,
  PAYMENT,
  RECEIVE,
} from '../../../Loan_Management/Api/Constants/constants';
import { MONEY_RECEIPT_TAG } from '../../../Money_Receipt/api/endpoints/moneyReceiptEndpoints';
import { EMPLOYEEEXPENSEREPORT } from '../../../Reports/Api/constants';
import {
  IGetAllCheque,
  IUpdateCheque,
} from '../../types/ChequeManagementTypes';
import { CHEQUE_PAYMENT } from '../Constants/constants';

export const AllChequeManagementEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //GET all Expense
    getAllCheque: build.query<HTTPResponse<IGetAllCheque[]>, string>({
      query: (arg) => ({
        url: `/cheques?status=${arg}`,
      }),
      providesTags: [
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
        { type: 'Expense', id: EXPENSE },
        { type: MONEY_RECEIPT_TAG },
        { type: 'Payroll' },
        EMPLOYEEEXPENSEREPORT,
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        NotificationConst,
        { type: 'Loan', id: LOAN },
        { type: 'Payment', id: PAYMENT },
        { type: 'Receive', id: RECEIVE },
        { type: 'Vendors', id: 'VENDOR' },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        COMBINE_CLIENT,
      ],
    }),
    getAllCheques: build.query<
      HTTPResponse<IGetAllCheque[]>,
      { query: string }
    >({
      query: ({ query }) => ({
        url: `/cheques${query ? query : ''}`,
      }),
      providesTags: [
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
        { type: 'Expense', id: EXPENSE },
        { type: MONEY_RECEIPT_TAG },
        { type: 'Payroll' },
        EMPLOYEEEXPENSEREPORT,
        { type: 'Expense', id: EXPENSE },
        { type: 'Expense', id: Expense_LOGS },
        { type: 'Accounts' },
        NotificationConst,
        { type: 'Loan', id: LOAN },
        { type: 'Payment', id: PAYMENT },
        { type: 'Receive', id: RECEIVE },
        { type: 'Vendors', id: 'VENDOR' },
        { type: 'Payments', id: 'VENDOR_PAYMENT' },
        COMBINE_CLIENT,
      ],
    }),

    //Update Cheque Status
    updateCheque: build.mutation<void, IUpdateCheque>({
      query: (body) => ({
        url: `/cheques`,
        method: 'PATCH',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification(
            'success',
            'Expense Cheque Deposited Successfully'
          );
        });
      },
      invalidatesTags: [
        { type: 'Cheque_Payment', id: CHEQUE_PAYMENT },
        { type: 'Accounts' },
        { type: MONEY_RECEIPT_TAG },
      ],
    }),
  }),
});

export const {
  useGetAllChequeQuery,
  useLazyGetAllChequesQuery,
  useUpdateChequeMutation,
} = AllChequeManagementEndpoints;
