import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Transport_Info_List_Info from './Transport_Info_List_Info';
import { ITransportInfo } from '../Type/Invoce.other.interface';

type Props = {
  form: FormInstance<any>;
  trans_info?: ITransportInfo[] | undefined;
};

const TransportInformation = ({ form, trans_info }: Props) => {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Transport Information 🚌' />
      <Form.List
        name='transport_information'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Transport_Info_List_Info
                trans_info={trans_info}
                add={add}
                key={index}
                name={name}
                index={index}
                remove={remove}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default TransportInformation;
