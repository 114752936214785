import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import Ticket_Details_List_Info from './Ticket_Details_List_Info';
import { ITicketInfo } from '../Type/Invoce.other.interface';

type Props = { form: FormInstance<any>; ticket_information?: ITicketInfo[] };

const TicketInformation = ({ form, ticket_information }: Props) => {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Ticket Information 🎟️' />

      <Form.List
        name='ticketInfo'
        initialValue={[{}]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Ticket_Details_List_Info
                ticket_information={ticket_information}
                add={add}
                key={index}
                name={name}
                index={index}
                remove={remove}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default TicketInformation;
