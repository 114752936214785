import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  DepartmentDataType,
  IDepartmentEditFormData,
  IDepartmentFormData,
} from '../types/departmentTypes';

const DEPARTMENT = 'DEPARTMENT';
export const departmentEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all department
    getAllDepartments: build.query<HTTPResponse<DepartmentDataType[]>, void>({
      query: () => ({ url: `/configuration/departments/get-all` }),
      providesTags: () => [{ type: 'Department', id: DEPARTMENT }],
    }),

    //get all department wit Pagination
    getAllDepartmentsWithPagination: build.query<
      HTTPResponse<DepartmentDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `configuration/departments?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Department', id: DEPARTMENT }],
    }),

    //create department

    createDepartment: build.mutation<void, IDepartmentFormData>({
      query: (body) => ({
        url: `/configuration/departments/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Department', id: DEPARTMENT }],
    }),

    //edit department
    editDepartment: build.mutation<void, IDepartmentEditFormData>({
      query: (body) => ({
        url: `/configuration/departments/edit/${body.department_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Department', id: DEPARTMENT }],
    }),

    //delete department
    deleteDepartment: build.mutation<void, DepartmentDataType>({
      query: (body) => ({
        url: `/configuration/departments/delete/${body.department_id}`,
        method: 'DELETE',
        body: { deleted_by: body?.deleted_by },
      }),

      invalidatesTags: () => [{ type: 'Department', id: DEPARTMENT }],
    }),
  }),
});

export const {
  useGetAllDepartmentsQuery,
  useCreateDepartmentMutation,
  useEditDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetAllDepartmentsWithPaginationQuery,
} = departmentEndpoints;
