import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { PASSPORTSTATUSREPORT } from '../../Api/constants';
import {
  IPassportStatusReportDataTypes,
  IPassportStatusReportFormDataTypes,
} from '../types/passportStatusReportTypes';

export const PassportStatusReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all PassportStatusReport
    getPassportStatusReport: build.query<
      HTTPResponse<IPassportStatusReportDataTypes[]>,
      {
        status_name: string;
        query: string;
      }
    >({
      query: ({ status_name, query }) => ({
        url: `/report/passport-status-report${query}`,
        method: 'POST',
        body: {
          status_name: status_name,
        },
      }),
      providesTags: [
        { type: 'PassportStatusReport', id: PASSPORTSTATUSREPORT },
      ],
    }),

    getExcelPassPortStatusReport: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
      }
    >({
      query: ({ client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}/${query}`,
        method: 'POST',
        body: {
          status_name: client_id,
        },
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useLazyGetPassportStatusReportQuery,
  useLazyGetExcelPassPortStatusReportQuery,
} = PassportStatusReportEndpoints;
