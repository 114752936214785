import { HomeOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

type Props = {};

const InvoiceNotFound = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Description>
        It seems like we couldn't find the invoice you were looking for
      </Description>

      <Row style={{ marginTop: 100 }} justify={'center'}>
        <Button
          icon={<HomeOutlined />}
          onClick={() => {
            navigate(-1);
          }}
          type='primary'
        >
          Go Back Home
        </Button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 120px;
`;

const Description = styled.h3`
  font-size: 2em;
  text-transform: uppercase;
  text-align: center;
  color: #bfc0c0;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default InvoiceNotFound;
