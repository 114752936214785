import { Col, Divider, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'rc-field-form';

type Props = {
  label?: string;
  name: string;
  required?: boolean;
  form: FormInstance<any>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  selectedAddonAfter: string;
  setSelectedAddonAfter: React.Dispatch<React.SetStateAction<string>>;
};

const EmailWithAddon = ({
  label,
  name,
  required,
  form,
  setEmail,
  selectedAddonAfter,
  setSelectedAddonAfter,
}: Props) => {
  const { Option } = Select;

  const emailField = useWatch([name], form);

  useEffect(() => {
    if (selectedAddonAfter === 'own') {
      return setEmail(emailField);
    } else if (selectedAddonAfter != 'own') {
      return setEmail(emailField + selectedAddonAfter);
    }
  }, [emailField, selectedAddonAfter]);

  // Handle the addonAfter change
  const handleAddonAfterChange = (value: any) => {
    if (value === 'own') {
      setSelectedAddonAfter('own');
    } else {
      setSelectedAddonAfter(value);
      setEmail(emailField + selectedAddonAfter);
    }
  };

  return (
    <Col span={12}>
      <Form.Item
        label={label || 'Email'}
        name={name}
        rules={[
          { required: required, message: 'Please enter email' },
          {
            validator(rule, value, callback) {
              if (value) {
                if (/@/.test(value) && selectedAddonAfter != 'own') {
                  return Promise.reject('Provide email with out domain!');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input
          style={{ width: '100%' }}
          type={selectedAddonAfter === 'own' ? 'email' : 'text'}
          addonAfter={
            selectedAddonAfter == 'own' ? (
              ' '
            ) : (
              <Select
                value={selectedAddonAfter}
                onChange={handleAddonAfterChange}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '1px 0' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <a
                        style={{
                          display: 'block',
                          cursor: 'pointer',
                          textAlign: 'center',
                        }}
                        onClick={() => handleAddonAfterChange('own')}
                      >
                        Own
                      </a>
                    </div>
                  </div>
                )}
              >
                <Option value='@gmail.com'>@gmail.com</Option>
                <Option value='@yahoo.com'>@yahoo.com</Option>
                <Option value='@hotmail.com'>@hotmail.com</Option>
                <Option value='@outlook.com'>@outlook.com</Option>
              </Select>
            )
          }
          placeholder='Please enter email'
        />
      </Form.Item>
    </Col>
  );
};

export default EmailWithAddon;
