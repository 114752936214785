import { Col, Form, FormListFieldData, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import {
  FormInputItem,
  NumberInput,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectCountry,
  SelectProduct,
  SelectVendors,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import { IBillInfo } from '../QuotationTypes/QuotationTypes';
type Props = {
  vendorField?: boolean;
  field: FormListFieldData;
  form: FormInstance<any>;
  billInitialValues?: IBillInfo[];
};

const Billing_Info = ({
  field,
  form,
  billInitialValues,
  vendorField,
}: Props) => {
  const [unitPrice, setUnitPrice] = useState<number>();
  const [qty, setQty] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();

  useEffect(() => {
    if (billInitialValues && billInitialValues[field.name]) {
      setUnitPrice(
        billInitialValues && billInitialValues[field.name].unit_price
      );
      setQty(billInitialValues && billInitialValues[field.name].quantity);
    }
  }, [billInitialValues]);

  useEffect(() => {
    if (qty !== undefined && unitPrice !== undefined) {
      setTotalPrice(qty * unitPrice);
    } else if (qty) {
      setTotalPrice(undefined);
      setUnitPrice(undefined);
    } else if (unitPrice) {
      setTotalPrice(undefined);
      setQty(undefined);
    } else if (billInitialValues && billInitialValues[field.name]) {
      setTotalPrice(billInitialValues[field.name].total_price);
    }
  }, [form.getFieldValue('bill_info')]);

  useEffect(() => {
    form.setFields([
      {
        name: ['bill_info', field.name, 'total_price'],
        value: totalPrice,
      },
    ]);
  }, [form.getFieldValue('bill_info')]);

  return (
    <>
      <SelectProduct
        label='Product'
        name={[field.name, 'product_id']}
        size={vendorField ? 3 : 4}
        placeholder='Select Product'
        required
      />
      <SelectCountry
        label='Country'
        name={[field.name, 'billing_country_id']}
        size={vendorField ? 2 : 4}
      />
      <FormInputItem
        label='Description'
        name={[field.name, 'description']}
        size={vendorField ? 3 : 4}
      />
      <Col span={3} xs={12} sm={12} md={12} lg={2}>
        <Form.Item
          label='Quantity'
          name={[field.name, 'quantity']}
          rules={[
            { required: true, message: 'Quantity is required' },
            {
              validator(_, value) {
                const roundedValue = Math.round(value);
                if (value) {
                  if (roundedValue !== value) {
                    return Promise.reject('Quantity cannot be decimal!!');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <InputNumber
            onChange={(e) => {
              setQty(Number(e));
            }}
            placeholder='Quantity'
            style={{ width: '100%' }}
            min={0}
          />
        </Form.Item>
      </Col>

      <NumberInput
        label='Unit Price'
        name={[field.name, 'unit_price']}
        onChange={(e) => {
          setUnitPrice(Number(e));
        }}
        size={4}
        required
        min='0'
        maxChar={14}
        minChar={0}
      />
      <NumberInput
        label='Total Price'
        name={[field.name, 'total_price']}
        size={4}
        readOnly
        min='0'
        maxChar={14}
        minChar={0}
      />
      {vendorField && (
        <SelectVendors
          required
          label='Vendor'
          name={[field.name, 'vendor_id']}
          size={4}
        />
      )}
    </>
  );
};

export default Billing_Info;
