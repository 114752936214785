import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormListAddRemove from '../../../../common/utils/FormListAddRemove';
import BillingIsDeleted from '../../../Invoice(Visa)/Components/BillingIsDeleted';
import { IInvoiceTourTransports } from '../../Types/InvoiceTourTypes';
import TransportForm from './TransportForm';

type Props = {
  form: FormInstance<any>;
  invComClientInfo?: string;
  tourTransport?: IInvoiceTourTransports[] | undefined;
};

const InvoiceTourTransportForm = ({
  form,
  invComClientInfo,
  tourTransport,
}: Props) => {
  return (
    <div style={{ borderBottom: '1px solid #ccc' }}>
      <Form.List name='tourTransports' initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => {
            return (
              <Row key={index} gutter={[10, 0]} align={'middle'}>
                <TransportForm
                  form={form}
                  field={field}
                  invComClientInfo={invComClientInfo}
                  index={index}
                />
                <Col md={2}>
                  <BillingIsDeleted
                    name={[field.name, 'transport_is_deleted']}
                  />
                  <FormListAddRemove
                    add={add}
                    index={index}
                    name={field.name}
                    remove={remove}
                    dataLength={tourTransport?.length}
                    form={form}
                    NamePath={[
                      'tourTransports',
                      field.name,
                      'transport_is_deleted',
                    ]}
                  />
                </Col>
              </Row>
            );
          })
        }
      </Form.List>
    </div>
  );
};

export default InvoiceTourTransportForm;
