import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { IPermission, ISetState } from '../../../../common/types/commonTypes';
import { useDeleteCompaniesMutation } from '../Endpoints/companiesEndpoints';
import { CompanyDataType } from '../types/companyTypes';
import dayjs from 'dayjs';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<CompanyDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const companiesColumns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<CompanyDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const searchColumnProps = useGetSearchColumnProps<CompanyDataType>();
  const dispatch = useAppDispatch();
  const handleClick = (record: CompanyDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteCompany, { isError, isSuccess, isLoading }] =
    useDeleteCompaniesMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Companies Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (values: CompanyDataType, index: number) => {
    setColumnIndex(index);
    await deleteCompany({ ...values, company_created_by: user?.user_id });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      ...searchColumnProps('company_name'),
    },
    {
      title: 'Address',
      dataIndex: 'company_address',
      key: 'company_address',
    },
    {
      title: 'Contact Person',
      dataIndex: 'company_contact_person',
      key: 'company_contact_person',
    },
    {
      title: 'Designation',
      dataIndex: 'company_designation',
      key: 'company_designation',
    },
    {
      title: 'Phone',
      dataIndex: 'company_phone',
      key: 'company_phone',
    },

    {
      title: 'Create Date',
      key: 'company_create_date',
      render: (_, record) => {
        return `${dayjs(record.company_create_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Action',
      key: 'operation',
      render: (_, record, index) => (
        <Space size='small'>
          {permission?.['update:any'] && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}
          {permission?.['delete:any'] && (
            <Button
              danger
              size='small'
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];
};
