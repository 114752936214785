export const configurationTags = [
  'ClientCategory',
  'ClientSource',
  'VendorCategory',
  'Airports',
  'Products',
  'VisaTypes',
  'Department',
  'RoomTypes',
  'Designation',
  'Employee',
  'PassportStatus',
  'Groups',
  'Maharam',
  'Agency',
  'Airline',
  'ExpenseHead',
  'ExpenseSubHead',
  'Companies',
  'User',
  'Ticket',
];
