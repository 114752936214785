import { Col, Form, FormInstance, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useEffect } from 'react';
import SelectPaymentMethod from '../../../components/common/paymentMethod/SelectPaymentMethod';
import { useGetAccountByTypeQuery } from '../../vendor/api/endpoints/vendorPaymentEndpoints';

type Props = {
  size: number;
  form: FormInstance<any>;
  payMethodName: string;
  accountName: string;
};

const SelectPaymentTypeAndAccount = ({
  size,
  form,
  payMethodName,
  accountName,
}: Props) => {
  const payment_method = useWatch(payMethodName, form);

  const { data: accounts } = useGetAccountByTypeQuery(Number(payment_method), {
    skip: payment_method ? false : true,
  });

  useEffect(() => {
    if (payment_method) {
      form.setFieldValue(accountName, undefined);
    }
  }, [payment_method]);

  return (
    <>
      <SelectPaymentMethod
        label='Payment Method'
        name={payMethodName}
        size={size || 6}
      />

      <Col xs={24} sm={24} md={24} lg={size ? size : 8}>
        <Form.Item name={accountName} label={'Select Account'}>
          <Select
            dropdownStyle={{ color: 'red' }}
            placeholder={'Select Account'}
            showSearch
            options={accounts?.data?.map((item) => {
              return { value: item.account_id, label: item.account_name };
            })}
          ></Select>
        </Form.Item>
      </Col>
    </>
  );
};

export default SelectPaymentTypeAndAccount;
