import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  visaDataType,
  IVisaFormData,
  IVisaEditFormData,
} from '../types/visaTypes';

const VISATYPE = 'VISATYPE';

export const visaEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all visa
    getAllVisa: build.query<HTTPResponse<visaDataType[]>, void>({
      query: () => ({ url: `/configuration/visa-types/view-all` }),
      providesTags: () => [{ type: 'VisaTypes', id: VISATYPE }],
    }),

    getAllVisaWithPag: build.query<
      HTTPResponse<visaDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/visa-types?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'VisaTypes', id: VISATYPE }],
    }),

    //create visa type
    createVisa: build.mutation<void, IVisaFormData>({
      query: (body) => ({
        url: `/configuration/visa-types/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'VisaTypes', id: VISATYPE }],
    }),

    //edit visa type

    editVisa: build.mutation<void, IVisaEditFormData>({
      query: (body) => ({
        url: `/configuration/visa-types/edit/${body.type_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'VisaTypes', id: VISATYPE }],
    }),

    //delete visa type

    deleteVisa: build.mutation<void, visaDataType>({
      query: (body) => ({
        url: `/configuration/visa-types/delete/${body.type_id}`,
        method: 'DELETE',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'VisaTypes', id: VISATYPE }],
    }),
  }),
});

export const {
  useGetAllVisaQuery,
  useCreateVisaMutation,
  useEditVisaMutation,
  useDeleteVisaMutation,
  useGetAllVisaWithPagQuery,
} = visaEndpoints;
