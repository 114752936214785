import { Table } from 'antd';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAllQuotationClientQuery } from '../../api/endpoints/clientEndpoints';
import { IClientQuotationData } from '../../types/clientInterfaces';
import { clientQuotationUtil } from '../../utils/utils_tsx/clientQuotationUtil';
import { pagination } from '../../../../../common/utils/common.utils';

type Props = { id: string };

const ClientDetailQuotation = ({ id }: Props) => {
  const { data, isLoading } = useGetAllQuotationClientQuery(id, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;

      const dataToReturn: IClientQuotationData[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });
  return (
    <div>
      <Table
        size='small'
        bordered
        columns={clientQuotationUtil()}
        pagination={pagination(data.length)}
        dataSource={data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ClientDetailQuotation;
