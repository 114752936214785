import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import AgentMoneyReceiptForm from '../components/AgentMoneyReceiptForm';

type Props = {};
const Agent_Money_Receipt = ({}: Props) => {
  return (
    <>
      <BreadCrumb arrOfOption={['Add Agent Money Receipt']} />
      <AgentMoneyReceiptForm />
    </>
  );
};

export default Agent_Money_Receipt;
