import { Button, Popconfirm, Space, Tag, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IPermission,
  ISetState,
} from '../../../../../common/types/commonTypes';
import { ITourGroupDataType } from '../types/TourTypes';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { useDeleteGroupTourMutation } from '../endpoints/tourGroupEndpoints';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';

type Props = {
  setEditInfo: ISetState<ITourGroupDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const TourGroupColumn = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<ITourGroupDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const searchColumnProps = useGetSearchColumnProps<ITourGroupDataType>();
  const handleClick = (record: ITourGroupDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteGroupTour, { isError, isSuccess, isLoading }] =
    useDeleteGroupTourMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Tour Group Deteted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: ITourGroupDataType, index: number) => {
    setColumnIndex(index);
    await deleteGroupTour({
      ...values,
      group_updated_by: user?.user_id as number,
    });
  };

  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Group Name',
      dataIndex: 'group_name',
      key: 'group_name',
      ...searchColumnProps('group_name'),
    },
    {
      title: 'Maximum Pax',
      dataIndex: 'group_maximum_pax_allowed',
      key: 'group_maximum_pax_allowed',
    },

    {
      title: 'Create Date',
      key: 'group_create_date',
      render: (_, record) => {
        return `${dayjs(record.group_create_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'group_status',
      render: (_, { group_status }) => (
        <Tag color={group_status === 0 ? 'red' : 'green'} key={group_status}>
          {group_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record, index) => {
        return (
          <Space size='small'>
            {permission?.['update:any'] && record.agency_id && (
              <Button
                size='small'
                type='primary'
                onClick={() => handleClick(record)}
              >
                Edit
              </Button>
            )}
            {permission?.['delete:any'] && record.agency_id && (
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleDelete(record, index)}
              >
                <Button
                  danger
                  size='small'
                  type='primary'
                  loading={columnIndex === index && true}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};
