import { Button } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { ISMSFormData } from '../../../modules/SMS System/SMSTypes/SMSTypes';
import { IPassportExpire } from '../Interfaces/Notification.interface';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';

type Props = {
  handleOnSubmit: (values: ISMSFormData) => Promise<void>;
  data: IPassportExpire;
};

export default function SendSmsButton({ handleOnSubmit, data }: Props) {
  const [isButton, setIsButton] = React.useState<boolean>(false);
  const user = useSelector(selectUser);
  return (
    <Button
      onClick={() => {
        setIsButton(true);
        handleOnSubmit({
          client_category_id: 0,
          client_id: `0`,
          client_mobile: data.passport_mobile_no,
          date: new Date().toLocaleDateString(),
          message: `Your passport will expired ${dayjs(
            data.passport_date_of_expire
          ).format('DD-MM-YYYY')}.`,
          text_type: 'TEXT',
          created_by: user?.user_id as number,
        });
      }}
      size='small'
      type='primary'
      disabled={isButton}
    >
      Send Sms
    </Button>
  );
}
