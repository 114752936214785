import { Col, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import postDateFormat from '../../../common/utils/postDateFormat';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import {
  useEditInvoiceHajjiPreMutation,
  useGetAllInvoiceHajjiForEditQuery,
} from '../API/Endpoints/invoiceHajjPreRegEndspoint';
import HajiInformation from '../Components/HajiInformation';
import InvoiceHajjPreRegHeader from '../Components/InvoiceHajjPreRegHeader';
import {
  IInvoiceHajjPreRegFormData,
  IPreRegEditEndPointDataType,
} from '../Types/InvoiceHajjiPre.Types';
type Props = {};

const EditInvoice_PreReg = (props: Props) => {
  // ===== @Get Invoice Details
  const invoice_id = Number(useParams().id);
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const { data: invoice, isFetching } =
    useGetAllInvoiceHajjiForEditQuery(invoice_id);

  const invoiceDetails = invoice?.data;
  // =========== @Set Form
  useEffect(() => {
    form.setFieldsValue({
      invoice_combclient_id: invoiceDetails?.invoice_combclient_id,
      invoice_no: invoiceDetails?.invoice_no,
      invoice_sales_date: dayjs(invoiceDetails?.invoice_sales_date),
      invoice_due_date: invoiceDetails?.invoice_due_date
        ? dayjs(invoiceDetails?.invoice_due_date)
        : undefined,
      invoice_sub_total: invoiceDetails?.invoice_sub_total,
      invoice_discount: invoiceDetails?.invoice_discount,
      invoice_net_total: invoiceDetails?.invoice_net_total,
      invoice_note: invoiceDetails?.invoice_note,
      invoice_reference: invoiceDetails?.invoice_reference,
      invoice_haji_group_id: invoiceDetails?.invoice_haji_group_id,
      invoice_service_charge: invoiceDetails?.invoice_service_charge,
      invoice_sales_man_id: invoiceDetails?.invoice_sales_man_id,
      invoice_vat: invoiceDetails?.invoice_vat,
      invoice_agent_id: invoiceDetails?.invoice_agent_id,
    });
  }, [invoiceDetails]);
  useEffect(() => {
    form.setFields([
      {
        name: 'billing_information',
        value: invoiceDetails?.billing_information?.length
          ? invoiceDetails?.billing_information
          : [{}],
      },
    ]);
  }, [invoiceDetails]);

  const [form] = Form.useForm();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  // ============== Submit form]
  const [
    postInvoiceHajjiPre,
    {
      isError: postError,
      isSuccess: postSuccess,
      isLoading: postLoading,
      error,
    },
  ] = useEditInvoiceHajjiPreMutation();
  const onFinish = (values: IInvoiceHajjPreRegFormData) => {
    const body: IPreRegEditEndPointDataType = {
      invoice_no: values.invoice_no,
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_sales_date: postDateFormat(values?.invoice_sales_date) || '',
      invoice_due_date: values?.invoice_due_date
        ? dayjs(values?.invoice_due_date).format('YYYY-MM-DD')
        : undefined,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,
      invoice_sub_total: values.invoice_sub_total,
      invoice_net_total: values.invoice_net_total,
      invoice_created_by: user?.user_id as number,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_vat: values.invoice_vat,
      invoice_service_charge: values.invoice_service_charge,
      invoice_discount: values.invoice_discount,
      invoice_agent_id: values.invoice_agent_id,
      invoice_agent_com_amount: values.invoice_agent_com_amount,
      invoice_client_previous_due: values.invoice_client_previous_due,
      client_present_balance: values.client_present_balance,
      haji_information: values?.haji_information?.map((item: any) => {
        return {
          ...item,
          hajiinfo_is_deleted: item.hajiinfo_is_deleted,
          haji_info_possible_year:
            item?.haji_info_possible_year?.format('YYYY'),
          haji_info_reg_year: item?.haji_info_reg_year?.format('YYYY'),
          // hajiinfo_dob: postDateFormat(item?.hajiinfo_dob)
          hajiinfo_dob: item?.hajiinfo_dob
            ? postDateFormat(item.hajiinfo_dob)
            : undefined,
        };
      }),

      billing_information: values.billing_information.map((el: any) => {
        delete el?.billing_total_cost_price;
        return {
          ...el,
        };
      }),
    };
    body.haji_information = body.haji_information.map((el, index) => ({
      ...el,
      hajiinfo_id: invoiceDetails?.haji_information[index]?.hajiinfo_id,
    }));
    postInvoiceHajjiPre({ body, id: invoice_id }).then((res) => {});
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (postSuccess) {
      toasterNotification(
        'success',
        'Invoice Hajji Pre Registration Updated Successfully!'
      );
      updateAndPrev
        ? navigate(`/hajjpreregistration/details/${invoice_id}`)
        : navigate('/hajjpreregistration');
    }
    if (postError) {
      toasterNotification('error', 'Thare was an error');
    }
  }, [postLoading]);

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice (Hajj)', 'Edit']} />
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <InvoiceHajjPreRegHeader
          form={form}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />
        {invoiceDetails?.haji_information ? (
          <HajiInformation
            value={invoiceDetails.haji_information}
            form={form}
          />
        ) : (
          loadingIndicator
        )}

        {invoice?.data?.billing_information ? (
          <InvoiceBillingInfo
            form={form}
            billing_information={invoice?.data?.billing_information}
          />
        ) : (
          loadingIndicator
        )}

        <InvoiceVisaSubTotalForm form={form} />
        {/* @AIRTICKET POST BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={postLoading}
              label='UPDATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={postLoading}
              label='UPDATE & VIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditInvoice_PreReg;
