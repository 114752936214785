import { Form, Select } from 'antd';
import { useGetAllProductCategoryQuery } from '../endpoints/productsEndpoints';
import { IProductCategory } from '../types/productTypes';

const CategorySelect = () => {
  const { data: productCategory } = useGetAllProductCategoryQuery();

  return (
    <Form.Item
      name='product_category_id'
      label='Product Category'
      rules={[{ required: true, message: 'Please input Product category!' }]}
    >
      <Select
        showSearch
        allowClear
        placeholder='Enter Product Category'
        optionFilterProp='children'
        filterOption={(input, option) =>
          (option!.children as unknown as string).toLowerCase().includes(input)
        }
        filterSort={(optionA, optionB) =>
          (optionA!.children as unknown as string)
            .toLowerCase()
            .localeCompare(
              (optionB!.children as unknown as string).toLowerCase()
            )
        }
      >
        {productCategory?.data?.map((item: IProductCategory) => {
          return (
            <Select.Option key={item.category_id} value={item.category_id}>
              {item.category_title}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CategorySelect;
