import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import TicketInfoForm from '../component/CommonInfoForm';
import { useCreateTourTicketMutation } from '../endpoints/tourTicketEndpoints';
import { ITicketTourCreateDataType } from '../types/TicketTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
import { queryType } from '../pages/TourTicket';

type Props = {
  queryType: queryType;
  isPlusButton?: boolean;
  setClick?: (arg: boolean) => void;
  modalTitle?: string;
  setInstanceTransportSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};
const CreateCommonTourItinearyModal = ({
  queryType,
  isPlusButton,
  setClick,
  modalTitle,
  setInstanceTransportSelect,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick && setClick(false);
  };

  //   submit form
  const [addTourGroup, { data, isError, isSuccess, isLoading }] =
    useCreateTourTicketMutation();
  useEffect(() => {
    if (isSuccess) {
      setInstanceTransportSelect && setInstanceTransportSelect(data?.data);
      message.success(`${queryType} Added Successfully`);
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async (values: ITicketTourCreateDataType) => {
    const body: ITicketTourCreateDataType = {
      ...values,
    };
    body.ticket_info.map((item) => {
      item.itinerary_created_by = user?.user_id as number;
    });

    await addTourGroup({ body: body?.ticket_info, queryType });
    handleOk();
    form.resetFields();
  };
  return (
    <>
      {isPlusButton ? (
        <Button
          style={{
            width: '100%',
            marginTop: '5px',
          }}
          onClick={() => {
            showModal();
            setClick && setClick(true);
          }}
          icon={<PlusOutlined />}
          loading={isLoading}
        />
      ) : (
        <Button
          type='primary'
          onClick={showModal}
          style={{ marginBottom: '20px' }}
        >
          Create {queryType}
        </Button>
      )}

      <Modal
        title={modalTitle || queryType.toLowerCase() + ' modal'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
        >
          <TicketInfoForm />
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
            loading={isLoading}
          >
            Save {queryType}
          </Button>
        </Form>
      </Modal>
      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default CreateCommonTourItinearyModal;
