import { Col, Divider, Form, Input, Select, Tooltip, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectUser } from '../../../auth/states/userSlice';
import { IPermission, crud } from '../../../common/types/commonTypes';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import CountryName from '../../../helpers/CountryName';
import { mobileChildren } from '../../Client/Client/utils/utils_tsx/utilAddClient';
import { useGetAllClientCategoryQuery } from '../../Configuration/clientCategory/endpoints/clientCategoryEndpoints';
import {
  useGetSMSBalanceQuery,
  useSendSMSMutation,
} from '../Api/Endpoints/SMSEndpoints';
import SelectClientWithPhoneNo from '../Components/SelectClientWithPhoneNo';
import { ISMSFormData } from '../SMSTypes/SMSTypes';
import SMSLog from './SMSLog';

type Props = { permission?: IPermission };

type clientInfo = {
  id?: string;
  name?: string;
  phone?: string;
};

const SendSMS = ({ permission }: Props) => {
  const [form] = Form.useForm();
  const { data: clientCategory } = useGetAllClientCategoryQuery();
  const user = useSelector(selectUser);
  const { data: sms } = useGetSMSBalanceQuery();

  const [clientInfo, setClientInfo] = useState<clientInfo[]>([]);

  useEffect(() => {
    form.setFieldValue('country_code', '88');
  }, []);

  const [sendSMS, { isError, isLoading, isSuccess }] = useSendSMSMutation();

  const onFinish = async (values: ISMSFormData) => {
    const invalidNames: string[] = [];
    const isValidPhoneNumber = values?.client_id?.every((entry: any) => {
      const [clientId, name, phoneNumber] = entry.split(' + ');

      // Validate Phone Number
      const isNumeric = /^\d+$/.test(phoneNumber.replace(/\D/g, ''));

      if (phoneNumber.length > 10 && phoneNumber.length < 15 && isNumeric) {
        return true;
      } else {
        invalidNames.push(name);
        return false;
      }
    });

    if (true) {
      const newObj = values.client_id.map((client: any) => {
        const [clientId, name, mobile] = client.split(' + ');

        let removeCountryCodeFromPhone;

        if (mobile.startsWith('88') || mobile.startsWith('+88')) {
          removeCountryCodeFromPhone = mobile.split(/88|\+88/);
        } else {
          removeCountryCodeFromPhone = [null, mobile];
        }

        const client_mobile =
          values.country_code +
          removeCountryCodeFromPhone[1].replace(/\s/g, '');

        return {
          client_category_id: values.client_category_id,
          client_id: clientId,
          text_type: values.text_type,
          message: values.message,
          client_mobile: client_mobile,
          created_by: user?.user_id as number,
          date: dayjs().format('YYYY-MM-DD'),
        };
      });

      sendSMS(newObj);
    } else {
      toasterNotification(
        'error',
        `Invalid phone number for client(s): ${invalidNames.join(', ')}`
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const country_code = form.getFieldValue(['country_code']);
      form.resetFields();
      form.setFieldValue(['country_code'], country_code);
    }
  }, [isSuccess]);

  const message = useWatch(['message'], form);

  return (
    <div>
      <BreadCrumb arrOfOption={['SMS', 'Send SMS']} />
      {/* @SEND SMS */}
      <div
        style={{
          minHeight: '60vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Form
          layout='horizontal'
          form={form}
          onFinish={onFinish}
          autoComplete='off'
        >
          <div
            style={{
              maxWidth: '450px',
              borderRadius: '15px',
              padding: '5px 20px',
            }}
            className='border'
          >
            <Divider
              orientation='right'
              children={
                <>
                  SMS
                  {` (${Math.ceil(
                    (Number(
                      sms?.Data !== null &&
                        sms?.Data[0].Credits?.replace('BDT', '')
                    ) || 0) / 2
                  )})`}
                </>
              }
            ></Divider>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name='client_category_id'
                label={false}
                rules={[
                  {
                    required: true,
                    message: 'Client Category is required!!',
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder='Select Client Category'
                  style={{ width: '100%' }}
                >
                  {clientCategory?.data?.map((item) => {
                    return (
                      <Select.Option
                        key={item.category_id}
                        value={item.category_id}
                      >
                        {item.category_title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <SelectClientWithPhoneNo
              form={form}
              setClientInfo={setClientInfo}
            />

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name='text_type'
                label={false}
                rules={[{ required: true, message: 'Text Type is required!!' }]}
              >
                <Select
                  allowClear
                  placeholder='Select Text Type'
                  style={{ width: '100%' }}
                >
                  <Select.Option key={1} value='TEXT'>
                    Plain Text
                  </Select.Option>
                  <Select.Option key={2} value='UNICODE'>
                    Unicode
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name='country_code'
                label={false}
                rules={[
                  { required: true, message: 'Country code is required' },
                ]}
              >
                <Select
                  showSearch
                  placeholder={CountryName[19].dialCode}
                  optionFilterProp='roleMobile'
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {mobileChildren}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6} xs={24} sm={24} md={12} lg={24}>
              <Tooltip
                trigger={['focus']}
                title={
                  message ? (
                    <span className='numeric-input-title'>{message}</span>
                  ) : (
                    'Input a message.'
                  )
                }
                placement='topLeft'
                overlayClassName='numeric-input'
              >
                <Form.Item
                  label={false}
                  name='message'
                  rules={[
                    { required: true, message: 'Please enter text' },
                    { max: 600, message: 'Maximum 600 characters allowed' },
                  ]}
                >
                  <Input
                    placeholder='Enter message text'
                    showCount={{
                      formatter: ({ count, value }) => {
                        return (
                          <>
                            {Math.ceil(value.length / 70)}/{count}
                          </>
                        );
                      },
                    }}
                    maxLength={600}
                    onChange={() => {}}
                    addonAfter={
                      <div
                        style={{
                          minWidth: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        className='send'
                        onClick={() => {
                          form.submit();
                        }}
                      >
                        <svg
                          className='send-icon'
                          version='1.1'
                          id='Capa_1'
                          xmlns='http://www.w3.org/2000/svg'
                          x='0px'
                          y='0px'
                          viewBox='0 0 512 512'
                        >
                          <g>
                            <g>
                              <path
                                fill='#6B6C7B'
                                d='M481.508,210.336L68.414,38.926c-17.403-7.222-37.064-4.045-51.309,8.287C2.86,59.547-3.098,78.551,1.558,96.808 L38.327,241h180.026c8.284,0,15.001,6.716,15.001,15.001c0,8.284-6.716,15.001-15.001,15.001H38.327L1.558,415.193 c-4.656,18.258,1.301,37.262,15.547,49.595c14.274,12.357,33.937,15.495,51.31,8.287l413.094-171.409 C500.317,293.862,512,276.364,512,256.001C512,235.638,500.317,218.139,481.508,210.336z'
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </div>
                    }
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </div>
        </Form>
        {clientInfo.length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            <Col span={6} xs={24} sm={24} md={12} lg={24}>
              <Typography.Text type='warning'>
                Client List with Phone no
              </Typography.Text>

              {clientInfo.map((item, index) => (
                <div key={index}>
                  <Typography.Text>
                    {item.name} - {item.phone},
                  </Typography.Text>
                </div>
              ))}
            </Col>
          </div>
        )}
      </div>

      {/* @SHOW SMS */}
      {permission?.[crud.read] && <SMSLog />}
    </div>
  );
};

export default SendSMS;
const ViewInvoiceStyle = styled.div``;
