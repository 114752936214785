import { Button, Form, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import CitiesInfo from '../components/CitiesInfo';
import { useCreateTourCitiesMutation } from '../endpoints/CitiesTourEndPoints';
import { ICitiesCreateDataType } from '../types/citiesType';

const CreateTourCitiesModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  //create New cities
  const [addTourCities, { isError, isSuccess, isLoading }] =
    useCreateTourCitiesMutation();
  const user = useSelector(selectUser);
  useEffect(() => {
    if (isSuccess) {
      message.success('Cities Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: ICitiesCreateDataType) => {
    const body = [...values.cities_info];
    const newCities = body?.map((item) => {
      return {
        ...item,
        city_created_by: user?.user_id as number,
        city_status: 1,
      };
    });

    await addTourCities(newCities);
    form.resetFields();
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        type='primary'
        onClick={showModal}
      >
        {'Create Tour Cities'}
      </Button>
      <Modal
        title='Add New Cities'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {/* {permission?.['create:any'] && ( */}
        <Form
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
        >
          <CitiesInfo />
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
            loading={isLoading}
          >
            Create City
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTourCitiesModal;
