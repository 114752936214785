import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  DateInput,
  FormInputItem,
} from '../../../components/common/FormItem/FormItems';
import {
  SelectMaharam,
  SelectOptionsInput,
} from '../../../components/common/FormItem/SelectCustomFeilds';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useCheckTrackingAndSerialMutation } from '../API/Endpoints/invoiceHajjPreRegEndspoint';
import { IHajiInformation } from '../Types/InvoiceHajjiPre.Types';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib/form';
import HajjDetails_List_Info from './HajjDetails_List_Info';

const HajiInformation = ({
  value,
  form,
}: {
  value?: IHajiInformation[];
  form?: FormInstance<any>;
}) => {
  const initialValue = value?.map((item) => {
    return {
      hajiinfo_name: item.hajiinfo_name,
      hajiinfo_tracking_number: item.hajiinfo_tracking_number,
      hajiinfo_nid: item.hajiinfo_nid,
      hajiinfo_serial: item.hajiinfo_serial,
      haji_info_maharam: item.haji_info_maharam,
      haji_info_vouchar_no: item.haji_info_vouchar_no,
      hajiinfo_mobile: item.hajiinfo_mobile,
      hajiinfo_gender: item.hajiinfo_gender,
      haji_info_possible_year: item.haji_info_possible_year
        ? dayjs(item.haji_info_possible_year)
        : undefined,
      haji_info_reg_year: dayjs(item.haji_info_reg_year),
      hajiinfo_dob: item.hajiinfo_dob ? dayjs(item.hajiinfo_dob) : undefined,
    };
  });

  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Haji Information 🕌' />
      <Form.List
        name='haji_information'
        initialValue={initialValue ? initialValue : [{}]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <HajjDetails_List_Info
                key={key}
                name={name}
                index={index}
                value={value}
                add={add}
                remove={remove}
                form={form}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default HajiInformation;
