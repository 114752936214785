import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import {
  Fixed2,
  formatAmount,
  formatAmountAbs,
} from '../../../../common/utils/common.utils';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  IClientLedger,
  IClientLedgerDataType,
} from '../types/clientLedgerTypes';

type Props = {
  clientLedger: HTTPResponse<IClientLedgerDataType> | undefined;
  columns: ColumnsType<IClientLedger>;
  isForPrint?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
  handlePaginationChange: (current: number, pageSize: number) => void;
  loading: boolean;
};

const { Text } = Typography;

const ClientLedgerTable = ({
  clientLedger,
  columns,
  isForPrint,
  pagination,
  handlePaginationChange,
  loading,
}: Props) => {
  if (!clientLedger?.data?.ledgers) return <></>;
  return (
    <div>
      <Text className='mb-5'>
        You have total: {clientLedger?.count} results
      </Text>
      <Table
        rowClassName={(record) => {
          const { ctrxn_lbalance, type } = record;
          let className = 'normal-table-row';
          const isBF = type === 'BF';

          if (ctrxn_lbalance == 0) {
            className += ' void_inv-row-style';
          }

          if (isBF) {
            className += ' bf-row';
          }

          return className;
        }}
        style={{ width: '100%' }}
        scroll={{ x: true }}
        tableLayout='fixed'
        size='small'
        bordered
        rowKey={(e) => e.ctrxn_id}
        className='normal-table'
        pagination={
          clientLedger?.count !== undefined && clientLedger?.count < 50
            ? false
            : {
              ...pagination,
              total: clientLedger?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        dataSource={clientLedger?.data?.ledgers}
        columns={columns}
        loading={{
          spinning: loading,
          indicator: loadingIndicator,
        }}
        summary={(rec) => {
          let total_debit = 0;
          let total_credit = 0;
          let discounted_total_credit = 0;

          rec.forEach((item) => {
            const { ctrxn_type, bf, ctrxn_amount, trxntype_name } = item;
            const is_bf_negative = Fixed2(bf) < 0;
            const is_discounted = trxntype_name?.includes('DISCOUNT');

            if (is_bf_negative) {
              total_debit += Math.abs(Fixed2(bf));
            } else {
              total_credit += Math.abs(Fixed2(bf));
            }

            if (ctrxn_type === 'DEBIT') {
              total_debit += Fixed2(ctrxn_amount);
            } else if (ctrxn_type === 'CREDIT' && !is_discounted) {
              total_credit += Fixed2(ctrxn_amount);
            } else if (ctrxn_type === 'CREDIT' && is_discounted) {
              discounted_total_credit += Fixed2(ctrxn_amount);
            }
          });
          const calculate_las_balance =
            total_credit + discounted_total_credit - total_debit;

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={0} />
                <Table.Summary.Cell index={0} className='no-print' />
                <Table.Summary.Cell index={0} className='no-print' />
                <Table.Summary.Cell index={0} className='no-print' />
                <Table.Summary.Cell index={0} className='no-print' />
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Text strong style={{ textAlign: 'center', fontSize: 14 }}>
                    TOTAL
                  </Text>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={1} colSpan={1}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ color: 'red' }}>
                      {formatAmount(total_debit)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2} colSpan={1}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ color: 'green' }}>
                      {formatAmount(total_credit)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>

                <Table.Summary.Cell index={56} colSpan={1}>
                  <div style={{ textAlign: 'right' }}>
                    <Typography.Text
                      style={{
                        color: calculate_las_balance < 0 ? 'red' : 'green',
                      }}
                    >
                      {isForPrint
                        ? formatAmount(calculate_las_balance)
                        : formatAmountAbs(calculate_las_balance)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={2} />
              </Table.Summary.Row>
              {/*      <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={16}>
                  <div>
                    {calculate_las_balance > 0 ? (
                      <Row justify={'center'}>
                        <Col span={10}>
                          <Typography.Text strong italic>
                            Your balance is :{'   '}
                            {Math.abs(calculate_las_balance).toLocaleString()}
                          </Typography.Text>
                        </Col>

                        <Col span={10}>
                          {
                            <NumToWord
                              number={Math.abs(calculate_las_balance)}
                            />
                          }
                        </Col>
                      </Row>
                    ) : (
                      <Row justify={'center'} align={'middle'}>
                        <Col span={10}>
                          <Typography.Text strong italic>
                            Amount Due To Us :{'   '}
                            {formatAmountAbs(calculate_las_balance)}
                          </Typography.Text>
                        </Col>
                        <Col span={10}>
                          {
                            <NumToWord
                              style={{
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                              }}
                              number={Math.abs(calculate_las_balance)}
                            />
                          }
                        </Col>
                      </Row>
                    )}
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row> */}
            </>
          );
        }}
      />
    </div>
  );
};

export default ClientLedgerTable;
