import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { IAirlineWiseSalesReportDataType } from '../types/AirlineWiseSalesReportTypes';

export const AirlineWiseSalesReportEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all Airline Wise Sales Report
    getAirlineWiseSalesReport: build.query<
      HTTPResponse<IAirlineWiseSalesReportDataType>,
      {
        airline_id: string | number;
        query: string;
      }
    >({
      query: ({ airline_id, query }) => ({
        url: `/report/airline-wise-sales-report/${airline_id}${query}`,
      }),
      providesTags: [{ type: 'AirlineReport', id: 'AIRLINE' }],
    }),
  }),
});

export const { useLazyGetAirlineWiseSalesReportQuery } =
  AirlineWiseSalesReportEndpoints;
