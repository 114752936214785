import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { useCreateExpenseHeadMutation } from '../Endpoints/expenseHeadEndpoints';
import { IExpenseHeadFormData } from '../types/ExpenseHeadTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

const AddNewHeadModal = ({ exHeadNaList }: { exHeadNaList: string[] }) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [addExpenseHead, { isError, isSuccess, isLoading }] =
    useCreateExpenseHeadMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Expense Head Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async (values: IExpenseHeadFormData) => {
    const body: IExpenseHeadFormData = {
      ...values,
      head_created_by: user?.user_id as number,
    };
    await addExpenseHead(body);
    dispatch(setModal(false));
    form.resetFields();
  };
  return (
    <Form
      layout='horizontal'
      form={form}
      labelAlign='left'
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label='Head Name'
        name='head_name'
        rules={[
          { required: true, message: 'Please enter head name!' },
          { max: 75, message: 'Maximum 75 characters' },
          {
            validator(_, value) {
              if (value) {
                if (exHeadNaList.includes(value.trim())) {
                  return Promise.reject('Expense Head Name is already Exist');
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder='Enter Head Name' />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default AddNewHeadModal;
