import { Button, Form, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import TourInfo from '../component/TourInfo';
import { useCreateTourGroupMutation } from '../endpoints/tourGroupEndpoints';
import { ITourGroupInfo } from '../types/TourTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';

type props = {
  btnTitle?: any;
  setClick: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setGroupName?: React.Dispatch<React.SetStateAction<number | undefined>>;
  modalTitle?: string;
};

const CreateTourGroup = ({
  btnTitle,
  setClick,
  setGroupName,
  modalTitle,
}: props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addTourGroup, { isError, data, isSuccess, isLoading }] =
    useCreateTourGroupMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Tour Group Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const handleSubmit = async (values: ITourGroupInfo) => {
    const body: ITourGroupInfo = {
      ...values,
    };
    body.tour_info.map((item) => {
      item.group_created_by = user?.user_id as number;
    });
    await addTourGroup(body?.tour_info);
    setIsModalOpen(false);
    setClick(false);
    form.resetFields();
  };

  /* collect Group id */
  useEffect(() => {
    setGroupName && setGroupName(data?.data as number | undefined);
  }, [data?.data]);

  const showModal = () => {
    setIsModalOpen(true);
    setClick(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick(false);
  };

  return (
    <>
      <Button
        type={!btnTitle ? 'primary' : 'default'}
        onClick={showModal}
        style={{
          marginBottom: btnTitle ? 0 : '22px',
          padding: '0 15px',
        }}
      >
        {btnTitle ? btnTitle : 'Create Tour Group'}
      </Button>

      <Modal
        title={'Create Tour Group'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout='vertical' onFinish={handleSubmit}>
          <TourInfo />
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
            loading={isLoading}
          >
            Create Group
          </Button>
        </Form>
      </Modal>

      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default CreateTourGroup;
