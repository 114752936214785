import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import HajjPassengerInfoList from './HajjPassengerInfoList';

type Props = { form: FormInstance<any> };

export default function NewAddHajjPilgrimsInfo({ form }: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Pilgrims Information' />
      <Form.List name='pilgrims_information' initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <HajjPassengerInfoList
                key={key}
                name={name}
                form={form}
                index={index}
                add={add}
                remove={remove}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}
