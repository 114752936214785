import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { IPassportStatusReportFormDataTypes } from '../../../Reports/PassportStatusReport/types/passportStatusReportTypes';
import { SMS } from '../../../SMS System/Api/Constants/Constants';
import {
  IInvoicePassportDataType,
  IInvoicePassportStatusFormDataType,
  IPassportDataType,
  IPassportStatusFormDataType,
} from '../../PassportTypes/PassportTypes';
import { PASSPORT, PASSPORT_STATUS } from '../Constants/Constants';

export const PassportStatusEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //change passport status
    changePassportStatus: build.mutation<void, IPassportStatusFormDataType>({
      query: (body) => ({
        url: `/passport-management/status/${body.passport_id}`,
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification(
            'success',
            'Passport Status Changed Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'Passport', id: PASSPORT },
        { type: 'Passport_Status', id: PASSPORT_STATUS },
        { type: 'Sms', id: SMS },
      ],
    }),

    //get all invoice passport status
    getAllInvoicePassportStatus: build.query<
      HTTPResponse<IInvoicePassportDataType[]>,
      void
    >({
      query: () => ({ url: `/passport-management/invoice-status` }),
      providesTags: () => [{ type: 'Passport_Status', id: PASSPORT_STATUS }],
    }),

    //change invoice passport status
    changeInvoicePassportStatus: build.mutation<
      void,
      IInvoicePassportStatusFormDataType
    >({
      query: (body) => ({
        url: `/passport-management/status/${body.passport_id}`,
        method: 'POST',
        body: body,
      }),

      onQueryStarted: async (arg, { queryFulfilled }) => {
        await assyncWrapper(async () => {
          await queryFulfilled;

          toasterNotification(
            'success',
            'Invoice Passport Status Changed Successfully'
          );
        });
      },
      invalidatesTags: () => [
        { type: 'Passport', id: PASSPORT },
        { type: 'Passport_Status', id: PASSPORT_STATUS },
        { type: 'Sms', id: SMS },
      ],
    }),
  }),
});

export const {
  useGetAllInvoicePassportStatusQuery,
  useChangePassportStatusMutation,
  useChangeInvoicePassportStatusMutation,
} = PassportStatusEndpoints;
