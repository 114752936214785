import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { useParams } from 'react-router';

type Props = {
  index: number;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  name: number;
  marginTop?: number | string;
  dataLength?: number | undefined;
  form?: FormInstance<any>;
  NamePath?: NamePath;
};

const FormListAddRemove = ({
  index,
  add,
  remove,
  name,
  marginTop,
  dataLength,
  form,
  NamePath,
}: Props) => {
  const { id } = useParams();
  return (
    <div style={{ marginTop: marginTop || '7px' }}>
      {index === 0 ? (
        <Button
          type='primary'
          onClick={() => add()}
        >
          <PlusOutlined />
        </Button>
      ) : (
        <Form.Item label={false}>
          <Button
            type='primary'
            danger
            onClick={() => {
              const prev = NamePath && form?.getFieldValue(NamePath);
              NamePath && form?.setFieldValue(NamePath, Number(!prev));
              if (id) {
                if (dataLength && index >= dataLength) remove(name);
              } else {
                remove(name);
              }
            }}
          >
            <MinusCircleOutlined />
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

export default FormListAddRemove;
