import { Table } from 'antd';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import { useGetAllMoneyReceiptClientQuery } from '../../api/endpoints/clientEndpoints';
import { IClientMoneyReceiptData } from '../../types/clientInterfaces';
import { clientPaymentUtil } from '../../utils/utils_tsx/clientPaymentUtil';
import { pagination } from '../../../../../common/utils/common.utils';

type Props = { id: string };

const ClientDetailPayment = ({ id }: Props) => {
  const { data, isLoading } = useGetAllMoneyReceiptClientQuery(id, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IClientMoneyReceiptData[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });
  return (
    <div>
      <Table
        size='small'
        bordered
        columns={clientPaymentUtil()}
        dataSource={data}
        pagination={pagination(data.length)}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading,
          indicator: loadingIndicator,
        }}
      />
    </div>
  );
};

export default ClientDetailPayment;
