import { Button, Popconfirm, Space, Tag, message } from 'antd';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import { IPermission } from '../../../../common/types/commonTypes';
import { useDeleteTransportTypesMutation } from '../endpoints/transportTypeEndpoints';
import { ITransportType } from '../types/transportTypeTypes';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';

type Props = {
  setEditInfo: React.Dispatch<React.SetStateAction<ITransportType | null>>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const transportColumn = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<ITransportType> => {
  const searchColumnProps = useGetSearchColumnProps<ITransportType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const handleClick = (record: ITransportType) => {
    setEditInfo(record);
    showModal();
  };
  /**
   * handle delete
   */
  const [deleteTransportType, { isError, isSuccess, isLoading }] =
    useDeleteTransportTypesMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Treansport Type has been Deleted');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (deleteId: number, index: number) => {
    setColumnIndex(index);
    await deleteTransportType({
      deleteId,
      deleted_by: user?.user_id as number,
    });
  };
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'serial',

      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Transport Type Name',
      dataIndex: 'ttype_name',
      key: 'ttype_name',

      ...searchColumnProps('ttype_name'),
    },

    {
      title: 'Status',
      dataIndex: 'ttype_status',
      key: 'ttype_status',
      align: 'center',

      render: (_, data) => {
        let color = 'green';
        color = data.ttype_status ? 'green' : 'red';

        return (
          <Tag color={color}>{data.ttype_status ? 'Active' : 'Inactive'}</Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          {record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}

          {record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record.ttype_id, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
