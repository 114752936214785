import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React from 'react';
interface DataType {
  key: string;
  serial: number;
  payment_date: string;
  vouchar_no: number;
  payment_amount: number;
  account: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'SL.',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
  },
  {
    title: 'Vouchar No',
    dataIndex: 'vouchar_no',
    key: 'vouchar_no',
  },
  {
    title: 'Payment Amount',
    dataIndex: 'payment_amount',
    key: 'payment_amount',
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
  },
];

const data: DataType[] = [
  {
    key: '1',
    serial: 1,
    payment_date: '2022-05-15',
    vouchar_no: 24145,
    payment_amount: 1230,
    account: 'ADSFAD',
  },
];

type Props = {};

const PaymentsVenDet = (props: Props) => {
  return (
    <div>
      <Table
        size='small'
        bordered
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default PaymentsVenDet;
