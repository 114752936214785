import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row } from 'antd';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import {
  useEditClientMutation,
  useGetSingleClientsQuery,
} from '../api/endpoints/clientEndpoints';
import Client_Add_Edit from '../components/common/Client_Add_Edit';
import { IClientEditFormData } from '../types/clientInterfaces';

function setUndefinedForNull(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === null) {
      obj[key] = undefined;
    }
  }
}

const ClientEdit = () => {
  const editMode = useRef<boolean>(false);
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { id } = useParams();

  if (id) {
    editMode.current = true;
  }
  const { data: client, isLoading } = useGetSingleClientsQuery(id as string);
  useEffect(() => {
    if (editMode.current == true) {
      form.setFieldsValue({
        ...client?.data,
        client_category_id: client?.data?.client_category_id,
        client_type: client?.data?.client_type,
        email: client?.data?.client_email,
        client_designation: client?.data?.client_designation,
        trade_license: client?.data?.client_trade_license,
        opening_balance_type: client?.data?.opening_balance_type,
        opening_balance: client?.data?.opening_balance || 0,
        address: client?.data?.client_address,
        client_name: client?.data?.client_name,
        client_gender: client?.data?.client_gender,
        mobile: client?.data?.client_mobile,
        client_source: client?.data?.client_source,
      });
    }
  }, [client?.data]);

  const [editClient, { isLoading: editLoading, isSuccess: editSuccess }] =
    useEditClientMutation();
  const handle = async () => {
    const values = (await form.getFieldsValue()) as IClientEditFormData;

    values.client_created_by = user?.user_id as number;

    setUndefinedForNull(values);

    await editClient({ id: id as string, values });
  };

  useEffect(() => {
    if (editSuccess) {
      navigate('/clients');
    }
  }, [editSuccess]);

  return (
    <>
      <BreadCrumb arrOfOption={['Edit Client']} />

      <Row align='middle' gutter={16}>
        <Col style={{ marginBottom: '1rem' }}>
          <Link to='/clients'>
            <Button type='primary'>
              <ArrowLeftOutlined />
              Return to Client List
            </Button>
          </Link>
        </Col>
      </Row>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Card>
          <Form layout='vertical' form={form} onFinish={handle}>
            <Client_Add_Edit form={form} />
            <Button type='primary' htmlType='submit' loading={editLoading}>
              Edit Client Information
            </Button>
          </Form>
        </Card>
      )}
    </>
  );
};

export default ClientEdit;
