import { Col, Form, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { ISetState } from '../../../../common/types/commonTypes';
import {
  FormButton,
  FormInputItem,
} from '../../../../components/common/FormItem/FormItems';
import { useEditAuthorityMutation } from '../../Api/Endpoints/AuthorityEndpoints';
import {
  LoanAuthorityDataType,
  LoanAuthorityEditFormData,
} from '../../LoanTypes/LoanTypes';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  info: LoanAuthorityDataType;
  setNull: ISetState<LoanAuthorityDataType | null>;
}
const EditAuthorityModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const user = useSelector(selectUser);

  const [editAuthority, { isLoading }] = useEditAuthorityMutation();

  useEffect(() => {
    form.setFieldsValue({
      name: info.authority_name,
      contact: info.authority_mobile,
      address: info.authority_address,
    });
  }, [info]);

  const onFinish = async (values: LoanAuthorityEditFormData) => {
    const body: LoanAuthorityEditFormData = {
      ...values,
      authority_id: info.authority_id,
      created_by: user?.user_id as number,
    };

    await editAuthority(body);
    dispatch(setModal(false));
    setNull(null);
    form.resetFields();
  };

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <FormInputItem
            label='Name'
            name='name'
            required
            size={24}
          />

          <FormInputItem
            label='Contact'
            name='contact'
            size={24}
          />
          <Col
            span={6}
            xs={24}
            sm={24}
            md={24}
            lg={24}
          >
            <Form.Item
              label='Address'
              name='address'
            >
              <TextArea
                placeholder='Address'
                maxLength={55}
              />
            </Form.Item>
          </Col>
          <FormButton
            label={'Edit Authority'}
            loading={isLoading}
          />
        </Row>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default EditAuthorityModal;
