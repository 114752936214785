import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { selectCommon, setModal } from '../../common/slices/commonSlice';
import {
  useGetAllAgentProfileQuery,
  useLazyGetAllTrashedAgentsQuery,
} from '../../modules/Client/Agents_Profile/api/endpoints/agentProfileEndPoints';

import { AgentProfileDataType } from '../../modules/Client/Agents_Profile/types/agentProfileTypes';
import AgentsAddModal from '../../modules/Client/Agents_Profile/modals/AgentsAddModal';

type Props = {
  setClick: (arg: boolean) => void;
  setInstanceAgentSelect?:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
};
const CreateAgentModal = ({ setClick, setInstanceAgentSelect }: Props) => {
  const [checkButtonState, setCheckButtonState] = useState(false);
  const modalState = useSelector(selectCommon);
  const handleModal = () => {
    dispatch(setModal(false));
    setClick(false);
  };

  const [editInfo, setEditInfo] = useState<AgentProfileDataType | null>(null);
  const dispatch = useAppDispatch();

  const showAddAgentModal = () => {
    setClick(true);
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: agentProfile,
    isSuccess,
  } = useGetAllAgentProfileQuery(undefined, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: AgentProfileDataType[] = [];
      const agentProfileList: string[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.agent_name) {
            agentProfileList.push(element.agent_name);
          }

          dataToReturn.push({ ...element, key: i + 1 });
        }
      }
      return { ...cache, data: { dataToReturn, agentProfileList } };
    },
  });

  const [
    fetchTrashedAgents,
    { data: trashedQuotation, isLoading: isTrashLoading, isUninitialized },
  ] = useLazyGetAllTrashedAgentsQuery({
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: AgentProfileDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  const handleTrashButton = () => {
    if (checkButtonState == false) {
      isUninitialized && fetchTrashedAgents(undefined, true);

      setCheckButtonState(true);
    } else {
      setCheckButtonState(false);
    }
  };

  return (
    <div>
      <Modal
        title='Add Agent Profile'
        open={modalState.isModal}
        okText={'Submit'}
        cancelText={'Close'}
        onOk={handleModal}
        onCancel={handleModal}
        footer={null}
        width={600}
        forceRender
      >
        <AgentsAddModal
          setClick={setClick}
          setInstanceAgentSelect={setInstanceAgentSelect}
        />
      </Modal>

      <Button onClick={showAddAgentModal} style={{ width: '100%' }}>
        <PlusOutlined /> Add New Agent
      </Button>
    </div>
  );
};

export default CreateAgentModal;
