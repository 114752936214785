import { HTTPResponse } from '../../../../common/types/commonTypes';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import { api } from '../../../../app/baseQuery';

import {
  IPassportStatusEditFormData,
  IPassportStatusFormData,
  PassportStatusDataType,
} from '../types/PassportStatusTypes';

const PASSPORTSTATUS = 'PASSPORTSTATUS';
export const passportStatusEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all passport status
    getAllPassportStatus: build.query<
      HTTPResponse<PassportStatusDataType[]>,
      void
    >({
      query: () => ({ url: `/configuration/passportStatus/view-all` }),
      providesTags: () => [{ type: 'PassportStatus', id: PASSPORTSTATUS }],
    }),

    //get all passport status
    getAllPassportStatusWithPagination: build.query<
      HTTPResponse<PassportStatusDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/passportStatus/passport?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'PassportStatus', id: PASSPORTSTATUS }],
    }),

    //create passport status
    createPassportStatus: build.mutation<void, IPassportStatusFormData>({
      query: (body) => ({
        url: `/configuration/passportStatus/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'PassportStatus', id: PASSPORTSTATUS }],
    }),

    //edit passport status
    editPassportStatus: build.mutation<void, IPassportStatusEditFormData>({
      query: (body) => ({
        url: `/configuration/passportStatus/update/${body.pstatus_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'PassportStatus', id: PASSPORTSTATUS }],
    }),

    //delete passport status
    deletePassportSttaus: build.mutation<
      void,
      { statusId: number; deleted_by: number }
    >({
      query: ({ deleted_by, statusId }) => ({
        url: `/configuration/passportStatus/delete/${statusId}`,
        method: 'DELETE',
        body: { deleted_by: deleted_by },
      }),

      invalidatesTags: () => [{ type: 'PassportStatus', id: PASSPORTSTATUS }],
    }),
  }),
});

export const {
  useGetAllPassportStatusQuery,
  useCreatePassportStatusMutation,
  useEditPassportStatusMutation,
  useDeletePassportSttausMutation,
  useGetAllPassportStatusWithPaginationQuery,
} = passportStatusEndpoints;
