import { Col, Divider, Form, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { useState } from 'react';
import { useGetAllAccommodationQuery } from '../../../Configuration/Tour/Accommodations/endpoints/accommodationEndpoints';
import CreateTourAccommodation from '../../../Configuration/Tour/Accommodations/modal/CreateTourAccommodation';

type Props = {
  name: NamePath;
  form?: FormInstance<any>;
  isRequired?: boolean;
  onChange: React.Dispatch<React.SetStateAction<number | undefined>>;
  size?: Number;
  setInstanceAccommodationSelect?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
};
const SelectTourAccommodation = ({
  name,
  form,
  onChange,
  isRequired,
  size,
  setInstanceAccommodationSelect,
}: Props) => {
  const { data } = useGetAllAccommodationQuery();
  const selectData = data?.data;
  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={
            selectData[i].accommodation_id +
            ' ' +
            selectData[i].accmvendor_vendor_id
          }
          value={selectData[i].accommodation_id}
        >
          {selectData[i].accommodation_hotel_name}
        </Select.Option>
      );
    }
  }

  const [click, setClick] = useState<boolean>();
  return (
    <Col
      xs={24}
      sm={12}
      md={8}
      lg={size ? Number(size) : 6}
    >
      <Form.Item
        required={isRequired}
        name={name}
        label='Accommodation'
      >
        <Select
          allowClear
          placeholder={`Select accomdation`}
          showSearch
          onChange={(e) => onChange(e)}
          style={{ padding: '0', margin: '0', border: '0' }}
          optionFilterProp='roleMobile'
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={(menu) => (
            <>
              <CreateTourAccommodation
                isPlusButton
                setClick={setClick}
                setInstanceAccommodationSelect={setInstanceAccommodationSelect}
              />
              <Divider style={{ margin: '8px 0' }} />

              {menu}
            </>
          )}
          open={click === true ? false : undefined}
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};
export default SelectTourAccommodation;
