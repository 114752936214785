import { Button, Card, Col, Form, Input, Row, message } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../auth/states/userSlice';
import { useChangeUserPassMutation } from '../../../modules/Configuration/Users/endpoints/userEndpoints';
import LoadingSkeleton from '../../../modules/Dashboard/Components/LoadingSkelton';
import { IChangePassword } from '../header/interface/headerTypes';

type Props = {};

const ChangeUserPassword = (props: Props) => {
  const normalizeEmail = (value: string) => {
    if (value) {
      return value.trim();
    }
    return value;
  };

  return (
    <Card>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col span={6} xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            name='current_user_email'
            label='User Email Address'
            rules={[{ required: true, message: `User Email is required!!` }]}
            normalize={normalizeEmail}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6} lg={24}>
          <Form.Item
            label='New Password'
            name='password'
            rules={[
              {
                required: true,
                message: `New Password is required!!`,
              },

              {
                validator: (_, value) => {
                  if (value === 'customer') {
                    return Promise.resolve();
                  }
                  if (value && value.length < 8) {
                    return Promise.reject('');
                  }
                  const pattern = /^(?=.*[!@#$%^&*])/;

                  if (value && !pattern.test(value)) {
                    return Promise.reject('');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password autoComplete='new-password' />
          </Form.Item>
        </Col>
      </Row>
      <Button type='primary' htmlType='submit'>
        Submit
      </Button>
    </Card>
  );
};

export default ChangeUserPassword;

export const ChangePassword = ({
  setIsmodal,
}: {
  setIsmodal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [form] = Form.useForm();
  const [ChangePassword, { isError, isLoading, isSuccess }] =
    useChangeUserPassMutation();
  const user = useSelector(selectUser);

  const onFinish = (values: IChangePassword) => {
    values.user_id = user?.user_id as number;

    ChangePassword(values);
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('User password has been changed');
      setIsmodal(false);
      form.resetFields();
    }
  }, [isLoading, isSuccess]);

  isLoading && <LoadingSkeleton />;

  return (
    <Card>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={6} xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name='current_password'
              label='Current Password'
              required
            >
              <Input.Password placeholder='Enter your current password' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={6} lg={24}>
            <Form.Item
              label='New Password'
              name='new_password'
              required
              rules={[
                {
                  required: true,
                  message: `New Password is required!!`,
                },

                {
                  validator: (_, value) => {
                    if (value && value.length < 8) {
                      return Promise.reject(
                        'Password must be at least 8 characters long'
                      );
                    }
                    const pattern = /^(?=.*[!@#$%^&*])/;

                    if (value && !pattern.test(value)) {
                      return Promise.reject(
                        'password must contain at least one special character and number'
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                autoComplete='new-password'
                placeholder='Enter your new password'
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form>
    </Card>
  );
};
