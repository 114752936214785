import { Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { InvoiceHajjType } from '../Types/InvoiceHajjTypes';
import TransportInfoSubCom from './TransportInfoSubCom';

type Props = { form: FormInstance<any>; edit_data?: InvoiceHajjType };

export default function NewAddHajjFormTransportInfo({
  form,
  edit_data,
}: Props) {
  return (
    <div className='border p-5 my-20'>
      <FormHeaderTitle title='Transport Information 🚌' />
      <Row>
        <Form.List name='transport_information' initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <div style={{ width: '100%' }}>
              {fields.map(({ key, name }, index) => (
                <TransportInfoSubCom
                  add={add}
                  fields={fields}
                  form={form}
                  index={index}
                  name={name}
                  remove={remove}
                  edit_data={edit_data}
                  key={key}
                />
              ))}
            </div>
          )}
        </Form.List>
      </Row>
    </div>
  );
}
