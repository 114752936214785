export const INVESTMENTS = 'INVESTMENTS';
export const BALANCE_STATUS = 'BALANCE_STATUS';
export const BALANCE_TRANSFER = 'BALANCE_TRANSFER';
export const NON_INVOICE_INCOME = 'NON_INVOICE_INCOME';
export const INCENTIVE_INCOME = 'INCENTIVE_INCOME';
export const ACCOUNT_LIST = 'ACCOUNT_LIST';
export const CLIENT_BILL_ADJUSTMENT = 'CLIENT_BILL_ADJUSTMENT';
export const OPENING_BALANCE = 'OPENING_BALANCE';
export const TRANSACTION_HISTORY = 'TRANSACTION_HISTORY';
export const VENDOR_BILL_ADJUSTMENT = 'VENDOR_BILL_ADJUSTMENT';

export const accountsTags = [
  'Accounts',
  'Transaction_History',
  'Opening_Balance',
  'Balance_Status',
  'Balance_Transfer',
  'Non_Invoice_Income',
  'Investments',
  'Incentive_Income',
  'Client_Bill_Adjustment',
  'Vendor_Bill_Adjustment',
];
