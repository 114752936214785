import { Button, Popconfirm, Space, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import useGetSearchColumnProps from '../../../../common/hooks/SearchData';
import {
  crud,
  IPermission,
  ISetState,
} from '../../../../common/types/commonTypes';
import { useDeleteVisaMutation } from '../endpoints/visaEndpoints';

import { visaDataType } from '../types/visaTypes';

type Props = {
  setEditInfo: ISetState<visaDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const columns = ({
  pagination,
  setEditInfo,
  showModal,
  permission,
}: Props): ColumnsType<visaDataType> => {
  const searchColumnProps = useGetSearchColumnProps<visaDataType>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  /**
   * handle click
   */

  const handleClick = (record: visaDataType) => {
    setEditInfo(record);
    showModal();
  };

  const [deleteVisa, { isError, isSuccess, isLoading }] =
    useDeleteVisaMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Visa Type Deleted Successfully');
    } else if (isError) {
      setColumnIndex(null);
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleDelete = async (record: visaDataType, index: number) => {
    setColumnIndex(index);
    await deleteVisa({ ...record });
  };
  return [
    {
      title: 'Sl',
      dataIndex: 'key',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Type Name',
      dataIndex: 'type_name',
      key: 'name',

      ...searchColumnProps('type_name'),
    },

    {
      title: 'Action',
      key: 'action',

      render: (_, record, index) => (
        <Space size='small'>
          {permission?.[crud.update] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => handleClick(record)}
            >
              Edit
            </Button>
          )}

          {permission?.[crud.delete] && record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record, index)}
            >
              <Button
                danger
                size='small'
                type='primary'
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
