import {
  Card,
  Col,
  Descriptions,
  Form,
  FormListFieldData,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import SelectPaymentMethod from '../../../../components/common/paymentMethod/SelectPaymentMethod';
import { IaccountsByMethod } from '../../../Accounts/AccountsTypes/AccountsTypes';
import {
  useLazyGetAllAccountsByTypeQuery,
  useLazyGetSingleAccountQuery,
} from '../../../Accounts/Api/Endpoints/accountsEndpoints';
import { payment_type } from '../../../Loan_Management/LoanTypes/LoanTypes';
import { IAirTicketInfoByNo, refund_type } from '../../RefundTypes/RefundTypes';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {};

const AirticketVendorInfo = ({
  field,
  form,
  airticketVendorInitialValues,
}: {
  field: FormListFieldData;
  form: FormInstance<any>;
  airticketVendorInitialValues?: IAirTicketInfoByNo[];
}) => {
  const [vendorRefundMethod, setVendorRefundMethod] =
    useState<string>('ADJUST');
  const [paymentType, setPaymentType] = useState<string>();
  const isVendorChanged = useRef(false);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [accountID, setAccountID] = useState<number>();
  const [balance, setBalance] = useState<number>();
  const [changeColor, setChangeColor] = useState('');

  const [fetchAccountsByPayment, { data: accountsByPayment }] =
    useLazyGetAllAccountsByTypeQuery();

  useEffect(() => {
    const lastBalance =
      airticketVendorInitialValues?.length &&
      airticketVendorInitialValues[field.name].vendor_last_balance
        ? airticketVendorInitialValues[field.name].vendor_last_balance
        : airticketVendorInitialValues?.length &&
          airticketVendorInitialValues[field.name].combined_last_balance;

    const balance = Number(lastBalance) || 0;

    let color;
    if (balance > 0) {
      color = 'green';
    } else if (balance < 0) {
      color = 'red';
    } else {
      color = '';
    }

    setChangeColor(color);
  }, [airticketVendorInitialValues]);

  useEffect(() => {
    if (paymentMethod) {
      fetchAccountsByPayment(Number(paymentMethod));
    }
    if (isVendorChanged.current) {
      form.setFields([
        {
          name: ['vendor_refund_info', field.name, 'vrefund_account_id'],
          value: undefined,
        },
      ]);
      setBalance(undefined);
      return;
    }
  }, [paymentType, paymentMethod]);

  useEffect(() => {
    form.setFields([
      {
        name: ['vendor_refund_info', field.name, 'payment_method'],
        value: undefined,
      },

      {
        name: ['vendor_refund_info', field.name, 'vrefund_account_id'],
        value: undefined,
      },
    ]);
  }, [vendorRefundMethod]);

  const [fetchBalance, { data: accountBalance }] =
    useLazyGetSingleAccountQuery();

  useEffect(() => {
    if (accountID) {
      fetchBalance(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    setBalance(accountBalance?.data && accountBalance?.data.amount);
  }, [accountBalance]);
  useEffect(() => {
    form.setFields([
      {
        name: ['vendor_refund_info', field.name, 'advance_amount'],
        value:
          airticketVendorInitialValues &&
          Fixed2(
            Number(
              airticketVendorInitialValues[field.name].airticket_purchase_price
            ) - airticketVendorInitialValues[field.name].vrefund_charge_amount
          ),
      },
    ]);
    form.setFields([
      {
        name: ['vendor_refund_info', field.name, 'return_amount'],
        value:
          (airticketVendorInitialValues &&
            Number(
              airticketVendorInitialValues[field.name].airticket_purchase_price
            ) -
              airticketVendorInitialValues[field.name].vrefund_charge_amount) ||
          0,
      },
    ]);
    if (airticketVendorInitialValues) {
      form.setFields([
        {
          name: ['vendor_refund_info', field.name, 'vrefund_return_amount'],
          value:
            Number(
              airticketVendorInitialValues[field.name].airticket_purchase_price
            ) -
              airticketVendorInitialValues[field.name].vrefund_charge_amount ||
            0,
        },
      ]);
    }

    form.setFields([
      {
        name: ['vendor_refund_info', field.name, 'vrefund_date'],
        value: dayjs(),
      },
    ]);
    if (
      airticketVendorInitialValues?.length &&
      Number(airticketVendorInitialValues[field.name].vendor_last_balance) < 0
    ) {
      form.setFields([
        {
          name: ['vendor_refund_info', field.name, 'vrefund_payment_type'],
          value: 'ADJUST',
        },
      ]);
    }
    if (airticketVendorInitialValues) {
      form.setFields([
        {
          name: ['vendor_refund_info', field.name, 'vrefund_total_amount'],
          value:
            airticketVendorInitialValues[field.name].airticket_purchase_price,
        },
      ]);
    }
  }, []);
  return (
    <>
      <Descriptions size='small' bordered column={1}>
        <DescriptionsItem label='Vendor Name'>
          {airticketVendorInitialValues &&
            (airticketVendorInitialValues[field.name].vendor_name
              ? airticketVendorInitialValues[field.name].vendor_name
              : airticketVendorInitialValues[field.name].combine_name)}
        </DescriptionsItem>
        <DescriptionsItem label='Total Refund'>
          {airticketVendorInitialValues &&
            Number(
              Number(
                airticketVendorInitialValues[field.name]
                  .airticket_purchase_price
              )
            )}
        </DescriptionsItem>
        <DescriptionsItem label='Refund Charge'>
          {airticketVendorInitialValues &&
            Number(
              airticketVendorInitialValues[field.name].vrefund_charge_amount
            )}
        </DescriptionsItem>
        <DescriptionsItem label='Return Amount'>
          {airticketVendorInitialValues &&
            Fixed2(
              Number(
                airticketVendorInitialValues[field.name]
                  .airticket_purchase_price
              ) - airticketVendorInitialValues[field.name].vrefund_charge_amount
            )}
        </DescriptionsItem>
        {airticketVendorInitialValues && (
          <DescriptionsItem label='Vendor Last Balance'>
            <span style={{ color: changeColor }}>
              {Number(
                airticketVendorInitialValues[field.name].vendor_last_balance
                  ? airticketVendorInitialValues[field.name].vendor_last_balance
                  : airticketVendorInitialValues[field.name]
                      .combined_last_balance
              ) || 0}
            </span>
          </DescriptionsItem>
        )}
      </Descriptions>
      <Card size='small'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col span={5} xs={24} sm={24} md={24} lg={8}>
            <Form.Item
              label='Vendor Refund Type'
              name={[field.name, 'vrefund_payment_type']}
              rules={[
                {
                  required: true,
                  message: 'Vendor Refund Type is required',
                },
              ]}
              initialValue='ADJUST'
            >
              <Select
                allowClear
                placeholder='Select Vendor Refund Type'
                style={{ width: '100%' }}
                onChange={(e: string) => {
                  setVendorRefundMethod(e);
                  if (!isVendorChanged.current) isVendorChanged.current = true;
                }}
              >
                {refund_type.map((item) => {
                  return (
                    <Select.Option
                      key={item}
                      value={item == 'Money Return' ? 'MONEY_RETURN' : 'ADJUST'}
                    >
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          {vendorRefundMethod == 'ADJUST' ? (
            <NumberInput
              name={[field.name, 'advance_amount']}
              label='Advance Amount'
              readOnly
              size={8}
              min='0'
              maxChar={14}
              minChar={0}
            />
          ) : (
            <>
              {paymentType == 'CHEQUE' ? (
                <>
                  <FormInputItem
                    label='Cheque No.'
                    required
                    name={[field.name, 'cheque_no']}
                    size={8}
                  />
                  <DateInput
                    label='Withdraw Date'
                    name={[field.name, 'withdraw_date']}
                    required
                    size={8}
                  />
                  <FormInputItem
                    label='Bank Name'
                    name={[field.name, 'bank_name']}
                    required
                    size={8}
                  />
                  <NumberInput
                    name={[field.name, 'return_amount']}
                    label='Return Amount'
                    disabled
                    size={8}
                    min='0'
                    maxChar={14}
                    minChar={0}
                  />
                  <DateInput
                    label='Date'
                    name={[field.name, 'vrefund_date']}
                    required
                    size={8}
                  />
                  <FormInputItem
                    label='Note'
                    name={[field.name, 'vrefund_note']}
                    size={8}
                  />
                </>
              ) : (
                <>
                  <SelectPaymentMethod
                    name={[field.name, 'payment_method']}
                    label='Payment Method'
                    size={8}
                    onChange={(value: string) => {
                      setPaymentMethod(value);
                      if (!isVendorChanged.current)
                        isVendorChanged.current = true;
                    }}
                  />
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item
                      name={[field.name, 'vrefund_account_id']}
                      label='Account'
                      rules={[
                        {
                          required: true,
                          message: `Account is required!`,
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder={'Select Account'}
                        optionFilterProp='children'
                        onSelect={(e: number) => {
                          setAccountID(e);
                        }}
                      >
                        {accountsByPayment?.data?.map(
                          (item: IaccountsByMethod) => {
                            return (
                              <Select.Option
                                key={item.account_id}
                                value={item.account_id}
                              >
                                {`${item.account_name}`}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <NumberInput
                    name={[field.name, 'return_amount']}
                    label='Return Amount'
                    disabled
                    size={8}
                    min='0'
                    maxChar={14}
                    minChar={0}
                  />
                  <Col span={6} xs={24} sm={24} md={24} lg={8}>
                    <Form.Item label='Available Balance' required>
                      <InputNumber
                        style={{ width: '100%' }}
                        readOnly
                        value={balance}
                      />
                    </Form.Item>
                  </Col>
                  {Number(paymentMethod) === 3 ? (
                    <Col span={6} xs={24} sm={24} md={24} lg={8}>
                      <Form.Item
                        label='Transaction Charge'
                        name={[field.name, 'trxn_charge_amount']}
                        required
                      >
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <DateInput
                    label='Date'
                    name={[field.name, 'vrefund_date']}
                    required
                    size={8}
                  />
                  <FormInputItem label='Note' name='vrefund_note' size={8} />
                </>
              )}
            </>
          )}
        </Row>
      </Card>
    </>
  );
};

export default AirticketVendorInfo;
