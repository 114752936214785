import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../auth/states/userSlice';
import { useGetAllProductsQuery } from '../../modules/Configuration/Products/endpoints/productsEndpoints';
import { useAddVendorMutation } from '../../modules/vendor/api/endpoints/vendorEndpoints';
import { IVendorFormData } from '../../modules/vendor/types/vendor.interfaces';
import CountryName from '../../helpers/CountryName';
import {
  DateInput,
  FormButton,
  FormInputItem,
  FormMobileNumberItem,
  NumberInput,
} from './FormItem/FormItems';
import { SelectOptionsInput } from './FormItem/SelectCustomFeilds';
import VendorProducts from '../../modules/vendor/components/common/VendorProducts';
import { ProductDataType } from '../../modules/Configuration/Products/types/productTypes';
import { selectCommon, setModal } from '../../common/slices/commonSlice';

type Props = {
  setInstanceVendorSelect:
    | React.Dispatch<React.SetStateAction<number | undefined>>
    | undefined;
  setClick: (arg: boolean) => void;
};

const CreateVendorModal = ({ setInstanceVendorSelect, setClick }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [checkedList, setCheckedList] = useState<number[]>([]);

  const navigate = useNavigate();
  const [openingBal, setOpeningBal] = useState('');
  const openingBalanceHandle = (value: string) => {
    setOpeningBal(value);
  };

  const user = useSelector(selectUser);

  const [selectProducts, setSelectProducts] = useState<number[]>([]);
  const { data: products } = useGetAllProductsQuery();

  const [CreateVendorModal, { isSuccess, isError, isLoading, data }] =
    useAddVendorMutation();
  // hendle add vendor--------------
  const handleAddVendor = async (values: IVendorFormData) => {
    setClick(false);
    if (selectProducts.length) {
      values.vendor_registration_date = dayjs(
        values.vendor_registration_date
      ).format('YYYY-MM-DD');
      values.vendor_created_by = user?.user_id as number;
      values.vendor_products_id = selectProducts;

      CreateVendorModal(values);
      setClick(false);
    } else message.error('Products is required!');
  };

  useEffect(() => {
    if (isSuccess) {
      message.success('Vendor has been added!');
      setIsModalOpen(false);
      setClick(false);
    }
    if (isError) {
      message.error('Vendor can not added!');
    }
  }, [isSuccess]);

  // set form value
  useEffect(() => {
    form.setFieldsValue({
      vendor_registration_date: dayjs(),
      dailCode: CountryName[19].dialCode,
    });
  }, []);

  useEffect(() => {
    if (data) {
      setInstanceVendorSelect && setInstanceVendorSelect(data?.data?.vendor_id);
    }
  }, [isSuccess]);

  /* Modal */
  const handleOk = () => {
    setIsModalOpen(false);
    setClick(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setClick(false);
  };
  const showModal = () => {
    setClick(true);
    setIsModalOpen(true);
  };
  return (
    <>
      <div>
        <Modal
          title='Add Vendor'
          open={isModalOpen}
          okText={'Submit'}
          cancelText={'Close'}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1000}
          forceRender
        >
          <Form form={form} layout='vertical' onFinish={handleAddVendor}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <FormInputItem
                name='vendor_name'
                label='Name'
                required
                size={6}
              />
              <FormInputItem
                name='vendor_email'
                label='Email'
                size={6}
                inputType='email'
              />
              <FormMobileNumberItem size={6} />
              <NumberInput
                label='Fixed advance:'
                name='vendor_fixed_advance'
                size={6}
                min='0'
                maxChar={14}
                minChar={0}
              />
              <DateInput
                name={'vendor_registration_date'}
                label='Date: '
                required
                size={6}
              />
              <SelectOptionsInput
                name={'vendor_opening_balance_pay_type'}
                label='Opening Balance Type: '
                options={['Due', 'Advance']}
                onSelect={openingBalanceHandle}
                size={6}
              />

              {openingBal && (
                <NumberInput
                  name={'vendor_opening_balance'}
                  label='Opening Balance : '
                  size={6}
                />
              )}
              <FormInputItem label='Address' name='vendor_address' size={6} />
              <VendorProducts
                products={products?.data as ProductDataType[]}
                setSelectProducts={(value) => setSelectProducts(value)}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
              />
            </Row>
            <FormButton label='Add vendor' loading={isLoading} />
          </Form>
        </Modal>
        <Button onClick={showModal} style={{ width: '100%' }}>
          <PlusOutlined />
        </Button>
      </div>
    </>
  );
};

export default CreateVendorModal;
