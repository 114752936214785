import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../app/hooks';
import { ISetState } from '../../../../common/types/commonTypes';
import { selectCommon, setModal } from '../../../../common/slices/commonSlice';
import { DepartmentDataType } from '../types/departmentTypes';
import { useEditDepartmentMutation } from '../endpoints/departmentEndpoints';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../../common/utils/assyncWrapper';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';

interface IProps {
  department: string[];
  info: DepartmentDataType;
  setNull: ISetState<DepartmentDataType | null>;
}
const DepartmentsEditModal = ({ info, setNull, department }: IProps) => {
  const dispatch = useAppDispatch();

  const [input, setInput] = useState<DepartmentDataType>(info);

  const modalSlice = useSelector(selectCommon);

  const [form] = Form.useForm();

  useEffect(() => {
    setInput(info);
  }, [info]);

  useEffect(() => {
    form.setFieldsValue({
      department_name: input.department_name,
    });
  }, [input]);

  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const [editDepartment, { isError, isSuccess, isLoading }] =
    useEditDepartmentMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Department Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as DepartmentDataType;
      values.department_id = info.department_id;
      await editDepartment({ ...values });
      setNull(null);
      dispatch(setModal(false));
    });
  };

  return (
    <div>
      <Form
        labelAlign='left'
        {...layout}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        form={form}
      >
        <Form.Item
          name='department_name'
          label='Enter Department Name: '
          rules={[
            { required: true, message: 'Please input Department name!' },
            { max: 65, message: 'Maximum 65 characters' },
            {
              validator(_, value) {
                if (value) {
                  if (department.includes(value)) {
                    return Promise.reject('Department Name is already Exist');
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder='Edit department name' />
        </Form.Item>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 9 }}>
          <Button
            disabled={modalSlice.modalLoading}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {isLoading && <LoadingIndicator />}
    </div>
  );
};

export default DepartmentsEditModal;
