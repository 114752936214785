import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';

import {
  AgencyDataType,
  IAgencyEditFormData,
  IAgencyFormData,
} from '../types/AgenciesTypes';

const AGENCY = 'AGENCY';
export const agencyEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all agency
    getAllAgency: build.query<HTTPResponse<AgencyDataType[]>, void>({
      query: () => ({ url: `/configuration/agency/view-all` }),
      providesTags: () => [{ type: 'Agency', id: AGENCY }],
    }),

    // get all agency
    getAllAgencyWithPagination: build.query<
      HTTPResponse<AgencyDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/agency?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Agency', id: AGENCY }],
    }),

    //create agency
    createAgency: build.mutation<void, IAgencyFormData>({
      query: (body) => ({
        url: `/configuration/agency/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Agency', id: AGENCY }],
    }),

    //edit agency
    editAgency: build.mutation<void, IAgencyEditFormData>({
      query: (body) => ({
        url: `/configuration/agency/update/${body.agency_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Agency', id: AGENCY }],
    }),

    //delete agency
    deleteAgency: build.mutation<void, AgencyDataType>({
      query: (body) => ({
        url: `/configuration/agency/delete/${body.agency_id}`,
        method: 'DELETE',
        body: { deleted_by: body.agency_updated_by },
      }),

      invalidatesTags: () => [{ type: 'Agency', id: AGENCY }],
    }),
  }),
});

export const {
  useGetAllAgencyQuery,
  useCreateAgencyMutation,
  useEditAgencyMutation,
  useDeleteAgencyMutation,
  useGetAllAgencyWithPaginationQuery,
} = agencyEndpoints;
