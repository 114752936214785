import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { NotificationConst } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import {
  IAdvanceReturnDetails,
  IGetAdvanceReturn,
  IGetAdvanceReturnForEdit,
  IVendorAdvanceReturn,
} from '../../types/vendor.interfaces';

export const advanceEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    /**
     * get all advance return vendors
     *
     */

    getAllAdvanceReturn: build.query<HTTPResponse<IGetAdvanceReturn[]>, void>({
      query: () => ({
        url: '/vendors/advance-return',
      }),
      providesTags: () => [{ type: 'Advance', id: 'ADVANCE' }],
    }),

    getVisaType: build.query<
      HTTPResponse<
        { type_id: number; type_name: string; type_created_by: number }[]
      >,
      void
    >({
      query: () => ({
        url: '/configuration/visa-types/view-all',
      }),
      providesTags: () => [{ type: 'Advance', id: 'ADVANCE' }],
    }),

    getAdvanceReturnForEdit: build.query<
      HTTPResponse<IGetAdvanceReturnForEdit>,
      number
    >({
      query: (id) => ({
        url: `/vendors/get-advancereturn-for-edit/${id}`,
      }),
      providesTags: () => [{ type: 'Advance', id: 'ADVANCE' }],
    }),

    advanceReturnDetails: build.query<
      HTTPResponse<IAdvanceReturnDetails>,
      number
    >({
      query: (id) => ({
        url: `/vendors/advance-return-details/${id}`,
      }),
      providesTags: () => [{ type: 'Advance', id: 'ADVANCE' }],
    }),

    postAdvanceReturn: build.mutation<HTTPResponse<void>, IVendorAdvanceReturn>(
      {
        query: (body) => ({
          url: '/vendors/advance-return',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Advance', 'ADVANCE', NotificationConst, 'Vendors'],
      }
    ),

    editAdvanceReturn: build.mutation<HTTPResponse<void>, IVendorAdvanceReturn>(
      {
        query: (body) => ({
          url: `/vendors/advance-return-edit/${body.advr_id}`,
          method: 'PATCH',
          body,
        }),
        invalidatesTags: [
          { type: 'Advance', id: 'ADVANCE' },
          'advance-edit',
          'Vendors',
          NotificationConst,
        ],
      }
    ),

    deleteAdvanceReturn: build.mutation<
      HTTPResponse<void>,
      { id: number; deleted_by: number }
    >({
      query: (body) => ({
        url: `/vendors/advance-return/${body.id}`,
        method: 'DELETE',
        body: body,
      }),
      invalidatesTags: [{ type: 'Advance', id: 'ADVANCE' }],
    }),
  }),
});

export const {
  useGetAllAdvanceReturnQuery,
  useDeleteAdvanceReturnMutation,
  usePostAdvanceReturnMutation,
  useGetAdvanceReturnForEditQuery,
  useLazyGetAdvanceReturnForEditQuery,
  useEditAdvanceReturnMutation,
  useGetVisaTypeQuery,
  useAdvanceReturnDetailsQuery,
} = advanceEndpoints;
