import { Form, Radio, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';
import { ISetState } from '../../../../../common/types/commonTypes';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  FormButton,
  FormInputItem,
} from '../../../../../components/common/FormItem/FormItems';
import {
  SelectCity,
  SelectCountry,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';
import { useEditTourPlaceMutation } from '../endpoints/PlaceTourEndPoints';
import {
  IPlaceSendDataType,
  IPlaceTourGetDataType,
} from '../types/PlaceTourTypes';
import { useAppDispatch } from '../../../../../app/hooks';
import { setModal } from '../../../../../common/slices/commonSlice';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  info: IPlaceTourGetDataType;
  setNull: ISetState<IPlaceTourGetDataType | null>;
}

const PlacesTourEditModal = ({ info }: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  // update Tour Place data
  const [updateTourPlace, { isError, error, isSuccess, isLoading }] =
    useEditTourPlaceMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Tour Place Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const user = useSelector(selectUser);
  const handleSubmit = async () => {
    assyncWrapper(async () => {
      const values = (await form.getFieldsValue()) as IPlaceSendDataType;
      values.place_created_by = user?.user_id as number;
      updateTourPlace({ body: values, id: info?.place_id });
    });
    dispatch(setModal(false));
  };

  const [getCityByCId, setCityByCId] = useState<number>();
  const handlechange = (e: number) => {
    setCityByCId(e);
  };

  useEffect(() => {
    form.setFieldsValue({
      place_city_id: info.place_city_id,
      place_country_id: info.place_country_id,
      place_name: info.place_name,
      place_status: info.place_status,
    });
    setCityByCId(info.place_country_id);
  }, [info]);
  return (
    <Form
      layout='vertical'
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      autoComplete='off'
      form={form}
      onFinish={handleSubmit}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <SelectCountry
          onChange={(e) => handlechange(Number(e))}
          required
          name={'place_country_id'}
          label='Country'
          size={24}
        />

        {getCityByCId && (
          <SelectCity
            required
            getCityByCId={getCityByCId}
            name={'place_city_id'}
            size={24}
            label='City'
          />
        )}
        <FormInputItem
          label='Place'
          name={'place_name'}
          size={24}
        />
      </Row>
      <Form.Item
        name='place_status'
        label='Status'
      >
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <FormButton label='Update Place' />
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default PlacesTourEditModal;
