import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import { Fixed2 } from '../../../common/utils/common.utils';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceMoneyReceiptModal from '../../../components/common/Invoice/InvoiceMoneyReceiptModal';
import { useGetAllAgentProfileQuery } from '../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';
import { useGetAllAirportQuery } from '../../Configuration/airport/endpoints/airportEndpoints';
import { usePostInvoiceAirticketMutation } from '../api/endpoints/airticketInvoiceEndpoints';
import { AirTicketInvoiceData } from '../types/invoiceAirTicketTypes';
import { getAgentCommissionRate } from '../utils/ts/getAgentCommissionRate';
import { postInvoiceAirticketDataFormat } from '../utils/ts/postInvoiceAirticketDataFormat';
import InvPostIsDiscountIsPrevDue from './InvPostIsDiscountIsPrevDue';
import InvoiceAirSubTotal from './InvoiceAirSubTotal';

type Props = {
  invoices: AirTicketInvoiceData[];
  setInvoices: React.Dispatch<React.SetStateAction<AirTicketInvoiceData[]>>;
  form: FormInstance<any>;
  setEditIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  editIndex?: number;
};

export default function PostOverview({
  invoices,
  setInvoices,
  form,
  setEditIndex,
  editIndex,
}: Props) {
  // @STATES
  const [billingForm] = Form.useForm();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [
    postInvoiceAirticket,
    { isError, isLoading, isSuccess, data: postIdData },
  ] = usePostInvoiceAirticketMutation();

  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  // @airport data
  const { data: airport } = useGetAllAirportQuery();
  const selectAirport = airport?.data;

  // @VARIABLES
  const client_price = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_client_price),
    0
  );
  const client_overall_discount = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_discount_total),
    0
  );
  const agent_profit = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_profit),
    0
  );
  const purchase_price = invoices.reduce(
    (a, b) => a + Fixed2(b?.airticket_purchase_price),
    0
  );
  const profit = invoices.reduce((a, b) => a + Fixed2(b?.airticket_profit), 0);

  // @HANDLE COPY
  const handleCopy = (index: number) => {
    form.setFieldsValue(invoices[index]);
    setEditIndex(undefined);
    message.info('Copyed');
  };

  // @HANDLE EDIT
  const handleEdit = (index: number) => {
    form.setFieldsValue(invoices[index]);
    setEditIndex(index);
  };

  // @HANDLE DELETE
  const handleDelete = (index: number) => {
    setInvoices(invoices.filter((_, i) => i !== index));
    setEditIndex(undefined);
  };

  // @TABLE COLUMNS
  const column: ColumnsType<AirTicketInvoiceData> = [
    {
      width: 15,
      title: 'SL',
      render(_, __, index) {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'Ticket',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'Sales',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },
    {
      title: 'Purchase',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },
    {
      title: 'Profit',
      dataIndex: 'airticket_profit',
      key: 'airticket_profit',
      render(value, record, index) {
        return Fixed2(value);
      },
      align: 'right',
    },
    {
      title: 'Action',
      render(value, record, index) {
        return (
          <div>
            <Button
              title='Copy'
              onClick={() => handleCopy(index)}
              size='small'
              type='text'
            >
              <CopyOutlined />
            </Button>
            <Button
              title='Edit'
              onClick={() => handleEdit(index)}
              size='small'
              type='text'
            >
              <EditOutlined />
            </Button>
            <Button
              title='Delete'
              onClick={() => handleDelete(index)}
              size='small'
              type='text'
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  // @HANDLE SUBMIT
  const handleSubmit = (data: AirTicketInvoiceData[]) => {
    const formatedData = postInvoiceAirticketDataFormat({
      invoices,
      billing_form: billingForm,
      user_id: user?.user_id as number,
      selectAirport,
      originalForm: form,
    });

    // console.log(formatedData);

    checkCreditLimit({
      amount: formatedData.invoice_info.invoice_net_total,
      combClient: formatedData.invoice_info.invoice_combclient_id,
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        postInvoiceAirticket(formatedData);
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  // @AFTER CREATE SHOW ALERT
  useEffect(() => {
    if (isSuccess && postIdData?.data?.invoice_id) {
      message.success('Invoice Added Successfully');

      navigate(
        updateAndPrev
          ? `/airticket/details/${postIdData?.data?.invoice_id}`
          : '/airticket',
        {
          state: location?.pathname,
        }
      );
    }
  }, [isSuccess, postIdData]);

  useEffect(() => {
    if (isError) {
      message.error('Something went wrong, Please try again!');
    }
  }, [isError]);

  // GET ALL AGENT
  const { data: agentData } = useGetAllAgentProfileQuery();
  const getAllAgentProfile = agentData?.data;

  useEffect(() => {
    billingForm.setFieldValue('invoice_show_prev_due', 0);
    billingForm.setFieldValue('invoice_show_discount', 1);
    billingForm.setFieldValue('invoice_show_unit', 1);

    // @HANDLE AGENT COMISSION
    if (invoices[invoices?.length - 1]?.invoice_agent_id) {
      const agentCom =
        getAgentCommissionRate({
          allAgents: getAllAgentProfile,
          invoice_agent_id: invoices[invoices?.length - 1]?.invoice_agent_id,
        }) || 0;
      const commission = Fixed2((agentCom / 100) * (agent_profit || 0));

      billingForm.setFieldValue('invoice_agent_com_amount', commission);
    } else {
      billingForm.setFieldValue('invoice_agent_com_amount', undefined);
    }
  }, [invoices]);

  return (
    <Row gutter={[10, 0]}>
      <Table
        style={{ width: '100%' }}
        size='small'
        columns={column}
        dataSource={invoices}
        pagination={false}
        bordered
        rowClassName={(record, index) =>
          index === editIndex ? 'record-edit-active' : ''
        }
        summary={(_) => {
          billingForm.setFieldsValue({ calc_profit: Fixed2(profit) });
          billingForm.setFieldsValue({
            calc_sub_total: Fixed2(client_price),
          });
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={2} index={0}>
                <Typography style={{ textAlign: 'right' }}>Total </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(client_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align='right'>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(purchase_price)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <Typography.Text style={{ textAlign: 'right' }}>
                  {Fixed2(profit)}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />

      <Form
        form={billingForm}
        onFinish={handleSubmit}
        layout='vertical'
        style={{ marginTop: '50px' }}
        disabled={!invoices?.length}
      >
        <InvoiceAirSubTotal
          form={billingForm}
          clientPriceSum={client_price}
          client_overall_discount={client_overall_discount}
          isAgentComission={
            invoices.length
              ? !invoices[invoices?.length - 1]?.invoice_agent_id
              : true
          }
        />
        <InvPostIsDiscountIsPrevDue billingForm={billingForm} />

        {/* @AIRTICKET POST BUTTON */}

        <Row align={'bottom'} justify={'space-around'}>
          <Form.Item>
            <InvoiceMoneyReceiptModal
              billingForm={billingForm}
              client_price={client_price}
              disabled={!invoices?.length}
            />
          </Form.Item>
          <FormButton
            loading={!updateAndPrev && (isLoading || limiLoading)}
            disabled={isLoading || !invoices?.length}
            label='CREATE'
            onClick={() => {
              setUpdateAndPrev(false);
            }}
          />
          <FormButton
            loading={updateAndPrev && (isLoading || limiLoading)}
            disabled={isLoading || !invoices?.length}
            label='CREATE & PREVIEW'
            onClick={() => {
              setUpdateAndPrev(true);
            }}
          />
        </Row>
      </Form>
    </Row>
  );
}
