import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { selectUser } from '../../../../auth/states/userSlice';
import { toasterNotification } from '../../../../common/utils/ToasterNotification';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import {
  useEditClientToClientMutation,
  useEditGroupToGroupMutation,
  useGetClienttoClientForEditQuery,
  useGetGroupToGroupForEditQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import ClientToClientForm from '../../Components/ClientToClientCom/ClientToClientForm';
import { Form, message } from 'antd';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import GrouptoGroupForm from '../../Components/GrouptoGroupCom/GrouptoGroupForm';
import { IPostGroupToGroup } from '../../Types/HajjiManagement.interface';

type Props = {};

const EditGrouptoGroup = (props: Props) => {
  const [form] = Form.useForm();
  const id = Number(useParams().id);
  const user = useSelector(selectUser);

  //   =========== get previous data
  const { data: dataForEdit } = useGetGroupToGroupForEditQuery(id);

  const previousData = dataForEdit?.data;

  //   =============== set form value
  useEffect(() => {
    form.setFieldsValue({
      gtransfer_from: previousData?.gtransfer_from,
      gtransfer_to: previousData?.gtransfer_to,
      gtransfer_job_name: previousData?.gtransfer_job_name,
      gtransfer_note: previousData?.gtransfer_note,
      gtransfer_tracking_no: previousData?.gtransfer_tracking_no,
      ctrcknumber_number: previousData?.ctrcknumber_number.map((item) =>
        Number(item)
      ),
    });
  }, [previousData]);

  // =========== form submit
  const [editGrouptoGroup, { isError, isSuccess, isLoading }] =
    useEditGroupToGroupMutation();
  const onFinish = (values: IPostGroupToGroup) => {
    values.gtransfer_created_by = user?.user_id;

    editGrouptoGroup({ body: values, id });
  };

  // =========== nevigate to view client to client
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      message.info('Group to group updated successfully!');
      navigate('/haji/transfer/group_list');
    }
    if (isError) {
      message.info('Thare was an error');
    }
  }, [isLoading]);

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Update Transfer-In Module (Client 2 client)']}
      />
      {previousData ? (
        <GrouptoGroupForm
          form={form}
          onFinish={onFinish}
          previousTrackNo={previousData?.ctrcknumber_number}
        />
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default EditGrouptoGroup;
