export const AIRTICKET_REFUND = 'AIRTICKET_REFUND';
export const PARTIAL_REFUND = 'PARTIAL_REFUND';
export const OTHERS_REFUND_CLIENT = 'OTHERS_REFUND_CLIENT';
export const OTHERS_REFUND_VENDOR = 'OTHERS_REFUND_VENDOR';
export const TOUR_PACKAGE_REFUND = 'TOUR_PACKAGE_REFUND';
export const Refund_LOGS = 'AIRTICKET_LOGS';
export const Other_Refund_CLIENT_LOGS = 'OTHER_REFUND_CLIENT_LOGS';
export const TOUR_REFUND_LOGS = 'TOUR_REFUND_LOGS';
export const Other_Refund_VENDOR_LOGS = 'OTHER_REFUND_VENDOR_LOGS';
export const refundTags = [
  'AirTicketRefund',
  'OthersRefundClient',
  'OthersRefundVendor',
];
