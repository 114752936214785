import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { crud, rolePermissionType } from '../../common/types/commonTypes';

import { moduleType } from '../../auth/states/userSlice';
import { getListPermission } from '../../common/utils/common.utils';

import Balance_Transfer from '../../modules/Accounts/Pages/Balance_Transfer/Balance_Transfer';
import ViewBalanceTransfer from '../../modules/Accounts/Pages/Balance_Transfer/ViewBalanceTransfer';
import Bill_Adjustment from '../../modules/Accounts/Pages/Bill_Adjustment/Bill_Adjustment';
import Client_Bill_Adjustment_View from '../../modules/Accounts/Pages/Client_Bill_Adjustment/Client_Bill_Adjustment_View';
import Agent_ViewIncentiveIncome from '../../modules/Accounts/Pages/Incentive_Income/Agent_ViewIncentiveIncome';
import Client_ViewIncentiveIncome from '../../modules/Accounts/Pages/Incentive_Income/Client_ViewIncentiveIncome';
import ViewIncentiveIncome from '../../modules/Accounts/Pages/Incentive_Income/ViewIncentiveIncome';
import Incentive_Income_main from '../../modules/Accounts/Pages/Incentive_Income/pages/Incentive_Income_main';
import Investment from '../../modules/Accounts/Pages/Investments/Investment';
import ViewInvestment from '../../modules/Accounts/Pages/Investments/ViewInvestment';
import Non_Invoice_Income from '../../modules/Accounts/Pages/Non_Invoice_Income/Non_Invoice_Income';
import ViewNonInvoiceIncome from '../../modules/Accounts/Pages/Non_Invoice_Income/ViewNonInvoiceIncome';
import OpeningBalance from '../../modules/Accounts/Pages/Opening_Balance/OpeningBalance';
import Transaction_History from '../../modules/Accounts/Pages/Transaction_History/Transaction_History';
import Vendor_Bill_Adjustment_View from '../../modules/Accounts/Pages/Vendor_Bill_Adjustment/Vendor_Bill_Adjustment_View';
import AccountStatement from '../../modules/Accounts/Pages/add list of accounts/AccountStatement';
import Add_List_Accounts from '../../modules/Accounts/Pages/add list of accounts/Add_List_Accounts';
import Balance_Status from '../../modules/Accounts/Pages/balance status/Balance_Status';

export const AccountRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => {
  return (
    <>
      <Route
        path='/accounts/bill-adjustment'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.account_opening_balance?.[crud.create]
            }
            element={
              <OpeningBalance
                permission={permission?.account_opening_balance}
              />
            }
          />
        }
      />

      <Route
        path='/accounts'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={
              <Add_List_Accounts permission={permission?.accounts_module} />
            }
          />
        }
      />
      <Route
        path='/accounts/transactions'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.accounts_module?.[crud.read]
            }
            element={<Transaction_History />}
          />
        }
      />

      <Route
        path='/accounts/balancestatus'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.accounts_module?.[crud.read]
            }
            element={<Balance_Status />}
          />
        }
      />

      <Route
        path='/accounts/balance_transfer'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_balance_transfer)
            }
            element={
              <Balance_Transfer
                permission={permission?.account_balance_transfer}
              />
            }
          />
        }
      />
      <Route
        path='/accounts/balance_transfer/view/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_balance_transfer)
            }
            element={<ViewBalanceTransfer />}
          />
        }
      />

      <Route
        path='/accounts/noninvoice'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_non_invoice_income)
            }
            element={
              <Non_Invoice_Income
                permission={permission?.account_non_invoice_income}
              />
            }
          />
        }
      />
      <Route
        path='/accounts/noninvoice/view/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_non_invoice_income)
            }
            element={<ViewNonInvoiceIncome />}
          />
        }
      />
      <Route
        path='/accounts/investments'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_investments)
            }
            element={
              <Investment permission={permission?.account_investments} />
            }
          />
        }
      />
      <Route
        path={`/accounts/investments/view/:id`}
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_investments)
            }
            element={<ViewInvestment />}
          />
        }
      />

      <Route
        path='/accounts/bill_adjustment'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.account_bill_adjustment)
            }
            element={
              <Bill_Adjustment
                permission={permission?.account_bill_adjustment}
              />
            }
          />
        }
      />

      <Route
        path='/accounts/vendor_bill_adjustment/view/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.account_bill_adjustment?.[crud.read]
            }
            element={<Vendor_Bill_Adjustment_View />}
          />
        }
      />

      <Route
        path='/accounts/client_bill_adjustment/view/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.account_bill_adjustment?.[crud.read]
            }
            element={<Client_Bill_Adjustment_View />}
          />
        }
      />

      <Route
        path='/accounts/account_statement/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              permission?.accounts_module?.[crud.read]
            }
            element={<AccountStatement />}
          />
        }
      />

      <Route
        path='/accounts/incentive'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={
              <Incentive_Income_main permission={permission?.accounts_module} />
            }
          />
        }
      />

      <Route
        path='/accounts/incentive/:activeTab'
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={
              <Incentive_Income_main permission={permission?.accounts_module} />
            }
          />
        }
      />

      <Route
        path={`/accounts/incentive/vendor/view/:id`}
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={<ViewIncentiveIncome />}
          />
        }
      />

      <Route
        path={`/accounts/incentive/client/view/:id`}
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={<Client_ViewIncentiveIncome />}
          />
        }
      />

      <Route
        path={`/accounts/incentive/agent/view/:id`}
        element={
          <PermissionRoute
            permission={
              hasPermission('accounts_module') &&
              getListPermission(permission?.accounts_module)
            }
            element={<Agent_ViewIncentiveIncome />}
          />
        }
      />
    </>
  );
};
