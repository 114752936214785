import { ColumnsType } from 'antd/es/table';
import Table from 'antd/lib/table';
import dayjs from 'dayjs';
import {
  IViewBillingInfo,
  IViewInvoiceDetails,
} from '../../../common/types/commonInterfaces';
import { HotelInformation } from '../../Invoice(Other)/Type/Invoce.other.interface';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { IIUPassengerInfo } from '../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types';

type Props = {
  invoiceDetails: IViewInvoiceDetails;
};
const ViewInvoiceUmrahBilling = ({ invoiceDetails }: Props) => {
  const passengerColumn: ColumnsType<IIUPassengerInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
      width: 25,
    },
    {
      title: 'Passport',
      dataIndex: 'passport_passport_no',
      key: 'passport_passport_no',
      align: 'center',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'passenger_tracking_number',
      key: 'passenger_tracking_number',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'passport_name',
      key: 'passport_name',
      align: 'center',
    },
    {
      title: 'Mobile',
      dataIndex: 'passport_mobile_no',
      key: 'passport_mobile_no',
      align: 'center',
    },
    {
      title: 'Email',
      key: 'passport_email',
      align: 'center',
      dataIndex: 'passport_email',
    },
    {
      title: 'Date of birth',
      align: 'center',
      key: 'passport_date_of_birth',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_birth &&
            dayjs(data?.passport_date_of_birth).format('D/MM/YYYY')}{' '}
        </>
      ),
    },
    {
      title: 'Date of issue',
      key: 'passport_date_of_issue',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_issue &&
            dayjs(data?.passport_date_of_issue).format('D/MM/YYYY')}{' '}
        </>
      ),
    },
    {
      title: 'Date of expire',
      key: 'passport_date_of_expire',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.passport_date_of_expire &&
            dayjs(data?.passport_date_of_expire).format('D/MM/YYYY')}{' '}
        </>
      ),
    },
  ];
  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
      width: 25,
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Pax Name',
      dataIndex: 'pax_name',
      key: 'pax_name',
      align: 'center',
    },

    ...((invoiceDetails?.billing_information.some(
      (item) => item.billing_description
    ) && [
      {
        title: 'Description',
        dataIndex: 'billing_description',
        key: 'billing_description',
      },
    ]) ||
      []),

    {
      title: 'Quantity',
      dataIndex: 'billing_quantity',
      key: 'billing_quantity',
      align: 'right',
    },
    {
      title: 'Unit Price',
      dataIndex: 'billing_unit_price',
      key: 'billing_unit_price',
      align: 'right',
    },
    {
      title: 'Sub Total',
      dataIndex: 'billing_subtotal',
      key: 'billing_subtotal',
      align: 'right',
    },
  ];
  const ticketColumn: ColumnsType<IIUPassengerInfo> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
      width: 25,
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      align: 'center',
    },
    {
      title: 'PNR',
      dataIndex: 'ticket_pnr',
      key: 'ticket_pnr',
      align: 'center',
    },
    {
      title: 'Route',
      dataIndex: 'airline_iata_code',
      key: 'airline_iata_code',
      align: 'center',
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
      align: 'center',
    },
    {
      title: 'Reference No.',
      dataIndex: 'ticket_reference_no',
      key: 'ticket_reference_no',
      align: 'center',
    },
    {
      title: 'Journey date',
      key: 'ticket_journey_date',
      render: (_, data) => (
        <>
          {' '}
          {data?.ticket_journey_date &&
            dayjs(data?.ticket_journey_date).format('D/MM/YYYY')}{' '}
        </>
      ),
    },
    {
      title: 'Return date',
      key: 'ticket_return_date',
      render: (_, data) => (
        <>
          {' '}
          {data?.ticket_return_date &&
            dayjs(data?.ticket_return_date).format('D/MM/YYYY')}{' '}
        </>
      ),
    },
  ];
  const hotelColumn: ColumnsType<HotelInformation> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Hotel Name',
      dataIndex: 'hotel_name',
      key: 'hotel_name',
      align: 'center',
    },
    {
      title: 'Check In Date',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.hotel_check_in_date &&
            dayjs(data?.hotel_check_in_date).format('D/MM/YYYY')}{' '}
        </>
      ),
      key: 'hotel_check_in_date',
    },
    {
      title: 'Check Out Date',
      align: 'center',
      render: (_, data) => (
        <>
          {' '}
          {data?.hotel_check_out_date &&
            dayjs(data?.hotel_check_out_date).format('D/MM/YYYY')}{' '}
        </>
      ),
      key: 'hotel_check_out_date',
    },
    {
      title: 'Room Type',
      dataIndex: 'hotel_room_type',
      key: 'hotel_room_type',
      align: 'center',
    },
  ];
  const passProperties: any = [];
  const passTicketProperties: any = [];
  if (invoiceDetails.passenger_info.length) {
    // Iterate through the objects in the data array
    for (const obj of invoiceDetails.passenger_info as any) {
      const passProps: any = {};
      const passTickProps: any = {};
      // Iterate through the properties of each object
      for (const prop in obj) {
        // Check if the property name starts with "pass"
        if (prop.startsWith('pass')) {
          passProps[prop] = obj[prop];
        } else if (prop.startsWith('tick')) {
          passTickProps[prop] = obj[prop];
        }
      }
      passProperties.push(passProps);
      passTicketProperties.push(passTickProps);
    }
  }
  const allpassPropertiesNull = areAllObjectsPropertiesNull(passProperties);
  const allpassTicketPropertiesNull =
    areAllObjectsPropertiesNull(passTicketProperties);
  return (
    <div>
      <>
        {!allpassPropertiesNull ? (
          <>
            <FormHeaderTitle title='PASSENGER INFO' />
            <Table
              size='small'
              rowKey={(e) => e.passenger_tracking_number}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={invoiceDetails.passenger_info}
              columns={passengerColumn}
              bordered
              pagination={false}
              locale={{ emptyText: ' ' }}
              style={{ marginBottom: '1rem' }}
            />
          </>
        ) : (
          ''
        )}

        {!allpassTicketPropertiesNull ? (
          <>
            <FormHeaderTitle title='TICKET INFO' />
            <Table
              size='small'
              rowKey={(e) => e.ticket_no as string}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={invoiceDetails.passenger_info}
              columns={ticketColumn}
              bordered
              pagination={false}
              locale={{ emptyText: ' ' }}
            />
          </>
        ) : (
          <></>
        )}

        {invoiceDetails?.hotel_information?.length ? (
          <>
            <FormHeaderTitle title='HOTEL INFO' />
            <Table
              size='small'
              rowKey={(e) => e.hotel_name as string}
              className='invoiceBillingTable'
              rowClassName={'invoiceBillingTd'}
              dataSource={invoiceDetails?.hotel_information}
              columns={hotelColumn}
              bordered
              pagination={false}
              locale={{ emptyText: ' ' }}
            />
          </>
        ) : (
          <></>
        )}
        <FormHeaderTitle title='BILLING INFO' />
        <Table
          size='small'
          className='invoiceBillingTable'
          rowClassName={'invoiceBillingTd'}
          dataSource={invoiceDetails?.billing_information}
          columns={billingColumn}
          style={{ marginBottom: '20px' }}
          bordered
          pagination={false}
          locale={{ emptyText: ' ' }}
        />
      </>
    </div>
  );
};

export default ViewInvoiceUmrahBilling;

// Function to check if all properties in an object are null
export function areAllPropertiesNull(obj: any) {
  for (const prop in obj) {
    if (obj[prop] !== null) {
      return false;
    }
  }
  return true;
}

// Function to check if all properties in an array of objects are null
function areAllObjectsPropertiesNull(arr: any) {
  for (const obj of arr) {
    if (!areAllPropertiesNull(obj)) {
      return false;
    }
  }
  return true;
}
