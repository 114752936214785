import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { MONTHLYSUMMARY } from '../../Api/constants';
import {
  IMonthlySummaryDataType,
  IMonthlySummaryFormDataType,
} from '../types/monthlySummaryTypes';

export const monthlySummaryEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all MonthlySummary
    getAllMonthlySummary: build.query<
      HTTPResponse<IMonthlySummaryDataType>,
      IMonthlySummaryFormDataType
    >({
      query: (body) => ({
        url: `/report/monthly-summary?month=${body.month}`,
      }),
      providesTags: [{ type: 'MonthlySummary', id: MONTHLYSUMMARY }],
    }),
  }),
});

export const { useLazyGetAllMonthlySummaryQuery } = monthlySummaryEndpoints;
