import { HTTPResponse } from '../../../../common/types/commonTypes';
import { api } from '../../../../app/baseQuery';

import {
  GroupDataType,
  IGroupEditFormData,
  IGroupFormData,
} from '../types/GroupsTypes';

const GROUPS = 'GROUPS';
export const groupEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all groups

    getAllGroups: build.query<HTTPResponse<GroupDataType[]>, void>({
      query: () => ({ url: `/configuration/group/view-all` }),
      providesTags: () => [{ type: 'Groups', id: GROUPS }],
    }),

    //get all groups with pagination
    getAllGroupsWithPagination: build.query<
      HTTPResponse<GroupDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/group?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [{ type: 'Groups', id: GROUPS }],
    }),

    //create group

    createGroup: build.mutation<
      HTTPResponse<{ group_id: number }>,
      IGroupFormData
    >({
      query: (body) => ({
        url: `/configuration/group/create`,
        method: 'POST',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Groups', id: GROUPS }],
    }),

    //edit group
    editGroup: build.mutation<void, IGroupEditFormData>({
      query: (body) => ({
        url: `/configuration/group/update/${body.group_id}`,
        method: 'PATCH',
        body: body,
      }),

      invalidatesTags: () => [{ type: 'Groups', id: GROUPS }],
    }),

    //delete group
    deleteGroup: build.mutation<void, GroupDataType>({
      query: (body) => ({
        url: `/configuration/group/delete/${body.group_id}`,
        method: 'DELETE',
        body: { deleted_by: body.group_updated_by },
      }),

      invalidatesTags: () => [{ type: 'Groups', id: GROUPS }],
    }),
  }),
});

export const {
  useGetAllGroupsQuery,
  useCreateGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
  useGetAllGroupsWithPaginationQuery,
} = groupEndpoints;
