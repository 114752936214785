import { AutoComplete, Col, Form, Input, List } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  IHajjPreList,
  IInvoicePreRegHajiInfo,
} from '../../../Invoice(Visa)/Type/invoiceVisa.interface';
import { useGetAllInvoiceHajjiInfoQuery } from '../../../Invoice_Hajj_Pre_Reg/API/Endpoints/invoiceHajjPreRegEndspoint';
import { NamePath } from 'antd/es/form/interface';
import { Rule } from 'antd/es/form';
import { DefaultOptionType } from 'rc-cascader';
import { FormInstance } from 'rc-field-form';
import { pilgrimInfoType } from '../Types/InvoiceHajjTypes';
import dayjs from 'dayjs';

type Props = {
  size: number;
  name?: NamePath;
  label: string;
  validateStatus: boolean | undefined;
  help: string;
  rules?: Rule[] | undefined;
  required?: boolean;
  fullNamePath: NamePath;
  form: FormInstance<any>;
  disabled?: boolean;
};

const Tracking_No = ({
  size,
  name,
  label,
  validateStatus,
  help,
  rules,
  required,
  form,
  fullNamePath,
  disabled,
}: Props) => {
  // Get All Approved Hajj Pre
  const { data, isLoading } = useGetAllInvoiceHajjiInfoQuery();
  const approvedHajjPre = data?.data;
  const [options, setOptions] = useState<DefaultOptionType[]>();

  const handleOptionSelect = (data: string) => {
    const selectedItem = approvedHajjPre?.find(
      (item) => item.hajiinfo_tracking_number === data
    );
    const namePath = [...(fullNamePath as [])];
    if (selectedItem) {
      const prevValue: pilgrimInfoType = form.getFieldValue([
        namePath[0],
        namePath[1],
      ]);

      // @SET VALUES USING TRACKING NUMBER
      form.setFields([
        {
          name: [namePath[0], namePath[1], 'hajiinfo_dob'],
          value: selectedItem?.hajiinfo_dob
            ? dayjs(selectedItem?.hajiinfo_dob)
            : prevValue.hajiinfo_dob,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_mobile'],
          value: selectedItem?.hajiinfo_mobile
            ? selectedItem?.hajiinfo_mobile
            : prevValue.hajiinfo_mobile,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_name'],
          value: selectedItem?.hajiinfo_name
            ? selectedItem?.hajiinfo_name
            : prevValue.hajiinfo_name,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_tracking_number'],
          value: selectedItem?.hajiinfo_tracking_number
            ? selectedItem?.hajiinfo_tracking_number
            : prevValue.hajiinfo_tracking_number,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_gender'],
          value: selectedItem?.hajiinfo_gender
            ? selectedItem?.hajiinfo_gender
            : prevValue.hajiinfo_gender,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_nid'],
          value: selectedItem?.hajiinfo_nid
            ? selectedItem?.hajiinfo_nid
            : prevValue.hajiinfo_nid,
        },
        {
          name: [namePath[0], namePath[1], 'hajiinfo_serial'],
          value: selectedItem?.hajiinfo_serial
            ? selectedItem?.hajiinfo_serial
            : prevValue.hajiinfo_serial,
        },
      ]);
    }
  };

  useEffect(() => {
    setOptions(
      approvedHajjPre?.map((item) => ({
        label: `${item.hajiinfo_name} ${item.hajiinfo_tracking_number}`,
        value: item.hajiinfo_tracking_number,
      }))
    );
  }, [approvedHajjPre]);
  return (
    <Col xs={24} sm={12} md={8} lg={{ span: size || 8, offset: 0 }}>
      <Form.Item
        rules={[...(rules || [])]}
        name={name}
        label={label}
        validateStatus={validateStatus ? 'error' : 'validating'}
        help={validateStatus ? help : null}
        required={required}
      >
        <AutoComplete
          onSearch={(data) => {
            const filter = approvedHajjPre
              ?.filter((item) => item.hajiinfo_tracking_number.includes(data))
              .map((item) => ({
                label: `${item.hajiinfo_name} ${item.hajiinfo_tracking_number}`,
                value: item.hajiinfo_tracking_number,
              }));

            setOptions(filter);
          }}
          onSelect={handleOptionSelect}
          placeholder={label}
          options={options}
          disabled={disabled}
        />
      </Form.Item>
    </Col>
  );
};

export default Tracking_No;
