import { HTTPResponse } from '../../../../common/types/commonTypes';
import { ALLTICKETNO, OVERALLPROFITLOSS } from '../../Api/constants';
import {
  IAllSubHeadReportDataType,
  IFormSubHeadReportDataType,
  ISubHeadWiseReportDataType,
} from '../types/SubHeadWiseType';

import { api } from './../../../../app/baseQuery';
export const SubheadWiseEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get Over all profit loss reports
    getAllSubHeadWiseReport: build.query<
      HTTPResponse<IAllSubHeadReportDataType[]>,
      void
    >({
      query: (body) => ({
        url: `/report/sub-heads`,
      }),
      providesTags: [{ type: 'SubHeadWiseReport', id: 'SUBHEADWISEREPORT' }],
    }),
    // get Over all profit loss reports
    getSubHeadWiseReportById: build.query<
      HTTPResponse<ISubHeadWiseReportDataType[]>,
      IFormSubHeadReportDataType
    >({
      query: (body) => ({
        url: `/report/sub-head-report/${body.expenseSubHead}?${body.date_range}`,
      }),
      providesTags: [{ type: 'SubHeadWiseReport', id: 'SUBHEADWISEREPORT' }],
    }),

    // //get all visa wise profitLoss
    // getAllVisaWiseProfitloss: build.query<
    //   HTTPResponse<IVisawiseProfitLoss[]>,
    //   IVisaWiseFormData
    // >({
    //   query: (body) => ({
    //     url: `/report/visa-profit-loss?${body.date_range}`,
    //     method: 'POST',
    //     body: body,
    //   }),
    //   providesTags: [{ type: 'VisaWiseProfitLoss', id: VISAWISEPROFITLOSS }],
    // }),
    // // get all single product wise profit loss
    // getSingleProductProfitloss: build.query<
    //   HTTPResponse<IProductWiseProfitLoseDataType[]>,
    //   IProfitLossSingleProductFormData
    // >({
    //   query: (body) => ({
    //     url: `/report/product-profit-loss?${body.date_range}`,
    //     method: 'POST',
    //     body: body,
    //   }),
    //   providesTags: [
    //     { type: 'SingalProductWiseProfit', id: SINGALPRODUCTWISEPROFITLOSS },
    //   ],
    // }),
    // // get all Product wise profit loss
    // getAllProductWiseProfitLoss: build.query<
    //   HTTPResponse<IAllProductWiseProfitDataType[]>,
    //   IAllProductWiseProfitFormDataType
    // >({
    //   query: (body) => ({
    //     url: `/report/all-product-wise-profit-loss?${body.date_range}`,
    //   }),
    //   providesTags: [
    //     { type: 'ProductWiseProfitLoss', id: PRODUCTWISEPROFITLOSS },
    //   ],
    // }),
    // // session wise ProfitLoss
    // getSessionWiseProfitLoss: build.query<
    //   HTTPResponse<ISessionWiseProfitLossDataType>,
    //   ISessionProfitFormDataType
    // >({
    //   query: (body) => ({
    //     url: `/report/session-wise-profit-loss?year=${body.year}`,
    //   }),
    //   providesTags: [
    //     { type: 'SessionWiseProfitLoss', id: SESSIONWISEPROFITLOSS },
    //   ],
    // }),
    // // Ticket wise ProfitLoss
    // getTicketWiseProfit: build.query<
    //   HTTPResponse<ITicketWiseProfitLossDataType>,
    //   ITicketWiseProfitLossFormDataType
    // >({
    //   query: (body) => ({
    //     url: `/report/ticket-wise-profit-loss/${body.ticket_id}`,
    //   }),
    //   providesTags: [
    //     { type: 'TicketWiseProfitLoss', id: TICKETWISEPROFITLOSS },
    //   ],
    // }),
    // //get all Ticket
    // getAllTicketNo: build.query<HTTPResponse<ITicketDataType[]>, void>({
    //   query: (body) => ({
    //     url: `/report/all-ticket-info`,
    //   }),
    //   providesTags: [{ type: 'AllTicketNo', id: ALLTICKETNO }],
    // }),
  }),
});

export const {
  useGetAllSubHeadWiseReportQuery,
  useGetSubHeadWiseReportByIdQuery,
  useLazyGetSubHeadWiseReportByIdQuery,
} = SubheadWiseEndpoints;
