import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
// import { INVOICE_HAJJ } from '../../../Hajj/Invoice_Hajj/api/constants/constants';

import {
  IMonthlySalesEarningReportDataType,
  IMonthlySalesEarningReportFormData,
} from '../types/monthly_Sales_EarningTypes';

export const monthly_Sales_EarningEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all monthly sales report
    getMonthly_Sales_Earning: build.query<
      HTTPResponse<IMonthlySalesEarningReportDataType[]>,
      IMonthlySalesEarningReportFormData
    >({
      query: (body) => ({
        url: `report/sales-earning?${body.date_range}`,
        method: 'POST',
        body: body,
      }),
      providesTags: [
        // {
        //   type: 'MonthlySalesAndEarning',
        //   id: MONTHLYSALESANDEARNING,
        // },
        // { type: 'AirticketInvoice' },
        // { type: 'InvoiceNonComission' },
        // { type: 'ReIssueAirTicket' },
        // { type: 'invoice-other' },
        // { type: 'invoice-visa' },
        // { type: 'InvoiceTourPackage' },
        // { type: 'invoice-hajji' },
        // { type: INVOICE_HAJJ },
        // { type: INVOICE_UMRAH },
      ],
    }),

    getAllMonthly_Sales_Earning: build.query<
      HTTPResponse<IMonthlySalesEarningReportDataType>,
      {
        client_id: number | string;
        employee_id: number | string;
        query: string;
      }
    >({
      query: ({ client_id, employee_id, query }) => ({
        url: `report/sales-earning${query}`,
        method: 'POST',
        body: {
          client_id: client_id,
          employee_id: employee_id,
        },
      }),
      providesTags: [
        // {
        //   type: 'MonthlySalesAndEarning',
        //   id: MONTHLYSALESANDEARNING,
        // },
        // { type: 'AirticketInvoice' },
        // { type: 'InvoiceNonComission' },
        // { type: 'ReIssueAirTicket' },
        // { type: 'invoice-other' },
        // { type: 'invoice-visa' },
        // { type: 'InvoiceTourPackage' },
        // { type: 'invoice-hajji' },
        // { type: INVOICE_HAJJ },
        // { type: INVOICE_UMRAH },
      ],
    }),
  }),
});

export const {
  useLazyGetMonthly_Sales_EarningQuery,
  useGetMonthly_Sales_EarningQuery,
  useLazyGetAllMonthly_Sales_EarningQuery,
} = monthly_Sales_EarningEndpoints;
