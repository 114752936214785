import { MinusOutlined, PlusOutlined } from '@ant-design/icons/lib/icons';
import { Button, Card, Col, Form, Input, Radio, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import { setModal } from '../../../../../common/slices/commonSlice';
import assyncWrapper from '../../../../../common/utils/assyncWrapper';
import {
  FormButton,
  NumberInput,
} from '../../../../../components/common/FormItem/FormItems';
import {
  SelectCity,
  SelectCountry,
  SelectRoomType,
  SelectVendors,
} from '../../../../../components/common/FormItem/SelectCustomFeilds';
import { useEditTourAccommodationMutation } from '../endpoints/accommodationEndpoints';
import {
  IAccommodationCreateDataType,
  IAccommodationGetDataType,
} from '../types/accommodationTypes';
import LoadingIndicator from '../../../../../components/common/spinner/LoadingIndicator';
interface IProps {
  info: IAccommodationGetDataType;
}
const AccommodationEditModal = ({ info }: IProps) => {
  const [form] = Form.useForm();
  const [getCityByCId, setCityByCId] = useState<number>();
  const dispatch = useAppDispatch();
  const handlechange = (e: number) => {
    setCityByCId(e);
  };

  // update Tour Accommodation data
  const [updateTourAccommodation, { isError, error, isSuccess, isLoading }] =
    useEditTourAccommodationMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Tour Accommodation Edited Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const user = useSelector(selectUser);
  const handleSubmitAccommodation = async () => {
    assyncWrapper(async () => {
      const values =
        (await form.getFieldsValue()) as IAccommodationCreateDataType;
      values.accommodation_updated_by = user?.user_id as number;
      updateTourAccommodation({
        body: values,
        id: info?.accommodation_id,
      });
    });

    dispatch(setModal(false));
  };
  useEffect(() => {
    form.setFieldsValue({
      accommodation_country_id: info.accommodation_country_id,
      accommodation_status: info.accommodation_status,
      accommodation_city_id: info.accommodation_city_id,
      accommodation_hotel_name: info.accommodation_hotel_name,
      accommodation_pax_name: info.accommodation_pax_name,
      accommodation_room_type_id: info.accommodation_room_type_id,
    });

    form.setFields([
      {
        name: 'vendors',
        value: prevVendorInfo,
      },
    ]);
    setCityByCId(info?.accommodation_country_id);
  }, [info]);

  //   ======= vendor info for edit
  const prevVendorInfo =
    info?.itinerary_vendor &&
    info?.itinerary_vendor.map((item) => {
      return {
        accmvendor_vendor_id: item.vendor_id,
        accmvendor_cost_price: Number(item.accmvendor_cost_price),
      };
    });
  return (
    <Form layout='vertical' form={form} onFinish={handleSubmitAccommodation}>
      <Card style={{ marginBottom: '5px' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <SelectCountry
            onChange={(e) => handlechange(Number(e))}
            required
            label='Country'
            name={'accommodation_country_id'}
            size={5}
          />

          <SelectCity
            name={'accommodation_city_id'}
            label='City'
            required
            getCityByCId={getCityByCId}
            size={5}
          />

          <Col xs={24} sm={24} md={24} lg={3}>
            <Form.Item
              label='Hotel Name'
              name={'accommodation_hotel_name'}
              rules={[
                {
                  required: true,
                  message: 'Hotel Name is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <SelectRoomType
            name={'accommodation_room_type_id'}
            label='Room Type'
            required
            size={5}
          />

          <Col xs={24} sm={24} md={24} lg={3}>
            <Form.Item
              label='Pax'
              name={'accommodation_pax_name'}
              rules={[
                {
                  required: true,
                  message: 'Pax is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Form.List name={'vendors'}>
        {(fieldsz, { add, remove }) =>
          fieldsz.map((fieldz, index) => {
            return (
              <Row key={index} gutter={16}>
                <SelectVendors
                  required
                  label='Vendor'
                  name={[fieldz.name, 'accmvendor_vendor_id']}
                />

                <NumberInput
                  label='Cost Price'
                  required
                  name={[fieldz.name, 'accmvendor_cost_price']}
                  min='0'
                  maxChar={14}
                />
                <Col xs={24} sm={24} md={24} lg={8}>
                  <Form.Item label='Action'>
                    {index === 0 ? (
                      <Button type='primary' onClick={() => add()}>
                        <PlusOutlined />
                        Add More
                      </Button>
                    ) : (
                      <Button
                        type='primary'
                        danger
                        onClick={() => remove(fieldz.name)}
                      >
                        <MinusOutlined />
                        Remove
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            );
          })
        }
      </Form.List>

      <Form.Item name='accommodation_status' label='Status'>
        <Radio.Group>
          <Radio value={1}>Yes</Radio>
          <Radio value={0}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <FormButton label='Update Accommodation' loading={isLoading} />
      {isLoading && <LoadingIndicator />}
    </Form>
  );
};

export default AccommodationEditModal;
