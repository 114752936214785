function rowHighlight(timestamp: Date) {
  const currentTime = Date.now();
  const timestampInMs = Date.parse(timestamp as any);

  const highlightDuration = 60000;

  if (currentTime - timestampInMs < highlightDuration) {
    return 'highlight_row';
  }

  return '';
}

export const getRowHightLightClass = (date: any) => {
  return rowHighlight(date);
};
