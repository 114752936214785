import { api } from '../../../app/baseQuery';
import { HTTPResponse } from '../../../common/types/commonTypes';
import { IFeedback } from '../types/FeedbackTypes';

const tags = 'FEEDBACK';

export const feedbackEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    createFeedback: build.mutation<HTTPResponse<string>, IFeedback>({
      query: (body) => ({
        url: `/feedback`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: () => [tags],
    }),
  }),
});

export const { useCreateFeedbackMutation } = feedbackEndpoints;
