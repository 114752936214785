import { api } from '../../../../app/baseQuery';
import { HTTPResponse } from '../../../../common/types/commonTypes';
import { SALEREPORTITEMANDSALSES } from '../../Api/constants';
import { ISalesReportItemAndSalesMandataType } from '../types/salesReportItemAndSalesmanTypes';

export const salesReportItemAndSalesmanEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all activities
    getsalesReportItemAndSalesman: build.query<
      HTTPResponse<{
        report: ISalesReportItemAndSalesMandataType[];
        total: { total_sales: number };
        employee: {
          department_name: string;
          employee_full_name: string;
          employee_mobile: string;
          designation_name: string;
        };
      }>,
      {
        item_id: number | string;
        sales_man_id: number | string;
        query: string;
      }
    >({
      query: ({ item_id, sales_man_id, query }) => ({
        url: `/report/sales-report-item/salesman${query}`,
        method: 'POST',
        body: {
          item_id: item_id,
          sales_man_id: sales_man_id,
        },
      }),
      providesTags: [
        { type: 'salesReportItemAndSalesman', id: SALEREPORTITEMANDSALSES },
      ],
    }),

    getSalesManExcelsReport: build.query<
      any,
      {
        client_id: string;
        query: string;
        excelName?: string;
        excelApiName: string;
        employee_id: string;
      }
    >({
      query: ({ employee_id, client_id, query, excelName, excelApiName }) => ({
        url: `/report/${excelApiName}${query}`,
        method: 'POST',
        body: {
          client_id: client_id,
          employee_id: employee_id,
        },
        responseHandler: async (response) => {
          const filename = `${excelName}.xlsx`;
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        cache: 'no-cache',
      }),
      providesTags: [
        { type: 'invoice-other' },
        { type: 'invoices' },
        { type: 'invoice-visa' },
      ],
    }),
  }),
});

export const {
  useLazyGetsalesReportItemAndSalesmanQuery,
  useLazyGetSalesManExcelsReportQuery,
} = salesReportItemAndSalesmanEndpoints;
