import { Col, Form, Row, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { selectUser } from '../../../auth/states/userSlice';
import postDateFormat from '../../../common/utils/postDateFormat';
import { FormButton } from '../../../components/common/FormItem/FormItems';
import InvoiceOtherAddMoney from '../../../components/common/Invoice/InvoiceMoneyReceipt';
import InvoiceVisaSubTotalForm from '../../../components/common/Invoice/InvoiceSubTotal';
import InvoiceBillingInfo from '../../../components/common/Invoice/billingInfo/InvoiceBillingInfo';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { useGetInvoiceNoQuery } from '../../Invoice(Visa)/Api/Endpoints/invoiceVisaEndpoints';
import { usePostInvoiceHajjiPreMutation } from '../API/Endpoints/invoiceHajjPreRegEndspoint';
import HajiInformation from '../Components/HajiInformation';
import InvoiceHajjPreRegHeader from '../Components/InvoiceHajjPreRegHeader';
import {
  IInvoiceHajjPreRegFormData,
  IPreRegPostCreateEndPointsTypes,
} from '../Types/InvoiceHajjiPre.Types';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { useCheckCreditLimitMutation } from '../../Client/Client/api/endpoints/clientEndpoints';

type Props = {};

const NewInvoicePreReg = (props: Props) => {
  const [form] = Form.useForm();
  const {
    data: getInvoiceNo,
    isLoading,
    isFetching,
  } = useGetInvoiceNoQuery('IHP');
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [updateAndPrev, setUpdateAndPrev] = useState<boolean>(false);

  // ============= set form value
  useEffect(() => {
    form.setFieldsValue({
      invoice_no: getInvoiceNo?.data,
      invoice_sales_date: dayjs(),
    });
  }, [isFetching]);

  const invoice_net_total = useWatch(['invoice_net_total'], form);

  // ============== Submit form
  const [
    postInvoiceHajjiPre,
    {
      isError: postError,
      isSuccess: postSuccess,
      isLoading: postLoading,
      error,
    },
  ] = usePostInvoiceHajjiPreMutation();

  const [checkCreditLimit, { isLoading: limiLoading }] =
    useCheckCreditLimitMutation();

  const location = useLocation();
  const onFinish = async (values: IInvoiceHajjPreRegFormData) => {
    const data: IPreRegPostCreateEndPointsTypes = {
      invoice_no: values.invoice_no,
      invoice_combclient_id: values.invoice_combclient_id,
      invoice_sales_man_id: values.invoice_sales_man_id,
      invoice_sales_date: dayjs(values?.invoice_sales_date).format(
        'YYYY-MM-DD'
      ),
      invoice_due_date: values?.invoice_due_date
        ? dayjs(values?.invoice_due_date).format('YYYY-MM-DD')
        : undefined,
      invoice_note: values.invoice_note,
      invoice_reference: values.invoice_reference,
      invoice_sub_total: values.invoice_sub_total,
      invoice_net_total: values.invoice_net_total,
      invoice_created_by: user?.user_id as number,
      invoice_haji_group_id: values.invoice_haji_group_id,
      invoice_vat: values.invoice_vat,
      invoice_service_charge: values.invoice_service_charge,
      invoice_discount: values.invoice_discount,
      invoice_agent_id: values.invoice_agent_id,
      invoice_agent_com_amount: values.invoice_agent_com_amount,
      invoice_client_previous_due: values.invoice_client_previous_due,
      client_present_balance: values.client_present_balance,
      money_receipt: values?.receipt_payment_type
        ? {
            receipt_total_amount: values.receipt_total_amount,
            receipt_payment_type: values.receipt_payment_type,
            account_id: values.account_id,
            charge_amount: values.charge_amount,
            cheque_bank_name: values.cheque_bank_name,
            cheque_number: values.cheque_number,
            cheque_withdraw_date: dayjs(values.cheque_withdraw_date).format(
              'YYYY-MM-DD'
            ),
            receipt_money_receipt_no: values.receipt_money_receipt_no,
            receipt_note: values.receipt_note,
            trans_no: values.trans_no,
            receipt_payment_date: dayjs(values.receipt_payment_date).format(
              'YYYY-MM-DD'
            ),
          }
        : undefined,

      haji_information: values?.haji_information?.map((item: any) => {
        return {
          ...item,
          haji_info_possible_year:
            item?.haji_info_possible_year?.format('YYYY'),
          haji_info_reg_year: item?.haji_info_reg_year?.format('YYYY'),
          hajiinfo_dob: postDateFormat(item?.hajiinfo_dob),
        };
      }),
      billing_information: values.billing_information.map((el: any) => {
        delete el?.billing_total_cost_price;
        return {
          ...el,
        };
      }),
    };

    checkCreditLimit({
      amount: values.invoice_net_total,
      combClient: values.invoice_combclient_id.toString(),
    }).then((res: any) => {
      if (res?.data?.data === 1) {
        postInvoiceHajjiPre(data).then((res: any) => {
          if (res.data.data.invoice_id)
            updateAndPrev
              ? navigate(
                  `/hajjpreregistration/details/${res.data.data.invoice_id}`,
                  {
                    state: location.pathname,
                  }
                )
              : navigate('/hajjpreregistration');
        });
      } else {
        message.error(res?.data?.data);
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error('Pleace select all require fields');
  };

  useEffect(() => {
    if (postSuccess) {
      message.success('Invoice Hajji Pre Registration Added Successfully!');
    }
    if (postError) {
      message.error('Thare was an error');
    }
  }, [postLoading]);

  return (
    <>
      {(isLoading || postLoading) && <LoadingIndicator />}
      <BreadCrumb arrOfOption={['Invoice (Hajj Pre Registration)']} />
      <Form
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <InvoiceHajjPreRegHeader
          form={form}
          invoice_agent_com_amountFieldName={['invoice_agent_com_amount']}
          invoice_net_totalFieldName={['invoice_net_total']}
        />
        <HajiInformation />
        <InvoiceBillingInfo form={form} setProductId={115} />
        <InvoiceVisaSubTotalForm form={form} />
        <InvoiceOtherAddMoney form={form} netTotal={invoice_net_total || 0} />
        {/* @AIRTICKET EIDT BUTTON */}
        <Row gutter={[10, 10]}>
          <Col>
            <FormButton
              loading={!updateAndPrev && (isLoading || limiLoading)}
              label='CREATE'
              onClick={() => {
                setUpdateAndPrev(false);
              }}
            />
          </Col>
          <Col>
            <FormButton
              loading={updateAndPrev && (isLoading || limiLoading)}
              label='CREATE & PREVIEW'
              onClick={() => {
                setUpdateAndPrev(true);
              }}
            />
          </Col>
        </Row>
      </Form>
      {isLoading || (postLoading && <LoadingIndicator />)}
    </>
  );
};

export default NewInvoicePreReg;
