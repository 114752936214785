import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { rolePermissionType } from '../../common/types/commonTypes';
import { getListPermission } from '../../common/utils/common.utils';
import AddProduct from '../../modules/Configuration/Products/pages/Products';
import Accommodations from '../../modules/Configuration/Tour/Accommodations/pages/Accommodations';
import Cities from '../../modules/Configuration/Tour/Cities/pages/Cities';
import Places from '../../modules/Configuration/Tour/Places/pages/Places';
import TourFood from '../../modules/Configuration/Tour/Ticket/pages/TourFood';
import TourGuide from '../../modules/Configuration/Tour/Ticket/pages/TourGuide';
import TourOtherTransport from '../../modules/Configuration/Tour/Ticket/pages/TourOtherTransport';
import TourTicket from '../../modules/Configuration/Tour/Ticket/pages/TourTicket';
import TourTransport from '../../modules/Configuration/Tour/Ticket/pages/TourTransport';
import TourGroup from '../../modules/Configuration/Tour/TourGroups/pages/TourGroup';
import EditUser from '../../modules/Configuration/Users/pages/EditUser';
import ViewRoles from '../../modules/Configuration/Users/pages/ViewRoles';
import ViewUser from '../../modules/Configuration/Users/pages/ViewUser';
import VisaType from '../../modules/Configuration/Visa/pages/VisaType';
import Agency from '../../modules/Configuration/agency/pages/Agency';
import Airline from '../../modules/Configuration/airline/pages/Airline';
import Airport from '../../modules/Configuration/airport/pages/Airport';
import AppConfig from '../../modules/Configuration/appConfig/pages/AppConfig';
import ClientCategory from '../../modules/Configuration/clientCategory/pages/ClientCategory';
import Companies from '../../modules/Configuration/companies/pages/Companies';
import Departments from '../../modules/Configuration/department/pages/Departments';
import Designation from '../../modules/Configuration/designation/pages/Designation';
import Employee from '../../modules/Configuration/employee/pages/Employee';
import ExpenseHead from '../../modules/Configuration/expenseHead/pages/ExpenseHead';
import Groups from '../../modules/Configuration/group/pages/Group';
import Maharam from '../../modules/Configuration/mahram/pages/Maharam';
import PassportStatus from '../../modules/Configuration/passportStatus/pages/PassportStatus';
import RoomTypes from '../../modules/Configuration/roomTypes/pages/RoomTypes';
import TransportType from '../../modules/Configuration/transportTypes/pages/TransportType';
import Profile from '../../modules/Configuration/Profile/pages/Profile';
import Employee_Signature from '../../modules/Configuration/appConfig/pages/Employee_Signature';

// DASHBOARD
export const ConfigurationRoutes = (
  permission: rolePermissionType | undefined,
  isAdmin: string[] | undefined
) => (
  <>
    {/* USERS */}
    <>
      <Route
        path='/users/edit/:user_id'
        element={
          <PermissionRoute permission={isAdmin} element={<EditUser />} />
        }
      />
      <Route
        path='/user/view'
        element={
          <PermissionRoute permission={isAdmin} element={<ViewUser />} />
        }
      />
      <Route
        path='/role/view'
        element={
          <PermissionRoute permission={isAdmin} element={<ViewRoles />} />
        }
      />
      <Route
        path='/passport/status'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.passport_status)}
            element={
              <PassportStatus permission={permission?.passport_status} />
            }
          />
        }
      />
      <Route
        path='/group'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.groups)}
            element={<Groups permission={permission?.groups} />}
          />
        }
      />
      <Route
        path='/maharam'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.maharam)}
            element={<Maharam permission={permission?.maharam} />}
          />
        }
      />
      <Route
        path='/agency'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.agency)}
            element={<Agency permission={permission?.agency} />}
          />
        }
      />
      <Route
        path='/airline'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.airline)}
            element={<Airline permission={permission?.airline} />}
          />
        }
      />

      {/* CONFIGURATIONS */}
      <>
        <Route
          path='/app-configuration'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.client_category)}
              element={<AppConfig />}
            />
          }
        />
        <Route
          path='/app-configuration/signature'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.client_category)}
              element={<Employee_Signature />}
            />
          }
        />

        <Route
          path='/profile-setting'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.configuration_module)}
              element={<Profile />}
            />
          }
        />

        <Route
          path='/clients/categories'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.client_category)}
              element={
                <ClientCategory permission={permission?.client_category} />
              }
            />
          }
        />
        <Route
          path='/airports'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.airports)}
              element={<Airport permission={permission?.airports} />}
            />
          }
        />
        <Route
          path='/companies'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.companies)}
              element={<Companies permission={permission?.companies} />}
            />
          }
        />
        <Route
          path='/products'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.products)}
              element={<AddProduct permission={permission?.products} />}
            />
          }
        />
        <Route
          path='/visa/type/new'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.visa_type)}
              element={<VisaType permission={permission?.visa_type} />}
            />
          }
        />
        <Route
          path='/departments'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.departments)}
              element={<Departments permission={permission?.departments} />}
            />
          }
        />
        <Route
          path='/roomtypes'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.room_types)}
              element={<RoomTypes permission={permission?.room_types} />}
            />
          }
        />
        <Route
          path='/transporttypes'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.room_types)}
              element={<TransportType permission={permission?.room_types} />}
            />
          }
        />
        <Route
          path='/designation/all_designation'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.designations)}
              element={<Designation permission={permission?.designations} />}
            />
          }
        />
        <Route
          path='/employee'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.employee)}
              element={<Employee permission={permission?.employee} />}
            />
          }
        />
        <Route
          path='/head'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.expense_head)}
              element={<ExpenseHead permission={permission?.expense_head} />}
            />
          }
        />
      </>

      {/* TOURS ITINERARY */}
      <>
        <Route
          path='/tour/groups'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={<TourGroup permission={permission?.tour_itinerary} />}
            />
          }
        />
        <Route
          path='/tour/ticket'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <TourTicket
                  queryType='ticket'
                  permission={permission?.tour_itinerary}
                />
              }
            />
          }
        />
        <Route
          path='/tour/guide'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <TourGuide
                  queryType='guide'
                  permission={permission?.tour_itinerary}
                />
              }
            />
          }
        />
        <Route
          path='/tour/transport'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <TourTransport
                  queryType='transport'
                  permission={permission?.tour_itinerary}
                />
              }
            />
          }
        />
        <Route
          path='/tour/foods'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <TourFood
                  queryType='food'
                  permission={permission?.tour_itinerary}
                />
              }
            />
          }
        />
        <Route
          path='/tour/accommodation'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <Accommodations permission={permission?.tour_itinerary} />
              }
            />
          }
        />
        <Route
          path='/tour/cities'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={<Cities permission={permission?.tour_itinerary} />}
            />
          }
        />
        <Route
          path='/tour/places'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={<Places permission={permission?.tour_itinerary} />}
            />
          }
        />
        <Route
          path='/tour/other-transport'
          element={
            <PermissionRoute
              permission={getListPermission(permission?.tour_itinerary)}
              element={
                <TourOtherTransport
                  queryType='other-transport'
                  permission={permission?.tour_itinerary}
                />
              }
            />
          }
        />
      </>
    </>
  </>
);
