import { ConfigProvider, Form, Table, theme, Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import dayjs from 'dayjs';
import {
  IDateWiseExpenseDataType,
  IDateWiseExpenseFormDataType,
} from '../types/dateWiseExpenseReportType';
import { DateWiseExpenseColumn } from '../utils/DateWiseExpenseColumn';
import { useLazyGetDateWiseExpenseReportQuery } from '../endpoints/DateWiseExpenseReportEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';

const DateWiseExpenseReport = () => {
  const [form] = Form.useForm();

  let totalExpense: number = 0;
  const [fetchDateWiseExpense, { data, isLoading }] =
    useLazyGetDateWiseExpenseReportQuery({
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const dataToReturn: IDateWiseExpenseDataType[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            totalExpense += Number(element.expense_total_amount);
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return {
          ...cache,
          data: dataToReturn,
        };
      },
    });

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  const onFinish = async (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date="${setFrom_date}"&to_date="${setTo_date}"`;
    const body: IDateWiseExpenseFormDataType = {
      date_range: date_range,
    };
    await fetchDateWiseExpense(body);
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Date Wise Expense Report']} />
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
      >
        <ReportsHeader
          required
          date_range
          reports_title='Date Wise Expense Report'
        />
      </Form>

      {
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <Table
            size='small'
            bordered
            dataSource={data}
            columns={DateWiseExpenseColumn()}
            loading={{ spinning: isLoading, indicator: loadingIndicator }}
            summary={(_) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <Typography.Text strong>{totalExpense}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </ConfigProvider>
      }
    </>
  );
};

export default DateWiseExpenseReport;
