import { Route } from 'react-router';
import PermissionRoute from '../../auth/components/PermissionRoute';
import { moduleType } from '../../auth/states/userSlice';
import { crud, rolePermissionType } from '../../common/types/commonTypes';
import { getListPermission } from '../../common/utils/common.utils';
import VendorDetails from '../../modules/vendor/component/Tabs/VendorDetails';
import EditVendor from '../../modules/vendor/components/EditVendor';
import AddVendor from '../../modules/vendor/pages/AddVendor';
import AddVendorPayment from '../../modules/vendor/pages/AddVendorPayment';
import AdvanceReturn from '../../modules/vendor/pages/AdvanceReturn';
import AdvanceReturnAdd from '../../modules/vendor/pages/AdvanceReturnAdd';
import AdvanceReturnEdit from '../../modules/vendor/pages/AdvanceReturnEdit';
import AdvanceReturnView from '../../modules/vendor/pages/AdvanceReturnView';
import ListOfVendPayment from '../../modules/vendor/pages/ListOfVendPayment';
import ListOfVendors from '../../modules/vendor/pages/ListOfVendors';
import VendorPaymentView from '../../modules/vendor/pages/VendorPaymentView';
import PurchasedReport from '../../modules/Dashboard/report/PurchasedReport';

// DASHBOARD
export const VendorRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => (
  <>
    {/* VENDORS */}
    <>
      <Route
        path='/vendors/add'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendors?.[crud.create]
            }
            element={<AddVendor />}
          />
        }
      />
      <Route
        path='/vendors/edit/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendors?.[crud.update]
            }
            element={<EditVendor />}
          />
        }
      />
      <Route
        path='/vendors/payment'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              getListPermission(permission?.vendors_payment)
            }
            element={
              <ListOfVendPayment permission={permission?.vendors_payment} />
            }
          />
        }
      />

      <Route
        path='/vendor/payment-report'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              getListPermission(permission?.vendors_payment)
            }
            element={
              <PurchasedReport permission={permission?.vendors_payment} />
            }
          />
        }
      />
      <Route
        path='/vendors/payment/:id'
        element={
          <PermissionRoute
            permission={getListPermission(permission?.vendors_payment)}
            element={
              <VendorPaymentView permission={permission?.vendors_payment} />
            }
          />
        }
      />

      <Route
        path='/vendors/payment/add'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              permission?.vendors_payment?.[crud.create]
            }
            element={<AddVendorPayment />}
          />
        }
      />

      <Route
        path='/vendors/payment/add/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              permission?.vendors_payment?.[crud.create]
            }
            element={<AddVendorPayment />}
          />
        }
      />

      <Route
        path='/vendors'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && getListPermission(permission?.vendors)
            }
            element={<ListOfVendors permission={permission?.vendors} />}
          />
        }
      />
      <Route
        path='/vendors/advance'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              getListPermission(permission?.vendor_advr)
            }
            element={<AdvanceReturn permission={permission?.vendor_advr} />}
          />
        }
      />
      <Route
        path='/vendors/advance/add'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendor_advr?.[crud.create]
            }
            element={<AdvanceReturnAdd />}
          />
        }
      />
      <Route
        path='/vendors/advance/edit/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendor_advr?.[crud.update]
            }
            element={<AdvanceReturnEdit />}
          />
        }
      />
      <Route
        path='/vendors/advance/view/:id'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendor_advr?.[crud.read]
            }
            element={<AdvanceReturnView />}
          />
        }
      />
      <Route
        path='/vendors/payment/edit/:vpay_id'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') &&
              permission?.vendors_payment?.[crud.update]
            }
            element={<AddVendorPayment />}
          />
        }
      />
      <Route path='/vendors/logs/:id' />
      <Route
        path='/vendors/details'
        element={
          <PermissionRoute
            permission={
              hasPermission('vendors') && permission?.vendors?.[crud.read]
            }
            element={<VendorDetails />}
          />
        }
      />
    </>
  </>
);
