import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from '../../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../../common/types/commonTypes';
import InvoiceListHeader from '../../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteInvoiceHajjMutation,
  useGetAllInvoiceHajjQuery,
} from '../../api/endpoints/invoicehajjEndpoints';

type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const InvoiceHajjLists = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  // constant
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [count, setCount] = useState<number>(0);
  const [isTrash, setIsTrash] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // LINKS
  const createLink = '/invoicehajj/add';
  const viewLink = '/invoicehajj/details';
  const editLink = '/invoicehajj/edit';

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const [query, setQuery] = useState<string>('');
  const {
    data,
    isFetching,
    isLoading: isLoadingData,
    refetch: getAllInvoices,
  } = useGetAllInvoiceHajjQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );
  const allData = data?.data;

  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteInvoiceHajjMutation();

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      query: !isTrash ? 'delete' : 'restore',
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success(
        !isTrash
          ? 'Invoice Deleted Successfully!'
          : 'Invoice Restored Successfully!'
      );
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isLoading, isError]);

  useEffect(() => {
    setDataSource(allData);
    setCount(data?.count || 0);
  }, [isFetching, isTrash, allData]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice', 'Hajji']} />

      <InvoiceListHeader
        InvocieModalTitle={'InvoiceHajj'}
        data={data?.data}
        setIsTrash={setIsTrash}
        createLink={createLink}
        editLink={editLink}
        handleOnDelete={handleOnDelete}
        isTrash={isTrash}
        permission={permission}
        viewLink={viewLink}
        setDataSource={setDataSource}
        dataSource={dataSource}
        isLoading={isLoadingData || isFetching}
        deleteLoading={isLoading}
        addMRPermission={addMRPermission}
        refetch={refetch}
        setCount={setCount}
        count={count}
        setQuery={setQuery}
        setDate={setDate}
        setSearch={setSearch}
        columnIndex={columnIndex}
      />
    </>
  );
};

export default InvoiceHajjLists;
